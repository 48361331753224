import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap'

import UserTable from './UsersTable'
import AddUserForm from './AddUserForm'

import caspianApiService from '../../services/CaspianApiService'
import utils from '../../utils'

var usersData = [
  {
    'activated': true,
    'authorities': [
      'string'
    ],
    'clientName': 'string',
    'companyRole': 'string',
    'createdBy': 'string',
    'createdDate': '2018-05-21T10:01:42.444Z',
    'email': 'string',
    'firstName': 'string',
    'id': 20,
    'imageUrl': 'string',
    'langKey': 'string',
    'lastModifiedBy': 'string',
    'lastModifiedDate': '2018-05-21T10:01:42.444Z',
    'lastName': 'string',
    'login': 'string',
    'resetPasswordLink': 'string',
    'sendActivationEMail': true,
    'status': 'string',
    'twoFactorAuthKey': 'string',
    'twoFactorAuthTemporaryKey': 'string'
  },
  {
    'activated': true,
    'authorities': [
      'string'
    ],
    'clientName': 'string',
    'companyRole': 'string',
    'createdBy': 'string',
    'createdDate': '2018-05-21T10:01:42.444Z',
    'email': 'string',
    'firstName': 'string',
    'id': 10,
    'imageUrl': 'string',
    'langKey': 'string',
    'lastModifiedBy': 'string',
    'lastModifiedDate': '2018-05-21T10:01:42.444Z',
    'lastName': 'string',
    'login': 'string',
    'resetPasswordLink': 'string',
    'sendActivationEMail': true,
    'status': 'string',
    'twoFactorAuthKey': 'string',
    'twoFactorAuthTemporaryKey': 'string'
  }
]

class UserTableCard extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      usersList: [],
      newUser: null,
      loading: false,
      error: '',
      clientName: this.props.clientName,
      oldClientName: this.props.clientName
    }

    this.handleOnLoading = this.handleOnLoading.bind(this);
  }

  componentDidMount () {
    if (this.props.clientName !== 'View As'){
      this.setState({loading: true});
      caspianApiService.getUsersForAClient(this.props.clientName)
          .then((usersList) => {
            usersList.forEach((val, idx) => val.idx = idx)
            this.setState({
              usersList: usersList,
              clientName: this.props.clientName,
              oldClientName: this.state.clientName,
              error: ''
            })
          }).finally(() => this.setState({loading: false}));
    }
    this.handleOnLoading(this.state.loading);
  }

  componentWillReceiveProps (nextProps) {
    if ((utils.isUserAdmin() || utils.isUserAccountManager()) && nextProps.clientName !== this.state.clientName && nextProps.clientName !== 'View As') {
      this.setState({clientName: nextProps.clientName, loading: true});
      caspianApiService.getUsersForAClient(nextProps.clientName)
        .then((usersList) => {
          usersList.forEach((val, idx) => val.idx = idx)
          this.setState({
            usersList: usersList,
            clientName: nextProps.clientName,
            oldClientName: this.state.clientName,
            error: ''
          })
        }).finally(() => this.setState({loading: false}));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.loading !== this.state.loading) {
      this.handleOnLoading(this.state.loading);
    }
  }

  handleOnLoading = (isLoading) => {
    //notify parent that loading is finished
    if(false === isLoading) {
      this.props.handleOnLoading('UsersTableCard', isLoading);
    }
  };

  save() {
     return caspianApiService.updateUsers(this.state.usersList, this.props.clientName).then(() => {
       this.componentDidMount();
     });
  }

  handleAddUser = (user) => {
    user.idx = this.state.usersList.length
    usersData = this.state.usersList
    usersData.push(user)
    this.setState({userList: usersData})
  }

  handleRemoveRow = (rowIdx) => {
    usersData = this.state.usersList
    usersData.splice(rowIdx, 1)
    this.setState({userList: usersData})
  }

  refreshData  = (clientName) => {
    this.setState({loading: true});
    caspianApiService.getUsersForAClient(clientName)
      .then((usersList) => {
        usersList.forEach((val, idx) => val.idx = idx)
        this.setState({usersList: usersList})
      }).finally(() => this.setState({loading: false}));
  };

  render () {
    return (
      <div>
        <Card className="card-stats card-raised" style={{display: this.props.visible ? 'block' : 'none'}}>
          <CardHeader>
            {/* <Alert color="warning">Warning! This panel will become available when all the tickets from the initial setup are procesed.</Alert> */}
            <h5>Users</h5>
          </CardHeader>
          <CardBody>
            <UserTable
              handleRemoveRow={this.handleRemoveRow}
              refreshData = {this.refreshData}
              readOnly={this.props.readOnly}
              data={this.state.usersList}
              clientName={this.state.clientName}
              kycStatus={this.props.kycStatus}
            />
          </CardBody>
          <CardFooter>
            <AddUserForm
              readOnly={this.props.readOnly}
              addUserCallback={this.handleAddUser}
              data={this.state.usersList}
              clientName={this.state.clientName}
            />
          </CardFooter>
        </Card>
      </div>
    )
  }
}

UserTableCard.propTypes = {
  clientName: PropTypes.string,
  visible: PropTypes.bool,
  readOnly: PropTypes.bool
}

export default UserTableCard
