import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Alert, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import {FormInputs} from '../../components';

import utilsValidation from '../../utils-validations';
import BootstrapTable from "react-bootstrap-table-next";
import caspianApiService from '../../services/CaspianApiService'
import Loadable from 'react-loading-overlay'

class AddUserForm extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            userData: [],
            firstName: '',
            lastName: '',
            login: '',
            email: '',
            phone: '',
            timezone:'',
            loading: false,
            error: '',
            oemsPrivileges: '',
            complianceAdmin:'',
            oemsAdmin:'',
            pmsPrivileges:'',
            pmsAdmin:'',
            showBillingAgreementPopup: false,
            isBillingAgreementLoading: false,
            clientName: this.props.clientName
        };
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    };

    handleChangeSelect = (name) => event => {
        this.setState({[name] : event})
    };

    componentWillReceiveProps (nextProps) {
        if (nextProps.data) {
          if (nextProps.clientName !== 'View As') {
            caspianApiService.getClientProductsUsed(nextProps.clientName)
              .then((productsUsed) => {
                this.setState({
                  userData: nextProps.data,
                  clientName: nextProps.clientName,
                  productsUsed: productsUsed,
                  error: ''
                })
              });
          } else {
            this.setState({
              userData: nextProps.data,
              clientName: nextProps.clientName,
              error: ''
            })
          }
        }
    }

    validateFields = (canViewOEMS, canViewPMS) => {
      this.setState({error: null})

      if (!utilsValidation.isValidEmail(this.state.email)) {
        this.setState({
          error: 'Please input the user\'s email!',
          showBillingAgreementPopup: false
        });

        return;
      }

      if (!this.state.firstName || !this.state.lastName) {
        this.setState({
          error: 'Please input the user\'s name!',
          showBillingAgreementPopup: false
        });

        return;
      }

      if (!utilsValidation.isValidPhoneNumber(this.state.phone)) {
        this.setState({
          error: 'Invalid phone number! Use only + and 0-9 digits',
          showBillingAgreementPopup: false
        });

        return;
      }

      if (!this.state.timezone) {
        this.setState({
          error: 'Please select a timezone',
          showBillingAgreementPopup: false
        });

        return;
      }

      if (canViewOEMS) {
        if (!this.state.oemsPrivileges) {
          this.setState({
            error: 'Please select OEMS Privileges',
            showBillingAgreementPopup: false
          });

          return;
        }

        if (!this.state.complianceAdmin) {
          this.setState({
            error: 'Please select Compliance Admin level',
            showBillingAgreementPopup: false
          });

          return;
        }

        if (!this.state.oemsAdmin) {
          this.setState({
            error: 'Please select OEMS Admin level',
            showBillingAgreementPopup: false
          });

          return;
        }
      }

      if (canViewPMS) {
        if (!this.state.pmsPrivileges) {
          this.setState({
            error: 'Please select PMS Privileges level',
            showBillingAgreementPopup: false
          });

          return;
        }

        if (!this.state.pmsAdmin) {
          this.setState({
            error: 'Please select PMS Admin level',
            showBillingAgreementPopup: false
          });

          return;
        }
      }

      this.toggleAddUserPopup()
    }

    handleAddUser = () => {
      this.setState({isBillingAgreementLoading: true})
      caspianApiService.getGeneratedUsername(this.state.firstName, this.state.lastName)
        .then(username => {
          this.props.addUserCallback({
            id: null,
            status: 'Pending Creation',
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            login: username,
            phone: this.state.phone,
            email: this.state.email,
            timezone: this.state.timezone.value,
            oemsPrivileges: this.state.oemsPrivileges.value,
            complianceAdmin: this.state.complianceAdmin.value,
            oemsAdmin: this.state.oemsAdmin.value,
            pmsPrivileges: this.state.pmsPrivileges.value,
            pmsAdmin: this.state.pmsAdmin.value,
            lastModifiedDate: new Date(),
          })

          this.setState({
            loading: false,
            firstName: "",
            lastName: "",
            login: "",
            phone: "",
            email: "",
            timezone: "",
            oemsPrivileges: "",
            complianceAdmin:"",
            oemsAdmin:"",
            pmsPrivileges:"",
            pmsAdmin:"",

          })
        })
        .catch((error) => {
          this.setState({
            error: error.errorMessage,
          })
        })
        .finally(() => {
          this.setState({
              showBillingAgreementPopup: false,
              isBillingAgreementLoading: false
          })
        })


    }

    toggleAddUserPopup = () => {
        this.setState({showBillingAgreementPopup: !this.state.showBillingAgreementPopup})
    }

    renderError() {
        return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
    }

    render() {
        let canViewOEMS;
        let canViewPMS;

        if (this.state.productsUsed) {
          canViewOEMS = this.state.productsUsed.includes('OEMS');
          canViewPMS = this.state.productsUsed.includes('PMS');
        }

        const oemsPrivilegeOptions = [{value: 'Trading', label: 'Trading'}, {value: 'Read-Only', label: 'Read-Only'}, {value: 'None', label: 'None'}];
        const pmsPrivilegeOptions =  [{value: 'Back-Office', label: 'Back-Office'}, {value: 'Front-Office', label: 'Front-Office'}, {value: 'Read-Only', label: 'Read-Only'}, {value: 'None', label: 'None'}];
        const boolOptions = [{value: 'true', label: 'Yes'}, {value: 'false', label: 'No'}];
        const timezoneOptions = [{value: 'Europe', label: 'Europe'}, {value: 'Americas', label: 'Americas'}, {value: 'Asia', label: 'Asia'}, {value: '24h', label: '24h'}];
        const oemsTable = [
            {
                dataField: 'privilege',
                text: 'OEMS Privilege',
                headerClasses:'w-10 static-table-header',
                classes: 'static-table-row'
            },
            {
                dataField: 'description',
                text: 'Description',
                headerClasses:'w-25 static-table-header',
                classes: 'static-table-description-row'
            }];
        var oemsData = [
            {"privilege": "Trading", "description": "Can register and send orders"},
            {"privilege": "Read-Only", "description": "View only"},
            {"privilege": "Compl. Admin", "description": "Can edit pre-trade compliance rules"},
            {"privilege": "Admin", "description": "Needs to approve any changes to prod envs (eg: new exchanges, users, etc)"}
        ];

        const pmsTable = [
            {
                dataField: 'privilege',
                text: 'PMS Privilege',
                headerClasses:'w-10 static-table-header',
                classes: 'static-table-row'
            },
            {
                dataField: 'description',
                text: 'Description',
                headerClasses:'w-25 static-table-header',
                classes: 'static-table-description-row'
            }];
        var pmsData = [
            {"privilege": "Front Office", "description": "Can only book unconfirmed trades"},
            {"privilege": "Back Office", "description": "Confirm, book, void trades, edit/upload marks."},
            {"privilege": "Read-Only", "description": "View only"},
            {"privilege": "Admin", "description": "Needs to approve any changes to prod envs (eg: new exchanges, users, etc)"}
        ];

        let formInputs = {
          "firstName": {
            inputProps: {
              type: "text",
              name: "firstName",
              className: "dark",
              value: this.state.firstName,
              disabled: this.props.readOnly,
              placeholder: "First name",
              onChange: this.handleChange
            }
          },
          "lastName": {
            inputProps: {
              type: "text",
              name: "lastName",
              className: "dark",
              value: this.state.lastName,
              disabled: this.props.readOnly,
              placeholder: "Last name",
              onChange: this.handleChange
            }
          },
          "phone": {
            inputProps: {
              type: "text",
              name: "phone",
              className: "dark",
              value: this.state.phone,
              disabled: this.props.readOnly,
              placeholder: "Phone",
              onChange: this.handleChange
            }
          },
          "email": {
            inputProps: {
              type: "email",
              name: "email",
              className: "dark",
              value: this.state.email,
              disabled: this.props.readOnly,
              placeholder: "Email",
              onChange: this.handleChange
            }
          },
          "timezone": {
            inputProps: {
              type: "select",
              name: "timezone",
              className: "dark",
              options: timezoneOptions,
              value: this.state.timezone,
              disabled: this.props.readOnly,
              placeholder: "Timezone",
              onChange: this.handleChangeSelect('timezone')
            }
          },
          "oemsPrivileges": {
            inputProps: {
              type: "select",
              name: "oemsPrivileges",
              options: oemsPrivilegeOptions,
              className: "dark",
              value: this.state.oemsPrivileges,
              disabled: this.props.readOnly,
              placeholder: "OEMS Privileges",
              onChange: this.handleChangeSelect('oemsPrivileges')
            }
          },
          "complianceAdmin": {
            inputProps: {
              type: "select",
              name: "complianceAdmin",
              options: boolOptions,
              className: "dark",
              value: this.state.complianceAdmin,
              disabled: this.props.readOnly,
              placeholder: "Compliance Admin",
              onChange: this.handleChangeSelect('complianceAdmin')
            }
          },
          "oemsAdmin": {
            inputProps: {
              type: "select",
              name: "oemsAdmin",
              className: "dark",
              options: boolOptions,
              value: this.state.oemsAdmin,
              disabled: this.props.readOnly,
              placeholder: "OEMS Admin",
              onChange: this.handleChangeSelect('oemsAdmin'),
              hidden: !canViewOEMS
            }
          },
          "pmsPrivileges": {
            inputProps: {
              type: "select",
              name: "pmsPrivileges",
              options: pmsPrivilegeOptions,
              value: this.state.pmsPrivileges,
              disabled: this.props.readOnly,
              placeholder: "PMS privileges",
              onChange: this.handleChangeSelect('pmsPrivileges')
            }
          },
          "pmsAdmin": {
            inputProps: {
              type: "select",
              name: "pmsAdmin",
              options: boolOptions,
              value: this.state.pmsAdmin,
              disabled: this.props.readOnly,
              placeholder: "PMS Admin",
              onChange: this.handleChangeSelect('pmsAdmin')
            }
          }
        }

        let allFormInputs = [formInputs["firstName"], formInputs["lastName"], formInputs["phone"], formInputs["email"],
          formInputs["timezone"], formInputs["oemsPrivileges"], formInputs["complianceAdmin"], formInputs["oemsAdmin"],
          formInputs["pmsPrivileges"], formInputs["pmsAdmin"]];
        let oemsFormInputs = [formInputs["firstName"], formInputs["lastName"], formInputs["phone"], formInputs["email"],
          formInputs["timezone"], formInputs["oemsPrivileges"], formInputs["complianceAdmin"], formInputs["oemsAdmin"]]
        let pmsFormInputs = [formInputs["firstName"], formInputs["lastName"], formInputs["phone"], formInputs["email"],
          formInputs["timezone"], formInputs["pmsPrivileges"], formInputs["pmsAdmin"]]

        return (
        <div>
            <Modal isOpen={this.state.showBillingAgreementPopup} toggle={this.toggleAddUserPopup} contentClassName="billing-popup">
              <Loadable active={this.state.isBillingAgreementLoading} spinner text="Please stand by ...">
                <ModalHeader toggle={this.toggle} className="text-center">Billing Agreement</ModalHeader>
                <ModalBody>
                  <i>I request to add User, {this.state.firstName} {this.state.lastName}, on the Caspian System and
                    acknowledge that in so doing, Caspian will begin billing in accordance with the Fee Schedule set out
                    in Section III of the Caspian System User Agreement upon the date User is added</i>
                  <br/>
                  <br/>
                  <div className="text-center confirm">Are you sure?</div>
                </ModalBody>
                <ModalFooter className="text-center popup-footer">
                  <Button color="primary" onClick={this.handleAddUser}>Accept</Button>{'   '}
                  <Button color="danger" onClick={this.toggleAddUserPopup}>Cancel</Button>
                </ModalFooter>
              </Loadable>
            </Modal>
            <Form>
                <h6>Request Additional Users</h6>
                <Row className="addUser">
                    <Col xs={11} md={11}>
                        {this.renderError()}
                        { canViewOEMS && canViewPMS &&
                          <FormInputs
                            ncols={Array(allFormInputs.length).fill("col-md-12-dashboard")}
                            proprieties={allFormInputs}/>
                        }
                        { canViewOEMS && !canViewPMS &&
                          <FormInputs
                            ncols={Array(oemsFormInputs.length).fill("col-md-12-dashboard")}
                            proprieties={oemsFormInputs}/>
                        }
                        { !canViewOEMS && canViewPMS &&
                          <FormInputs
                            ncols={Array(pmsFormInputs.length).fill("col-md-12-dashboard")}
                            proprieties={pmsFormInputs}/>
                        }
                    </Col>
                    <Col>
                        <Button disabled={this.props.readOnly} className="btn btn-primary btn-sm add-user-button"
                                onClick={() => this.validateFields(canViewOEMS, canViewPMS)}>
                            Add User
                        </Button>
                    </Col>
                </Row>
            </Form>
            <br/>

            <Row>
                <Col className={canViewOEMS ? "" : "display-none"}>
                    <BootstrapTable
                        condensed
                        keyField='privilege'
                        rowStyle={{ fontSize: '10px' }}
                        classes="static-table-responsive"
                        bordered={ true }
                        data={oemsData}
                        columns= {oemsTable}
                    />
                </Col>

              <Col className={canViewPMS ? "" : "display-none"}>
                    <BootstrapTable
                        bor
                        condensed
                        bordered={ true }
                        keyField='privilege'
                        rowStyle={{ fontSize: '10px' }}
                        classes="static-table-responsive"
                        data={pmsData}
                        columns= {pmsTable}
                    />
                </Col>
            </Row>
        </div>
        )

    }
}

AddUserForm.propTypes = {
    addUserCallback: PropTypes.func,
    readOnly: PropTypes.bool
};

export default AddUserForm;
