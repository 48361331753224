import React from 'react'
import PropTypes from 'prop-types'
import {Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'

import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import utils from '../../utils'
import Loader from '../../components/Loader/Loader'
import caspianApiService from '../../services/CaspianApiService'
import caspianConfig from '../../config'
import Loadable from 'react-loading-overlay'

const firstNameFormater = (cell, row) => {
  let activeClass;
  if (row.activated) {
    activeClass = 'active'
  }

  return (
    <div className="td-editable-content">
      <span className={'bullet ' + activeClass }></span>
      <span>{ cell }</span>
      {
        (row.isEditable)
          ? <i className="cas-icon cas-edit-75"></i>
          : ''
      }
    </div>
  )
};

const booleanFormatter = (cell, row) => {
  if (cell === false || cell === 'false') {
    return (
      <span style={ { color: 'red' } }>No</span>
    )
  }

  return (
    <span style={ { color: 'green' } }>Yes</span>
  )
};


class UserTable extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      userData: [],
      clientName: this.props.clientName,
      editableUserIndex: null,
      editableUserOldValue: null,
      editableUser: null,
      loading: false,
      error: '',
      showOemsPopUp: false,
      toggledUserName: null,
      toggledUser:null,
      showPmsPopUp: false,
      isPmsLoading: false,
      isOemsLoading: false,
      showDeleteUserPopUp: false,
      showDeleteUserSetup: false,
      deleteUserSystems: null,
      isDeleteLoading: false,
      disabledPMS: false,
      disabledOEMS: false,
    };

    this.actionsFormatter = this.actionsFormatter.bind(this);
    this.afterSavecell = this.afterSavecell.bind(this);
    this.afterSave = this.afterSave.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.toggleOems = this.toggleOems.bind(this);
    this.togglePms = this.togglePms.bind(this);
  }

  handleRemoveRow = (rowIdx) => {
    this.props.handleRemoveRow(rowIdx)
  };

  componentWillMount () {
    if (this.props.clientName !== 'View As'){
      this.setState({clientName: this.props.clientName});
      caspianApiService.getUsersForAClient(this.props.clientName)
        .then((userList) => {
          caspianApiService.getClientProductsUsed(this.props.clientName)
            .then((productsUsed) => {
              this.setState({
                userData: userList,
                clientName: this.props.clientName,
                productsUsed: productsUsed,
                error: ''
              })
            })
        })
    }
  };

  componentWillReceiveProps (nextProps) {
    if (nextProps.data) {
      if (nextProps.clientName !== this.state.clientName && nextProps.clientName !== 'View As') {
        caspianApiService.getClientProductsUsed(nextProps.clientName)
          .then((productsUsed) => {
            this.setState({
              userData: nextProps.data,
              clientName: nextProps.clientName,
              productsUsed: productsUsed,
              error: ''
            })
          });
      } else {
        this.setState({userData: nextProps.data})
      }
    }

    this.setState({
      clientName: nextProps.clientName,
      error: ''
    })
  }

  actionsFormatter (cell, row, rowIndex) {
    let canViewOEMS;
    let canViewPMS;
    let disabled = this.props.readOnly

    if (cell === utils.getUserData().id) {
        disabled = true
    }

    if (this.state.productsUsed) {
      canViewOEMS = this.state.productsUsed.includes('OEMS');
      canViewPMS = this.state.productsUsed.includes('PMS');
    }

    if (utils.isUserAdmin() && !utils.isUserAdminReadOnly() && caspianConfig.ENABLE_AUTOMATIC_CREATION_OF_USER_IN_PMS_AND_OMS) {
      let setupCompleted = row.setupCompleted;
      let userActivated = row.activated;
      let clientStatusOK = this.props.kycStatus === 'APPLICATION_APPROVED' || this.props.kycStatus === 'ONBOARDING_COMPLETED';

      let usernameIsNull = utils.isNullOrEmpty(row.login);
      let noOEMSUser = !row.oemsPrivileges || row.oemsPrivileges === 'None';
      let noPMSUser = !row.pmsPrivileges || row.pmsPrivileges ==='None';

      let oemsUserSetupTicket = utils.getTicketByType(row.tickets, "OEMS_USER_SETUP")
      let oemsDisableUserTicket = utils.getTicketByType(row.tickets, "OEMS_DISABLE_USER")
      
      let oemsDisabled = oemsUserSetupTicket != null || setupCompleted || !clientStatusOK || usernameIsNull || noOEMSUser;

      let oemsSetupNotDone = (oemsUserSetupTicket == null || oemsUserSetupTicket.status !== 'RESOLVED')
      let pmsDisabled = row.pmsSetup || ((oemsSetupNotDone && !noOEMSUser) && canViewOEMS) || setupCompleted || !clientStatusOK || usernameIsNull || noPMSUser;
      let oemsId = row.email.replace(/\./g,'').replace(/\@/g,'')+"oems";
      let pmsId = row.email.replace(/\./g,'').replace(/\@/g,'')+"pms";


      let oemsCompleted= ( (oemsUserSetupTicket != null && oemsUserSetupTicket.status === "RESOLVED") || (setupCompleted && oemsUserSetupTicket==null) ) && (row.oemsPrivileges !== null && row.oemsPrivileges !== 'None');
      let pmsCompleted = row.pmsSetup;

      let oemsPending = oemsUserSetupTicket != null && oemsUserSetupTicket.status !== "RESOLVED"
      let oemsTicketSetup = oemsPending && oemsUserSetupTicket
      let oemsTicketDelete = oemsPending && oemsDisableUserTicket



      var oemsButtonClass = "btn btn-primary btn-sm";
      if (oemsCompleted) {
        oemsButtonClass = "btn btn-success btn-sm";
      }
      if (oemsPending){
        oemsDisabled = false;
        oemsButtonClass = "btn btn-warning btn-sm";
      }

      var pmsButtonClass = "btn btn-primary btn-sm";
      if (pmsCompleted) {
        pmsButtonClass = "btn btn-success btn-sm";
      }

    return (


      <div className="td-actions">
        <ButtonGroup>
          { canViewOEMS &&
            <span id={oemsId}>
              <Button disabled={oemsDisabled} className={oemsButtonClass} onClick={this.oemsSetup.bind(this, row)}>
                OEMS Setup
              </Button>
            </span>
          }
          { canViewOEMS && oemsCompleted && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={oemsId}>Completed</UncontrolledTooltip>
          }
          { canViewOEMS && oemsPending && oemsTicketSetup && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={oemsId}>Create OEMS User Ticket Pending. Click to view SF case.</UncontrolledTooltip>
          }
          { canViewOEMS && oemsPending && oemsTicketDelete && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={oemsId}>Disable OEMS User Ticket Pending. Click to view SF case.</UncontrolledTooltip>
          }
          { canViewOEMS && !clientStatusOK &&
          <UncontrolledTooltip placement="top" target={oemsId}>Client has to be in Application Approved or Onboarding Completed status</UncontrolledTooltip>
          }
          { canViewOEMS && clientStatusOK && !oemsCompleted && !oemsPending && usernameIsNull &&
          <UncontrolledTooltip placement="top" target={oemsId}>User has no username</UncontrolledTooltip>
          }
          { canViewOEMS && clientStatusOK && !oemsCompleted && !oemsPending && !usernameIsNull && noOEMSUser &&
          <UncontrolledTooltip placement="top" target={oemsId}>OEMS Privileges: None</UncontrolledTooltip>
          }
          &nbsp;
          { canViewPMS &&
            <span id={pmsId}>
              <Button disabled={pmsDisabled} className={pmsButtonClass} onClick={this.pmsSetup.bind(this, row)}>
                PMS Setup
              </Button>
            </span>
          }
          { canViewPMS && pmsCompleted && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={pmsId}>Completed</UncontrolledTooltip>
          }
          { canViewPMS && !clientStatusOK &&
          <UncontrolledTooltip placement="top" target={pmsId}>Client has to be in Application Approved or Onboarding Completed status</UncontrolledTooltip>
          }
          { canViewPMS && clientStatusOK && !oemsCompleted && !oemsPending && usernameIsNull &&
          <UncontrolledTooltip placement="top" target={pmsId}>User has no username</UncontrolledTooltip>
          }
          { canViewPMS && clientStatusOK && !pmsCompleted && !usernameIsNull && noPMSUser &&
          <UncontrolledTooltip placement="top" target={pmsId}>PMS Privileges: None</UncontrolledTooltip>
          }
          { canViewPMS && clientStatusOK && !pmsCompleted && !usernameIsNull && !noPMSUser && ((oemsSetupNotDone && !noOEMSUser)&& canViewOEMS) &&
            <UncontrolledTooltip placement="top" target={pmsId}>OEMS Setup is not completed</UncontrolledTooltip>
          }

          <Button disabled={( (!oemsCompleted || this.pendingOEMSDisable(row)) && !pmsCompleted) || !userActivated} className="btn btn-danger btn-icon btn-sm danger-button" onClick={this.deleteUser.bind(this, row)}>
            <i className="cas-icon cas-simple-remove"></i>
          </Button>
        </ButtonGroup>

      </div>
    )}
    else{
      return (
        <div className="td-actions">
          <Button disabled={disabled} className="btn btn-danger btn-icon btn-sm danger-button" onClick={this.handleRemoveRow.bind(this, rowIndex)}>
            <i className="cas-icon cas-simple-remove"></i>
          </Button>
        </div>)
    }
  }

  refreshData  = () => {
    this.props.refreshData(this.props.clientName)
  }

  callDeleteUserFromPMS  = () =>{
    this.setState({isDeleteLoading: true})
    caspianApiService.disablePMSUser(this.state.toggledUser, this.props.clientName)
        .then(() => {
          this.showNotification('PMS User deleted!', 'success')
          this.refreshData()
        })
        .catch(() => {
          this.showNotification('PMS user could not be deleted. Please try again later.', 'danger');
        })
        .finally(() => {
          this.toggleDeleteUserSetup()
          this.setState({isDeleteLoading: false})
        })

  }

  callDeleteUserFromOEMS = () => {
      this.setState({isDeleteLoading: true})
      caspianApiService.disableOEMSUser(this.state.toggledUser, this.props.clientName)
          .then(() => {
              this.showNotification('OEMS User Removal SF case created!', 'success')
              this.refreshData()
          })
          .catch(() => {
              this.showNotification('Ticket could not be created. Please try again later.', 'danger');
          })
          .finally(() => {
              this.toggleDeleteUserSetup()
              this.setState({isDeleteLoading: false})
          })
  }

  callDeleteUserFromOEMSandPMS = () => {
      this.setState({isDeleteLoading: true})
      caspianApiService.disablePMSUser(this.state.toggledUser, this.props.clientName)
          .then(() =>{
              this.showNotification('PMS User deleted!', 'success')
              caspianApiService.disableOEMSUser(this.state.toggledUser, this.props.clientName)
                  .then(() =>{
                      this.showNotification('OEMS User Removal SF case created!', 'success')
                      this.refreshData()
                  })
                  .catch(() => {
                      this.showNotification('OEMS Removal SF case could not be created. Please try again later.', 'danger');
                      this.refreshData()
                  })
                  .finally(() => {
                      this.toggleDeleteUserSetup()
                      this.setState({isDeleteLoading: false})
                  })
          })
          .catch(() => {
              this.showNotification('Could not disable users neither from OEMS or PMS!.', 'danger');
              this.toggleDeleteUserSetup()
              this.setState({isDeleteLoading: false})
          })
  }

  callDeleteUser = () =>{
    const system = this.state.deleteUserSetup;
    if(system === 'PMS'){
      this.callDeleteUserFromPMS();
    }
    else if(system === 'OEMS'){
      this.callDeleteUserFromOEMS()
    }
    else if(system === 'OEMS&PMS'){
      this.callDeleteUserFromOEMSandPMS()
    }
  }

  deleteUserFromOEMS = () =>{
    this.setState({deleteUserSetup: 'OEMS'})
    this.toggleDeleteUser()
    this.toggleDeleteUserSetup()
  }

  deleteUserFromPMS = () =>{
    this.setState({deleteUserSetup: 'PMS'})
    this.toggleDeleteUser()
    this.toggleDeleteUserSetup()
  }

  deleteUserFromPMSandOEMS = () =>{
    this.setState({deleteUserSetup: 'OEMS&PMS'})
    this.toggleDeleteUser()
    this.toggleDeleteUserSetup()
  }

  pendingOEMSDisable = (row) =>{
    return row.ticketType === 'OEMS_DISABLE_USER' && (row.ticketStatus === 'PENDING' || row.ticketStatus === 'NEW')
  }

  deleteUser = (row) => {
    this.setState({disabledPMS: !row.pmsSetup,
                         disabledOEMS: row.oemsPrivileges === null || row.oemsPrivileges === 'None' || this.pendingOEMSDisable(row)
    })
    this.setState({toggledUser: row})
    this.setState({toggledUserName: row.login})
    this.toggleDeleteUser()
  }

  toggleDeleteUserSetup = () =>{
    this.setState({showDeleteUserSetup: !this.state.showDeleteUserSetup})
  }

  toggleDeleteUser =() => {
    this.setState({showDeleteUserPopUp: !this.state.showDeleteUserPopUp})
  }

  getDeleteText = () =>{
    switch(this.state.deleteUserSetup) {
      case 'OEMS':
        return  <p>This will create SF Removal Case from OEMS for {this.state.toggledUserName}</p>
      case 'OEMS&PMS':
        return <p> - this will delete user {this.state.toggledUserName} from PMS and <br/>
        - this will create SF Removal Case from OEMS for {this.state.toggledUserName}</p>
      default:
        return <p> This will delete user {this.state.toggledUserName} from {this.state.deleteUserSetup}</p>
    }
  }

  toggleOems () {
    this.setState( {showOemsPopUp: !this.state.showOemsPopUp})
  }

  togglePms () {
    this.setState( {showPmsPopUp: !this.state.showPmsPopUp})
  }

  oemsSetup = (row) => {
    let oemsTicket = utils.getTicketByType(row.tickets, "OEMS_USER_SETUP");
    if (oemsTicket == null){
      oemsTicket = utils.getTicketByType(row.tickets, "OEMS_DISABLE_USER");
    }
    if (oemsTicket != null) {
      window.open("https://tora.my.salesforce.com/" + oemsTicket.salesforceId);
    } else {
      this.setState( {toggledUserName: row.login})
      this.setState( {toggledUser: row})
      this.toggleOems();
    }

  };

  pmsSetup = (row) => {
    this.setState( {toggledUserName: row.login})
    this.setState( {toggledUser: row})
    this.togglePms();
  };

  callOemsSetup = () => {
    this.setState({isOemsLoading: true})
    caspianApiService.oemsUserSetup(this.state.toggledUser, this.props.clientName)
      .then(res => {
        this.showNotification('OEMS user Setup SF case created!', 'success')
        this.refreshData()
      })
      .catch(err => {
        if(err.errorMessage.length > 0){
          this.showNotification(err.errorMessage, 'danger')
        }
        else{
          this.showNotification('Ticket could not be created. Please try again later.', 'danger');
        }
      })
      .finally(() => {
        this.toggleOems()
        this.setState({isOemsLoading: false})
      })
  }

  callPmsSetup = () => {
    this.setState({isPmsLoading: true})
    caspianApiService.pmsUserSetup(this.state.toggledUser, this.props.clientName)
      .then(res => {
        this.showNotification('User created in PMS env!', 'success')
        this.refreshData()
      })
      .catch(err => {
        this.showNotification(err.errorMessage,'danger');
      })
      .finally(() => {
        this.togglePms()
        this.setState({isPmsLoading: false})
      })
  }



    isEmailEditable(cell) {
        let editable = this.readOnlyParam;
        if (editable === false) {
            if (cell === utils.getUserData().email) {
                editable = false
            }
            else {
                editable = true
            }
        } else {
            editable = false
        }
        return editable
    }

  afterSave (row) {
    if (row.edited) {
      this.setState({...{editableUserIndex: row.id, editableUser: row}})
    }
  }

  afterSavecell (oldValue, newValue, row, column) {
    this.afterSave(row)
  }

  showNotification (message, type = 'primary') {
    // var color = Math.floor((Math.random() * 5) + 1);

    var options = {};
    options = {
      place: 'tc',
      message: (
        <div>
          <div>
            { message }
          </div>
        </div>
      ),
      type: type,
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options)
  }

  render () {
    let canViewOEMS;
    let canViewPMS;

    if (this.state.productsUsed) {
      canViewOEMS = this.state.productsUsed.includes('OEMS');
      canViewPMS = this.state.productsUsed.includes('PMS');
    }

    let head1 = "user-head1";
    let head2 = "user-head2";
    let head3 = "user-head3";
    let line1 = "line-user";
    let line2 = "line-oems";
    let line3 = "line-pms";
    if (utils.isUserAdmin() && caspianConfig.ENABLE_AUTOMATIC_CREATION_OF_USER_IN_PMS_AND_OMS) {
       head1 = "user-admin-head1";
       head2 = "user-admin-head2";
       head3 = "user-admin-head3";
       line1 = "line-admin-user";
       line2 = "line-admin-oems";
       line3 = "line-admin-pms";

      if (!canViewOEMS && canViewPMS) {
        line1 = "line-admin-user-long1"
        head3 = "user-admin-head3-with-margin"
      } else if (canViewOEMS && !canViewPMS) {
        line1 = "line-admin-user-long2"
        line2 = "line-admin-oems-long"
        head2 = "user-admin-head2-with-margin"
      }
    } else if (!canViewOEMS && canViewPMS) {
      line1 = "line-admin-user-long3"
      head3 = "user-head3-with-margin"
    } else if (canViewOEMS && !canViewPMS) {
      line1 = "line-user-long2"
      line2 = "line-oems-long"
      head2 = "user-head2-with-margin"
    }

    const oemsPrivilegeOptions = [{value: 'Trading', label: 'Trading'}, {value: 'Read-Only', label: 'Read-Only'}, {value: 'None', label: 'None'}];
    const pmsPrivilegeOptions = [{value: 'Back-Office', label: 'Back-Office'}, {value: 'Front-Office', label: 'Front-Office'}, {value: 'Read-Only', label: 'Read-Only'}, {value: 'None', label: 'None'}];
    const boolOptions = [{value: true, label: 'Yes'}, {value: false, label: 'No'}];
    const timezoneOptions = [{value: 'Europe', label: 'Europe'}, {value: 'Americas', label: 'Americas'}, {value: 'Asia', label: 'Asia'}, {value: '24h', label: '24h'}];
    const usersColumnsConfig = [
      {
        dataField: 'firstName',
        text: 'First Name',
        formatter: firstNameFormater,
        editable: !this.props.readOnly,
        headerClasses: 'w-10 user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'lastName',
        text: 'Last Name',
        editable: !this.props.readOnly,
        headerClasses: 'w-7 user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'phone',
        text: 'Phone',
        editable: !this.props.readOnly,
        headerClasses: 'w-7 user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'email',
        text: 'Email',
          readOnlyParam: this.props.readOnly,
          editable: this.isEmailEditable,
        headerClasses: 'w-10 user-table-header',
        classes: (utils.isUserAccountManager() && this.isEmailEditable === true) ||
                 (utils.isUserAdmin() && this.isEmailEditable) ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'timezone',
        text: 'Timezone',
        editable: !this.props.readOnly,
        editor: {
          type: Type.SELECT,
          options: timezoneOptions
        },
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'login',
        text: 'Username',
        editable: utils.isUserAdmin() && !utils.isUserAdminReadOnly(),
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'lastModifiedDate',
        text: 'Last Modified',
          formatter: (cell) => {
              let dateObj = cell;
              if (typeof cell !== 'object') {
                  dateObj = new Date(cell);
              }
              return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getMonth() + 1)).slice(-2)}/${dateObj.getFullYear()}`;
          },
        editable: false,
        headerClasses: 'w-5 user-table-header',
        classes: 'cursor-not-allowed'
      },
      {
        dataField: 'status',
        text: 'Portal Status',
        editable: false,
        headerClasses: 'user-table-header',
        classes: 'cursor-not-allowed'
      },
      {
        dataField: 'oemsPrivileges',
        text: 'Privileges',
        editable: !this.props.readOnly,
        editor: {
          type: Type.SELECT,
          options: oemsPrivilegeOptions
        },
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        hidden: !canViewOEMS
      },
      {
        dataField: 'complianceAdmin',
        text: 'Compl. Admin',
        editable: !this.props.readOnly,
        headerClasses: 'w-7 user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        editor: {
          type: Type.SELECT,
          options: boolOptions
        },
        formatter: booleanFormatter,
        hidden: !canViewOEMS
      },
      {
        dataField: 'oemsAdmin',
        text: 'Admin',
        editable: !this.props.readOnly,
        editor: {
          type: Type.SELECT,
          options: boolOptions
        },
        formatter: booleanFormatter,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        hidden: !canViewOEMS
      },
      {
        dataField: 'pmsPrivileges',
        text: 'Privileges',
        editable: !this.props.readOnly,
        editor: {
          type: Type.SELECT,
          options: pmsPrivilegeOptions
        },
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        hidden: !canViewPMS
      },
      {
        dataField: 'pmsAdmin',
        text: 'Admin',
        editable: !this.props.readOnly,
        editor: {
          type: Type.SELECT,
          options: boolOptions
        },
        formatter: booleanFormatter,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        hidden: !canViewPMS
      },
      {
        dataField: 'id',
        text: 'Actions',
        editable: false,
        formatter: this.actionsFormatter,
        headerClasses: utils.isUserAdmin() && caspianConfig.ENABLE_AUTOMATIC_CREATION_OF_USER_IN_PMS_AND_OMS ? 'user-table-header action-column' : 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      }
    ]

    return (
      <div className="dashboard-table-wrapper">

        <NotificationAlert ref="notify" />
        <Modal isOpen={this.state.showDeleteUserSetup} toggle={this.toggleDeleteUserSetup} className="center-loader">
          <Loadable active={this.state.isDeleteLoading} spinner text="Please stand by ...">
          <ModalHeader className="text-center">Delete User From {this.state.deleteUserSetup}</ModalHeader>
          <ModalBody>
            {this.getDeleteText()}
            <br/>
            <br/>
            <div className="text-center confirm">Are you sure?</div>
          </ModalBody>
          <ModalFooter className="text-center popup-footer">
            <Button color="primary" onClick={this.callDeleteUser}>Yes</Button>{'   '}
            <Button color="danger" onClick={this.toggleDeleteUserSetup}>No</Button>
          </ModalFooter>
          </Loadable>
        </Modal>
        <Modal isOpen={this.state.showDeleteUserPopUp} toggle={this.toggleDeleteUser} className="center-loader">
          <ModalHeader className="text-center">Delete User From OEMS or PMS</ModalHeader>
          <ModalBody>
            <div className="delete-user-buttons">
              <Button disabled={!canViewPMS || this.state.disabledPMS} id="button-pms-delete" color="danger" onClick={this.deleteUserFromPMS}>Delete User from PMS</Button>
              <Button disabled={!canViewOEMS || this.state.disabledOEMS} id="button-oems-delete" color="danger" onClick={this.deleteUserFromOEMS}>Delete User from OEMS</Button>
              <Button disabled={!canViewPMS || !canViewOEMS || this.state.disabledOEMS || this.state.disabledPMS} id="button-oems-pms-delete" color="danger" onClick={this.deleteUserFromPMSandOEMS}>Delete User from OEMS and PMS</Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showOemsPopUp} toggle={this.toggleOems} className="center-loader">
          <Loadable active={this.state.isOemsLoading} spinner text="Please stand by ...">
            <ModalHeader toggle={this.toggle} className="text-center">Request OEMS Setup</ModalHeader>
            <ModalBody>
              This will create an OEMS Setup SF case for user {this.state.toggledUserName}
              <br/>
              <br/>
              <div className="text-center confirm">Are you sure?</div>
            </ModalBody>
            <ModalFooter className="text-center popup-footer">
              <Button color="primary" onClick={this.callOemsSetup}>Yes</Button>{'   '}
              <Button color="danger" onClick={this.toggleOems}>No</Button>
            </ModalFooter>
          </Loadable>
        </Modal>
        <Modal isOpen={this.state.showPmsPopUp} toggle={this.togglePms} className="center-loader">
          <Loadable active={this.state.isPmsLoading} spinner text="Please stand by ...">
            <ModalHeader toggle={this.toggle} className="text-center">Request PMS Setup</ModalHeader>
            <ModalBody>
              This will create a user in PMS for {this.state.toggledUserName}
              <br/>
              <br/>
              <div className="text-center confirm">Are you sure?</div>
            </ModalBody>
            <ModalFooter className="text-center popup-footer">
              <Button color="primary" onClick={this.callPmsSetup}>Yes</Button>{'   '}
              <Button color="danger" onClick={this.togglePms}>No</Button>
            </ModalFooter>
          </Loadable>
        </Modal>

        <NotificationAlert ref="notificationAlert"/>

        <Loader
          loading={this.state.loading} />

        <h6 id={ head1 }>User Details</h6>
        <h6 id={ head2 } hidden={!canViewOEMS}>OEMS</h6>
        <h6 id={ head3 } hidden={!canViewPMS}>PMS</h6>
        <hr className={ line1 }></hr>
        <hr className={ line2 } hidden={!canViewOEMS}></hr>
        <hr className={ line3 } hidden={!canViewPMS}></hr>
        <BootstrapTable
          hover
          condensed
          keyField='idx'
          classes='table-responsive table-client-info'
          rowStyle={{ fontSize: '10px' }}
          bordered={ false }
          data={ this.props.data }
          columns={ usersColumnsConfig }
          cellEdit = { cellEditFactory({
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: (oldValue, newValue, row, column) => {
              if (oldValue !== newValue && !row.edited) {
                this.setState({editableUserOldValue: Object.assign({}, row)})
                row.edited = true
              }
            },
            afterSaveCell: this.afterSavecell
          })} />

      </div>
    )
  }
}

UserTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleRemoveRow: PropTypes.func,
  readOnly: PropTypes.bool,
  clientName: PropTypes.string,
  refreshData: PropTypes.func,
};

export default UserTable
