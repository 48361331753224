import React from 'react'
import FetchEnvironmentContent from './FetchEnvironmentContent'
import Script from 'react-load-script'

class EnvironmentContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      environment: props.match.params.environment,
      someScriptFailedLoading: false
    }

    this.scriptFailedLoading = this.scriptFailedLoading.bind(this)
  }

  scriptFailedLoading () {
    console.log('Caught an error!')
    this.setState({someScriptFailedLoading: true})
  }

  static loadTimeline () {
    const loadTimelineScript = document.createElement('script')
    const loadTimelineScriptBody = document.createTextNode("google.charts.load('current', {packages:['timeline']});")

    loadTimelineScript.appendChild(loadTimelineScriptBody)
    document.head.appendChild(loadTimelineScript)
  }

  render () {
    const appUrl = `/dashboard/environments/${this.state.environment}/js/app.js`
    const bootstrapDatePickerUrl = `/dashboard/environments/${this.state.environment}/js/bootstrap-datepicker.js`
    const dataUrl = `/dashboard/environments/${this.state.environment}/js/data.js`
    const timelineUrl = `/dashboard/environments/${this.state.environment}/js/timeline.js`
    const uiUrl = `/dashboard/environments/${this.state.environment}/js/ui.js`
    const utilsUrl = `/dashboard/environments/${this.state.environment}/js/utils.js`
    const momentUrl = `/dashboard/environments/${this.state.environment}/js/moment.min.js`
    const lzUrl = `/dashboard/environments/${this.state.environment}/js/lz-string-1.3.3-min.js`
    const jsonDataUrl = `/dashboard/environments/${this.state.environment}/timeline_data.json`
    const jqueryUrl = 'https://ajax.googleapis.com/ajax/libs/jquery/2.1.0/jquery.min.js'
    const jqueryUiUrl = 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.10.4/jquery-ui.min.js'
    const googleChartsLoaderUrl = 'https://www.gstatic.com/charts/loader.js'

    if (this.state.someScriptFailedLoading) {
      return (
        <p style={{ fontSize: '20px' }}>Environment data could not be loaded, please contact web.services@tora.com</p>
      )
    } else {
      return (
        <React.Fragment>
          <Script url={jqueryUrl} onError={this.scriptFailedLoading}/>
          <Script url={jqueryUiUrl} onError={this.scriptFailedLoading}/>
          <Script url={timelineUrl} onError={this.scriptFailedLoading}/>
          <Script url={dataUrl} onError={this.scriptFailedLoading}/>
          <Script url={uiUrl} onError={this.scriptFailedLoading}/>
          <Script url={utilsUrl} onError={this.scriptFailedLoading}/>
          <Script url={momentUrl} onError={this.scriptFailedLoading}/>
          <Script url={bootstrapDatePickerUrl} onError={this.scriptFailedLoading}/>
          <Script url={lzUrl} onError={this.scriptFailedLoading}/>
          <Script url={jsonDataUrl} onError={this.scriptFailedLoading}/>
          <Script url={appUrl} onError={this.scriptFailedLoading}/>
          <Script url={googleChartsLoaderUrl} onLoad={EnvironmentContainer.loadTimeline} onError={this.scriptFailedLoading}/>
          <FetchEnvironmentContent content={this.state.environment}/>
        </React.Fragment>
      )
    }
  }
}

export default EnvironmentContainer
