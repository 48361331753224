import React from 'react'
import Iframe from 'react-iframe'

class DocumentationContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      documentationName: props.match.params.documentationName,
      documentations: {
        'pms': 'https://d3fc4vhq4onaim.cloudfront.net',
        'mdata': 'https://d2ugutu95xyi1g.cloudfront.net'
      }
    }
  }

  render () {
    return (
      <React.Fragment>
        <Iframe url={this.state.documentations[this.state.documentationName]}
          width="100%"
          height="1000px"
          display="initial"
          position="relative"
          styles={{ marginTop: '65px'}}
          allowFullScreen/>
      </React.Fragment>
    )
  }
}

export default DocumentationContainer
