import React from 'react'
import {
  Card, CardHeader, CardTitle, CardBody,
  Container, Row, Col
} from 'reactstrap'

class Terms extends React.Component {
  render () {
    return (
      <div>
        <div className="full-page-content static-page">
          <div>
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={8} md={8} xs={12}>
                  <Card className="content">
                    <CardHeader>
                      <CardTitle><h3>Terms of use</h3></CardTitle>
                    </CardHeader>
                    <CardBody className="content-body">
                      <p>PLEASE READ THE FOLLOWING TERMS OF USE (“TERMS”). THESE TERMS GOVERN YOUR USE OF THE CASPIAN LIMITED
                                                WEBSITE AT <a href="https://caspian.tech/" >WWW.CASPIAN.TECH</a> (THE “WEBSITE”), WHICH IS OWNED AND OPERATED BY CASPIAN LIMITED (“CASPIAN,” “OUR,” “WE”  “US” OR “PROVIDER”). BY ACCESSING THIS WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO THESE TERMS.
                                                IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT ACCESS THIS WEBSITE, OR ANY OTHER CONTENT AVAILABLE THROUGH THIS WEBSITE.
                                                If you are a representative of a firm, corporation, organization or other entity, you represent and warrant that you are authorized to bind the same to these Terms. This Agreement governs your access to and use of the Site, Web Services, Data and Third Party Data, and constitutes a binding legal agreement between you (referred to here in as “You” or “Customer”) and Caspian.
                      </p>
                      <h5>DEFINITIONS:</h5>
                      <p>“Data Owner” means a legal entity that holds ownership rights to some of the Data or Third Party Data (defined below)
                                                and is the original licensing source of such portion of the Data or Third Party Data when such portion of the Data or Third Party Data
                                                is not in the public domain. In cases of Third Party Data, Provider has secured redistribution agreements with the Data Owner.
                                                “Distinct Software Applications” means software applications that automate substantially different business products, services,
                                                processes or functions of Customer. Caspian r reserves the right, in its sole discretion, to make a reasonable determination as to whether
                                                software applications that use the Web Services or the Data or Third Party Data constitute Distinct Software Applications.
                                                “Data Exchange Format” means an electronic version of the Data or Third Party Data used for sharing the Data or Third Party Data between software applications,
                                                including but not limited to any application programming interface (API), any database access (e.g., ODBC, etc.),
                                                any network transmission format (e.g., EDI, SOAP, RSS, XML, etc.) and any data file format (e.g., XLS, CSV, etc.).
                                                Provider reserves the right, in its sole discretion, to determine if a particular electronic version of the Data or Third Party Data constitutes a Data Exchange Format.
                                                “Subscription Plan” means all the subscription plans selected by the Customer in connection with the purchase of the use of the Web Services, Data and Third Party Data.
                                                 The Provider posts the available subscription plans and the specific use of the Web Services, Data and Third Party Data provided by each subscription plan on the Site,
                                                  as they may be updated by the Provider from time to time. The current subscription plans offered with respect to the Web Services are located
                                                  at <a href="https://caspian.tech/" >http://________________ </a>. “Support Plan” means the Support Plan selected by the Customer in connection with the purchase of the use of the Web Services,
                                                   Data, and Third Party Data. The Provider posts the available support plans and specific service levels provided by each support plan on the Site, as they
                                                   may be updated by the Provider from time to time.
                      </p>
                      <h5>REGISTRATION:</h5>
                      <p>In order to access the Site, Web Services, Data and Third Party Data, You must register to create an account (“Account”).
                                                 During the registration process, You will be required to provide certain information and You will establish a password.
                                                  You agree to provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete.
                                                   Provider reserves the right to suspend or terminate Your Account if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete. You are responsible for safeguarding Your password.
                                                    You agree not to disclose Your password to any third party and to take sole responsibility for any activities or actions under Your Account, whether or not You have authorized such activities or actions.
                                                     You will immediately notify Provider of any unauthorized use of Your Account.
                      </p>
                      <h6>FEES:</h6>
                      <p>Subscription Fees. Customer will pay to Provider fees in connection with the Subscription Plan and
                                                 the Support Plan selected by Customer to use the Web Services, Data, and Third Party Data (“Subscription Fees”).
                                                 Thereafter, Subscription Fees for Customer’s use of the Web Services, Data and Third Party Data shall be (i) as
                                                 described and/or as selected by You via the Site in connection with purchase to use the Web Services,
                                                 Data and Third Party Data or (ii) negotiated between You and Provider by creating an addendum to this Agreement.
                                                 Unless otherwise negotiated between You and Provider in an addendum to this agreement,
                                                 all Subscription Fees are due at the beginning of each subscription term as specified in the Subscription
                                                 Plan and Provider will charge the method of payment You provide via the Site in the amount of the Subscription
                                                 Fees in connection with the Subscription Plan that You select. Subscription Fees are exclusive of taxes, duties,
                                                 levies, tariffs, and other governmental charges (including, without limitation, VAT) (collectively, “Taxes”).
                                                  Customer shall be responsible for payment of all Taxes and any related interest and/or penalties resulting from
                                                  any payments made hereunder, other than any taxes based on Provider’s net income.

                      </p>
                      <h5>CONTENT OF THE WEBSITE:</h5>
                      <p>The Website may contain information, data, text, graphics, video, messages, software, code, audio, feedback,
                                                documentation, photographs, articles, listings, advertisements, marketing and other materials, available,
                                                 displayed on, posted to, transmitted or distributed through the Website, whether provided by unaffiliated
                                                 third party providers (“Third Party Providers”) or us (collectively, “Content”). The Content may include
                                                 financial market data, quotes, news, analyst opinions and research reports. CASPIAN does not endorse or
                                                 approve the Content, and we make it available to you only as a service and convenience. CASPIAN does not
                                                  guarantee the accuracy, timeliness, completeness or correct sequencing of the Content or warrant any results
                                                   from your use or reliance on the Content. The Content may quickly become unreliable for various reasons including,
                                                    without limitation, changes in market conditions or economic circumstances. Neither CASPIAN nor the Third Party Providers
                                                     are obligated to update any information or opinions contained in the Content.
                                                      CASPIAN may discontinue offering any of the Content at any time without notice.
                                                       You agree that neither CASPIAN nor the Third Party Providers will be liable to you in any way for the termination,
                                                        interruption, delay or inaccuracy of any of the Content. You will not redistribute or facilitate the redistribution
                                                         of any of the Content, nor will you provide access to the Content to anyone who is not authorized by CASPIAN
                                                          to receive the Content.
                      </p>
                      <h5>RULES AND LIMITS ON AND ENHANCEMENTS/MODIFICATIONS TO THE WEBSITE:</h5>
                      <p>We reserve the right, for any reason, in our sole discretion and without notice to you, to terminate, change, suspend or discontinue any aspect of the Website,
                                                including, without limitation, the Content, features and/or hours of availability, and we will not be liable to you or to any third party for doing so.
                                                We may also impose rules for and limits on use of the Website or restrict your access to part, or all, of the Website without notice or penalty.
                                                We have the right to change these rules and/or limitations at any time, in our sole discretion. You should check these Terms periodically for any change.
                                                If you continue to use the Website after any change, you will be bound by such change.
                      </p>
                      <p>In addition, We will provide You with enhancements or modifications (“Updates”) to the Web Services as they become available.
                                                You acknowledge that additional Subscription Fees may be charged for the use of Updates,
                                                and that We have the sole discretion in identifying which Updates require the payment of additional Subscription Fees.
                                                We acknowledge that You have sole discretion in deciding to use Updates and are only responsible for additional
                                                Subscription Fees for those Updates that You decide to use. Should You decide to use Updates that require additional Subscription Fees,
                                                then new Subscription Fees for Your use of the Web Services shall be (i) as described and/or as selected by You via the Site in connection
                                                with purchase to use the Web Services, Data and Third Party Data or (ii) negotiated between You and Us by creating an addendum to this Agreement.
                      </p>
                      <h5>USER CONDUCT:</h5>
                      <p>You agree you will not, and will not assist or permit others to:
                        <ul>
                          <li>Frame the Website, place pop-up windows on any page of the Website, or otherwise change the display or compilation of the Website or its pages;</li>
                          <li>Interfere with or disrupt the Website or servers or networks connected to the Website, or disobey any requirements, procedures, policies or regulations of networks connected to the Website; or</li>
                          <li>Modify, create derivative works of, reverse engineer, disassemble, or decompile the Website or any software or technology on the Website or redistribute or sell any section thereof.</li>
                        </ul>
                      </p>
                      <h5>PRIVACY POLICY; USER INFORMATION:</h5>
                      <p>In the course of your use of the Website, you may be asked to provide certain information to us.
                                                You acknowledge and agree that you are solely responsible for the accuracy and content of such information.
                                                 CASPIAN’s use of any information that you provide to us in connection with the Website shall be governed by our
                                                  Privacy Policy available <a href="privacy">here</a>. We urge you to read our <a href="privacy">Privacy Policy</a>.
                      </p>
                      <h5>INTERNATIONAL USE:</h5>
                      <p>We control and operate the Website from our office in the State of California, United States of America.
                                                We do not represent that materials on the Website are appropriate or available for use in other locations.
                                                Persons who choose to access the Website from other locations do so on their own initiative and are responsible
                                                for compliance with local laws, if and to the extent local laws are applicable.
                      </p>
                      <p>
                                            You agree to comply with all applicable laws, rules and regulations in connection with your use of the Website.
                                            Without limiting the generality of the foregoing, you agree to comply with all applicable laws regarding the transmission of
                                            technical data and personal information exported from the United States or the country in which you reside.
                                            Access to the Website from jurisdictions or territories where the Content is illegal or penalized is prohibited.
                      </p>
                      <h5>SOFTWARE AND TOOLS:</h5>
                      <p>
                                            You acknowledge and agree that use of all software and tools downloaded or otherwise accessed from the Website (“Software”)
                                             may be subject to additional terms and conditions.
                                            You may not download any Software without agreeing to such additional terms and conditions.
                      </p>
                      <h5>PROPRIETARY RIGHTS</h5>
                      <p>As between you and CASPIAN, CASPIAN is the owner of any trademark, trade name, trade secret,
                                                     copyright, patent and/or service mark appearing on or included in the Website, and we own all right,
                                                      title and interest in and to the Website, including without limitation, the Content,
                                                      Software, products and services on the Website. We do not grant any license or other
                                                       authorization to any user of such trade names, trademarks, service marks, trade secrets, patents,
                                                        or copyrights or any other intellectual property by including them on or in the Website.</p>
                      <p>Except as otherwise expressly provided herein, use of the Website does not grant you any rights to any Content, Software,
                                                    features or materials you may access on the Website and you may not modify, rent, lease, loan, sell, distribute or create
                                                     derivative works of such Content, Software, features or materials, in whole or in part. Any commercial use of the Website
                                                      is strictly prohibited, except as allowed herein or otherwise approved by us. If you make use of the Website, other than
                                                       as provided herein, in doing so you may violate copyright and other laws of the United States, other countries, as well
                                                        as applicable state laws and may be subject to liability for such unauthorized use.</p>
                      <p>CASPIAN hereby grants you a limited, revocable, nonexclusive, nontransferable license to view, bookmark, download and print
                                                    the pages within the Website and the documents, Data and Third Party and Third Party Data available graphics and media
                                                     posted on or displayed via the Website solely for your personal or noncommercial use. You may not modify, copy, reverse
                                                      engineer, distribute, transmit, post, display, perform, reproduce, publish, license, create derivative works from,
                                                       transfer or sell any Content, Software, pages, products or services obtained from the Website, unless you have obtained
                                                        the prior written permission of CASPIAN. CASPIAN reserves all rights not expressly granted herein; CASPIAN expressly
                                                         reserves the right to monitor any and all use of the Website.</p>
                      <p>In addition, any Third Party Data may be subject to restrictions of use and require additional license agreements with the
                                                     Data Owner. While Caspian may, in its sole discretion, offer administrative assistance to Customer
                                                      in obtaining such additional licenses, Customer is solely responsible for obtaining all required license
                                                       agreements with each Data Owner pursuant to Customer’s use of such Third Party Data. If Customer does not
                                                        obtain a required license agreement for any portion of the Third Party Data and as such is in violation
                                                        of the Data Owner’s licensing requirements, Provider may terminate access to the unlicensed portion of
                                                        Third Party Data until Customer obtains such license agreements with the Data Owner.
                      </p>
                      <h6>Restrictions on the Data and Third Party Data</h6>
                      <p>Customer may use the Data and Third Party Data solely with any software application owned or
                                                licensed by Customer. Customer will not use, redistribute, sublicense, sell or otherwise
                                                make available any portion of the Data or Third Party Data in any Data Exchange Format for
                                                use in software applications not owned or licensed by Customer. Customer may not display or
                                                 make the Data or Third Party Data available in any Data Exchange Format to non-employee users,
                                                  including but not limited to, vendors, contractors, partners and the general public
                                                  (e.g., public websites, partner and vendor extranets, EDI applications, etc.).
                                                  Customer may cache and store the Data and Third Party Data for use within each
                                                  Distinct Software Application provided such use is in compliance with restrictions
                                                  imposed by the Data Owners. The Data or Third Party Data may not be shared between
                                                  Distinct Software Applications or made available in any Data Exchange Format for the
                                                  purpose of sharing between Distinct Software Applications. Enforcement. Except as otherwise provided herein,
                                                  Customer is responsible for all of Customer’s activities occurring through its use of the Web Services.
                      </p>
                      <p>Telecommunications and Internet Services. Customer acknowledges that the use of the Web Services by Customer is dependent
                                                upon access to telecommunications and Internet services. Customer will be solely responsible for acquiring and maintaining
                                                all telecommunications and Internet services and other hardware and software required to access and use the Web Services,
                                                including, without limitation, any and all costs, fees, expenses, and taxes of any kind related to the foregoing.
                      </p>
                      <h5>COPYRIGHTS</h5>
                      <p>The Content and works of authorship contained in the Website including, without limitation, all design, text, graphics,
                                                audio clips, logos, buttons, digital downloads, design, layout and look and feel of the Website, data compilations,
                                                 Software, icons, html code, xml code and images are owned or used under license by CASPIAN or a Third Party Provider
                                                 and are protected by United States and international copyright and other intellectual property laws.
                                                 All Software used on the Website is the exclusive property of CASPIAN or a Third Party Provider and is protected by U.S.
                                                 and international copyright laws and other laws.</p>
                      <p>You agree not to remove any notice signifying CASPIAN’s, any Third Party Provider’s or any third party’s intellectual
                                                 property or other proprietary rights. Nothing contained on the Website shall be deemed or construed to confer any license
                                                  or right, expressly, by implication, estoppel or otherwise, under any of CASPIAN, any Third Party Provider’s or any third party’s
                                                   intellectual property rights.</p>
                      <p>The information on the Website is protected by law including intellectual property law.</p>
                      <p>COPYRIGHT NOTICE: Copyright © 2008 CASPIAN, Limited All Rights Reserved.</p>
                      <h5>TRADEMARK INFORMATION</h5>
                      <p>Product or service names, logos, designs, titles, words or phrases may be protected under law as the trademarks, service marks or trade names of CASPIAN or other entities. Such trademarks, service marks and trade names may be registered in the United States and internationally.</p>
                      <p>“CASPIAN”, the CASPIAN logo, and other CASPIAN product and service names including “CASPIAN____”, “CASPIAN_____” are trademarks or registered trademarks of CASPIAN (the “CASPIAN Marks”). Without CASPIAN’s prior written permission, you agree not to display or use the CASPIAN Marks in any manner. Nothing on the Website should be construed to grant any license or right to use any CASPIAN Mark without the written consent of CASPIAN.</p>
                      <p>CASPIAN Marks may not be used in connection with any product or service that is not CASPIAN’s product or service, in any manner that is likely to cause confusion among customers or in any manner that disparages or discredits CASPIAN.</p>
                      <p>All other marks appearing on the Website are the exclusive property of their respective owners.</p>
                      <h5>SERVICE PUBLICATIONS</h5>
                      <p>Except as expressly prohibited on the Website, you are permitted to view, copy and print informational pages available through the Website subject to your agreement that:</p>
                      <p>
                        <ul>
                          <li>your use of the information is for informational, personal and noncommercial purposes only;</li>
                          <li>you will not modify the information or graphics;</li>
                          <li>you will not copy or distribute the graphics separate from their accompanying text and you will not quote materials out of their context;</li>
                          <li>you will reproduce and display the appropriate copyright and trademark notices on all documents or portions of documents and retain any other intellectual property and other proprietary notices on every copy you make; and</li>
                          <li>you agree that we may revoke this permission at any time and that in such event you will immediately cease your activities related to this permission upon notice from us.</li>
                        </ul>
                      </p>
                      <p>The permission above does not include permission to copy the design elements, compilation, look and feel or layout of the Website.</p>
                      <h5>NO RECOMMENDATIONS OR ADVICE</h5>
                      <p>None of CASPIAN and its affiliates, their respective officers, directors, employees, vendors, suppliers and agents, nor any of the Third Party Providers, recommend, endorse, advocate or sponsor any of the securities or investments appearing on the Website. You should not construe any information provided on the Website as investment, financial, tax, legal or other advice. You acknowledge the risks associated with securities and trading transactions. Any price information, quotes, forecasts, return estimates or indications of past performance are for information purposes only and do not guarantee future performance and do not constitute an offer to buy or sell or any solicitation of an offer to buy or sell any securities, investments or other property. The Website shall not serve as the primary basis for any of your investment decisions and neither CASPIAN nor any of the Third Party Providers shall be or be deemed to be your financial advisor or fiduciary. In exchange for using such data, information or content, you agree not to hold CASPIAN or the Third Party Providers liable for any possible claim for damages arising from any decision you make based on information made available to you through the Website.</p>
                      <h5>LINKS FROM AND TO THE WEBSITE</h5>
                      <p>You may be able to link to third party Web sites (“Linked Websites”) from the Website. Linked Websites are not, however, reviewed, controlled or examined by CASPIAN in any way and CASPIAN is not responsible for the content, availability, advertising, products, information or use of user information or other materials of any such Linked Websites, or any additional links contained therein. These links do not imply CASPIAN’s endorsement of or association with the Linked Websites. It is your sole responsibility to comply with the appropriate terms of use and privacy policies of the Linked Websites as well as with any other obligation under copyright, secrecy, defamation, decency, privacy, security and export laws related to the use of such Linked Websites and any content contained thereon. In no event shall CASPIAN be liable, directly or indirectly, to anyone for any loss or damage arising from or occasioned by the creation or use of the Linked Websites or the content, information or material accessed through these Linked Websites. You should direct any concerns to that website’s administrator or webmaster. CASPIAN reserves the exclusive right, at its sole discretion, to add, change, decline or remove, without notice, any feature or link to any of the Linked Websites from the Website and/or introduce different features or links to different users.</p>
                      <h5>ELECTRONIC RECORDING</h5>
                      <p>In connection with your use of the Website, you acknowledge and consent to the taping or any form of electronic recording of any communication, electronic or otherwise, between you and CASPIAN or its representatives, agents or affiliates. You acknowledge and consent to the recording, retention and use by CASPIAN (and its employees, representatives and agents) of all information and data that you input during your use of the Website.</p>
                      <h5>INDEMNITY</h5>
                      <p>You (and your firm or organization, if you are a representative of same) agree to defend, indemnify and hold CASPIAN and any Third Party Provider, and their respective directors, officers, employees, agents and affiliates (“Indemnified Persons”) harmless to the fullest extent permitted by applicable law from any and all claims, liabilities, damages, costs and expenses, including reasonable attorneys’ fees and costs to investigate any claims (“Expenses”), in any way arising from, related to or in connection with your access to and use of the Website, your breach of these Terms, or your infringement or misappropriation of CASPIAN’s, our affiliates’ or a Third Party Provider’s intellectual property or proprietary rights.</p>
                      <h5>DISCLAIMER OF WARRANTIES</h5>
                      <p>YOU UNDERSTAND AND AGREE THAT:</p>
                      <p>THE WEBSITE, INCLUDING, WITHOUT LIMITATION, ALL CONTENT, SOFTWARE, FUNCTION, MATERIALS AND SERVICES IS PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION, DATA, DATA PROCESSING SERVICES OR UNINTERRUPTED ACCESS, ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, COMPLETENESS, USEFULNESS, OR RELIABILITY OF THE CONTENT, AND ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. CASPIAN DOES NOT WARRANT THAT THE WEBSITE OR THE FUNCTION, CONTENT, SOFTWARE OR SERVICES MADE AVAILABLE THEREBY WILL BE TIMELY, SECURE, UNINTERRUPTED OR FREE OF ERRORS, DEFECTS, VIRUSES OR OTHER CONTAMINATING OR DESTRUCTIVE ELEMENTS, OR THAT SUCH PROBLEMS WILL BE CORRECTED. CASPIAN MAKES NO WARRANTY THAT THE WEBSITE WILL MEET USERS’ EXPECTATIONS OR REQUIREMENTS. NO ADVICE, RESULTS OR INFORMATION, OR MATERIALS WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE WEBSITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. IF YOU ARE DISSATISFIED WITH THE WEBSITE, YOUR SOLE REMEDY IS TO DISCONTINUE USING THE WEBSITE.</p>
                      <p>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.)THE FOREGOING EXCLUSIONS AND DISCLAIMERS OF WARRANTIES ARE AN ESSENTIAL PART OF THIS AGREEMENT AND FORMED THE BASIS FOR DETERMINING THE PRICE CHARGED FOR THE PRODUCTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM PROVIDER OR ELSEWHERE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES IN CERTAIN CIRCUMSTANCES. ACCORDINGLY, SOME OF THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY.</p>
                      <p>CASPIAN DOES NOT ENDORSE, WARRANT OR GUARANTEE ANY PRODUCTS OR SERVICES OFFERED OR PROVIDED BY OR ON BEHALF OF THE THIRD PARTY PROVIDERS ON OR THROUGH THE WEBSITE OR A LINKED WEBSITE. CASPIAN IS NOT A PARTY TO, AND DOES NOT MONITOR, ANY TRANSACTION BETWEEN USERS AND THIRD PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                                                CASPIAN OR ANY THIRD PARTY PROVIDER ENGAGED IN PROVIDING SERVICES TO YOU ON OR THROUGH THE WEBSITE SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES CAUSED BY THEFT, UNAUTHORIZED ACCESS, SYSTEMS FAILURE OR INTERRUPTION, INTERNET CONNECTIVITY, COMMUNICATIONS LINE FAILURE OR OTHER OCCURRENCES BEYOND THE CONTROL OF CASPIAN OR SUCH THIRD PARTY PROVIDER.</p>
                      <h6>Customer Responsibilities</h6>
                      <p>Customer will promptly report any errors in the operation of the Web Services to Provider and will not take any actions that would increase the severity of the error. Customer will use the Web Services solely as described herein. In the event that Customer violates any of the requirements of this Section, Provider will have no responsibility to provide Support.</p>
                      <h5>LIMITATION OF LIABILITY</h5>
                      <p>IN NO EVENT SHALL CASPIAN, ITS AFFILIATES OR ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR THE THIRD PARTY PROVIDERS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM OR DIRECTLY OR INDIRECTLY RELATED TO THE USE OF, OR THE INABILITY TO USE, THE WEBSITE OR THE CONTENT, SOFTWARE, MATERIALS AND FUNCTION RELATED THERETO, INCLUDING, WITHOUT LIMITATION, LOSS OF REVENUE, OR ANTICIPATED PROFITS, OR LOST BUSINESS OR GOODWILL, DATA OR SALES, OR COST OF SUBSTITUTE SERVICES, EVEN IF CASPIAN OR ITS REPRESENTATIVE OR SUCH INDIVIDUAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL THE TOTAL LIABILITY OF CASPIAN TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING FROM THE TERMS OR YOUR USE OF THE WEBSITE EXCEED, IN THE AGGREGATE, $100.00.</p>
                      <h5>TERMINATION, SUSPENSION OR MODIFICATION</h5>
                      <p>The rights granted to you herein terminate immediately upon any violation by you of these Terms. CASPIAN reserves the right to terminate, suspend or modify your access to the Website at any time, without notice, for any reason whatsoever. You agree that CASPIAN shall not be liable to you or any third party for any termination of your access to the Website.</p>
                      <h5>APPLICABLE LAW/JURISDICTION</h5>
                      <p>You agree that the laws of the State of California, United States, excluding its conflicts-of-law rules, shall govern these Terms. Please note that your use of the Website may be subject to other local, state, national, and international laws. You expressly agree that exclusive jurisdiction for any claim or dispute with CASPIAN or relating in any way to your use of the Website resides in the state and federal courts of San Francisco County, California, and you further agree and expressly consent to the exercise of personal jurisdiction in the state and federal courts of San Francisco County, California, in connection with any such dispute and including any claim involving CASPIAN or its affiliates, subsidiaries, employees, contractors, officers, directors and any Third Party Provider.</p>
                      <h6>MODIFICATION OF THE AGREEMENT:</h6>
                      <p>We reserve the right, for any reason, in our sole discretion and with or without notice to you to modify this Agreement. If Caspian  modifies this Agreement, Caspian will post the modification on the Site or provide you with notice of the modification. By continuing to access or use the Site, Web Services, Data and Third Party Data after Caspian has posted a modification on the Site or has provided you with notice of a modification, you are indicating that you agree to be bound by the modified Agreement. If the modified Agreement is not acceptable to You, Your only recourse is to cease using the Site, Web Services, Data and Third Party Data.</p>
                      <h5>GENERAL INFORMATION</h5>
                      <p>These Terms constitute the entire agreement between you and CASPIAN and govern your use of the Website, superseding any prior agreements between you and CASPIAN, unless you and CASPIAN enter into a separate agreement related to the use of the Website, in which case you also may be subject to additional terms and conditions. The failure of CASPIAN to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect. You may not assign these Terms or any of your rights or obligations under these Terms without CASPIAN’s express written consent. These Terms inure to the benefit of CASPIAN’s successors, assigns and licensees. The section titles in these Terms are for convenience only and have no legal or contractual effect.</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

export default Terms
