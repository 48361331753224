import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

import { CardHeader, CardBody } from 'reactstrap'

class CardSuccessMessage extends React.Component {
  render () {
    return (
      <div className="card-success-message">
        <CardHeader className="text-center">
          <h5>{this.props.title}</h5>
        </CardHeader>
        <CardBody>
          <div className="icon-wrapper"><i className="cas-icon cas-check-circle-07"></i></div>
          {this.props.children}
        </CardBody>
      </div>
    )
  }
}

CardSuccessMessage.propTypes = {
  // Where the user to be redirected on clicking the avatar
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  children: PropTypes.array
}

export default CardSuccessMessage
