import utils from './artemis_utils'

export class CorporateType {
  constructor () {
    this.corporateTypes = ['SOLE-PROPRIETORSHIP (SPR)', 'PARTNERSHIP (PTR)',
      'LIMITED PARTNERSHIP (LPT)', 'LIMITED LIABILITY PARTNERSHIP (LLP)',
      'LOCAL PRIVATE COMPANY (PTE)', 'LOCAL PUBLIC COMPANY (PBL)',
      'FOREIGN COMPANY (FRC)', 'TRUST', 'CHARITY', 'ASSOCIATION', 'LIMITED (LTD)']
  }

  getCorporateTypeOptions () {
    const corporateTypesJson = []
    this.corporateTypes.forEach(c => {
      corporateTypesJson.push({
        value: c,
        label: utils.titleCase(c)
      })
    })
    return corporateTypesJson
  }
}
