import axios from 'axios'
import caspianApi from './CaspianApi'
import qs from 'qs'
import _ from 'lodash'
import utils from '../utils'
import caspianConfig from '../config'
import moment from 'moment'
import {PGP_PUBLIC_KEY} from '../mss-key'

class CaspianApiService {
  verifyAcccessCode (code) {
    return caspianApi.get(`/access-codes/verify/${code}`)
      .then((response) => {
        return response
      })
  }

  verifyResetPasswordLinkKey (key) {
    return caspianApi.get(`/account/reset-password/verify/${key}`)
      .then((response) => {
        return response
      })
      .catch((err) => {
        throw err
      })
  }

  loginUser (username, password, rememberMe) {
    return caspianApi.post('/authenticate',
      {
        username,
        rememberMe,
        password
      })
      .then((response) => {
        localStorage.setItem('token', response.id_token)
        return this.getUserDetails()
      })
      .then((user) => {
        localStorage.setItem('user', JSON.stringify(user))
        return true
      })
  }

  checkUser (username, password, rememberMe) {
    return caspianApi.post('/authenticate/checkUser',
      {
        username,
        rememberMe,
        password
      })
      .then(() => {
        return true
      })
  }

  recaptchaCheck (token) {
    return caspianApi.post('/authenticate/recaptcha/verify', {
      token
    })
  }

  authenticateTwoFactor (digitCode, email, password, rememberMe) {
    return caspianApi.post('/authenticate/authenticateTwoFactor',
      {
        digitCode,
        email,
        rememberMe,
        password
      })
      .then((response) => {
        localStorage.setItem('token', 'Bearer ' + response.id_token)
        return this.reloadUserDetails()
      })
  }

  publishSSOLoginCompleted () {
    return caspianApi.post('/authenticate/sso-auth-completed')
  }

  reloadUserDetails () {
    return this.getUserDetails()
      .then((user) => {
        if (utils.isUserAdmin() || (!utils.isUserAdmin() && !utils.isUserAccountManager())) {
          localStorage.setItem('kyc_status', user.kycStatus)
        }
        localStorage.setItem('user', JSON.stringify(user))

        return true
      })
  }

  getUserDetails () {
    return caspianApi.get('/account')
      .then((userResponse) => {
        return userResponse
      })
  }

  getResourcesFileForClient (clientName) {
    return caspianApi.get(`/resources/all-client-files/${clientName}`)
      .then((resourceFiles) => {
        let filesTree = {}

        resourceFiles.map((fileResource) => {
          let pathParts = fileResource.key.split('/')
          let auxTree = filesTree
          if (pathParts.length === 2) {
            // case when we have a resource directly in client root folder
            if (pathParts[0] in auxTree) {
              auxTree = auxTree[pathParts[0]]
            } else {
              // if there already are resources in root folder
              auxTree[pathParts[0]] = {}
              auxTree = auxTree[pathParts[0]]
            }
            if (!Array.isArray(auxTree['children'])) {
              auxTree['children'] = []
            }
            auxTree['children'].push(fileResource)
            auxTree['children'].map((file) => {
              file.fullName = file.name
            })
          } else {
            for (let i = 0; i < pathParts.length - 1; i++) {
              if (pathParts[i] in auxTree) {
                auxTree = auxTree[pathParts[i]]
              } else {
                auxTree[pathParts[i]] = {}
                auxTree = auxTree[pathParts[i]]
              }
            }
            if (!Array.isArray(auxTree['children'])) {
              auxTree['children'] = []
            }
            auxTree['children'].push(fileResource)
            return auxTree['children'].map((file) => {
              file.fullName = file.name + (file.extension === '' ? '' : '.') + file.extension
            })
          }
        })
        return filesTree
      })
  };

  getResourceFiles (clientName) {
    return caspianApi.get(`/resources/all-client-files/${clientName}`)
      .then((resourceFiles) => {
        let filesTree = {}

        resourceFiles.map((fileResource) => {
          let pathParts = fileResource.key.split('/')
          let auxTree = filesTree

          if (pathParts.length === 2) {
            // case when we have a resource directly in client root folder
            if (pathParts[0] in auxTree) {
              auxTree = auxTree[pathParts[0]]
            } else {
              // if there already are resources in root folder
              auxTree[pathParts[0]] = {}
              auxTree = auxTree[pathParts[0]]
            }
            if (!Array.isArray(auxTree['children'])) {
              auxTree['children'] = []
            }
            auxTree['children'].push(fileResource)
            auxTree['children'].map((file) => {
              file.fullName = file.name
            })
          } else {
            for (let i = 0; i < pathParts.length - 1; i++) {
              if (pathParts[i] in auxTree) {
                auxTree = auxTree[pathParts[i]]
              } else {
                auxTree[pathParts[i]] = {}
                auxTree = auxTree[pathParts[i]]
              }
            }
            if (!Array.isArray(auxTree['children'])) {
              auxTree['children'] = []
            }
            auxTree['children'].push(fileResource)
            auxTree['children'].map((file) => {
              file.fullName = file.name + (file.extension === '' ? '' : '.') + file.extension
            })
          }
        })
        return filesTree
      })
  }

  resetPasswordSendEmail (mail) {
    return caspianApi.post('/account/reset-password/sendEmail',
      {email: mail})
      .then((resp) => {
        return resp
      })
  }

  initResetPassword (newPassword, resetKey) {
    return caspianApi.post('/account/reset-password/init', {newPassword, resetKey})
      .then((resp) => {
        return resp
      })
  }

  finishResetPassword (digitCode, key, newPassword) {
    return caspianApi.post('/account/reset-password/finish', {digitCode, newPassword, key})
      .then((resp) => {
        return resp
      })
  }

  initChangePassword (email, newPassword, oldPassword) {
    return caspianApi.post('/account/change-password/init', {email, newPassword, oldPassword})
      .then((resp) => {
        return resp
      })
  }

  finishChangePassword (digitCode, email, password, rememberMe) {
    return caspianApi.post('/account/change-password/finish', {digitCode, email, password, rememberMe})
      .then((resp) => {
        return resp
      })
  }

  sendGetInTouchRequest (firstName, email, company = null, role = null) {
    var getInTouchData = {
      oid: '00D300000001BGa',
      retURL: caspianConfig.GET_IN_TOUCH_FORM_RETURN_URL,
      Crypto_Related_Flag__c: '1',
      description: 'New Get-In-Touch inquiry from Caspian Portal',
      first_name: firstName,
      email: email
    }

    if (!_.isNull(company)) {
      getInTouchData.company = company
    }

    if (!_.isNull(role)) {
      getInTouchData.role = role
    }

    return axios({
      method: 'POST',
      url: caspianConfig.GET_IN_TOUCH_SALESFORCE_URL,
      data: qs.stringify(getInTouchData)
    })
  }

  createAccountManager (accessCode, clientName, email, firstName, lastName, password, role) {
    return caspianApi.post(`/users/accountManager/${accessCode}`, {clientName, email, firstName, lastName, password, role})
      .then((resp) => {
        return resp
      })
  }

  createIndividualUser (accessCode, clientName, email, password) {
    return caspianApi.post(`/users/individual/${accessCode}`, {clientName, email, password})
      .then((resp) => {
        return resp
      })
  }

  verifyDigitCode (digitCode, email, password, rememberMe) {
    return caspianApi.post('/users/verifyDigitCode', {digitCode, email, password, rememberMe})
      .then((resp) => {
        return resp
      })
  }

  validateUserAccount (validationCode) {
    return caspianApi.get(`/users/activate/${validationCode}`)
      .then((resp) => {
        return resp
      })
  }

  getUsersForAClient (clientName) {
    return caspianApi.get(`/users/client/${clientName}`)
      .then((resp) => resp)
  }

  getClientList () {
    return caspianApi.get('/clients/enabled')
      .then((resp) => resp)
  }

  getAccountHolderClientsList () {
    return caspianApi.get('/clients/accountHolder')
      .then((resp) => resp)
  }

  updateKey (key) {
    return caspianApi.put('/mss-keys/update', key)
      .then((resp) => resp)
  };

  getAllActiveClientList () {
    return caspianApi.get('/clients/active')
      .then((resp) => resp)
  }

  getMssKeysForClient (clientName) {
    return caspianApi.get(`/mss-keys/client/account-holder/${clientName}`)
      .then((resp) => resp)
  };

  getMssKeysClient (clientName) {
    return caspianApi.get(`/mss-keys/client/${clientName}`)
      .then((resp) => resp)
  };

  getAvailableAuditDates (clientName) {
    return caspianApi.get(`/reports/audit/client/${clientName}`)
      .then((resp) => resp)
  };

  getAvailableAuditReports (fundName, year, month) {
    return caspianApi.get(`/reports/audit/fund/${fundName}/${year}/${month}`)
      .then((resp) => resp)
  };

  getFundsList() {
    return caspianApi.get('/funds/client')
                     .then((resp) => resp)
  }

  getClientFundsList(clientName) {
    return caspianApi.get(`/funds/client/${clientName}`)
                     .then((resp) => resp)
  }

  getCurrencies() {
    return caspianApi.get('/currency')
                     .then((resp) => resp)
  }

  getLatestStableVersion() {
    return caspianApi.get('/funds/latestStableVersion')
                     .then((resp) => resp)
  }

  oemsSetup(fund) {
    return caspianApi.put('/funds/oemsSetup', fund)
                     .then((resp) => resp)
  }

  pmsSetup (fund) {
    return caspianApi.put('/funds/pmsSetup', fund)
      .then((resp) => resp)
  }

  oemsUserSetup (user, clientName) {
    return caspianApi.put(`/users/oemsSetup/${clientName}`, user)
      .then((resp) => resp)
  }

  pmsUserSetup (user, clientName) {
    return caspianApi.put(`/users/pmsSetup/${clientName}`, user)
      .then((resp) => resp)
  }

  disablePMSUser (user, clientName) {
    return caspianApi.put(`/users/pmsDisable/${clientName}`, user)
      .then((resp) => resp)
  }

  disableOEMSUser (user, clientName) {
    return caspianApi.put(`/users/oemsDisable/${clientName}`, user)
      .then((resp) => resp)
  }

  linkSetup (fund) {
    return caspianApi.put('/funds/linkSetup', fund)
      .then((resp) => resp)
  }

  removeFund (fund) {
    return caspianApi.post('/funds/remove', fund)
      .then((resp) => resp)
  }

  getClientTradingFlowsList (clientName) {
    return caspianApi.get(`/client-trading-flows/client/${clientName}`)
      .then((resp) => resp)
  }

  getTradingFlowsList () {
    return caspianApi.get(`/client-trading-flows/client/`)
      .then((resp) => resp)
  }

  getWebAndInHouseTradingFlowsList (clientName) {
    return caspianApi.get(`/client-trading-flows/web-and-in-house/client/${clientName}`)
      .then((resp) => resp)
  }

  getOTCAndOthersTradingFlowsList (clientName) {
    return caspianApi.get(`/client-trading-flows/otc-and-others/client/${clientName}`)
      .then((resp) => resp)
  }

  getTicketsList () {
    return caspianApi.get('/tickets')
      .then((resp) => resp)
  }

  getTicketsListForAClient (clientName) {
    return caspianApi.get(`/tickets/currentUser/${clientName}`)
      .then((resp) => resp)
  }

  getProductList () {
    return caspianApi.get('/products')
      .then((resp) => resp)
  }

  getExchangesForProductForClient (clientName) {
    if (clientName) {
      return caspianApi.get(`/exchanges-for-products/client/${clientName}`)
        .then((resp) => resp)
    }
    return caspianApi.get(`/exchanges-for-products/client`)
      .then((resp) => resp)
  }

  createUser (email, firstName, lastName, login, phone, timezone, oemsPrivileges,
    complianceAdmin, oemsAdmin, pmsPrivileges, pmsAdmin, clientName) {
    // let clientName = utils.getUserData().clientName
    let authorities = ['TRADER']
    return caspianApi.post(`/users/${clientName}`, {
      authorities,
      clientName,
      email,
      firstName,
      lastName,
      login,
      phone,
      timezone,
      oemsPrivileges,
      complianceAdmin,
      oemsAdmin,
      pmsPrivileges,
      pmsAdmin
    })
      .then((resp) => resp)
  }

  disableUser (email, clientName) {
    return caspianApi.delete(`/users/disable/${clientName}`, {
      data: {email}
    })
      .then((resp) => resp)
  }

  enableUser (email, clientName) {
    return caspianApi.put(`/users/enable/${clientName}`, {
      email
    })
      .then((resp) => resp)
  }

  cancelUserTicket (email, clientName) {
    return caspianApi.delete(`/users/ticket/${clientName}`, {
      data: {email}
    })
      .then((resp) => resp)
  }

  uploadKYCDocument (formData, clientName) {
    return caspianApi.post(`/kyc/document/${clientName}`, formData)
      .then((resp) => resp)
  }

  removeKYCDocument (clientName, type, description, internalType, crpNo = null) {
    return caspianApi.delete(`/kyc/document/${clientName}`, {
      data: {
        document_type: type,
        please_mention: description,
        internal_type: internalType,
        crp_no: crpNo
      }
    })
      .then((resp) => resp)
  }

  updateAllExchangesForProduct (exchangesForProducts) {
    return caspianApi.put('/exchanges-for-products/all', exchangesForProducts).then((resp) => resp)
  }

  updateAllExchangesForProductForClient (clientName, exchangesForProducts) {
    return caspianApi.put(`/exchanges-for-products/all/${clientName}`, exchangesForProducts).then((resp) => resp)
  }

  updateUser (user, clName) {
    const {
      firstName,
      lastName,
      authorities,
      clientName,
      email,
      activated,
      companyRole,
      id,
      imageUrl,
      langKey,
      login,
      phone,
      timezone,
      oemsPrivileges,
      complianceAdmin,
      oemsAdmin,
      pmsPrivileges,
      pmsAdmin
    } = user

    return caspianApi.put(`/users/${clName}`, {
      firstName,
      lastName,
      authorities,
      clientName,
      email,
      activated,
      companyRole,
      id,
      imageUrl,
      langKey,
      login,
      phone,
      timezone,
      oemsPrivileges,
      complianceAdmin,
      oemsAdmin,
      pmsPrivileges,
      pmsAdmin
    })
      .then((resp) => resp)
  }

  getClientAdditionalInformation (clientName) {
    if (clientName) {
      return caspianApi.get(`/clients/additionalInformation/${clientName}`)
        .then((resp) => resp)
    }
    return caspianApi.get(`/clients/additionalInformation`)
      .then((resp) => resp)
  }

  getClientKycStatus (clientName) {
    if (clientName) {
      return caspianApi.get(`/clients/kycStatus/${clientName}`)
        .then((resp) => resp)
    }
    return caspianApi.get(`/clients/kycStatus`)
      .then((resp) => resp)
  }

  initiateFullSetup(clientId){
    return caspianApi.post(`/clients/${clientId}/full-setup`)
                     .then((resp) => resp)
  }

  getClientProductsUsed (clientName) {
    if (clientName) {
      return caspianApi.get(`/clients/productsUsed/${clientName}`)
        .then((resp) => resp)
    }

    return caspianApi.get('/clients/productsUsed')
      .then((resp) => resp)
  }

  getGeneratedUsername (firstName, lastName) {
    return caspianApi.get(`/users/username/${firstName}/${lastName}`)
      .then((resp) => resp)
  }

  updateClientAdditionalInformation (client) {
    return caspianApi.put('/clients/additionalInformation', client)
      .then((resp) => resp)
  }

  updateClientKycStatus (clientName) {
    const status = 'SUBMITTED_INITIAL_DATA'
    return caspianApi.put(`/clients/kycStatus/${clientName}`, {status})
      .then((resp) => resp)
  }

  sendSaveOrSubmitFailureEmail (actionName, clientName) {
    return caspianApi.post(`/clients/saveOrSubmitFailure/${clientName}`, {actionName})
      .then((resp) => resp)
  }

  sendSubmitSuccessEmail (clientName) {
    return caspianApi.post(`/clients/submitSuccess/${clientName}`)
      .then((resp) => resp)
  }

  sendKYC (data, clientName) {
    let crpCorporate = []
    let crpIndividual = []

    data.crp.filter(crp => crp.crpType.value === 'CORPORATE').forEach(crp => {
      let appointmentDate = moment(crp.dateAppointed).format('DD/MM/YYYY')
      crpCorporate.push({
        corp_name: crp.name,
        crp_no: crp.id,
        role: crp.role.map(r => r.value),
        country: crp.country.value,
        country_of_operations: crp.countryOfOperations.value,
        corporate_category: crp.corporateCategory.value,
        date_appointed: appointmentDate
      })
    }
    )

    data.crp.filter(crp => crp.crpType.value === 'INDIVIDUAL').forEach(crp => {
      let appointmentDate = moment(crp.dateAppointed).format('DD/MM/YYYY')
      crpIndividual.push({
        first_name: crp.name,
        last_name: crp.lastName,
        crp_no: crp.id,
        role: crp.role.map(r => r.value),
        country_of_birth: crp.country.value,
        country_of_residence: crp.countryOfResidence.value,
        nationality: crp.nationality.value,
        gender: crp.gender ? crp.gender.value : null,
        date_appointed: appointmentDate
      })
    })

    let incorporationDate = moment(data.incorporationDate).format('DD/MM/YYYY')
    return caspianApi.post(`/kyc/company/${clientName}`, {
      customer: {
        existing_entity: true,
        name: data.name,
        addresses: data.companyAddress,
        website: data.website,
        telephone_numbers: [data.phoneNo],
        emails: [data.officialEmail],
        entity_type: data.entityType.value,
        issue_date: incorporationDate,
        acra_no: data.acraNumber,
        country: data.country.value,
        country_of_operations: data.countryOfOperations.value
      },
      crp_corporate: crpCorporate,
      crp_individual: crpIndividual
    })
      .then((resp) => resp)
  }

  updateKYC (clientName, data) {
    let crpCorporate = []
    let crpIndividual = []

    data.crp.filter(crp => crp.crpType.value === 'CORPORATE').forEach(crp => {
      let appointmentDate = moment(crp.dateAppointed).format('DD/MM/YYYY')
      crpCorporate.push({
        corp_name: crp.name,
        crp_no: crp.id,
        role: crp.role.map(r => r.value),
        country: crp.country.value,
        country_of_operations: crp.countryOfOperations.value,
        corporate_category: crp.corporateCategory.value,
        date_appointed: appointmentDate,
        cust_rfr_id: crp.customerReferenceId,
        crp_rfr_id: crp.crpReferenceId
      })
    }
    )

    data.crp.filter(crp => crp.crpType.value === 'INDIVIDUAL').forEach(crp => {
      let appointmentDate = moment(crp.dateAppointed).format('DD/MM/YYYY')
      crpIndividual.push({
        first_name: crp.name,
        last_name: crp.lastName,
        crp_no: crp.id,
        role: crp.role.map(r => r.value),
        country_of_birth: crp.country.value,
        country_of_residence: crp.countryOfResidence.value,
        nationality: crp.nationality.value,
        gender: crp.gender ? crp.gender.value : null,
        date_appointed: appointmentDate,
        cust_rfr_id: crp.customerReferenceId,
        crp_rfr_id: crp.crpReferenceId
      })
    })

    let incorporationDate = moment(data.incorporationDate).format('DD/MM/YYYY')
    return caspianApi.put(`/kyc/company/${clientName}`, {
      customer: {
        existing_entity: true,
        name: data.name,
        addresses: data.companyAddress,
        website: data.website,
        telephone_numbers: [data.phoneNo],
        emails: [data.officialEmail],
        entity_type: data.entityType.value,
        issue_date: incorporationDate,
        acra_no: data.acraNumber,
        country: data.country.value,
        country_of_operations: data.countryOfOperations.value,
        cust_rfr_id: data.customerReferenceId,
        domain_name: data.domainName
      },
      crp_corporate: crpCorporate,
      crp_individual: crpIndividual
    })
      .then((resp) => resp)
  }

  getCorporateKYCForClient (clientName) {
    return caspianApi.get(`/kyc/company/${clientName}`)
      .then((userResponse) => {
        return userResponse
      })
  }

  updateFund (fund) {
    return caspianApi.put('/funds/edit', fund)
      .then((resp) => resp)
  }

  updateAllFunds (fundsList, client) {
    return caspianApi.put(`/funds/client/${client}`, fundsList)
      .then((response) => {
        return response
      }).catch((err) => {
        return err
      })
  }

  getAllExchanges () {
    return caspianApi.get('/exchange/all')
      .then((response) => response)
  }

  getOnboardingExchanges (client) {
    if (client === null) {
      return caspianApi.get(`/onboarding-client-exchanges/client`)
        .then((response) => response)
    }
    return caspianApi.get(`/onboarding-client-exchanges/client/${client}`)
      .then((response) => response)
  }

  postClientOnboardingExchanges (onboardingExchanges, clientName) {
    if (clientName) {
      return caspianApi.post(`/onboarding-client-exchanges/client/${clientName}`, onboardingExchanges)
        .then((resp) => resp)
    }
  }

  postExchangeWalletsCustodians (exchangeWallets, clientName) {
    return caspianApi.post(`/exchange-wallets-custodians/client/${clientName}`, exchangeWallets)
      .then((resp) => resp)
  }

  updateUsers (users, clientName) {
    return caspianApi.post(`/users/client/${clientName}`, users)
      .then((resp) => resp)
  }

  postClientTradingFlowsAdmin (clientTradingFlows, clientName) {
    return caspianApi.post(`/client-trading-flows/client/${clientName}`, clientTradingFlows)
      .then((resp) => resp)
  }

  postClientTradingFlows (clientTradingFlows) {
    return caspianApi.post(`/client-trading-flows/client`, clientTradingFlows)
      .then((resp) => resp)
  }

  approveApplication (clientName) {
    return caspianApi.post(`/clients/approve-application/client/${clientName}`)
      .then((resp) => resp)
  }

  getExchangeWalletsCustodians (client) {
    if (client === null) {
      return caspianApi.get(`/exchange-wallets-custodians/client`)
        .then((response) => response)
    }
    return caspianApi.get(`/exchange-wallets-custodians/client/${client}`)
      .then((response) => response)
  }

  getMarketData (client) {
    if (client === null) {
      return caspianApi.get(`/market-data/client`)
        .then((response) => response)
    }
    return caspianApi.get(`/market-data/client/${client}`)
      .then((response) => response)
  }

  postMarketData (marketDataList, client = null) {
    if (client === null) {
      return caspianApi.post(`/market-data/client`, marketDataList)
        .then((response) => response)
    }
    return caspianApi.post(`/market-data/client/${client}`, marketDataList)
      .then((response) => response)
  }

  completeOndoardingForClient (client) {
    const status = 'ONBOARDING_COMPLETED'
    return caspianApi.put(`/clients/kycStatus/${client}/`, {status})
      .then((response) => response)
  }

  async createNewMSSKey (client, route, fundName, exchange, type, key, subKey, secret, keysCount, clientDescription, wasExpired, receivedScope, keyProducts) {
    const openpgp = require('openpgp')
    const JsSHA = require('jssha')
    route = route.replace(new RegExp(' ', 'g'), '_')

    openpgp.initWorker({path: '../openpgp.worker.min.js'})

    if (!PGP_PUBLIC_KEY) {
      return
    }

    const shaObj = new JsSHA('SHA-256', 'TEXT')
    shaObj.setHMACKey(secret, 'TEXT')

    const clientToken = route
    const product = type === 'read' ? 'prism' : 'compass'

    let scope
    if (wasExpired === true && receivedScope != null) {
      scope = receivedScope
    } else {
      scope = `${product}.${type}.${clientToken}.${fundName + (keysCount === 0 ? '' : `_${keysCount + 1}`)}.${exchange}`.replace(new RegExp(' ', 'g'), '_')
    }

    shaObj.update(`${route} ${scope}`)

    const fingerprint = shaObj.getHMAC('B64')
    const armored = await openpgp.key.readArmored(PGP_PUBLIC_KEY)

    const options = {
      message: openpgp.message.fromText(secret),
      publicKeys: armored.keys,
      armor: false
    }

    const secretBin = await openpgp.encrypt(options).then(encrypted => {
      return encrypted.message.packets.write()
    })

    const secretHex = utils.buf2hex(secretBin)

    const clientPath = client ? client + '/' : ''

    return caspianApi.post(`/mss-keys/key/${clientPath}${route}/${scope}`, {
      key: key,
      subKey: subKey || '',
      secret: secretHex,
      fingerprint: fingerprint,
      clientDescription: clientDescription,
      fund: fundName,
      wasExpired: wasExpired,
      keyProducts: keyProducts
    }).then((response) => response)
  }

  getUserGuide() {
    return caspianApi.get('/user-guides/guide')
                     .then((resp) => resp)
  }

  postDownloadedFile(downloadFileName, downloadPath) {
    let resource = {
      nameFile: downloadFileName,
      urlPath: downloadPath,
    }
    return caspianApi.post('/audit/download-file-resource-audit', resource)
                     .then((resp) => resp)
  }

  postWebPageAccessed(pageName, path) {
    let resource = {
      pageName: pageName,
      path: path,
    }
    return caspianApi.post('/audit/user-page-accessed', resource)
                     .then((resp) => resp)
  }

}

export default new CaspianApiService()
