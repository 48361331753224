import React from 'react'
import PropTypes from 'prop-types'
import {Button, Card, CardBody, CardHeader, Col, Row} from 'reactstrap'

import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import caspianApiService from '../../services/CaspianApiService'

import FormInputs from '../../components/FormInputs/FormInputs'

// const editableCellFormater = (cell, row) => {
//   return (
//     <div className="td-editable-content">
//       <span>{cell}</span>
//       {
//         (row.isEditable)
//           ? <i className="cas-icon cas-edit-75"></i>
//           : ''
//       }
//     </div>
//   )
// }

class ExchangeWalletsCustodiansTable extends React.Component {

  booleanFormatter = (cell, row) => {
    return cell === 'true' ?
      <i className='now-ui-icons ui-1_check checked'></i> :
      <i className='now-ui-icons ui-1_simple-remove not-checked' color='danger'></i>
  }

  handleRemoveRow = (data, row) => {
    const ewc = this.state.exchangeWalletsCustodians
    ewc.splice(row, 1)
    this.setState({exchangeWalletsCustodians: ewc})
  }

  handleAddNew = () => {
    const ecw = this.state.exchangeWalletsCustodians
    const type = this.state.type ? this.state.type.value : null
    ecw.push({
      idx: ecw.length,
      id: null,
      client: null,
      caspianName: this.state.caspianName,
      address: this.state.address,
      coin: this.state.coin,
      type: type,
    })
    this.setState({
      exchangeWalletsCustodians: ecw,
      caspianName: '',
      address: '',
      coin: '',
      type: null,
    })
  }

  handleChange = (event) => {
    const st = this.state
    st[event.target.name] = event.target.value
    this.setState(st)
  }

  handleSelectChange = (name) => value => {
    const st = this.state
    st[name] = value
    this.setState(st)
  }

    save = () => {

        caspianApiService.postExchangeWalletsCustodians(this.state.exchangeWalletsCustodians, this.props.clientName)

    }



  constructor (props) {
    super(props);

    this.state = {
      exchangeWalletsCustodians: [],
      loading: false,
      clientName: this.props.clientName,
      error: '',
      caspianName: null,
      address: null,
      coin: null,
      type: null,
    };

    this.columnHeaderTitle = {
      'paddingRight' : '8px'
    };

    this.typeMap = new Map();
    this.typeMap.set('BLOCK_CHAIN', 'Block Chain')
    this.typeMap.set('WALLET', 'Wallet')
    this.typeMap.set('CUSTODIAN', 'Custodian')

    this.actionsFormatter = this.actionsFormatter.bind(this)
    this.typeFormatter = this.typeFormatter.bind(this)
    this.handleOnLoading = this.handleOnLoading.bind(this);
  }

  componentDidMount () {
    this.loadClientExchangeWalletsCustodians(this.props.clientName);
    this.handleOnLoading(this.state.loading);
  }

  componentWillReceiveProps (nextProps) {
      if (this.state.clientName !== nextProps.clientName) {
        this.setState({clientName: nextProps.clientName})
        this.loadClientExchangeWalletsCustodians(nextProps.clientName)
      }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.loading !== this.state.loading) {
      this.handleOnLoading(this.state.loading);
    }
  }

  handleOnLoading = (isLoading) => {
    //notify parent that loading is finished
    if(false === isLoading) {
      this.props.handleOnLoading('ExchangeWalletsCustodiansTable', isLoading);
    }
  };

  renderError () {
    if (this.state.error && this.state.error.length > 0) {
      this.showNotification(this.state.error, 'danger')
    }
  }

  showNotification (message, type = 'primary') {
    // var color = Math.floor((Math.random() * 5) + 1)

    let options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

  columnHeaderFormatter (title)  {
      return (
          <div>
              <span style={this.columnHeaderTitle}>{title}</span>
              <i className="cas-icon cas-edit-75"></i>
          </div>
      );
  };


  render () {
    const usersColumnsConfig = [
      {
        dataField: 'caspianName',
        text: this.columnHeaderFormatter('Name in Caspian'),
        headerClasses: 'w-15 user-table-header',
        editable: !this.props.readOnly,
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'address',
        text: this.columnHeaderFormatter('Address'),
        headerClasses: 'w-15 user-table-header',
        editable: !this.props.readOnly,
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'coin',
        text: this.columnHeaderFormatter('Coin'),
        headerClasses: 'w-15 user-table-header',
        editable: !this.props.readOnly,
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
      },
      {
        dataField: 'type',
        text: this.columnHeaderFormatter('Type'),
        headerClasses: 'w-15 user-table-header',
        editable: !this.props.readOnly,
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        editor: {
          type: Type.SELECT,
          options: [
            {
              value: 'BLOCK_CHAIN',
              label: 'Block Chain'
            }, {
              value: 'WALLET',
              label: 'Wallet'
            }, {
              value: 'CUSTODIAN',
              label: 'Custodian'
            }
          ]
        },
        formatter: this.typeFormatter,
      },
      {
        dataField: 'idx',
        text: 'Actions',
        editable: false,
        formatter: this.actionsFormatter,
        headerClasses: 'w-15 user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
      }
    ]
    return (
      <Card className="card-stats card-raised" style={{display: this.props.visible ? 'block' : 'none'}}>
        <CardHeader>
          <h4>Off Exchange Wallets & Custodians</h4>
        </CardHeader>
        <CardBody>
          <div className="dashboard-table-wrapper" style={{display: this.props.visible && this.state.exchangeWalletsCustodians.length > 0 ? 'block' : 'none'}}>

            <BootstrapTable
              hover
              condensed
              keyField='idx'
              classes="table-responsive table-client-info"
              rowStyle={{ fontSize: '10px' }}
              bordered={false}
              data={this.state.exchangeWalletsCustodians}
              columns={usersColumnsConfig}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                beforeSaveCell: (oldValue, newValue, row, column) => {
                  if (oldValue !== newValue && !row.edited) {
                    this.setState({editableUserOldValue: Object.assign({}, row)})
                    row.edited = true
                  }
                },
                afterSaveCell: this.afterSaveCell
              })}/>
          </div>
          <Row>
            <Col xs={11} md={11}>
              <p style={{fontSize: 12, color: 'black'}}>If you hold currency in off exchange wallets, custodians, or
                other 3rd parties, please list them below</p>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <FormInputs
                    ncols={['col-md-3', 'col-md-3', 'col-md-3', 'col-md-3']}
                    proprieties={[
                      {
                        inputProps: {
                          type: 'text',
                          name: 'caspianName',
                          className: 'dark',
                          value: this.state.caspianName,
                          readOnly: this.props.readOnly,
                          placeholder: 'Name in Caspian',
                          onChange: this.handleChange
                        }
                      }, {
                        inputProps: {
                          type: 'text',
                          name: 'address',
                          className: 'dark',
                          value: this.state.address,
                          readOnly: this.props.readOnly,
                          placeholder: 'Address',
                          onChange: this.handleChange
                        }
                      }, {
                        inputProps: {
                          type: 'text',
                          name: 'coin',
                          className: 'dark',
                          value: this.state.coin,
                          readOnly: this.props.readOnly,
                          placeholder: 'Coin',
                          onChange: this.handleChange
                        }
                      }, {
                        inputProps: {
                          type: 'select',
                          name: 'type',
                          className: 'dark',
                          value: this.state.type,
                          disabled: this.props.readOnly,
                          placeholder: 'Type',
                          onChange: this.handleSelectChange('type'),
                          options: [
                            {label: 'Block Chain', value: 'BLOCK_CHAIN'},
                            {label: 'Wallet', value: 'WALLET'},
                            {label: 'Custodian', value: 'CUSTODIAN'}
                          ]
                        }
                      }
                    ]}
                />
                <Button disabled={this.props.readOnly}
                        onClick={this.handleAddNew}
                        className="btn btn-primary btn-sm"
                        style={{margin: '0px 0px 10px 3%'}}>
                  Add Wallet
                </Button>
              </div>
            </Col>
          </Row>
          {/*<Button onClick={this.handleSave}>Save</Button>*/}
        </CardBody>
      </Card>
    )
  }

  loadClientExchangeWalletsCustodians (clientName) {
    if (clientName!== 'View As'){
     this.setState({loading: true});
      caspianApiService.getExchangeWalletsCustodians(clientName)
          .then((exchangeWalletsCustodians) => {
            this.processExchangeWalletsCustodians(exchangeWalletsCustodians)
          }) .finally(() => this.setState({loading: false}));
    }
  }

  processExchangeWalletsCustodians (exchangeWalletsCustodians) {
    exchangeWalletsCustodians.forEach((ecw, idx) => {
      ecw.idx = idx
    });
    this.setState({
      exchangeWalletsCustodians: exchangeWalletsCustodians,
      oldClientName: this.state.clientName,
      loading: false
    });
  }

  typeFormatter (cell, row, rowIndex, formatExtraData) {
    return this.typeMap.get(cell)
  }

  actionsFormatter (cell, row, rowIndex, formatExtraData) {
    return (
      <div className="td-actions">
        <Button disabled={this.props.readOnly} className="btn btn-sm btn-icon btn-danger" onClick={this.handleRemoveRow.bind(this, row, rowIndex)}>
          <i className="now-ui-icons ui-1_simple-remove"></i>
        </Button>
      </div>
    )
  }
}

ExchangeWalletsCustodiansTable.propTypes = {
  readOnly: PropTypes.bool,
  clientName: PropTypes.string
}

export default ExchangeWalletsCustodiansTable
