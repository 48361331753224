import React from 'react'
import PropTypes from 'prop-types'
import TicketsTable from './TicketsTable'

import caspianApiService from '../../services/CaspianApiService'
import eventsService from '../../services/event-system/EventsClient.js'

class TicketsTableCard extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      ticketsList: [],
      resourcesFiles: [],
      loading: false,
      error: '',
      clientName: this.props.clientName,
      oldClientName: this.props.clientName
    }

    this.getTickets = this.getTickets.bind(this);
    this.handleOnLoading = this.handleOnLoading.bind(this);
  }

  componentWillMount () {
    eventsService.on('UPDATE_TICKETS_LIST', this.getTickets)
    this.getTickets()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.clientName !== nextProps.clientName) {
      this.setState({loading: true});
      caspianApiService.getTicketsListForAClient(nextProps.clientName)
        .then((ticketsList) => {
          this.setState({
            ticketsList: ticketsList,
            clientName: nextProps.clientName,
            oldClientName: this.state.clientName
          })
        }).finally(() => this.setState({loading: false}));
    }
  }

  componentWillUnmount () {
    eventsService.removeEventListener('UPDATE_TICKETS_LIST', this.getTickets)
  }


  componentDidMount() {
    this.handleOnLoading(this.state.loading);
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.loading !== this.state.loading) {
      this.handleOnLoading(this.state.loading);
    }
  }

  handleOnLoading = (isLoading) => {
    //notify parent that loading is finished
    if(false === isLoading) {
      this.props.onLoading('TicketsTableCard', false);
    }
  };

  getTickets () {
    this.setState({loading: true});
    let currentClient = JSON.parse(localStorage.getItem('activeClientName'))
    caspianApiService.getTicketsListForAClient(currentClient)
      .then((ticketsList) => {
        this.setState({ ticketsList })
      }).finally(() => this.setState({loading: false}));
  }

  render () {
    return (
      <div style={{visibility: this.props.visible ? 'visible' : 'hidden'}}>
        <h4>Tickets History</h4>
        <TicketsTable
          readOnly={this.props.readOnly}
          data={this.state.ticketsList} />
      </div>
    )
  }
}

TicketsTableCard.propTypes = {
  readOnly: PropTypes.bool
}

export default TicketsTableCard
