import React from 'react'
import {
  Card, CardHeader, CardTitle, CardBody,
  Container, Row, Col
} from 'reactstrap'

class Privacy extends React.Component {
  render () {
    return (
      <div>
        <div className="full-page-content static-page">
          <div>
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={8} md={8} xs={12}>
                  <Card className="content">
                    <CardHeader>
                      <CardTitle><h3>Privacy policy</h3></CardTitle>
                    </CardHeader>
                    <CardBody className="content-body">
                      <h6>CASPIAN, Limited</h6>
                      <h6>Effective July 13, 2018</h6>
                      <p>We appreciate your interest in the CASPIAN, Limited website (the “Website”) and services. We at CASPIAN, Limited (“CASPIAN,” “our,” “we” or “us”), recognize and appreciate the importance of respecting the privacy of our customers and are committed to safeguarding against unauthorized disclosure of, or access to, personal information of our customers. CASPIAN’s privacy policy (the “Privacy Policy”) sets forth CASPIAN’s current policies and practices with respect to collecting, using, protecting and maintaining nonpublic personal information provided to us by you when you access or browse the Website or use CASPIAN services.
                                            By accessing the Website and/or using CASPIAN services, you signify acceptance of the terms of this Privacy Policy and consent to the collection, use, disclosure and storage of your personal information as described below.</p>

                      <h5>INFORMATION THAT IS COLLECTED</h5>
                      <h6>Personal Information</h6>
                      <p>We only collect the personally identifiable information that you provide to us.</p>
                      <p>If you request certain services available through this Website, you may be asked to provide certain personally identifiable information, such as your first and last name, e-mail address, home address, telephone number, date of date of birth, proof of identity, investment experience, risk tolerance, information about your personal investment goals or objectives and other personal information that is deemed necessary for us to comply with the applicable laws and regulations, including, but not limited to, anti-money laundering obligations. We may also collect information about your transactions with or through us, such as your wallet balance or your account information, trading activity, and account history, if applicable.</p>
                      <p>We may use personally identifiable information in order to develop, offer and deliver products and services to you; respond to inquiries from you or your representative(s); or to fulfill legal and regulatory requirements. CASPIAN may collect nonpublic personal information about you from any of the following sources:
                        <ul>
                          <li>From you or your representative(s) on applications, registrations or other forms (e.g., your contact information);</li>
                          <li>From you or your representative(s) regarding your preferences (e.g., based on your activity on the Website);</li>
                          <li>From other interactions with CASPIAN (e.g., discussions with our customer service staff or your entry of information into our interactive tools); or</li>
                          <li>From oral and written correspondence from you (e.g., we may retain the information in a file specific to you).</li>
                        </ul>
                      </p>
                      <h6>Non-Personal Information</h6>
                      <p>We also may collect certain non-personally identifiable information when you visit the Website. This information includes the type of Internet browser and operating system you are using, the domain name of your Internet service provider, your IP (Internet Protocol) address, pages visited, and average time spent on the Website. This information may be used, for example, to alert you to software compatibility issues, or it may be analyzed to improve the Website design and functionality. When you contact us through the Website, you consent to CASPIAN’s review and internal use of your communication. We also may use non-personally identifiable information in the aggregate to analyze Website usage and to gather broad demographic information.</p>
                      <h6>Cookies</h6>
                      <p>Like most major websites, CASPIAN uses cookies on the Website. Cookies are pieces of information that a website transfers to a user’s hard drive for record-keeping purposes while visiting a Website. Cookies make using the Internet easier by, among other things, saving your passwords and preferences for you. For example, a cookie lets the Website remember that you have registered, which allows us to speed up your future activities on the Website, and which allows you to enter your password/log in ID less frequently while visiting the Website. Our cookies do not contain any personally identifying information, though cookies may enable us to relate your use of the Website to personally identifying information that you previously submitted.</p>
                      <h5>HOW WE USE AND DISCLOSE THE INFORMATION</h5>
                      <p>As a general rule, CASPIAN will not share, rent, sell or otherwise disclose any of your personal information, except with your permission. We use and disclose personal information for the purposes that we have disclosed to you, except as otherwise permitted by law. We will request your consent before we use or disclose your information for any materially different purpose. Unless specifically described below, consent may be obtained in any legally sufficient method. For example, depending on the circumstance and the laws of your jurisdiction, consent may be obtained by providing you with notice and the opportunity to opt-out. </p>
                      <p>CASPIAN may use your personal information for one or more of the following purposes:
                        <ol>
                          <li>to provide you with information or services that you request from us, including ongoing support;</li>
                          <li>to carry out our obligations and enforce our rights relating to you;</li>
                          <li>to comply with legal and/or regulatory requirements, including, but not limited to, anti-money laundering laws;</li>
                          <li>to verify your identity and confirm your eligibility to use CASPIAN services;</li>
                          <li>to notify you about changes to the Website and/or services;</li>
                          <li>to respond to your feedback or inquiries;</li>
                          <li>for any other lawful purposes with your consent.</li>
                        </ol>
                      </p>
                      <p>CASPIAN may disclose your personal information:
                        <ol>
                          <li>to unaffiliated persons or entities whom you designate;</li>
                          <li>to affiliates, including affiliated services providers;</li>
                          <li>to our third-party contractors, service providers and agents who perform services for CASPIAN, provided that such contractors, service providers and agents use the personal information only for the purposes of such services and are bound by confidentiality obligations;</li>
                          <li>to the government, law enforcement officials or third parties under certain circumstances, for example, in response to legal process, a court order or a subpoena or to report taxes, or if we believe it is necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Website Terms of Use, abuse of the Website, security breaches of the Website, or as necessary to protect our systems, business, users or others or as otherwise required by law;</li>
                          <li>as part of a merger, acquisition or other sale or disposition of our assets or business;</li>
                          <li>to other organizations, with your consent or as directed by your representative(s); or</li>
                          <li>to other organizations, as permitted or required by the laws that protect your privacy (for example, for fraud prevention).</li>
                        </ol>
                      </p>
                      <p>In addition, we may disclose aggregated information to advertisers and for marketing or promotional purposes. In these situations, we do not disclose to these entities any information that could be used to personally identify you.</p>
                      <p>We require each of the above parties to adhere to our privacy standards and to use this information only for the limited purpose for which it was shared. Individuals interact with us in a variety of ways, and under some of these variations, we may exchange information with parties in addition to those described above.</p>
                      <h5>FORMER CUSTOMERS</h5>
                      <p>If you are a former customer, your information is treated in the same manner as the information of current customers.
                                        If you have any questions or concerns regarding your privacy with CASPIAN, please contact us at <a href="mailto:privacy@caspian.tech">privacy@caspian.tech</a>.</p>
                      <h5>SECURITY</h5>
                      <p>Except as described above, we do not reveal information about you to unaffiliated third parties. We restrict access to nonpublic personal information about you to those employees who need to know that information to provide products or services to you. We maintain reasonable physical, electronic, and procedural safeguards to guard your nonpublic personal information. With respect to information given on the Internet, CASPIAN uses a variety of proven protections to maintain the security of your online session. This may include the use of firewall barriers, encryption techniques and authentication procedures.</p>
                      <h5>LINKED WEBSITES AND OTHER THIRD PARTIES</h5>
                      <p>This Privacy Policy only addresses the use and disclosure of information we collect from you. The Website, or services on the Website, may contain links to third-party websites whose information, security, and privacy practices may be different than ours. We encourage you to consult such websites’ privacy policies and terms of use prior to providing your information because we do not control information that is submitted to, or collected by, these unaffiliated third parties or the content of those linked websites. Once you access another website, you are subject to the privacy policy and terms of use of that website.</p>
                      <h5>YOUR INDIVIDUAL RIGHTS</h5>
                      <p>Right to Withdraw Consent. You have the right to withdraw your consent to our processing of your personal information collected on the basis of your consent at any time. Please note that withdrawing this consent does not affect the lawfulness of processing we may have undertaken prior to the withdrawal of consent. The withdrawal of consent will not affect the lawfulness of processing of information for which consent is not required.</p>
                      <p>Right to Access and Change Your Information. You have the right to request a copy of all personal information pertaining to you that we hold. For details on how to access your personal information collected and held by us, and how to update that information or correct factual errors in it, please contact us at privacy@caspian.tech. We may retain original and updated information for reasons such as technical constraints, dispute resolution, troubleshooting, record retention requirements and agreement enforcement. For security purposes, we may request information to help verify your identity before granting access or making changes to the information.</p>
                      <p>Right to Erasure. You may also request the deletion and/or destruction of your personal information by contacting us at <a href="mailto:privacy@caspian.tech">privacy@caspian.tech</a>. CASPIAN will promptly action your request, except, where it is not consistent with our legal and regulatory obligations.</p>
                      <h5>DATA RETENTION</h5>
                      <p>CASPIAN may store, transfer, and otherwise process your personal information in courtiers outside of the country of your residence, including Japan, Hong Kong and the United States. We store your information securely throughout the duration of your relationship with us and for a minimum of three years thereafter as necessary to comply with our legal and regulatory obligations, resolve disputes, and enforce our agreements.</p>
                      <p>Your data is only accessed internally on a need to know basis, and it will only be accessed or processed if absolutely necessary. We will delete data that is no longer required by any relevant law or jurisdiction in which we operate.</p>
                      <h5>MODIFICATIONS</h5>
                      <p>We may amend this Privacy Policy at any time by posting the amended version on the Website including the effective date of such amended version. We will inform you of any material changes to the Policy via email.</p>
                      <h5>REQUEST OF CERTAIN INFORMATION</h5>
                      <p>You may request certain information from us regarding our disclosure of your personal information to unaffiliated third parties for their direct marketing purposes. To make such a request, please send an e-mail to <a href="mailto:privacy@caspian.tech">privacy@caspian.tech</a> or write us at:</p>
                      <p>Attn: Privacy Department, CASPIAN,<br />
                                            1440 Chapin Ave, Suite 205 <br />
                                            Burlingame, California 94010 <br />
                      </p>
                      <h5>TERMS OF USE</h5>
                      <p>Please note that access to and use of the Website and its services are also subject to the <a href="terms">Website Terms of Use</a>.</p>
                      <h5>CONTACTING US</h5>
                      <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:privacy@caspian.tech">privacy@caspian.tech</a> .</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

export default Privacy
