import React from 'react'
import {
  Card, Container, Row, Col
} from 'reactstrap'
import queryString from 'qs'
import _ from 'lodash'

import { CardSuccessMessage } from '../../components'

import ResetPasswordTFAuth from '../TFAuth/ResetPasswordTFAuth'
import ResetPasswordForm from './ResetPasswordForm'

import caspianApiService from '../../services/CaspianApiService'
import SweetAlert from 'react-bootstrap-sweetalert'

class ResetPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      qrCodeImage: null,
      secretKey: null,
      error: '',
      passwordsMatch: false
    }

    this.resetPasswordLinkInvalidAlert = this.resetPasswordLinkInvalidAlert.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
  }

  componentWillMount () {
    const values = queryString.parse(this.props.location.search.replace('?', ''))
    if (_.isUndefined(values.key)) {
      this.props.history.push('/forgot-password')
    } else {
      this.setState({ resetKey: values.key })
      caspianApiService.verifyResetPasswordLinkKey(values.key)
        .then((response) => {

        })
        .catch((err) => {
          this.resetPasswordLinkInvalidAlert()
        })
    }
  }

  resetPasswordLinkInvalidAlert () {
    this.setState({
      resetPasswordLinkInvalidAlert: (
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title="Password link invalid"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}>
          <div>
            <div>In order to reset your password you need a valid password reset link.
                            If you think this is a mistake please contact caspian support at: support@caspian.tech
            </div>
            <small>You will be redirected in a few seconds</small>
          </div>
        </SweetAlert>
      )
    })
    setTimeout(this.hideAlert, 5000)
  }

  hideAlert () {
    this.setState({
      resetPasswordLinkInvalidAlert: null
    })
    this.props.history.push('/login')
  }

  renderScreen () {
    if (this.state.isVerified) {
      return (
        <Card className="card-form">
          <CardSuccessMessage
            title = "Password successfully changed">
            <p>Please click <a href="login">here</a> to login with your new password.</p>
          </CardSuccessMessage>
        </Card>
      )
    } else if (this.state.passwordsMatch === true) {
      return (
        <Card className="card-form">
          <ResetPasswordTFAuth
            newPassword = {this.state.newPassword}
            resetKey = {this.state.resetKey}
            secretKey = {this.state.secretKey}
            qrCodeData = {this.state.qrCodeImage}
            onVerifiedListenerFn = { () => this.setState({ isVerified: true }) } />
        </Card>
      )
    } else {
      return (
        <ResetPasswordForm
          resetKey = {this.state.resetKey}
          onPasswordResetListenerFn = {(passwords) => {
            this.setState({
              newPassword: passwords.newPassword,
              password: passwords.confirmNewPassword,
              secretKey: passwords.secretKey,
              qrCodeImage: passwords.qrCodeImage,
              passwordsMatch: true
            })
          }} />
      )
    }
  }

  render () {
    return (
      <div className="forgot-password">
        <div className="full-page-content">
          {this.state.resetPasswordLinkInvalidAlert}
          <div>
            <Container>
              <Row className="justify-content-center">
                <Col lg={4} md={8} xs={12}>
                  {this.renderScreen()}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword
