import React from 'react'
import PropTypes from 'prop-types'
import {Alert, Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, Row} from 'reactstrap'

import {SubmitButton} from '../../components'

import caspianApiService from '../../services/CaspianApiService'
import Select from 'react-select'
import utils from '../../utils'
import Loadable from 'react-loading-overlay'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

class AddKeyForm extends React.Component {
  constructor (props) {
    super(props)

    this.organization = null
    this.clientName = null
    this.userKeysData = props.userKeysData
    this.scrollToForm = false
    this.isClearableFundNameField = false

    this.state = {
      readKey: {
        readOnly: false,
        fundName: null,
        exchange: null,
        key: "",
        subKey: "",
        secret: "",
        type: 'read',
        clientDescription: null,
      },
      writeKey: {
        readOnly: false,
        fundName: null,
        exchange: null,
        key: "",
        subKey: "",
        secret: "",
        type: 'trade',
        clientDescription: null,
      },
      account: null,
      accountWarning: false,
      exchange: null,
      fundName: null,
      selectedFundName: null,
      clientDescription: "",
      newKey: false,
      exchangeList: [],
      fundList: [],
      exchangeFullDetailsList: [],
      currentExchange: '',
      productList: [],
      productFullDetailsList: [],
      keyProducts: [],
    }

    this.handleAddKeySubmit = this.handleAddKeySubmit.bind(this)
    this.handleKeyAddSuccess = this.handleKeyAddSuccess.bind(this)
    this.addPairKey = this.addPairKey.bind(this)
    this.setFund = this.setFund.bind(this)
  }

  populatePairKey = (type, row, exchange, keyType) =>{
    let key;

    if(row.type.toLowerCase() !== type){
      return key = {
        fundName: row.fundName,
        readOnly: true,
        exchange: exchange,
        clientDescription: row.clientDescription,
        key: row.apiKey,
        subKey: row.apiSubkey,
        secret: row.apiSecret,
        account: row.account,
        type: keyType
      }
    }
    else{
      //if there is no pair key
      return key = {
        readOnly: true
      }
    }
  }

  addPairKey (row, type) {
    //if key has pair the row will be the opposite key that we want to reupload
    //e.g. if the key to be added is trade the row will be his pair - read one
    //otherwise the row will be exactly the row to be reuploaded
    this.scrollToForm = true

    const exchangeObject = this.state.exchangeList.find((value) => {
      return row.exchange === value.label;
    })
    const currentExchange = this.state.exchangeFullDetailsList.find(exchange => {
      return row.exchange.toUpperCase() === exchange.description.toUpperCase()
    })

    let currentExchangeProducts = [];
    if (currentExchange){
      currentExchangeProducts = this.state.productList.filter(product => currentExchange.products.some(exProducts => product.value === exProducts.name));
    }
    let keyProducts = [];
    if (row.products && row.products.length > 0){
      const currentKeyProducts = row.products.split(',');
      keyProducts = currentExchangeProducts.filter(product => currentKeyProducts.some(keyProduct => product.value === keyProduct));
    }
    let needsSubkey = false
    let subkeyName = "Subkey"
    if (exchangeObject !== null && exchangeObject !== undefined) {
      needsSubkey = exchangeObject.needsSubkey
      subkeyName = exchangeObject.subkeyName
    }

    const exchange = {
      label: row.exchange,
      value: currentExchange.name.toLowerCase(),
      needsSubkey: needsSubkey,
      subkeyMandatory: currentExchange.subkeyMandatory,
      subkeyName: subkeyName,
      products: currentExchangeProducts
    }

    let readKey
    let writeKey

    //make sure type of key are only 'trade' or 'read' in lowercase
    if (type === 'trade') {

      readKey = this.populatePairKey(type, row, exchange, 'read')

      writeKey = {
        //scope needed for reupload
        scope: row.fullScope,
        fundName: row.fundName,
        readOnly: false,
        exchange: exchange,
        clientDescription: row.clientDescription,
        account: row.account,
        wasExpired: row.expired,
        type: 'trade',
        key: '',
        subKey: '',
        secret: '',
        products: keyProducts,
      }
    } else if (type === 'read') {

      readKey = {
        //scope needed for reupload
        scope: row.fullScope,
        fundName: row.fundName,
        readOnly: false,
        exchange: exchange,
        clientDescription: row.clientDescription,
        account: row.account,
        wasExpired: row.expired,
        type: 'read',
        key: '',
        subKey: '',
        secret: '',
        products: keyProducts,
      }

      writeKey = this.populatePairKey(type, row, exchange, 'trade')
    }

    this.setState({
                    fundName: row.fundName,
                    error: null,
                    accountWarning: null,
                    exchange: exchange,
                    clientDescription: row.clientDescription,
                    account: row.account,
                    newKey: true,
                    readKey: readKey,
                    writeKey: writeKey,
                    currentExchange: currentExchange,
                    keyProducts: keyProducts,
                  })
  }

  handleChange = (keyType) => (event) => {
    let newState = this.state
    if (!keyType) {
      newState[event.target.name] = event.target.value
      newState.readKey[event.target.name] = event.target.value
      newState.writeKey[event.target.name] = event.target.value
    } else {
      newState[keyType][event.target.name] = event.target.value
    }
    this.setState(newState)
  }

  handleFundSelectChange = (name, newState, value) =>{
    if (value) {
      this.state.fundName = value.label
    }

    if (value && this.state.exchange) {
      const keyNumber = this.computeKeyNumber(this.state.exchange.label, value.label);
      newState.account = this.getAccount(this.state.exchange.label, keyNumber)
      newState.accountWarning = keyNumber > 0
    }

    this.setState(newState)
  }

  handleExchangeSelectChange = (name, newState, value) =>{
    if (value) {
      this.state.exchangeFullDetailsList.forEach(exchange => {
        if (exchange.name === value.label) {
          let selectedExchange = JSON.parse(JSON.stringify(exchange));
          selectedExchange.products = exchange.products.map(prod => {
            return {value: prod.name, label: prod.name}
          });
          newState.currentExchange = selectedExchange;
        }
      })
    } else {
      newState.currentExchange = ''
    }

    newState.readKey[name] = value
    newState.writeKey[name] = value
    if (value) {
      const keyNumber = this.computeKeyNumber(value.label, this.state.fundName);
      newState.account = this.getAccount(value.label, keyNumber)
      newState.accountWarning = keyNumber > 0
    } else {
      newState.account = null
      newState.accountWarning = false
    }

    this.setState(newState)
  }

  handleExchangeProductSelectChange = (name, newState, value) => {
    if (value){
      newState.keyProducts = value;
    }
    this.setState(newState);
  }

  handleSelectChange = (name) => (value) => {
    let newState = this.state
    newState[name] = value

    if (name === 'fund'){
      this.handleFundSelectChange(name, newState, value)
    }
    else if (name === 'exchange') {
      this.handleExchangeSelectChange(name, newState, value)
    }
    else if (name === 'exchange-product'){
      this.handleExchangeProductSelectChange(name, newState, value)
    }
  }

  getAccount (exchange, keyNumber) {
    return (`${exchange} ${keyNumber === 0 ? '' : keyNumber + 1}`.trim())
  }

  checkWhiteSpaces = () => {
    const regex = /\s/gm
    if (regex.test(this.state.readKey.key) || regex.test(this.state.readKey.subKey)
        || regex.test(this.state.readKey.secret)
        || regex.test(this.state.writeKey.key) || regex.test(this.state.writeKey.subKey)
        || regex.test(this.state.writeKey.secret)) {
      this.setState({showWhiteSpaceAlert: true})
    } else {
      this.validateAndSendKey()
    }
  }

  validateKeysData = () => {
    const isReadKeyPair = this.state.readKey.readOnly;
    const isWriteKeyPair = this.state.writeKey.readOnly;

    if (!this.state.exchange) {
      this.setState({error: 'Please input the exchange'})
      return null
    }

    if (!this.state.clientDescription) {
      this.setState({error: 'Please input the description'})
      return null
    }

    let mandatorySubkey = this.state.exchange.needsSubkey && this.state.exchange.subkeyMandatory;

    let allReadFieldsCompleted = !utils.isNullOrEmpty(this.state.readKey.key) &&
        !utils.isNullOrEmpty(this.state.readKey.secret) &&
        (!utils.isNullOrEmpty(this.state.readKey.subKey) || !this.state.exchange.needsSubkey || !mandatorySubkey)

    let allWriteFieldsCompleted = !utils.isNullOrEmpty(this.state.writeKey.key) &&
        !utils.isNullOrEmpty(this.state.writeKey.secret) &&
        (!utils.isNullOrEmpty(this.state.writeKey.subKey) || !this.state.exchange.needsSubkey || !mandatorySubkey)

    let oneWriteFieldCompleted = !utils.isNullOrEmpty(this.state.writeKey.key) ||
        !utils.isNullOrEmpty(this.state.writeKey.secret) ||
        !utils.isNullOrEmpty(this.state.writeKey.subKey)

    let oneReadFieldCompleted = !utils.isNullOrEmpty(this.state.readKey.key) ||
        !utils.isNullOrEmpty(this.state.readKey.secret) ||
        !utils.isNullOrEmpty(this.state.readKey.subKey)

    let sameReadFieldsCompleted =  allReadFieldsCompleted && this.state.readKey.key === this.state.readKey.secret &&
        (!this.state.exchange.needsSubkey || this.state.readKey.key === this.state.readKey.subKey);

    let sameWriteFieldsCompleted =  allWriteFieldsCompleted && this.state.writeKey.key === this.state.writeKey.secret &&
        (!this.state.exchange.needsSubkey || this.state.writeKey.key === this.state.writeKey.subKey);

    let hasErrors = false;
    let errors = [];
    if ((!allReadFieldsCompleted && oneReadFieldCompleted) || (!allReadFieldsCompleted && isWriteKeyPair)) {
      hasErrors = true;
      errors.push('Please input all the fields for the "Read" key')
    }
    if ((!allWriteFieldsCompleted && oneWriteFieldCompleted) || (!allWriteFieldsCompleted && isReadKeyPair)) {
      hasErrors = true;
      errors.push('Please input all the fields for the "Trade" key')
    }
    if (!hasErrors && !allReadFieldsCompleted && !allWriteFieldsCompleted) {
      hasErrors = true;
      errors.push('Please input all the fields for at least one key')
    }

    if (sameReadFieldsCompleted) {
      hasErrors = true;
      let errMsg = 'Please input different values for the "Read" API Key and API Secret';
      if (this.state.exchange.needsSubkey) {
        errMsg += ' and Subkey'
      }
      errors.push(errMsg)
    }

    if (sameWriteFieldsCompleted) {
      hasErrors = true;
      let errMsg = 'Please input different values for the "Trade" API Key and API Secret';
      if (this.state.exchange.needsSubkey) {
        errMsg += ' and Subkey'
      }
      errors.push(errMsg)
    }

    if (hasErrors && errors.length > 0) {
      this.setState({error: errors})
      return null
    }

    this.setState({error: null})
    return {
      sendReadKey: allReadFieldsCompleted && !isReadKeyPair,
      sendWriteKey: allWriteFieldsCompleted && !isWriteKeyPair,
    }
  }

  computeKeyNumber (exchange, fundName) {
    const accounts = new Set();
    this.userKeysData
        .filter(key => key.exchange !== null && key.fundName !== null)
        .filter(key => key.exchange.toLowerCase() === exchange.toLowerCase() &&
            key.fundName.toLowerCase() === fundName.toLowerCase())
        .forEach(key => accounts.add(key.account.trim()));

    let keyNumber = 0;
    while (accounts.has(this.getAccount(exchange, keyNumber))) {
      keyNumber++;
    }

    return keyNumber;
  }

  newAPIKey = () => {
    this.setState({
                    newKey: true,
                    fundName: this.state.selectedFundName,
                  })
  }

  componentWillMount () {

    caspianApiService.getClientFundsList(this.props.clientName).then(result => {
      if (result) {
        if (result.length > 0) {
          this.setState({fundName: result[0].name, selectedFundName: result[0].name,})
        }
        this.setState({
                        fundList: result.map(fund => {
                          return {
                            value: fund.name,
                            label: fund.name,
                          }
                        })
                      })
      }
    })

    caspianApiService.getAllExchanges().then(r => {
      if (r) {
        this.setState({
                        exchangeList: r.map(ex => {
                          return {
                            value: ex.name.toLowerCase(),
                            label: ex.description,
                            needsSubkey: ex.needsSubkey,
                            subkeyMandatory: ex.subkeyMandatory,
                            subkeyName: ex.subkeyName,
                          }
                        }),
                        exchangeFullDetailsList: r
                      })
      }
    })

    caspianApiService.getProductList().then(response =>{
      if (response){
        this.setState({
                        productList: response.map(product => {
                          return {
                            value: product.name,
                            label: product.name,
                          }
                        }),
                        productFullDetailsList: response
                      })
      }
    })

  }


  componentDidUpdate () {
    const readKeyRights = document.getElementById('read-key-rights-row')
    const writeKeyRights = document.getElementById('write-key-rights-row')
    const readKeyRightsEmpty = document.getElementById('read-key-rights-row-empty')
    const writeKeyRightsEmpty = document.getElementById('write-key-rights-row-empty')

    if (readKeyRights || writeKeyRights) {
      if (writeKeyRights === null) {
        const readKeyRightsHeaderClientHeight = readKeyRights.getElementsByTagName('p')[0].clientHeight
        const readKeyRightsMessageClientHeight = readKeyRights.getElementsByTagName('p')[1].clientHeight

        readKeyRightsEmpty.style.display = 'none';
        writeKeyRightsEmpty.style.display = 'block';
        writeKeyRightsEmpty.style.height = readKeyRightsMessageClientHeight + readKeyRightsHeaderClientHeight + 40 + 'px';
        readKeyRights.style.height = readKeyRightsMessageClientHeight + readKeyRightsHeaderClientHeight + 40 + 'px';
      } else if (readKeyRights === null) {
        const writeKeyRightsHeaderClientHeight = writeKeyRights.getElementsByTagName('p')[0].clientHeight
        const writeKeyRightsMessageClientHeight = writeKeyRights.getElementsByTagName('p')[1].clientHeight

        writeKeyRightsEmpty.style.display = 'none';
        readKeyRightsEmpty.style.display = 'block';
        readKeyRightsEmpty.style.height = writeKeyRightsMessageClientHeight + writeKeyRightsHeaderClientHeight + 40 + 'px';
        writeKeyRights.style.height = writeKeyRightsMessageClientHeight + writeKeyRightsHeaderClientHeight + 40 + 'px';
      } else {
        const readKeyRightsHeaderClientHeight = readKeyRights.getElementsByTagName('p')[0].clientHeight
        const readKeyRightsMessageClientHeight = readKeyRights.getElementsByTagName('p')[1].clientHeight
        const writeKeyRightsHeaderClientHeight = writeKeyRights.getElementsByTagName('p')[0].clientHeight
        const writeKeyRightsMessageClientHeight = writeKeyRights.getElementsByTagName('p')[1].clientHeight
        const height = Math.max(readKeyRightsMessageClientHeight + readKeyRightsHeaderClientHeight,
                                writeKeyRightsMessageClientHeight + writeKeyRightsHeaderClientHeight)

        readKeyRights.style.height = height + 40 + 'px'
        writeKeyRights.style.height = height + 40 + 'px'
        readKeyRightsEmpty.style.display = 'none';
        writeKeyRightsEmpty.style.display = 'none';
      }
    } else {
      if (readKeyRightsEmpty) {
        readKeyRightsEmpty.style.display = 'none';
      }

      if (writeKeyRightsEmpty) {
        writeKeyRightsEmpty.style.display = 'none';
      }
    }

    if (this.scrollToForm) {
      this.formBottom.scrollIntoView({behavior: 'smooth', block: 'end'})
      this.scrollToForm = false
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.clientName !== nextProps.clientName && nextProps.clientName !== 'View As') {
      this.clientName = nextProps.clientName
      this.setState({
                      fundList: [],
                      fundName: null
                    })
      caspianApiService.getClientFundsList(this.clientName).then(result => {
        if (result) {
          if (result.length > 0) {
            this.setState({fundName: result[0].name, selectedFundName: result[0].name,})
          }
          if(result.length !== 0){
            this.organization = result[0].organization
          }
          this.setState({
                          fundList: result.map(fund => {
                            return {
                              value: fund.name,
                              label: fund.name,
                            }
                          })
                        })
        }
      })
      this.setState(this.clearData())
    }

    if (nextProps.userKeysData) {
      this.userKeysData = nextProps.userKeysData
    }
  }

  handleAddKeySubmit (event) {
    event.preventDefault()

    this.checkWhiteSpaces()
  }

  validateAndSendKey = () => {
    this.setState({showWhiteSpaceAlert: false})
    const validation = this.validateKeysData()
    if (!validation) {
      return
    }
    const keyNumber = this.getKeyNumberFromAccount(this.state.account);

    this.setState({loading: true})
    let readKeyPromise = null
    if (validation.sendReadKey) {
      readKeyPromise = caspianApiService.createNewMSSKey(
          this.clientName,
          this.organization,
          this.state.fundName,
          this.state.readKey.exchange.value,
          this.state.readKey.type,
          this.state.readKey.key,
          this.state.readKey.subKey,
          this.state.readKey.secret,
          keyNumber,
          this.state.readKey.clientDescription,
          this.state.readKey.wasExpired,
          this.state.readKey.scope,
          this.state.keyProducts.map(product => product.value).join(',')
      )
    }

    let writeKeyPromise = null
    if (validation.sendWriteKey) {
      writeKeyPromise = caspianApiService.createNewMSSKey(
          this.clientName,
          this.organization,
          this.state.fundName,
          this.state.writeKey.exchange.value,
          this.state.writeKey.type,
          this.state.writeKey.key,
          this.state.writeKey.subKey,
          this.state.writeKey.secret,
          keyNumber,
          this.state.readKey.clientDescription,
          this.state.writeKey.wasExpired,
          this.state.writeKey.scope,
          this.state.keyProducts.map(product => product.value).join(',')
      )
    }

    this.showLoader(true, 'Uploading the keys. Please stand-by')

    Promise.all([readKeyPromise, writeKeyPromise])
           .then((newUser) => {
             this.handleKeyAddSuccess()
           }, () => {
             this.handleKeyAddFailed()
           })
           .catch((error) => {
             this.setState({error: error.errorMessage})
           })
           .finally(() => {
             this.showLoader(false)
             this.setState({loading: false})
           })
  }

  handleKeyAddSuccess = () => {
    this.setState({
                    error: null,
                    success: 'The key was successfully added. It will be reflected in the portal in a few minutes'
                  })

    setTimeout(() => {
      this.clearData()
      this.props.refreshKeys()
    }, 5000)
  }

  clearData = () => {
    this.setState({
                    loading: false,
                    exchange: null,
                    account: null,
                    accountWarning: false,
                    newKey: false,
                    success: null,
                    clientDescription: '',
                    error: null,
                    readKey: {
                      readOnly: false,
                      exchange: null,
                      key: '',
                      subKey: '',
                      secret: '',
                      type: 'read',
                      clientDescription: null,
                    },
                    writeKey: {
                      readOnly: false,
                      exchange: null,
                      key: '',
                      subKey: '',
                      secret: '',
                      type: 'trade',
                      clientDescription: null,
                    },
                  })
  }

  handleKeyAddFailed = () => {
    this.setState({
                    error: 'Sorry, the key couldn\'t be added, please try again.',
                    success: null
                  })
  }

  handleCancelNewKey = () => {
    this.clearData()
    this.setState({
                    loading: false,
                    key: '',
                    subKey: '',
                    secret: '',
                    exchange: null,
                    type: null,
                    fingerprint: '',
                    newKey: false,
                    currentExchange: '',
                    accountWarning: false
                  })
  }

  renderError () {
    if (this.state.error) {
      if (Array.isArray(this.state.error)) {
        return (
            <Alert color="danger">
              {this.state.error.map((val) => (
                  <div>
                    {val}
                  </div>
              ))}
            </Alert>
        )
      } else {
        return <Alert color="danger">{this.state.error}</Alert>
      }
    } else if (this.state.success) {
      return <Alert color="success">{this.state.success}</Alert>
    }
  }

  showLoader = (isLoading, loadingText) => {
    this.setState({isLoading, loadingText})
  }

  toggleShowWhiteSpaceAlert = () => {
    this.setState({showWhiteSpaceAlert: !this.state.showWhiteSpaceAlert})
  }

  setFund(fund) {
    let validFund = this.state.fundList
                        .map(x => x.value)
                        .includes(fund)

    let newFundName = this.state.fundList[0].value
    if (validFund) {
      newFundName = fund
    }

    this.setState({selectedFundName: newFundName})
  }

  render() {
    let {currentExchange} = this.state
    const hasExistingFunds = (this.state.fundList && this.state.fundList.length > 0)
    // const typeOptions = [{value: 'read', label: 'Read'}, {value: 'trade', label: 'Trade'}]
    return (
        <Loadable active={this.state.isLoading} spinner text={this.state.loadingText}>
          <Modal isOpen={this.state.showWhiteSpaceAlert} toggle={this.toggleShowWhiteSpaceAlert}
                 contentClassName="billing-popup">
            <ModalBody>
              <i>One of the keys/secrets has white spaces, please makes sure the key is correct.</i>
              <br/>
              <br/>
              <div className="text-center confirm">Do you wish to continue?</div>
            </ModalBody>
            <ModalFooter className="text-center popup-footer">
              <Button color="primary" onClick={this.validateAndSendKey}>Continue</Button>{'   '}
              <Button color="danger" onClick={this.toggleShowWhiteSpaceAlert}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <div>
            {this.state.newKey &&
            <Form className="margin-auto addKey"
                  onSubmit={this.handleAddKeySubmit}
                  disabled={this.props.readOnly}>
              <div className="submit-button-container">
                <Button className="btn-neutral" size="sm" color="danger" onClick={this.handleCancelNewKey}>
                  <i className="now-ui-icons ui-1_simple-remove"></i>
                </Button>
              </div>
              <br/>

              <div className="security-message-container">
                <p className="security-message-header">
                  <b>
                    Your API keys are encrypted in the web page (not in the back-end), using Caspian key
                  </b>
                </p>
                <p className="security-message">
                  Please generate a new set of keys for CASPIAN use only. Do not use these keys in other applications.
                  Caspian needs 2 keys. One read-only, for the PMS (to do trade, balance recons, etc), and one with
                  trading
                  rights for the OEMS.<br/>Please note that deposits and withdrawals cannot be initiated from CASPIAN.
                  IP bounding of keys is currently not supported via the portal, but if you require it, please contact
                  your
                  Client Service Representative.
                </p>
              </div>
              <h6>New API Key Details</h6>
              {this.renderError()}


              <Row className="addUser">

                <Col md={2} className="margin-auto">
                  <Label for="fund">Fund *</Label>
                  <Select name="fund" value={this.state.fundName}
                          clearable={this.isClearableFundNameField}
                          disabled={this.state.readKey.readOnly || this.state.writeKey.readOnly}
                          options={this.state.fundList} onChange={this.handleSelectChange('fund')}
                  />
                </Col>
              </Row>


              <Row className="addUser">

                <Col md={2} className="margin-auto">
                  <Label for="exchange">Exchange *</Label>
                  <Select name="exchange" value={this.state.exchange}
                          disabled={this.state.readKey.readOnly || this.state.writeKey.readOnly}
                          onChange={this.handleSelectChange('exchange')} options={this.state.exchangeList}
                  />
                </Col>
              </Row>

              <Row className="exchange-product-row">

                <Col md={3} className="margin-auto">
                  <Label for="exchange_products">Exchange Asset Classes</Label>
                  <Select name="exchange_products" multi value={this.state.keyProducts}
                          disabled={this.state.readKey.readOnly || this.state.writeKey.readOnly}
                          onChange={this.handleSelectChange('exchange-product')}
                          options={this.state.currentExchange ? this.state.currentExchange.products : []}
                  />
                </Col>
              </Row>

              {this.state.account &&
              <Row>
                <Col md={2} className="margin-auto">
                  <Label for="account">
                    Account
                  </Label>
                  <div id='account'>
                    {this.state.account}
                  </div>
                </Col>
              </Row>
              }
              {this.state.accountWarning &&
              <Alert color="warning" className="text-center">
                You are adding another account to the same Exchange, please make sure you actually have multiple account
                on the same exchange
              </Alert>
              }
              <Row>
                <Col md={4} className="margin-auto">
                  <Label for="clientDescription">Description *</Label>
                  <Input type="text" name="clientDescription" value={this.state.clientDescription}
                         readOnly={false}
                         onChange={this.handleChange(null)}
                  />
                </Col>
              </Row>
              <Row>
                {/*Col for type read*/}
                <Col md={6}>
                  <Col md={8} className="margin-auto">
                    <Row id="read-key-rights-row-empty" className="key-rights">
                      <Col>
                        <p className="key-rights-header">
                          <b>Read Key Rights</b>
                        </p>
                        <p className="key-rights-message">{currentExchange.readKeyRights}</p>
                      </Col>
                    </Row>
                    {(currentExchange && currentExchange.readKeyRights) &&
                    <Row id="read-key-rights-row" className="key-rights">
                      <Col>
                        <p className="key-rights-header">
                          <b>Read Key Rights</b>
                        </p>
                        <p className="key-rights-message">{currentExchange.readKeyRights}</p>
                      </Col>
                    </Row>
                    }
                    <Row id="read-key-types" className="key-types">
                      <Col>
                        <h6 className="text-center">Read</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label for="key">API Key *</Label>
                        <Input type="textarea" rows="4" name="key" value={this.state.readKey.key}
                               onChange={this.handleChange('readKey')} readOnly={this.state.readKey.readOnly}
                        />
                      </Col>
                    </Row>
                    {(this.state.exchange && this.state.exchange.needsSubkey) &&
                    <Row>
                      <Col>
                        <Label for="subkey">{this.state.exchange.subkeyName} {this.state.exchange.subkeyMandatory ? '*'
                                                                                                                  : ''}</Label>
                        <Input type="textarea" rows="4" name="subKey" value={this.state.readKey.subKey}
                               onChange={this.handleChange('readKey')} readOnly={this.state.readKey.readOnly}
                        />
                      </Col>
                    </Row>
                    }
                    <Row>
                      <Col>
                        <Label for="secret">API Secret *</Label>
                        <Input type="textarea" rows="4" name="secret" value={this.state.readKey.secret}
                               onChange={this.handleChange('readKey')} readOnly={this.state.readKey.readOnly}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Col>

                {/*Col for type write*/}
                <Col md={6}>
                  <Col md={8} className="margin-auto">
                    <Row id="write-key-rights-row-empty" className="key-rights" style={{display: 'none'}}>
                      <Col>
                        <p className="key-rights-header">
                          <b>Trading Key Rights</b>
                        </p>
                      </Col>
                    </Row>
                    {(currentExchange && currentExchange.writeKeyRights) &&
                    <Row id="write-key-rights-row" className="key-rights">
                      <Col>
                        <p className="key-rights-header">
                          <b>Trading Key Rights</b>
                        </p>
                        <p className="key-rights-message">{currentExchange.writeKeyRights}</p>
                      </Col>
                    </Row>
                    }
                    <Row id="write-key-types" className="key-types">
                      <Col>
                        <h6 className="text-center">Trade</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label for="key">API Key *</Label>
                        <Input type="textarea" rows="4" name="key" value={this.state.writeKey.key}
                               onChange={this.handleChange('writeKey')} readOnly={this.state.writeKey.readOnly}
                        />
                      </Col>
                    </Row>
                    {(this.state.exchange && this.state.exchange.needsSubkey) &&
                    <Row>
                      <Col>
                        <Label for="subkey">{this.state.exchange.subkeyName} {this.state.exchange.subkeyMandatory ? '*'
                                                                                                                  : ''}</Label>
                        <Input type="textarea" rows="4" name="subKey" value={this.state.writeKey.subKey}
                               onChange={this.handleChange('writeKey')} readOnly={this.state.writeKey.readOnly}
                        />
                      </Col>
                    </Row>
                    }
                    <Row>
                      <Col>
                        <Label for="secret">API Secret *</Label>
                        <Input type="textarea" rows="4" name="secret" value={this.state.writeKey.secret}
                               onChange={this.handleChange('writeKey')} readOnly={this.state.writeKey.readOnly}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col className="submit-button-container">
                  <SubmitButton className="btn btn-primary btn-sm" type="submit" loading={this.state.loading}>
                    Upload Key
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
            }
            {!this.state.newKey &&
            <div className="submit-button-container">
              <OverlayTrigger overlay={
                <Tooltip id="tooltip-disabled">
                  {hasExistingFunds ?
                   'Click to add details for a new exchange account'
                                    : 'You don\'t have any configured funds to add an account to'}
                </Tooltip>
              }>
              <span>
                <Button className="btn btn-primary btn-sm"
                        onClick={this.newAPIKey}
                        disabled={!hasExistingFunds || utils.isUserAdminReadOnly()}>
                  Add Exchange Account
                </Button>
              </span>
              </OverlayTrigger>
            </div>
            }
          </div>
          <div ref={el => this.formBottom = el}></div>
        </Loadable>
    )

  }

  getKeyNumberFromAccount (account) {
    if (account) {
      const tokens = account.split(' ');
      if (tokens.length > 1) {
        const keyNo = parseInt(tokens[tokens.length - 1]);
        if (!isNaN(keyNo)) {
          return keyNo - 1;
        }
      }
    }
    return 0;
  }
}

AddKeyForm
    .propTypes = {
  handleSaveUserFn: PropTypes.func,
  readOnly: PropTypes.bool,
  clientName: PropTypes.string,
  refreshKeys: PropTypes.func,
  userKeysData: PropTypes.array,
}

export default AddKeyForm
