import React from 'react'
import {Col, Row} from 'reactstrap'
import $ from 'jquery'

import {Redirect} from 'react-router-dom'

import Loadable from 'react-loading-overlay'

import UsersTableCard from './UsersTableCard'
import TicketsTableCard from './TicketsTableCard'

import utils from '../../utils'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import KeysTableCard from "./KeysTableCard";
import NotificationAlert from "react-notification-alert";
import ClientsSidebar from "../../components/ClientsSidebar/ClientsSidebar";
import caspianApiService from "../../services/CaspianApiService";

class Dashboard extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: utils.getUserData(),
      clientList: [],
      visible: false,
      isDropdownOpen: false,
      clientCanUploadKeys: JSON.parse(localStorage.getItem('activeClientUploadKeys')),
      selectedClient: 'View As',
      loading: true,
    };
      this.usersTableCardLoading = true;
      this.keysTableCardLoading = true;
      this.ticketsTableCardLoading = true;

    this.handleChildOnLoading = this.handleChildOnLoading.bind(this);
  };

    componentDidMount () {
    if (utils.isUserAdmin()) {
        let clientName = JSON.parse(localStorage.getItem('activeClientName'));
        let clientCanUploadKeys = JSON.parse(localStorage.getItem('activeClientUploadKeys'));
        this.setState({
            selectedClient: clientName,
            clientCanUploadKeys: clientCanUploadKeys,
            visible: true
        });

        caspianApiService.getClientList()
            .then((clientsList) => {
                clientsList.forEach((client)=>{
                    if(client.name === clientName){
                        this.setState({
                            clientCanUploadKeys:client.canUploadKeys
                        })
                    }
                })
            })


    } else if (utils.isUserAccountManager()) {
        if (this.state.user && Array.isArray(this.state.user.clientsNames)) {

            this.setState({isMultipleClientsAccountManager: true})
        } else {
            this.setState({isMultipleClientsAccountManager: false})

            let container = document.getElementById('content'),
                footer = document.getElementById('footer');

                container.style.width = '99%';
                container.style.transition ='none';
                footer.style.paddingLeft= '0 1%';
        }

        let clientName = JSON.parse(localStorage.getItem('activeClientName'));
        this.setState({
            selectedClient: clientName,
            visible: true
        });

    }
  };

  onClientChange = () => {
      this.setState({loading: true});
      this.usersTableCardLoading = true;
      this.keysTableCardLoading = true;
      this.ticketsTableCardLoading = true;
      let clientName = '';
      let clientCanUploadKeys;
      if (utils.isUserAdmin()) {
          clientName = JSON.parse(localStorage.getItem('activeClientName'));
           clientCanUploadKeys = JSON.parse(localStorage.getItem('activeClientUploadKeys'));
      } else {
          clientName = JSON.parse(localStorage.getItem('activeClientName'));
           clientCanUploadKeys = JSON.parse(localStorage.getItem('activeClientUploadKeys'));
      }

      this.setState({
          selectedClient: clientName,
          clientCanUploadKeys: clientCanUploadKeys,
          visible: true
      })
  };

  jQueryHover() {
    $('.table-client-info > tbody > tr').hover(function () {
      $(this).find('td').each(function () {
        if ($(this).hasClass('table-cell-editable') || $(this).hasClass('table-cell-with-border')) {
          $(this).addClass('hover');
        }
      });
    }, function () {
      $(this).removeClass('hover');
    });
  };

  showNotification = (message, type = 'primary') => {
    const options = {
      place: 'tc',
        message: (
            <div  style={{marginTop: 400 +'px !important'}}>
                <div style={{marginTop: 400 +'px !important', whiteSpace: 'pre-line'}}>
                    { message }</div>
            </div>
        ),
        type: type,
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };


  render () {

    let { selectedClient, visible, user } = this.state;
    this.jQueryHover();

    if (utils.isUserAdmin()) {

        let isAdminReadOnly = false
        if(utils.isUserAdminReadOnly()){
            isAdminReadOnly = true
        }

      return (
        <div className="full-page section-image">
            <NotificationAlert ref="notificationAlert"/>
          <Loadable active={this.state.loading}>
          <div className="full-page-content small">
            <ClientsSidebar onClientChange={this.onClientChange}/>
            <div id={'content'} className="content" style={{'paddingTop': '35px'}}>
              <div>
                <Row>
                  <Col xs={12} md={12}>
                    <UsersTableCard clientName={selectedClient} visible={this.state.visible}
                                    readOnly={true}
                                    onLoading = {this.handleChildOnLoading}/>
                  </Col>
                </Row>

                  <Row>
                      <Col xs={12} md={12}>
                          <KeysTableCard clientName={selectedClient}
                                         visible={visible}
                                         readOnly={isAdminReadOnly}
                                         notificationAlertHandler={this.showNotification}
                                         clientCanUploadKeys={this.state.clientCanUploadKeys}
                                         onLoading = {this.handleChildOnLoading}/>
                      </Col>
                  </Row>

                <Row className="justify-content-center">
                  <Col xs={12} md={12}>
                    <TicketsTableCard clientName={selectedClient}
                                      visible={visible}
                                      readOnly={true}
                                      onLoading = {this.handleChildOnLoading}/>
                  </Col>
                </Row>
              </div>
            </div>
              <div style={{clear: 'both'}}> </div>
          </div>
          </Loadable>
        </div>
      )
    } else if (!utils.isUserAccountManager()) {
      return <Redirect to={'/dashboard/resources'} />
    }

    if (!utils.isUserAdmin()) {
      if (!utils.isOnboardingCompleted()) {
        if (utils.isUserAccountManager() && (utils.isAwaitingKYC() || utils.isKYCPending())) {
          return (
            <Redirect to={'/kyc'}/>
          )
        }

        if (utils.isUserAccountManager() && (utils.isKYCCompleted() || utils.isSubmittedInitialData() || utils.isApplicationApproved())) {
          return (
            <Redirect to={'/client-info'}/>
          )
        }
      }
    }

    return (
      <div className="full-page-content small" style={{marginTop: '65px'}}>
          <Loadable active={this.state.loading}>
          {
              this.state.isMultipleClientsAccountManager && this.state.isMultipleClientsAccountManager === true ?
                  <ClientsSidebar isMultipleClientsAccountManager={true} user={this.state.user} onClientChange={this.onClientChange}/>
                  :
                  ''
          }
        <div className="content" style={{'paddingTop': '35px', 'minHeight': 'calc(100vh - 160px'}} id={'content'}>
          <Row>
            <Col xs={12} md={12}>
              <UsersTableCard visible={true} clientName={selectedClient}
                              readOnlyTableFields={true}
                              onLoading={this.handleChildOnLoading}/>
            </Col>
          </Row>

          <Row>
              <Col xs={12} md={12}>
                  <KeysTableCard visible={true}
                                 readOnly={true}
                                 clientName={this.state.selectedClient}
                                 notificationAlertHandler={this.showNotification}
                                 clientCanUploadKeys={this.state.clientCanUploadKeys}
                                 onLoading = {this.handleChildOnLoading}/>
              </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={12}>
              <TicketsTableCard visible={true}
                                clientName={this.state.selectedClient}
                                onLoading = {this.handleChildOnLoading}/>
            </Col>
          </Row>
        </div>
          <div style={{clear: 'both'}}> </div>
        </Loadable>
      </div>
    )
  }

    handleChildOnLoading = (childName, isLoading) =>  {
      // console.log(childName + " notifies dashboard with loading: " + isLoading);
      switch (childName) {
            case 'UsersTableCard' : this.usersTableCardLoading = isLoading;
                break;
            case 'KeysTableCard' : this.keysTableCardLoading = isLoading;
                break;
            case 'TicketsTableCard' : this.ticketsTableCardLoading = isLoading;
                break;
            default:
                // console.log(childName + ' component loading state is ignored');
                break;
        }

        let loading = this.usersTableCardLoading || this.keysTableCardLoading || this.ticketsTableCardLoading;
        if(false === loading && false !== this.state.loading) {
            //parent still loading if any child is loading
            this.setState({loading: false})
        }
    }

}

export default Dashboard
