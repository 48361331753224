import React from 'react'
import { Alert, Button, Col, Input, Label, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import { FileUpload, FormInputs } from '../../components'
import KycForm from './KycForm.jsx'
import CaspianApiService from '../../services/CaspianApiService'
import Select from 'react-select'
import { Country } from '../../components/ArtemisData/Country'
import { Nationality } from '../../components/ArtemisData/Nationality'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/entry.nostyle'
import { CRPCorporateType } from '../../components/ArtemisData/CRPCorporateCategory'
import { CorporateType } from '../../components/ArtemisData/CorporateType'
import { CRPOptions } from '../../components/ArtemisData/CRPOptions'
import artemis_utils from '../../components/ArtemisData/artemis_utils'
import utils from '../../utils'
import Modal from 'react-awesome-modal'

var docTypeToCRPNo = {
  'CERTIFICATE OF INCORPORATION': 1,
  'BOARD RESOLUTIONS': 2,
  'FIN' : 3,
  'NATIONAL ID': 4,
  'WORK PERMIT': 5,
  'OTHERS': 6
}

var requiredFiles = [
  {
    label: 'Certification of incorporation/ certificate of good standing',
    name: 'CERTIFICATE OF INCORPORATION',
    description: '',
    objName: 'certificateOfIncorporation'
  },
  {
    label: 'Corporate diagram showing ownership stakes down to 10% and control lines (Certified)',
    name: 'BOARD RESOLUTIONS',
    description: 'Corporate diagram',
    objName: 'corporateDiagram'
  },
  {
    label: 'Register of directors',
    name: 'OTHERS',
    description: 'Register of directors',
    objName: 'registerOfDirectors'
  },
  {
    label: 'Register of members (Shareholders)',
    name: 'FIN',
    description: 'Register of members(shareholders)',
    objName: 'registerOfMembers'
  },
  {
    label: ' Copy of IDs of ALL individuals who are directors and/or shareholders',
    name: 'NATIONAL ID',
    description: 'Copy of IDs of ALL individuals who are directors or shareholders\'',
    objName: 'copyOfIndividualIds',
    multipleSelect: true,
  },
  {
    label: 'Proof of address for ALL individuals who are directors and/or shareholders (not older than 3 months)',
    name: 'WORK PERMIT',
    description: 'Proof of address for ALL individuals who are directors or shareholders (not older than 3 months)',
    objName: 'proofOfIndividualsAddress',
    multipleSelect: true
  }
]

const crpOptions = new CRPOptions();
const crpTypeOptions = crpOptions.getCRPTypeOptions();
const crpGenderOptions = crpOptions.getCRPGenderOptions();
const crpRoleOptions = crpOptions.getCRPRolesOptions();

const countryOpt = new Country().getCountriesOptions();
const nationalityOpt = new Nationality().getNationalitiesOptions();
const crpCorporateCategories = new CRPCorporateType().getCategoryOptions();
const corporateTypes = new CorporateType().getCorporateTypeOptions();

class KycCompany extends React.Component {
  handleChange = event => {
    // eslint-disable-next-line
    this.setState({[event.target.name]: event.target.value})
  }
  handleAddCRP = () => {
    this.setState({
      crp: this.state.crp.concat(
        {
          name: null,
          lastName: null,
          id: this.state.crp.length,
          proofOfAddress: null,
          crpType: null,
          role: null,
          gender: null,
          country: null,
          countryOfOperations: null,
          corporateCategory: null,
          countryOfResidence: null,
          nationality: null,
          isCorporate: false,
          isIndividual: false,
          dateAppointed: null,
          incorporationDate: null,
          idDoc: null,
        }
      )
    })
    this.shouldScrollBottom = true
  }
  handleRemoveCRP = (idx) => () => {
    this.setState({
      crp: this.state.crp.filter((d, didx) => idx !== didx)
    })
  }
  handleCRPDateChange = (idx, name) => (event) => {
    const newDirectors = this.state.crp.map((crp, didx) => {
      if (idx !== didx) return crp
      return {...crp, [`${name}`]: event}
    })
    this.setState({crp: newDirectors})
  }

  handleDateChange = (name) => (event) => {
    this.setState({[name]: event})
  };

  handleCRPChange = (idx) => (event) => {
    const newDirectors = this.state.crp.map((director, didx) => {
      if (idx !== didx) return director
      return {...director, [`${event.target.name}`]: event.target.value}
    })
    this.setState({crp: newDirectors})
  }
  handleCRPOptionChange = (idx, name) => (value) => {

    if (name === 'crpType') {
        const crp = this.state.crp;
        crp[idx].isIndividual = value && value.value === 'INDIVIDUAL'
        crp[idx].isCorporate = value && value.value === 'CORPORATE'
      this.setState({crp})
    }
    const newDirectors = this.state.crp.map((crp, didx) => {
      if (idx !== didx) return crp
      return {...crp, [`${name}`]: value}
    })
    this.setState({crp: newDirectors})
  }
  handleOptionChange = (name) => (value) => {
    this.setState({[name]: value})
  }

  handleMultipleFilesSelectChange = (type, description, objName) => (event) =>{
    Array.from(event.target.files).forEach((file, index) => {
      this.handleUploadFile(file, type, description, objName, index);
    });
  }

  handleUploadFile = (file, type, description, objName, index) =>{
    if (!this.validateFile(file)) {
      this.handleUploadFail(objName, true)
      return
    }
    this.setState({[objName]: file});

    const filesUploading = this.state.filesUploading;
    filesUploading[objName] = true
    this.setState({filesUploading: filesUploading})

    let fd = new FormData()
    fd.append('file', file)
    fd.append('filename', file.name)
    fd.append('docType', type)
    fd.append('internal_type', 'CUSTOMER')
    fd.append('description', index ? description + '-' + index : description)
    fd.append('crp_no', docTypeToCRPNo[type])
    CaspianApiService.uploadKYCDocument(fd, this.state.clientName).then(() => {
      this.updateFilesUploaded(objName)
    }, () => {
      this.handleUploadFail(objName)
    })
  }

  handleFileSelectChange = (type, description, objName) => (event) => {
    let file = event.target.files[0];
    this.handleUploadFile(file, type, description, objName);
  };

  handleFileSelectRemove = (type, description, objName) => (event) => {
    this.resetUploadFail(objName);
    this.setState({[objName]: null});
    CaspianApiService.removeKYCDocument(this.state.clientName, type, description, 'CUSTOMER', docTypeToCRPNo[type])
  };

  handleCRPFileSelectChange = (idx, type, description, objName) => (event) => {
    if (!this.validateFile(event.target.files[0])) {
      this.handleUploadFail(`CRP#${idx + 1}id`, true)
      return
    }
    const filesUploading = this.state.filesUploading
    filesUploading[`CRP#${idx + 1}id`] = true
    this.state.crp[idx][objName] = true
    this.setState({filesUploading: filesUploading})

    let fd = new FormData()
    fd.append('file', event.target.files[0])
    fd.append('filename', event.target.files[0].name)
    fd.append('docType', type)
    fd.append('internal_type', 'CRP')
    fd.append('crp_no', idx)
    fd.append('description', description)
    CaspianApiService.uploadKYCDocument(fd, this.state.clientName).then(() => {
      this.updateFilesUploaded(`CRP#${idx + 1}id`)
    }, () => {
      this.handleUploadFail(`CRP#${idx + 1}id`)
    })
  }

  validateFile = (file) => {
    return (file.size / 1024 / 1024) < 5
  }

  updateFilesUploaded (name) {
    const filesUploading = this.state.filesUploading
    filesUploading[name] = false
    this.setState({filesUploading: filesUploading})
  }

  handleCRPFileSelectRemove = (idx, type, description, objName) => () => {
    CaspianApiService.removeKYCDocument(this.state.clientName,type, description, 'CRP', idx)
    this.state.crp[idx][objName] = null
  }

  allFilesUploaded = () => {
    for (let prop in this.state.filesUploading) {
      if (this.state.filesUploading.hasOwnProperty(prop) && this.state.filesUploading[prop] === true) {
        return false
      }
    }
    return true
  }

  noFilesFailed = () => {
    for (let prop in this.state.filesUploadFail) {
      if (this.state.filesUploadFail.hasOwnProperty(prop) && this.state.filesUploadFail[prop] === true) {
        return false
      }
    }
    return true
  }

  constructor (props) {
    super(props)
    this.state = {
      error: [],
      singleSelect: null,
      fundAdmin: null,
      companyAddress: null,
      website: null,
      name: null,
      officialEmail: null,
      phoneNo: null,
      acraNumber: null,
      country: null,
      countryOfOperations: null,
      certificateOfIncorporation: null,
      proofOfIndividualsAddress: null,
      copyOfIndividualIds: null,
      corporateDiagram: null,
      entityType: null,
      crp: [],
      requiredFilesLoaded: {},
      filesUploading: {},
      fieldsErrors: new Map(),
      submitResponse: null,
      filesUploadFail: {},
      visible: this.props.visible,
      clientName: null,
      readOnly: utils.isUserAdmin(),
      readOnlyClass: utils.isUserAdmin() ? ' cursor-not-allowed' : '',
      sizeModalVisible: false,
    };

    this.shouldScrollBottom = false
    this.shouldScrollTop = false
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }


    componentWillMount () {
    this.loadExistingData(this.props.clientName)
  }

  validateAllRequiredFields () {
    let fields = new Map()
    const errors = []
    if (!this.state.name) {
      errors.push('Please enter the fund name')
      fields.set('name', true)
    }
    if (!this.state.companyAddress) {
      errors.push('Please enter the company address')
      fields.set('companyAddress', true)
    }
    if (!this.state.officialEmail) {
      errors.push('Please enter the official email')
      fields.set('officialEmail', true)
    }
    if (!this.state.country) {
      errors.push('Please enter the country of incorporation')
      fields.set('country', true)
    }
    if (!this.state.countryOfOperations) {
      errors.push('Please enter the country of operations')
      fields.set('countryOfOperations', true)
    }
    if (!this.state.entityType) {
      errors.push('Please enter the entity type')
      fields.set('entityType', true)
    }
    if (!this.state.incorporationDate) {
      errors.push('Please enter the incorporation date')
      fields.set('incorporationDate', true)
    }

    if (!this.state.acraNumber) {
      errors.push('Please enter the incorporation number')
      fields.set('acraNumber', true)
    }

    //check docs
    if (!sessionStorage.getItem(this.state.clientName +'_edit-kyc')) {
      if (!this.state.certificateOfIncorporation) {
        errors.push('Please upload the Certificate of incorporation')
        fields.set('certificateOfIncorporation', true)
      }
      if (!this.state.proofOfIndividualsAddress) {
        errors.push('Please upload Proof of address for ALL individuals who are directors and/or shareholders (not older than 3 months)')
        fields.set('proofOfIndividualsAddress', true)
      }
      if (!this.state.copyOfIndividualIds) {
        errors.push('Please upload the copy of IDs of ALL individuals who are directors and/or shareholders')
        fields.set('copyOfIndividualIds', true)
      }
      if (!this.state.corporateDiagram) {
        errors.push('Please upload Corporate diagram')
        fields.set('corporateDiagram', true)
      }
    }

    //check CRPs
    this.validateCRPFields(errors, fields)
    this.setState({error: errors, fieldsErrors: fields})

    if (errors.length > 0) {
      return false
    }
    return true
  }

  validateCRPFields (errors, fields) {
    if (this.state.crp.length < 1) {
      errors.push('Please add at least one CRP')
      fields.set(`CRPS`, true)
      return
    }

    let hasErrors = false
    this.state.crp.map((crp, idx) => {
      if (!crp.crpType) {
        hasErrors = true
        fields.set(`CRP#${idx + 1}crpType`, true)
      }
      if (!crp.dateAppointed) {
        hasErrors = true
        fields.set(`CRP#${idx + 1}dateAppointed`, true)
      }
      if (!crp.name) {
        hasErrors = true
        fields.set(`CRP#${idx + 1}name`, true)
      }
      if (!crp.role) {
        hasErrors = true
        fields.set(`CRP#${idx + 1}role`, true)
      }
      if (!crp.country) {
        hasErrors = true
        fields.set(`CRP#${idx + 1}country`, true)
      }

      if (crp.isIndividual) {
        if (!crp.lastName) {
          hasErrors = true
          fields.set(`CRP#${idx + 1}lastName`, true)
        }
        if (!crp.countryOfResidence) {
          hasErrors = true
          fields.set(`CRP#${idx + 1}countryOfResidence`, true)
        }
        if (!crp.nationality) {
          hasErrors = true
          fields.set(`CRP#${idx + 1}nationality`, true)
        }
      } else if (crp.isCorporate) {
        if (!crp.countryOfOperations) {
          hasErrors = true
          fields.set(`CRP#${idx + 1}countryOfOperations`, true)
        }
        if (!crp.corporateCategory) {
          hasErrors = true
          fields.set(`CRP#${idx + 1}corporateCategory`, true)
        }
      }
      if ((!sessionStorage.getItem(this.state.clientName +'_edit-kyc') || !crp.crpReferenceId) && !crp.idDoc) {
        hasErrors = true
        fields.set(`CRP#${idx + 1}id`, true)
      }
      if (hasErrors) {
        errors.push(`Please enter all required fields for CRP #${idx + 1}`)
      }
    })
  }

  async handleSubmit (event) {
    if (this.state.readOnly) {
      return
    }
    event.preventDefault()
    this.shouldScrollTop = true
    if (this.validateAllRequiredFields()) {
      this.props.loading(true, 'Waiting for files to be uploaded')
      await this.waitFor(this.allFilesUploaded)
      if (!this.noFilesFailed()) {
        this.props.loading(false)
        return
      }
      this.props.loading(true, 'Sending KYC data')
      if (sessionStorage.getItem(this.state.clientName +'_edit-kyc')) {
        CaspianApiService.updateKYC(this.state.clientName, this.state).then((resp) => {
          this.submitSuccessful(resp)
        }, (resp) => {
          this.submitFailed(resp)
        })
      } else {
        CaspianApiService.sendKYC(this.state, this.state.clientName).then((resp) => {
          this.submitSuccessful(resp)
        }, (resp) => {
          this.submitFailed(resp)
        })
      }
      this.setState({requestSent: true, submitResponse: null})
    }
  }

  waitFor (conditionFunction) {
    const poll = resolve => {
      if (conditionFunction() === true) {
        resolve()
      } else {
        setTimeout(_ => poll(resolve), 400)
      }
    }

    return new Promise(poll)
  }

  renderFormError () {
    if (this.state.error.length === 0) {
      return ''
    } else {
      return (
        <Alert color="danger">
          {
            this.state.error.map((obj, idx) => {
              return (
                <div key={idx}>{obj}</div>
              )
            })
          }
        </Alert>
      )
    }
  }

  componentDidUpdate () {
    if (this.shouldScrollBottom) {
      this.pageEnd.scrollIntoView({behavior: 'smooth'})
      this.shouldScrollBottom = false
    }
    if (this.shouldScrollTop) {
      this.pageTop.scrollIntoView({behavior: 'smooth'})
      this.shouldScrollTop = false
    }
  }

  renderFilesForm () {
    return (
      requiredFiles.map((fileObj, index) => {
        return (
          <Col key={index} md={4} className="vertical-bottom">
            <div>
              <div>
                <Label>{fileObj.label}</Label>
              </div>
              <FileUpload onChange={fileObj.multipleSelect ? this.handleMultipleFilesSelectChange(fileObj.name, fileObj.description, fileObj.objName) : this.handleFileSelectChange(fileObj.name, fileObj.description, fileObj.objName)}
                          onRemove={this.handleFileSelectRemove(fileObj.name, fileObj.description, fileObj.objName)}
                          isUploading={this.state.filesUploading[fileObj.objName]}
                          className={'document ' + this.getErrorClass(fileObj.objName, 'document')}
                          disabled={this.state.readOnly}
                          multipleSelect={fileObj.multipleSelect}
                          removeClass='btn-neutral'/>
              <hr/>
            </div>
          </Col>
        )
      })
    )
  }

  componentWillReceiveProps (newProps) {
    if (newProps.clientName !== this.state.clientName) {
      this.setState({visible: newProps.visible, clientName: newProps.clientName})
      if (this.props.loading) {
        this.props.loading(true)
      }

      this.loadExistingData(newProps.clientName)

      CaspianApiService.getCorporateKYCForClient(newProps.clientName).then(
        kycDTO => this.processKYCData(kycDTO), res => this.handleNoData(), res => this.handleNoData())
    }
  }

  handleNoData () {
    if (this.props.loading) {
      this.props.loading(false)

      if (utils.isUserAdmin()){
        this.setState({error: ['No KYC Data Found!']})
      }

      this.setState({
        name: '',
        companyAddress: '',
        website: '',
        phoneNo: '',
        officialEmail: '',
        incorporationDate: '',
        acraNumber: '',
        domainName: [],
        countryOfOperations: null,
        country: null,
        entityType: null,
        customerReferenceId: '',
        crp: []
      })
    }
  }

  render () {
    if (!this.props.visible) {
      return (null)
    }

    return (
      <div className="kyc">
        <Modal
          visible={this.state.sizeModalVisible}
          width="450"
          height="150"
          effect="fadeInUp"
          onClickAway={() => this.closeSizeModal()}>
          <span className={'float-right'}>
              <Button className={'btn-modal-close'} size="sm" onClick={() => this.closeSizeModal()}>
                <i className="now-ui-icons ui-1_simple-remove"/>
              </Button>
          </span>
          <div className={'modal-kyc'}>
            <span className={'modal-text'}>Please only upload files that are smaller than 5MB</span>
          </div>
        </Modal>
        <div style={{float: 'left', clear: 'both'}} id="top"
             ref={el => this.pageTop = el}>
        </div>
        <KycForm
          title="Company Details"
          description="As accustomed, we need to run verifications on your company, please fill the following details">

          {this.renderSubmitStatus()}
          {this.renderFormError()}
          {this.renderFilesUploadFail()}

          <FormInputs
            ncols={['col-md-6', 'col-md-6']}
            proprieties={[
              {
                inputProps: {
                  type: 'text',
                  name: 'name',
                  value: this.state.name,
                  onChange: this.handleChange,
                  className: this.getErrorClass('name'),
                  disabled: this.state.readOnly
                },
                label: 'Full Business Name *'
              },
              {
                inputProps: {
                  type: 'text',
                  name: 'companyAddress',
                  value: this.state.companyAddress,
                  onChange: this.handleChange,
                  className: this.getErrorClass('companyAddress'),
                  disabled: this.state.readOnly
                },
                label: 'Registered Company Address *'
              }
            ]}
          />
          <FormInputs
            ncols={['col-md-6', 'col-md-6']}
            proprieties={[
              {
                inputProps: {
                  type: 'text',
                  name: 'website',
                  value: this.state.website,
                  onChange: this.handleChange,
                  className: this.getErrorClass('website'),
                  disabled: this.state.readOnly
                },
                label: 'Website'
              },
              {
                inputProps: {
                  type: 'text',
                  name: 'officialEmail',
                  value: this.state.officialEmail,
                  onChange: this.handleChange,
                  className: this.getErrorClass('officialEmail'),
                  disabled: this.state.readOnly
                },
                label: 'Authorised Official Email *'
              }
            ]}
          />
          <Row className="justify-content-left">
            <Col md="6">
              <FormInputs
                ncols={['col-md-12']}
                proprieties={[
                  {
                    inputProps: {
                      type: 'text',
                      name: 'phoneNo',
                      value: this.state.phoneNo,
                      onChange: this.handleChange,
                      className: this.getErrorClass('phoneNo'),
                      disabled: this.state.readOnly
                    },
                    label: 'Telephone Number'
                  }
                ]}
              />
            </Col>
            <Col md="6">
              <div className="form-group">
                <Label>Entity Type *</Label>
                <Select name="entityType"
                        value={this.state.entityType}
                        onChange={this.handleOptionChange('entityType')}
                        options={corporateTypes}
                        className={this.getErrorClass('entityType', 'select')}
                        disabled={this.state.readOnly}
                />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="6">
              <div className="form-group">
                <Label>Country of Incorporation *</Label>
                <Select name="country"
                        value={this.state.country}
                        onChange={this.handleOptionChange('country')}
                        options={countryOpt}
                        className={this.getErrorClass('country', 'select')}
                        disabled={this.state.readOnly}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <Label>Country of Operations *</Label>
                <Select name="countryOfOperations"
                        value={this.state.countryOfOperations}
                        onChange={this.handleOptionChange('countryOfOperations')}
                        className={this.getErrorClass('countryOfOperations', 'select')}
                        options={countryOpt}
                        disabled={this.state.readOnly}
                />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="6">
              <FormInputs
                ncols={['col-md-12']}
                proprieties={[
                  {
                    inputProps: {
                      type: 'text',
                      name: 'acraNumber',
                      value: this.state.acraNumber,
                      onChange: this.handleChange,
                      className: this.getErrorClass('acraNumber'),
                      disabled: this.state.readOnly
                    },
                    label: 'Incorporation Number *'
                  }
                ]}
              />
            </Col>
            <Col md="6">
              <div className={`form-group ${this.getErrorClass('incorporationDate', 'date')}`}>
                <Label>Incorporation Date *</Label><br/>
                <DatePicker class='form-control' dateFormat="DD/MM/YYYY" value={this.state.incorporationDate} disabled={this.state.readOnly}
                            maxDate={new Date()} onChange={this.handleDateChange('incorporationDate')}/>
              </div>
            </Col>
          </Row>

          <h4 className="justify-content-center">Required Documents</h4>

          <Row className="justify-content-center">
            {this.renderFilesForm()}
            {/*<Col md={6}>
             <div>
             <Label>New file upload</Label>
             </div>
             <FileUpload onChange={this.handleFileSelectChange('name')} />
             <hr />
             </Col>*/}
          </Row>

          <h4 className="justify-content-center">Customer Related Persons (CRP)</h4>
          <Row className="justify-content-left kyc">
            {
              this.state.crp.map((crp, idx) =>
                (
                  <Col key={idx} md="6">
                    <div className="form-group">
                      <h5 className="row">
                        <div className="col-6 vertical-middle">CRP #{idx + 1}</div>
                        <div className="col-6">
                          <span className={"float-right" + this.state.readOnlyClass}>
                            <Button className="btn-neutral" size="sm" color="danger" disabled={crp.crpReferenceId || this.state.readOnly}
                                    onClick={this.handleRemoveCRP(idx)}>
                              <i className="now-ui-icons ui-1_simple-remove"></i>
                            </Button>
                          </span>
                        </div>
                      </h5>
                      <Label>{`CRP #${idx + 1} Type *`}</Label>
                      <Select name="crpType" clearable={false} value={crp.crpType} disabled={this.state.readOnly}
                              onChange={this.handleCRPOptionChange(idx, 'crpType')} options={crpTypeOptions}
                              className={this.getErrorClass(`CRP#${idx + 1}crpType`, 'select')}/>
                      <div className={this.getErrorClass(`CRP#${idx + 1}dateAppointed`, 'date')}>
                        <Label>{`CRP #${idx + 1} Date of Appointment *`}</Label><br/>
                        <DatePicker class='form-control' dateFormat="DD/MM/YYYY" value={crp.dateAppointed}
                                    maxDate={new Date()} disabled={this.state.readOnly}
                                    onChange={this.handleCRPDateChange(idx, 'dateAppointed')}
                        />
                      </div>
                      {(crp.isIndividual || crp.isCorporate) &&
                      <Label>{`CRP #${idx + 1} ${crp.isCorporate ? 'Corporation' : 'First'} Name *`}</Label>
                      }
                      {(crp.isIndividual || crp.isCorporate) &&
                      <Input type='text' value={crp.name}
                             name="name"
                             disabled={this.state.readOnly}
                             className={this.getErrorClass(`CRP#${idx + 1}name`, 'input')}
                             onChange={this.handleCRPChange(idx)}/>
                      }
                      {crp.isIndividual &&
                      <Label>{`CRP #${idx + 1} Last Name *`}</Label>
                      }
                      {crp.isIndividual &&
                      <Input type='text' value={crp.lastName} name="lastName"
                             onChange={this.handleCRPChange(idx)}
                             disabled={this.state.readOnly}
                             className={this.getErrorClass(`CRP#${idx + 1}lastName`, 'input')}
                      />
                      }
                      {crp.isIndividual &&
                      <Label>{`CRP #${idx + 1} Gender`}</Label>
                      }
                      {crp.isIndividual &&
                      <Select name="gender" value={crp.gender}
                              disabled={this.state.readOnly}
                              onChange={this.handleCRPOptionChange(idx, 'gender')} options={crpGenderOptions}
                              className={this.getErrorClass(`CRP#${idx + 1}gender`, 'select')}
                      />
                      }
                      <Label>{`CRP #${idx + 1} Role *`}</Label>
                      <Select multi={true} name="role" value={crp.role}
                              disabled={this.state.readOnly}
                              onChange={this.handleCRPOptionChange(idx, 'role')} options={crpRoleOptions}
                              className={this.getErrorClass(`CRP#${idx + 1}role`, 'select')}
                      />
                      {(crp.isIndividual || crp.isCorporate) &&
                      <Label>{`CRP #${idx + 1} Country ${crp.isIndividual ? 'of Birth *' : ' *'}`}</Label>
                      }
                      {(crp.isIndividual || crp.isCorporate) &&
                      <Select name="country"
                              value={crp.country}
                              disabled={this.state.readOnly}
                              onChange={this.handleCRPOptionChange(idx, 'country')} options={countryOpt}
                              className={this.getErrorClass(`CRP#${idx + 1}country`, 'select')}
                      />
                      }
                      {crp.isCorporate &&
                      <Label>{`CRP #${idx + 1} Country of Operations *`}</Label>
                      }
                      {crp.isCorporate &&
                      <Select name="countryOfOperations"
                              value={crp.countryOfOperations}
                              disabled={this.state.readOnly}
                              onChange={this.handleCRPOptionChange(idx, 'countryOfOperations')}
                              options={countryOpt}
                              className={this.getErrorClass(`CRP#${idx + 1}countryOfOperations`, 'select')}
                      />
                      }
                      {crp.isCorporate &&
                      <Label>{`CRP #${idx + 1} Corporate Category *`}</Label>
                      }
                      {crp.isCorporate &&
                      <Select name="corporateCategory"
                              value={crp.corporateCategory}
                              disabled={this.state.readOnly}
                              onChange={this.handleCRPOptionChange(idx, 'corporateCategory')}
                              options={crpCorporateCategories}
                              className={this.getErrorClass(`CRP#${idx + 1}corporateCategory`, 'select')}
                      />
                      }
                      {crp.isIndividual &&
                      <Label>{`CRP #${idx + 1} Country of Residence *`}</Label>
                      }
                      {crp.isIndividual &&
                      <Select name="countryOfResidence"
                              value={crp.countryOfResidence}
                              disabled={this.state.readOnly}
                              onChange={this.handleCRPOptionChange(idx, 'countryOfResidence')}
                              className={this.getErrorClass(`CRP#${idx + 1}countryOfResidence`, 'select')}
                              options={countryOpt}/>
                      }
                      {crp.isIndividual &&
                      <Label>{`CRP #${idx + 1} Nationality *`} </Label>
                      }
                      {crp.isIndividual &&
                      <Select name="nationality" value={crp.nationality}
                              disabled={this.state.readOnly}
                              onChange={this.handleCRPOptionChange(idx, 'nationality')} options={nationalityOpt}
                              className={this.getErrorClass(`CRP#${idx + 1}nationality`, 'select')}
                      />
                      }
                      {crp.isIndividual &&
                      <Label>{`CRP #${idx + 1} ID Document *`}</Label>
                      }
                      {crp.isCorporate &&
                      <Label>{`CRP #${idx + 1} Certificate of Incorporation *`}</Label>
                      }
                      {(crp.isCorporate || crp.isIndividual) &&
                      <br/>
                      }
                      {(crp.isCorporate || crp.isIndividual) &&
                      <FileUpload value={crp.idDoc} name="id"
                                  disabled={this.state.readOnly}
                                  onChange={this.handleCRPFileSelectChange(crp.id, crp.isIndividual ? 'NATIONAL ID' : 'CERTIFICATE OF INCORPORATION', '', 'idDoc')}
                                  onRemove={this.handleCRPFileSelectRemove(crp.id, crp.isIndividual ? 'NATIONAL ID' : 'CERTIFICATE OF INCORPORATION', '', 'idDoc')}
                                  isUploading={this.state.filesUploading[`CRP#${crp.id + 1}id`]}
                                  removeClass='btn-neutral'
                                  className={'document ' + this.getErrorClass(`CRP#${crp.id + 1}id`, 'document')}
                      />
                      }
                      {(crp.isCorporate || crp.isIndividual) &&
                      <br/>
                      }
                    </div>
                    <hr/>
                  </Col>
                )
              )
            }
          </Row>

          <div className={`align-right document ${this.getErrorClass('CRPS', 'input')}`}>
            <span className={this.state.readOnlyClass}>
              <Button disabled={this.state.readOnly} type="button" onClick={this.handleAddCRP}>Add new CRP</Button>
            </span>
          </div>

          <Row className="justify-content-center">
            <Col md={3} className={this.state.readOnlyClass}>
              <Button disabled={this.state.readOnly} className="btn w-100" type="submit" onClick={this.handleSubmit}>Submit</Button>
              <p>Your data will be submitted to our staff and we will come back to you as soon as possible.</p>
            </Col>
          </Row>
          <div style={{float: 'left', clear: 'both'}} id="bottom"
               ref={el => this.pageEnd = el}>
          </div>
        </KycForm>
      </div>
    )
  }

  getErrorClass (field, fieldType) {
    if (fieldType) {
      switch (fieldType) {
        case 'select':
          return this.state.fieldsErrors.get(field) || this.state.filesUploadFail[field] ? 'select-error' : null
        case 'date':
          return this.state.fieldsErrors.get(field) || this.state.filesUploadFail[field] ? 'error-date' : null
        default:
          return this.state.fieldsErrors.get(field) || this.state.filesUploadFail[field] ? 'error' : null
      }
    }
    return this.state.fieldsErrors.get(field) ? 'error' : null
  }

  loadExistingData (clientName) {
    if (sessionStorage.getItem(this.state.clientName +'_edit-kyc')) {
      if (this.props.loading) {
        this.props.loading(true)
      }
        CaspianApiService.getCorporateKYCForClient(clientName).then((kycDTO) => this.processKYCData(kycDTO))
    }
  }

  processKYCData (kycDTO) {
    if (this.props.loading) {
      this.props.loading(false)
    }
    let customerDTO = kycDTO.customer
    if (!customerDTO) {
      return
    }

    let crpList = []
    if (kycDTO.crp_corporate) {
      kycDTO.crp_corporate.forEach((crp) => {
        crpList.push({
          id: crpList.length,
          isCorporate: true,
          name: crp.corp_name,
          role: crp.role.map(r => (
            {
              value: r,
              label: artemis_utils.titleCase(r)
            }
          )),
          country: {
            value: crp.country,
            label: artemis_utils.titleCase(crp.country)
          },
          countryOfOperations: {
            value: crp.country_of_operations,
            label: artemis_utils.titleCase(crp.country_of_operations)
          },
          corporateCategory: {
            value: crp.corporate_category,
            label: artemis_utils.titleCase(crp.corporate_category)
          },
          crpType: {
            value: crp.crp_type,
            label: artemis_utils.titleCase(crp.crp_type)
          },
          dateAppointed: new Date(crp.date_appointed),
          customerReferenceId: kycDTO.customer.cust_rfr_id,
          crpReferenceId: crp.crp_rfr_id
        })
      })
    }

    if (kycDTO.crp_individual) {
      kycDTO.crp_individual.forEach((crp) => {
        crpList.push({
          id: crpList.length,
          isIndividual: true,
          name: crp.first_name,
          lastName: crp.last_name,
          role: crp.role.map(r => (
            {
              value: r,
              label: artemis_utils.titleCase(r)
            }
          )),
          country: {
            value: crp.country_of_birth,
            label: artemis_utils.titleCase(crp.country_of_birth)
          },
          countryOfResidence: {
            value: crp.country_of_residence,
            label: artemis_utils.titleCase(crp.country_of_residence)
          },
          nationality: {
            value: crp.nationality,
            label: artemis_utils.titleCase(crp.nationality)
          },
          crpType: {
            value: crp.crp_type,
            label: artemis_utils.titleCase(crp.crp_type)
          },
          gender: crp.gender,
          dateAppointed: new Date(crp.date_appointed),
          customerReferenceId: kycDTO.customer.cust_rfr_id,
          crpReferenceId: crp.crp_rfr_id
        })
      })
    }

    this.setState({
      name: customerDTO.name,
      companyAddress: customerDTO.addresses,
      website: customerDTO.website,
      phoneNo: customerDTO.telephone_numbers ? customerDTO.telephone_numbers[0] : '',
      officialEmail: customerDTO.emails ? customerDTO.emails[0] : '',
      incorporationDate: new Date(customerDTO.issue_date),
      acraNumber: customerDTO.acra_no,
      domainName: customerDTO.domain_name,
      error: [],
      countryOfOperations: {
        value: customerDTO.country_of_operations,
        label: artemis_utils.titleCase(customerDTO.country_of_operations)
      },
      country: {
        value: customerDTO.country,
        label: artemis_utils.titleCase(customerDTO.country)
      },
      entityType: {
        value: customerDTO.entity_type,
        label: artemis_utils.titleCase(customerDTO.entity_type)
      },
      customerReferenceId: customerDTO.cust_rfr_id,
      crp: crpList
    })
  }

  submitSuccessful (response) {
    CaspianApiService.reloadUserDetails().then(() => {
      this.props.loading(false)
      sessionStorage.removeItem(this.state.clientName +'_edit-kyc')
      window.location.reload()
    })
  }

  submitFailed (response) {
    this.props.loading(false)
    this.setState({
      submitResponse: {
        success: false,
        text: response.errorMessage ? response.errorMessage.split('\n') : ['There was an error processing this request']
      },
    })
  }

  renderSubmitStatus () {
    if (this.state.submitResponse) {
      return (
        <Alert color={this.state.submitResponse.success ? 'success' : 'danger'} className="submit-status">
          {
            this.state.submitResponse.text.map((value) => (
              <div>{value}</div>
            ))
          }
        </Alert>
      )
    }
  }

  handleUploadFail (objName, limitExcedeed = false) {
    this.shouldScrollTop = true
    this.updateFilesUploaded(objName)
    const filesUploadFail = this.state.filesUploadFail
    filesUploadFail[objName] = true
    this.setState({filesUploadFail: filesUploadFail})
    if (limitExcedeed) {
      this.setState({sizeModalVisible: true})
    }
  }

  renderFilesUploadFail () {
    if (!this.noFilesFailed()) {
      return (
        <Alert color="danger">
          Some files were not uploaded, please check and upload them again
        </Alert>
      )
    }
  }

  resetUploadFail (objName) {
    const filesUploadFail = this.state.filesUploadFail
    filesUploadFail[objName] = false
    this.setState({filesUploadFail: filesUploadFail})
  }

  closeSizeModal () {
    this.setState({sizeModalVisible: false})
  }
}

KycCompany.propTypes = {
  loading: PropTypes.func,
  visible: PropTypes.bool,
  clientName: PropTypes.string,
}

export default KycCompany
