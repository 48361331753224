import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import NotificationAlert from 'react-notification-alert';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import caspianApiService from '../../services/CaspianApiService';

import {FINAL_TICKET_STATUSES} from '../../constants';
import utils from '../../utils';
import Loader from '../../components/Loader/Loader';

const firstNameFormater = (cell, row) => {
    let activeClass
    if(row.activated) {
        activeClass = "active"
    }

    return (
        <div className="td-editable-content">
            <span className={"bullet " + activeClass }></span>
            <span>{ cell }</span>
            {
                (row.isEditable) ?
                    <i className="cas-icon cas-edit-75"></i>
                : ''
            }
        </div>
    )
}

const booleanFormatter = (cell, row) =>{
    if (cell===false || cell==='false') {
        return (
        <span style={ { color: 'red' } }>No</span>
        );
    }

    return (
        <span style={ { color: 'green' } }>Yes</span>
    );
}

class UserTable extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
          userData: this.props.data,
          editableUserIndex: null,
          editableUserOldValue: null,
          editableUser: null,
          loading: false,
          editPermission: false,
          error: ''
        };

        this.onSaveClick = this.onSaveClick.bind(this)
        this.onClearEdit = this.onClearEdit.bind(this)
        this.actionsFormatter = this.actionsFormatter.bind(this)
        this.afterSavecell = this.afterSavecell.bind(this)
        this.afterSave = this.afterSave.bind(this)
        this.isCellEditable = this.isCellEditable.bind(this)
        this.showNotification = this.showNotification.bind(this)
      }

      componentWillMount() {
        caspianApiService.getClientProductsUsed(this.props.clientName)
          .then((productsUsed) => {
            this.setState({
              clientName: this.props.clientName,
              productsUsed: productsUsed,
              error: ''
            })
          })
      }

      componentWillReceiveProps(nextProps) {
        if (nextProps.clientName !== this.state.clientName && nextProps.clientName !== 'View As') {
          caspianApiService.getClientProductsUsed(nextProps.clientName)
            .then((productsUsed) => {
              this.setState({
                userData: nextProps.data,
                clientName: nextProps.clientName,
                productsUsed: productsUsed,
                error: ''
              })
            });
        } else if (nextProps.data) {
          this.setState({userData: nextProps.data});
        }
      }

      onCancelClick(userData, e) {
        this.setState({
            error: null,
            loading: true
        })
        caspianApiService.cancelUserTicket(userData.email, this.props.clientName)
            .then((user) => {
                userData.ticketStatus = "CANCELED";
                this.props.handleCancelTicketFn(user)
                this.showNotification('Ticket has been canceled!', 'success');
            })
            .catch((error) => {
                this.setState({ error: error.errorMessage });
                this.renderError();
            })
            .finally(() => {
                this.setState({ loading: false })
            })
      }

      onDisableUserClick(userData, e) {
        this.setState({
            error: null,
            loading: true
        })

        caspianApiService.disableUser(userData.email, this.props.clientName)
            .then((user) => {
                this.showNotification('Request to disable the user has been created!', 'success');
                this.props.handleSaveUserFn(user)
            })
            .catch((error) => {
                this.setState({ error: error.errorMessage });
                this.renderError();
            })
            .finally(() => {
                this.setState({ loading: false })
            })
      }

      onEnableUserClick(userData, e) {
        this.setState({
            error: null,
            loading: true
        })

        caspianApiService.enableUser(userData.email, this.props.clientName)
            .then((user) => {
                this.showNotification('Request to enable the user has been created!', 'success');
                this.props.handleSaveUserFn(user)
            })
            .catch((error) => {
                this.setState({ error: error.errorMessage })
                this.renderError();
            })
            .finally(() => {
                this.setState({ loading: false })
            })
      }

      onSaveClick() {
        this.setState({
            error: null,
            loading: true
        })

        caspianApiService.updateUser(this.state.editableUser, this.props.clientName)
            .then((updatedUser) => {
                this.showNotification('User updated!', 'success');
                this.setState({
                    editableUserIndex: null,
                    editableUser: null,
                    editableUserOldValue: null,
                    error: null
                  })
                this.props.handleSaveUserFn(updatedUser)
            })
            .catch((error) => {
                this.setState({ error: error.errorMessage });
                this.showNotification(`The user couldn't be updated!\n${error.errorMessage}`, 'danger')
            })
            .finally(() => {
                this.setState({ loading: false })
            })
      }

      onClearEdit() {
          this.props.handleClearEditFn(this.state.editableUserOldValue)
          this.setState({
            editableUserIndex: null,
            editableUser: null,
            editableUserOldValue: null,
            error: null
          })
      }

      actionsFormatter (cell, row, rowIndex, formatExtraData) {
        if (utils.getUserData().email === row.email) {
            return null;
        }

        let readOnly = this.props.readOnly || this.props.readOnlyActions

        if (row.edited && this.state.editPermission) {
            return (
                <div className="td-actions">
                    <Button className="btn btn-primary btn-sm" disabled={readOnly} onClick={this.onSaveClick}>Save</Button>
                    <Button className="btn btn-round btn-icon btn-sm" disabled={readOnly} onClick={this.onClearEdit}>
                        <i className="cas-icon cas-simple-remove"></i>
                    </Button>
                </div>
            );
        } else {
            return (
                <div className="td-actions">
                    {
                        (row.ticketStatus === 'NEW' && row.ticketType !== 'CREATE_USER') ?
                            <Button className="btn btn-sm" disabled={readOnly} onClick={this.onCancelClick.bind(this, row)}>Cancel</Button>
                        :
                        (FINAL_TICKET_STATUSES.indexOf(row.ticketStatus) > -1) ?
                            (row.activated) ?
                                <Button className="btn btn-danger btn-sm" disabled={readOnly} onClick={this.onDisableUserClick.bind(this, row)}>Disable</Button>
                            :
                                <Button className="btn btn-primary btn-sm" disabled={readOnly} onClick={this.onEnableUserClick.bind(this, row)}>Enable</Button>
                        : ''
                    }
                </div>
            );
        }
      }


      isCellEditable (cell, row, rowIndex, colIndex) {
        if (this.props.readOnly)
            return false;

        let isEditable = ((this.state.editableUserIndex === row.id
            || this.state.editableUserIndex === null)
            && (FINAL_TICKET_STATUSES.indexOf(row.ticketStatus) > -1)
            && utils.getUserData().email !== row.email)
        row.isEditable = isEditable

        return isEditable
    }

      afterSave(row) {
          if(row.edited){
            this.setState({...{editableUserIndex: row.id, editableUser: row}})
          }
      }

    afterSavecell(oldValue, newValue, row, column) {
        this.afterSave(row)
    }



    showNotification(message, type = 'primary'){
        // var color = Math.floor((Math.random() * 5) + 1);

        let options = {
            place: 'tc',
            message: (
                <div>
                    <div>
                        { message }
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7
        }
        this.refs.notificationAlert.notificationAlert(options);
    }

      render(){
        let canViewOEMS;
        let canViewPMS;

        if (this.state.productsUsed) {
          canViewOEMS = this.state.productsUsed.includes('OEMS');
          canViewPMS = this.state.productsUsed.includes('PMS');
        }

        let head1 = "user-head1";
        let head2 = "user-head2";
        let head3 = "user-head3";
        let line1 = "line-user";
        let line2 = "line-oems";
        let line3 = "line-pms";

        if (!canViewOEMS && canViewPMS) {
          line1 = "line-admin-user-long3"
          head3 = "user-admin-head3-with-margin2"
        } else if (canViewOEMS && !canViewPMS) {
          line1 = "line-admin-user-long1"
          line2 = "line-admin-oems-long"
          head2 = "user-admin-head2-with-margin2"
        }

        let readOnly = this.props.readOnly || this.props.readOnlyTableFields

        const oemsPrivilegeOptions = [{value: 'Trading', label: 'Trading'}, {value: 'Read-Only', label: 'Read-Only'}, {value: 'None', label: 'None'}];
        const pmsPrivilegeOptions =  [{value: 'Back-Office', label: 'Back-Office'}, {value: 'Front-Office', label: 'Front-Office'}, {value: 'Read-Only', label: 'Read-Only'}, {value: 'None', label: 'None'}];
        const boolOptions = [{value: true, label: 'Yes'}, {value: false, label: 'No'}];
        const timezoneOptions = [{value: 'Europe', label: 'Europe'}, {value: 'Americas', label: 'Americas'}, {value: 'Asia', label: 'Asia'}, {value: '24h', label: '24h'}];
        const usersColumnsConfig = [
            {
                dataField: 'firstName',
                text: 'First Name',
                formatter: firstNameFormater,
                editable: !readOnly,
                headerClasses: 'w-10 user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
            },
            {
                dataField: 'lastName',
                text: 'Last Name',
                editable: !readOnly,
                headerClasses: 'w-7 user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
            },
            {
                dataField: 'phone',
                text: 'Phone',
                editable: !readOnly,
                headerClasses: 'w-7 user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
            },
            {
                dataField: 'email',
                text: 'Email',
                editable: false,
                headerClasses: 'w-10 user-table-header',
                classes: 'cursor-not-allowed'
            },
            {
                dataField: 'timezone',
                text: 'Timezone',
                editable: !readOnly,
                editor: {
                    type: Type.SELECT,
                    options: timezoneOptions
                },
                headerClasses:'user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
            },
            {
                dataField: 'login',
                text: 'Username',
                editable: false,
                headerClasses:'user-table-header',
                classes: 'cursor-not-allowed'
            },
            {
                dataField: 'lastModifiedDate',
                text: 'Last Modified',
                formatter: (cell) => {
                    let dateObj = cell;
                    if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                    }
                    return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getMonth() + 1)).slice(-2)}/${dateObj.getFullYear()}`;
                },
                editable: false,
                headerClasses: 'w-5 user-table-header',
                classes: 'cursor-not-allowed'
            },
            {
                dataField: 'status',
                text: 'Portal Status',
                editable: false,
                formatter: utils.statusFormater,
                headerClasses: 'user-table-header',
                classes: 'cursor-not-allowed'
            },
            {
                dataField: 'oemsPrivileges',
                text: 'Privileges',
                editable: !readOnly,
                editor: {
                    type: Type.SELECT,
                    options: oemsPrivilegeOptions
                },
                headerClasses: 'user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
                hidden: !canViewOEMS
            },
            {
                dataField: 'complianceAdmin',
                text: 'Compl. Admin',
                editable: !readOnly,
                headerClasses: 'w-7 user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
                editor: {
                    type: Type.SELECT,
                    options: boolOptions
                },
                formatter: booleanFormatter,
                hidden: !canViewOEMS
            },
            {
                dataField: 'oemsAdmin',
                text: 'Admin',
                editable: !readOnly,
                editor: {
                    type: Type.SELECT,
                    options: boolOptions
                },
                formatter: booleanFormatter,
                headerClasses:'user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
                hidden: !canViewOEMS
            },
            {
                dataField: 'pmsPrivileges',
                text: 'Privileges',
                editable: !readOnly,
                editor: {
                    type: Type.SELECT,
                    options: pmsPrivilegeOptions
                },
                headerClasses:'user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
                hidden: !canViewPMS
            },
            {
                dataField: 'pmsAdmin',
                text: 'Admin',
                editable: !readOnly,
                editor: {
                    type: Type.SELECT,
                    options: boolOptions
                },
                formatter: booleanFormatter,
                headerClasses:'user-table-header',
                classes: !readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
                hidden: !canViewPMS
            },
            {
                dataField: 'id',
                text: 'Actions',
                editable: false,
                formatter: this.actionsFormatter,
                headerClasses:'user-table-header'
            }
        ];

        return (
            <div className="dashboard-table-wrapper">
                <NotificationAlert ref="notificationAlert"/>

                <Loader
                    loading={this.state.loading} />

                <h6 id={head1}>User Details</h6>
                <h6 id={head2} hidden={!canViewOEMS}>OEMS</h6>
                <h6 id={head3} hidden={!canViewPMS}>PMS</h6>
                <hr className={line1}></hr>
                <hr className={line2} hidden={!canViewOEMS}></hr>
                <hr className={line3} hidden={!canViewPMS}></hr>
                <BootstrapTable
                    hover
                    condensed
                    keyField='id'
                    classes='table-responsive table-client-info'
                    rowStyle={{ fontSize: '10px' }}
                    bordered={ false }
                    data={ this.state.userData }
                    columns={ usersColumnsConfig }
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        beforeSaveCell: (oldValue, newValue, row, column) => {
                            if (!utils.isNullOrEmpty(newValue) && String(oldValue) !== newValue && !row.edited) {
                                this.setState({editableUserOldValue: Object.assign({}, row)})
                                row.edited = true
                            }
                        },
                        afterSaveCell: this.afterSavecell
                    })} />


            </div>
        )
      }

}

UserTable.propTypes = {
    data: PropTypes.array.isRequired,
    handleSaveUserFn: PropTypes.func,
    handleCancelTicketFn: PropTypes.func,
    handleDeleteUserFn: PropTypes.func,
    handleClearEditFn: PropTypes.func,
    readOnlyTableFields: PropTypes.bool,
    readOnlyActions: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default UserTable;
