import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardHeader } from 'reactstrap'

import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import caspianApiService from '../../services/CaspianApiService'


class MarketDataTable extends React.Component {

  handleChange = (event) => {
    const st = this.state
    st[event.target.name] = event.target.value
    this.setState(st)
  }

  save = () => {
      let shouldReloadData = !this.state.marketData[0].id;
          caspianApiService.postMarketData(this.state.marketData, this.props.clientName).then(() => {
              if (shouldReloadData) {
                  this.loadMarketData(this.props.clientName)
              }
          });
  };


  constructor (props) {
    super(props)

    this.state = {
      marketData: [],
      loading: false,
      clientName: this.props.clientName,
      error: '',
      caspianName: null,
      address: null,
      coin: null,
      type: null,
    }

    this.handleOnLoading = this.handleOnLoading.bind(this);
  }

  componentDidMount () {
    this.loadMarketData(this.props.clientName);
    this.handleOnLoading(this.state.loading);
  }

  componentWillReceiveProps (nextProps) {
      if (this.state.clientName !== nextProps.clientName) {
        this.setState({clientName: nextProps.clientName});
        this.loadMarketData(nextProps.clientName);
      }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.loading !== this.state.loading) {
      this.handleOnLoading(this.state.loading);
    }
  }

  handleOnLoading = (isLoading) => {
    //notify parent that loading is finished
    if(false === isLoading) {
      this.props.handleOnLoading('MarketDataTable', isLoading);
    }
  };

  renderError () {
    if (this.state.error && this.state.error.length > 0) {
      this.showNotification(this.state.error, 'danger')
    }
  }

  showNotification (message, type = 'primary') {
    // var color = Math.floor((Math.random() * 5) + 1)

    let options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

  render () {
    const usersColumnsConfig = [
      {
        text: '',
        dataField: 'product',
        editable: false,
        classes: 'cursor-not-allowed static-table-responsive static-table-row',
      },
      {
        text: '',
        dataField: 'eodMarks',
        editable: false,
        classes: 'cursor-not-allowed static-table-responsive'
      },
      {
        text: '',
        dataField: 'type',
        editable: (content, row, rowIndex) => (rowIndex === 0 && !this.props.readOnly),
        classes: (cell, row, rowIndex, colIndex) => (rowIndex === 0 && !this.props.readOnly) ? '' : 'static-table-responsive cursor-not-allowed',
        editor: {
          type: Type.SELECT,
          options: [
            {
              value: 'CoinMarketCap',
              label: 'CoinMarketCap'
            }, {
              value: 'Manual CSV upload',
              label: 'Manual CSV upload'
            },
          ]
        }
      }
    ]
    return (
      <Card className="card-stats card-raised" style={{display: this.props.visible ? 'block' : 'none'}}>
        <CardHeader>
          <h4>Market Data</h4>
        </CardHeader>
        <CardBody style={{ paddingTop: 0 }}>
          <div className="dashboard-table-wrapper" style={{display: this.props.visible && this.state.marketData.length > 0 ? 'block' : 'none'}}>

            <BootstrapTable
              hover
              condensed
              keyField='id'
              classes="table-responsive table-client-info"
              bordered={false}
              rowStyle={{ fontSize: '10px' }}
              data={this.state.marketData}
              columns={usersColumnsConfig}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                beforeSaveCell: (oldValue, newValue, row, column) => {
                  if (oldValue !== newValue && !row.edited) {
                    this.setState({editableUserOldValue: Object.assign({}, row)})
                    row.edited = true
                  }
                },
                afterSaveCell: this.afterSaveCell
              })}>

            </BootstrapTable>
            <div>* PMS accounting day is 00:00-24:00 UTC</div>
          </div>
        </CardBody>
      </Card>
    )
  }

  loadMarketData (clientName) {
    if (clientName !== 'View As'){
      this.setState({loading: true});
      caspianApiService.getMarketData(clientName)
          .then((exchangeWalletsCustodians) => {
            this.processMarketData(exchangeWalletsCustodians)
          }).finally(() => this.setState({loading: false}));
    }
  }

  processMarketData (marketDataList) {
    this.setState({
      marketData: marketDataList,
      loading: false
    })}
}

MarketDataTable.propTypes = {
  readOnly: PropTypes.bool,
  clientName: PropTypes.string
}

export default MarketDataTable
