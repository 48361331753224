import React from 'react'
import PropTypes from 'prop-types'
import TableRow from './TableRow'
import { Row, Col } from 'reactstrap'

const TableBody = ({
  rows,
  customRowClass,
  expandableRows,
  expandedRowComponentFn,
  customColumnClass,
  color
}) => {
  if (rows === null || rows.length === 0) {
    return (<Row className>
      <Col xs={12}>
        <div className="body-row row no_data_message">No data at the moment here!</div>
      </Col>
    </Row>)
  }

  return (
    <tbody key={'table'}>
      {
        rows.map(
          (rowData, index) =>
            <TableRow
              row={rowData}
              customRowClass={customRowClass}
              customColumnClass={customColumnClass}
              isExpandable={expandableRows}
              expandedRowComponentFn={
                expandedRowComponentFn && expandedRowComponentFn.bind(this, index, rowData)
              }
              key={index}
              id={index}
            />
        )
      }
    </tbody>)
}

TableBody.propTypes = {
  rows: PropTypes.array.isRequired,

  expandableRows: PropTypes.bool,
  customRowClass: PropTypes.string,
  customColumnClass: PropTypes.string
}

export default TableBody
