import React from 'react'

import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip
} from 'reactstrap'

import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import caspianApiService from '../../services/CaspianApiService'

import Loader from '../../components/Loader/Loader'
import PropTypes from 'prop-types'
import utils from '../../utils'
import NotificationAlert from 'react-notification-alert'
import caspianConfig from '../../config'
import Loadable from 'react-loading-overlay'

class FundsTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      editableFundIndex: null,
      editableFundOldValue: null,
      editableFund: null,
      loading: false,
      clientName: this.props.clientName,
      error: '',
      fundData: [],
      currencyOptions: [],
      latestStableVersion: '',
      showOemsPopUp: false,
      toggledFundName: null,
      toggledFund: null,
      showLinkPopUp: false,
      showPmsPopUp: false,
      showRemoveFundPopUp: false,
      isOemsLoading: false,
      isPmsLoading: false,
      isLinkLoading: false,
      removeFundCompleted: true,
    }

    this.columnHeaderTitle = {
      'paddingRight': '8px'
    }

    this.onSaveClick = this.onSaveClick.bind(this)
    this.onClearEdit = this.onClearEdit.bind(this)
    this.afterSavecell = this.afterSavecell.bind(this)
    this.afterSave = this.afterSave.bind(this)
    this.showNotification = this.showNotification.bind(this)
    this.actionsFormatter = this.actionsFormatter.bind(this)
    this.toggleOems = this.toggleOems.bind(this)
    this.togglePms = this.togglePms.bind(this)
    this.toggleLink = this.toggleLink.bind(this)
    this.toggleRemoveFundPopUp = this.toggleRemoveFundPopUp.bind(this);
    this.handleOnLoading = this.handleOnLoading.bind(this);

  }

  componentDidMount () {
    if (this.props.clientName !== 'View As') {
      this.setState({loading: true, clientName: this.props.clientName});
      caspianApiService.getLatestStableVersion()
                       .then((latestStableVersion) => {
                         this.setState({latestStableVersion: latestStableVersion})
                       })
      this.getCurrency();
      caspianApiService.getClientFundsList(this.props.clientName)
                       .then((fundList) => {
                         caspianApiService.getClientProductsUsed(this.props.clientName)
                                          .then((productsUsed) => {
                                            this.setState({
                                                            loading: false,
                                                            fundData: fundList,
                                                            clientName: this.props.clientName,
                                                            productsUsed: productsUsed,
                                                            error: ''
                                                          })
                                          })
                       })
        .finally(() => this.setState({loading: false}));
    }
    this.handleOnLoading(this.state.loading);
  }

  refreshData () {
    this.setState({loading: true});
    caspianApiService.getClientFundsList(this.props.clientName)
      .then((fundList) => {
        this.setState({fundData: fundList})
      }).finally(() => this.setState({loading: false}));
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.clientName !== this.state.clientName && nextProps.clientName !== 'View As') {
      this.setState({clientName: nextProps.clientName, loading: true})
      caspianApiService.getLatestStableVersion()
                       .then((latestStableVersion) => {
                         this.setState({latestStableVersion: latestStableVersion})
                       })
      this.getCurrency();
      caspianApiService.getClientFundsList(nextProps.clientName)
                       .then((fundList) => {
                         caspianApiService.getClientProductsUsed(nextProps.clientName)
                                          .then((productsUsed) => {
                                            this.setState({
                                                            fundData: fundList,
                                                            clientName: nextProps.clientName,
                                                            productsUsed: productsUsed,
                                                            error: ''
                                                          })
                                          })
                       }).finally(() => this.setState({loading: false}));
    }
  }

  getCurrency() {
    caspianApiService.getCurrencies()
                     .then((currencies) => {
                       let currencyOptions = []
                       currencies.forEach(currency => {
                         currencyOptions.push({value: currency.name, label: currency.name})
                       })
                       this.setState({currencyOptions: currencyOptions})
                     })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.loading !== this.state.loading) {
      this.handleOnLoading(this.state.loading);
    }
  }

  handleOnLoading = (isLoading) => {
    //notify parent that loading is finished
    if (false === isLoading) {
      this.props.handleOnLoading('FundsTable', isLoading);
    }
  };

  onSaveClick () {
    this.setState({
      error: null,
      loading: false
    });

    caspianApiService.updateFund(this.state.editableFund)
      .then((updatedFund) => {
        this.showNotification('Fund updated!', 'success')
        this.setState({
          editableFundIndex: null,
          editableFund: null,
          editableFundOldValue: null,
          error: null
        })
        this.props.handleSaveFundFn(updatedFund)
      })
      .catch((error) => {
        this.setState({error: error.errorMessage})
        this.renderError()
      })
      .finally(() => {
        this.setState({loading: false});
      })
  }

  onClearEdit () {
    this.props.handleClearEditFn(this.state.editableFundOldValue)
    this.setState({
      editableFundIndex: null,
      editableFund: null,
      editableFundOldValue: null,
      error: null
    })
  }

  afterSave (row) {
    if (row.edited) {
      this.setState({...{editableFundIndex: row.id, editableFund: row}})
    }
  }

  afterSavecell (oldValue, newValue, row, column) {
    this.afterSave(row)
  }

  showNotification (message, type = 'primary') {
    // var color = Math.floor((Math.random() * 5) + 1)

    let options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7
    }
    this.refs.notify.notificationAlert(options)
  }

  columnHeaderFormatter (title) {
    return (
      <div>
        <span style={this.columnHeaderTitle}>{title}</span>
        <i className="cas-icon cas-edit-75"></i>
      </div>
    )
  };

  oemsSetup = (row) => {
    const oemsTicket = utils.getTicketByType(row.tickets, "OEMS_SETUP")
    if (oemsTicket != null) {
      window.open('https://tora.my.salesforce.com/' + oemsTicket.salesforceId)
    } else {
      this.setState({toggledFundName: row.organization})
      this.setState({toggledFund: row})
      this.toggleOems()
    }

  }

  pmsSetup = (row) => {
    const pmsTicket = utils.getTicketByType(row.tickets, "PMS_BOOTSTRAP_SETUP")
    if (pmsTicket != null) {
      window.open('https://toratrading.atlassian.net/browse/' + pmsTicket.jiraId)
    } else {

      if (this.invalidCurrencyForFund(row)) {
        this.setState({error: 'Please input the account\'s currency!'})
        return
      }

      this.setState({error: ''})
      this.setState({toggledFundName: row.organization})
      this.setState({toggledFund: row})
      this.togglePms()
    }
  }

  linkSetup = (row) => {
    const linkTicket = utils.getTicketByType(row.tickets, "LINK_SETUP")
    if (linkTicket != null) {
      window.open('https://tora.my.salesforce.com/' + linkTicket.salesforceId)
    } else {
      this.setState({toggledFundName: row.organization})
      this.setState({toggledFund: row})
      this.toggleLink()
    }
  }

  removeFundPopUpSetup = (row) => {
    this.setState({
      toggledFund: row,
      toggledFundName: row.organization,
      showRemoveFundPopUp: true
    })
  }

  callOemsSetup = () => {
    this.setState({isOemsLoading: true})
    caspianApiService.oemsSetup(this.state.toggledFund)
      .then(res => {
        this.showNotification('OEMS Setup SF case created!', 'success')
        this.refreshData()
      })
      .finally(() => {
        this.setState({isOemsLoading: false})
        this.toggleOems()
      })
  }

  callPmsSetup = () => {
    this.setState({isPmsLoading: true})
    caspianApiService.pmsSetup(this.state.toggledFund)
      .then(res => {
        this.showNotification('Fund and organization created in PMS env!', 'success')
        this.refreshData()
      })
      .catch(err => {
        this.showNotification('Organization and fund could not be created. Please try again later.', 'danger')
      })
      .finally(() => {
        this.setState({isPmsLoading: false})
        this.togglePms()
      })
  }

  callLinkSetup = () => {
    this.setState({isLinkLoading: true})
    caspianApiService.linkSetup(this.state.toggledFund)
      .then(res => {
        this.showNotification('Bridge setup SF case created!', 'success')
        this.refreshData()
      })
      .finally(() => {
        this.setState({isLinkLoading: false})
        this.toggleLink()
      })
  }

  removeFund = () => {
    this.setState({removeFundCompleted: false})
    caspianApiService.removeFund(this.state.toggledFund)
      .then(res => {
        this.showNotification('Fund removal request successful!', 'success')
        this.refreshData()
      })
      .catch((err) => {
        this.showNotification(err.errorMessage, 'danger')
      })
      .finally(() => {
        this.setState({
          removeFundCompleted: true,
          showRemoveFundPopUp: false
        })
      })
  }

  isTicketResolved = ticket => ticket != null && ticket.status === 'RESOLVED'

  actionsFormatter (cell, row, rowIndex) {
    let canViewOEMS
    let canViewPMS

    if (this.state.productsUsed) {
      canViewOEMS = this.state.productsUsed.includes('OEMS')
      canViewPMS = this.state.productsUsed.includes('PMS')
    }

    let setupCompleted = row.setupCompleted
    let deleteRequested = row.status === 'PENDING_DELETION';

    let clientStatusOK = this.props.kycStatus === 'APPLICATION_APPROVED' || this.props.kycStatus === 'ONBOARDING_COMPLETED'
    let isOnboardingCompleted = this.props.kycStatus === 'ONBOARDING_COMPLETED';

    const oemsTicket = utils.getTicketByType(row.tickets, "OEMS_SETUP")
    const linkTicket = utils.getTicketByType(row.tickets, "LINK_SETUP")
    const oemsBootstrapTicket = utils.getTicketByType(row.tickets, "OEMS_BOOTSTRAP_SETUP")
    const pmsBootstrapTicket = utils.getTicketByType(row.tickets, "PMS_BOOTSTRAP_SETUP")

    let oemsDisabled = oemsTicket != null || setupCompleted || !clientStatusOK
    let pmsDisabled = row.pmsSetup || pmsBootstrapTicket != null || ((oemsTicket == null || oemsTicket.status !== 'RESOLVED') && canViewOEMS) || setupCompleted || !clientStatusOK
    let linkDisabled = linkTicket != null || !row.pmsSetup || setupCompleted || !clientStatusOK

    let oemsId = row.name.replace(/[\s\d]/g, '') + 'oems' + rowIndex
    let pmsId = row.name.replace(/[\s\d]/g, '') + 'pms' + rowIndex
    let linkId = row.name.replace(/[\s\d]/g, '') + 'link' + rowIndex
    let deleteRequestedId = row.name.replace(/[\s\d]/g, '') + 'deleteRequested' + rowIndex

    let oemsCompleted = (this.isTicketResolved(oemsTicket) && this.isTicketResolved(oemsBootstrapTicket)) || (setupCompleted && oemsTicket == null && oemsBootstrapTicket == null)
    //the bootstrap setup is taken into consideration when setting pmsSetup to true in backend
    let pmsCompleted = row.pmsSetup || setupCompleted
    let linkCompleted = (linkTicket != null && linkTicket.status === 'RESOLVED') || (setupCompleted && linkTicket == null)

    let oemsPending = oemsTicket != null && oemsTicket.status !== 'RESOLVED'
    let pmsPending = pmsBootstrapTicket != null && pmsBootstrapTicket.status !== 'RESOLVED'
    let linkPending = linkTicket != null && linkTicket.status !== 'RESOLVED'

    let oemsButtonClass = 'btn btn-primary btn-sm';
    if (oemsCompleted) {
      oemsButtonClass = 'btn btn-success btn-sm'
    }
    if (oemsPending) {
      oemsDisabled = false
      oemsButtonClass = 'btn btn-warning btn-sm'
    }

    let pmsButtonClass = 'btn btn-primary btn-sm';
    if (pmsCompleted) {
      pmsButtonClass = 'btn btn-success btn-sm'
    }
    if (pmsPending){
      pmsDisabled = false
      pmsButtonClass = 'btn btn-warning btn-sm'
    }

    let linkButtonClass = 'btn btn-primary btn-sm';
    if (linkCompleted) {
      linkButtonClass = 'btn btn-success btn-sm'
    }
    if (linkPending) {
      linkDisabled = false
      linkButtonClass = 'btn btn-warning btn-sm'
    }

    return (
      <div>
        <ButtonGroup>
          {canViewOEMS &&
          <span id={oemsId}>
              <Button disabled={oemsDisabled} className={oemsButtonClass} onClick={this.oemsSetup.bind(this, row)}>
                OEMS Setup
              </Button>
            </span>
          }
          {canViewOEMS && oemsCompleted && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={oemsId}>Completed</UncontrolledTooltip>
          }
          {canViewOEMS && oemsPending && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={oemsId}>Ticket Pending. Click to view SF
            case.</UncontrolledTooltip>
          }
          {canViewOEMS && !clientStatusOK &&
          <UncontrolledTooltip placement="top" target={oemsId}>Client has to be in Application Approved or Onboarding
            Completed status</UncontrolledTooltip>
          }
          &nbsp;
          {canViewPMS &&
          <span id={pmsId}>
              <Button disabled={pmsDisabled} className={pmsButtonClass} onClick={this.pmsSetup.bind(this, row)}>
                PMS Setup
              </Button>
            </span>
          }
          {canViewPMS && pmsCompleted && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={pmsId}>Completed</UncontrolledTooltip>
          }
          {canViewPMS && pmsPending && clientStatusOK &&
              <UncontrolledTooltip placement="top" target={pmsId}>Ticket Pending.</UncontrolledTooltip>
          }
          {canViewPMS && !clientStatusOK &&
          <UncontrolledTooltip placement="top" target={pmsId}>Client has to be in Application Approved status or
            Onboarding Completed status</UncontrolledTooltip>
          }
          &nbsp;
          {canViewOEMS && canViewPMS &&
          <span id={linkId}>
              <Button disabled={linkDisabled} className={linkButtonClass} onClick={this.linkSetup.bind(this, row)}>
                Bridge Setup
              </Button>
            </span>
          }
          {canViewOEMS && canViewPMS && linkCompleted && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={linkId}>Completed</UncontrolledTooltip>
          }
          {canViewOEMS && canViewPMS && linkPending && clientStatusOK &&
          <UncontrolledTooltip placement="top" target={linkId}>Ticket Pending. Click to view SF
            case.</UncontrolledTooltip>
          }
          {canViewOEMS && canViewPMS && !clientStatusOK &&
          <UncontrolledTooltip placement="top" target={linkId}>Client has to be in Application Approved status or
            Onboarding Completed status</UncontrolledTooltip>
          }

          &nbsp;
          {utils.isUserAdmin() && setupCompleted && isOnboardingCompleted &&
          <span id={deleteRequestedId}>
            <Button className="btn-sm btn-danger btn-icon danger-button"
                    disabled={deleteRequested}
                    onClick={this.removeFundPopUpSetup.bind(this, row)}>
              <i className="cas-icon cas-simple-remove"></i>
            </Button>
          </span>
          }
          {utils.isUserAdmin() && setupCompleted && isOnboardingCompleted &&
          <UncontrolledTooltip placement="top" target={deleteRequestedId}>Request Deletion</UncontrolledTooltip>
          }
          {utils.isUserAdmin() && setupCompleted && isOnboardingCompleted && deleteRequested &&
          <UncontrolledTooltip placement="top" target={deleteRequestedId}>Pending Deletion</UncontrolledTooltip>
          }
        </ButtonGroup>

      </div>

    )
  }

  toggleOems () {
    this.setState({showOemsPopUp: !this.state.showOemsPopUp})
  }

  togglePms () {
    this.setState({showPmsPopUp: !this.state.showPmsPopUp})
  }

  toggleLink() {
    this.setState({showLinkPopUp: !this.state.showLinkPopUp})
  }

  toggleRemoveFundPopUp() {
    this.setState({showRemoveFundPopUp: !this.state.showRemoveFundPopUp})
  }

  invalidCurrency() {
    return this.state.fundData.some(fund => {
      return this.invalidCurrencyForFund(fund)
    })
  }

  invalidCurrencyForFund(fund) {
    return fund.currency === null || fund.currency === ''
  }

  invalidAmount() {
    return this.state.fundData.some(fund => {
      return fund.aum === null || fund.aum === ''
    })
  }

  invalidFundAdmin() {
    return this.state.fundData.some(fund => {
      return fund.fundAdmin === null || fund.fundAdmin === ''
    })
  }

  validateOrganization() {
    let organizations = []

    this.state.fundData.map(fund => organizations.push(fund.organization.trim()))
    let validationMsg = this.getAppropriateErrorMessage(organizations, 'Organization');
    if (validationMsg === '') {
      let singleOrganization = new Set(organizations);
      if (singleOrganization.size !== 1) {
        validationMsg = 'Client’s funds must have the same Organization'
      }

    }
    return validationMsg
  }

  invalidFundName() {
    return this.state.fundData.some(fund => {
      return fund.name === null || fund.name === ''
    })
  }

  validateGroupName() {
    let groups = []

    this.state.fundData.map(fund => groups.push(fund.groupName.trim()))
    return this.getAppropriateErrorMessage(groups, 'Group Name')
  }

  validateFundName() {
    let names = []

    this.state.fundData.map(fund => names.push(fund.name.trim()))
    return this.getFundNameErrorMessage(names)
  }

  includesErrorOrFatal(text) {
    return text.includes('error') || text.includes('fatal')
  }

  getFundNameErrorMessage(data) {
    let erroneousString = this.getErroneousStrings(data, /^[a-zA-Z0-9\-\s]+$/g)
    let errorMessage = ''

    if (erroneousString === undefined) {
      return ''
    } else {
      let errorMessageFromFatalErrorChecking = this.checkFatalOrErrorString(erroneousString)
      if (errorMessageFromFatalErrorChecking !== '') {
        errorMessage = errorMessageFromFatalErrorChecking
      } else {
        errorMessage = ' must be alphanumeric and allowed special characters are - and space!'
      }
    }

    return 'Fund Name' + errorMessage
  }

  getErroneousStrings(data, regex) {
    return data.find(element => !element.match(regex) || this.includesErrorOrFatal(element.toLowerCase()))
  }

  checkFatalOrErrorString(erroneousString) {
    if (erroneousString.toLowerCase() === 'error' || erroneousString.toLowerCase().includes('error')) {
      return ' cannot contain the word \'ERROR\'!'
    } else if (erroneousString.toLowerCase() === 'fatal' || erroneousString.toLowerCase().includes('fatal')) {
      return ' cannot contain the word \'FATAL\'!'
    }
    return ''
  }

  getAppropriateErrorMessage(data, prefix) {
    let erroneousString = this.getErroneousStrings(data, /^([a-z0-9]+)$/gi)
    let errorMessage = ''

    if (erroneousString === undefined) {
      return ''
    } else if (erroneousString.includes(' ')) {
      errorMessage = ' must be a word only!'
    } else {
      let errorMessageFromFatalErrorChecking = this.checkFatalOrErrorString(erroneousString)
      if (errorMessageFromFatalErrorChecking !== '') {
        errorMessage = errorMessageFromFatalErrorChecking
      } else {
        errorMessage = ' must be alphanumeric!'
      }
    }

    return prefix + errorMessage
  }

  renderError() {
    return ((this.state.error) ? <Alert color="danger"
                                        style={{marginTop: 0, marginBottom: 0}}>{this.state.error}</Alert> : '')
  }

  render() {
    const amountOptions = [{value: '< 5M', label: '< 5M'}, {value: '5 - 10M', label: '5 - 10M'},
      {value: '10 - 15M', label: '10 - 15M'}, {value: '15 - 200M', label: '15 - 200M'},
      {value: '> 200M', label: '> 200M'}]
    var fundColumnsConfig = [
      {
        dataField: 'organization',
        text: this.columnHeaderFormatter('Organization'),
        editable: !this.props.readOnly,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
      },
      {
        dataField: 'name',
        text: this.columnHeaderFormatter('Fund Name'),
        editable: !this.props.readOnly,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
      },
      {
        dataField: 'groupName',
        text: this.columnHeaderFormatter('Group Name'),
        editable: !this.props.readOnly,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
      },
      {
        dataField: 'currency',
        text: this.columnHeaderFormatter('Fund Currency'),
        editable: !this.props.readOnly,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        editor: {
          type: Type.SELECT,
          options: this.state.currencyOptions
        },
      },
      {
        dataField: 'launchDate',
        text: this.columnHeaderFormatter('Launch date'),
        editable: !this.props.readOnly,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        editor: {
          type: Type.DATE
        },
        formatter: (cell) => {
          let dateObj = cell
          if (typeof cell !== 'object') {
            dateObj = new Date(cell)
          }
          if (dateObj != null) {
            return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getMonth() + 1)).slice(-2)}/${dateObj.getFullYear()}`
          }
        },
      },
      {
        dataField: 'aum',
        text: this.columnHeaderFormatter('AUM ($USD)'),
        editable: !this.props.readOnly,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        editor: {
          type: Type.SELECT,
          options: amountOptions
        }
      },
      {
        dataField: 'monthlyTradeVolume',
        text: this.columnHeaderFormatter('Monthly Trade Volume ($)'),
        editable: !this.props.readOnly,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
      },
      {
        dataField: 'fundAdmin',
        text: this.columnHeaderFormatter('Fund Admin'),
        editable: !this.props.readOnly,
        headerClasses: 'user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
      }
    ]

    if (utils.isUserAdmin() && !utils.isUserAdminReadOnly()) {
      fundColumnsConfig.push({
        dataField: 'id',
        text: 'Actions',
        hidden: !caspianConfig.ENABLE_AUTOMATIC_CREATION_OF_FUNDS_IN_PMS_AND_OMS,
        editable: false,
        formatter: this.actionsFormatter,
        headerClasses: 'user-table-header action-column'
      })
    }

    return (
      <div>
        <NotificationAlert ref="notify"/>
        <Modal isOpen={this.state.showOemsPopUp} toggle={this.toggleOems} className="center-loader">
          <Loadable active={this.state.isOemsLoading} spinner text="Please stand by ...">
            <ModalHeader toggle={this.toggle} className="text-center">Request OEMS Setup</ModalHeader>
            <ModalBody>
              This will create an OEMS Setup SF case for {this.state.toggledFundName}
              <br/>
              <br/>
              <div className="text-center confirm">Are you sure?</div>
            </ModalBody>
            <ModalFooter className="text-center popup-footer">
              <Button color="primary" onClick={this.callOemsSetup}>Yes</Button>{'   '}
              <Button color="danger" onClick={this.toggleOems}>No</Button>
            </ModalFooter>
          </Loadable>
        </Modal>

        <Modal isOpen={this.state.showPmsPopUp} toggle={this.togglePms} className="center-loader">
          <Loadable active={this.state.isPmsLoading} spinner text="Please stand by ...">
            <ModalHeader toggle={this.toggle} className="text-center">Request PMS Setup</ModalHeader>
            <ModalBody>
              This will create the fund/organization in PMS for {this.state.toggledFundName}
              <br/>
              <br/>
              <div className="text-center confirm">Are you sure?</div>
            </ModalBody>
            <ModalFooter className="text-center popup-footer">
              <Button color="primary" onClick={this.callPmsSetup}>Yes</Button>{'   '}
              <Button color="danger" onClick={this.togglePms}>No</Button>
            </ModalFooter>
          </Loadable>
        </Modal>

        <Modal isOpen={this.state.showLinkPopUp} toggle={this.toggleLink} className="center-loader">
          <Loadable active={this.state.isLinkLoading} spinner text="Please stand by ...">
            <ModalHeader toggle={this.toggle} className="text-center">Request OEMS-PMS Bridge Setup</ModalHeader>
            <ModalBody>
              This will create an OEMS-PMS Bridge Setup SF case for {this.state.toggledFundName}
              <br/>
              <br/>
              <div className="text-center confirm">Are you sure?</div>
            </ModalBody>
            <ModalFooter className="text-center popup-footer">
              <Button color="primary" onClick={this.callLinkSetup}>Yes</Button>{'   '}
              <Button color="danger" onClick={this.toggleLink}>No</Button>
            </ModalFooter>
          </Loadable>
        </Modal>

        <Modal isOpen={this.state.showRemoveFundPopUp} toggle={this.toggleRemoveFundPopUp} className='center-loader'>
          <Loadable active={!this.state.removeFundCompleted} spinner text='Your request is being processed'>
            <ModalHeader toggle={this.toggle} className='text-center'>
              Request Fund Removal for {this.state.toggledFundName}
            </ModalHeader>
            <ModalBody>
              Removing a fund implies the following: <br/>
                &ensp; -Disable all health-checks <br/>
                &ensp; -Disable all connectivity <br/>
                {this.state.toggledFund && this.state.toggledFund.pmsSetup &&
                <div>&ensp; -Disable fund in PMS</div>}
                &ensp; -Disable RAG machines <br/>
                &ensp; -Unload all exchange keys <br/>
                {this.state.toggledFund && this.state.toggledFund.setupCompleted &&
                <div>&ensp; -Delete group from Caspian</div>}

              You will receive an email with the cases created for all of the above <br/><br/>
              <div className='text-center confirm'>
                Are you sure you want to proceed?
              </div>
            </ModalBody>
            <ModalFooter className='text-center popup-footer'>
              <Button color='primary' onClick={this.removeFund}>Proceed</Button>
              <Button color='danger' onClick={this.toggleRemoveFundPopUp}>Cancel</Button>
            </ModalFooter>
          </Loadable>
        </Modal>

        <Card className="card-stats card-raised" style={{display: this.props.visible ? 'block' : 'none'}}>
          <CardHeader>
            <h4>Account setup</h4>
            {utils.isUserAdmin() && <h5>Latest OEMS Stable Version: {this.state.latestStableVersion}</h5>}
            {this.renderError()}
          </CardHeader>
          <CardBody>
            <div className="dashboard-table-wrapper">
              <Loader
                loading={this.state.loading}/>
              <BootstrapTable
                hover
                condensed
                classes='table-client-info'
                keyField='id'
                rowStyle={{fontSize: '10px'}}
                bordered={false}
                data={this.state.fundData}
                columns={fundColumnsConfig}
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                    if (oldValue !== newValue && !row.edited) {
                      this.setState({editableFundOldValue: Object.assign({}, row)})
                      row.edited = true
                    }
                  },
                  afterSaveCell: this.afterSavecell
                })}/>

            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

    save = async () => {
      if (this.state.editableFund !== null) {
        if (this.invalidFundName()) {
          this.setState({error: 'Please input the Fund\'s name!'})
          return false
        }

        let validateFundNameMsg = this.validateFundName();
        if (validateFundNameMsg !== '') {
          this.setState({error: validateFundNameMsg})
          return false
        }
        
        if (this.invalidCurrency()) {
          this.setState({error: 'Please input the account\'s currency!'})
          return false
        }
        if (this.invalidAmount()) {
          this.setState({error: 'Please input the account\'s AUM ($USD)!'})
          return false
        }

        if (this.invalidFundAdmin()) {
          this.setState({error: 'Please input the account\'s Fund Admin!'})
          return false
        }
        let validateOrganizationMsg = this.validateOrganization();
        if (validateOrganizationMsg !== '') {
          this.setState({error: validateOrganizationMsg})
          return false
        }
        let validateGroupNameMsg = this.validateGroupName();
        if (validateGroupNameMsg !== '') {
          this.setState({error: validateGroupNameMsg})
          return false
        }

        let updateResponse = await caspianApiService.updateAllFunds(this.state.fundData, this.state.clientName);
        if (updateResponse.errorMessage !== undefined) {
          //error on server-side
          this.setState({error: updateResponse.errorMessage})
          return false;
        }
        //finally save if all data valid
        this.setState({error: ''})
        return true;
      }
    }

  validFundsForFullSetup() {
    if (this.invalidCurrency()) {
      this.setState({error: 'Please input the account\'s currency!'})
      return false
    }
    this.setState({error: ''})
    return true
  }

  saveWithoutValidation = async () => {
    let updateResponse = await caspianApiService.updateAllFunds(this.state.fundData, this.state.clientName);
    if (updateResponse.errorMessage !== undefined) {
      //error on server-side
      this.setState({error: updateResponse.errorMessage})
    }
    this.setState({error: ''})
  }
}

FundsTable.propTypes = {
  data: PropTypes.array.isRequired,
  visible: PropTypes.bool,
  readOnly: PropTypes.bool,
  clientName: PropTypes.string,
  kycStatus: PropTypes.string
}

export default FundsTable
