import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

import { Redirect } from 'react-router-dom'
import utils from '../../utils'
import Keycloak from 'keycloak-js'
import CaspianApiService from '../../services/CaspianApiService'

class SSOLoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      isAuthenticated: false,
      isVerified: false,
      keyCloak: null,
      noPortalUser: false
    }
  }

  componentDidMount () {
    let keyCloak = this.state.keyCloak;

    if (keyCloak === null) {
      keyCloak = Keycloak('/keycloak.json')
      keyCloak.init({onLoad: 'login-required'}).success(authenticated => {
        if (authenticated) {
          localStorage.setItem('token', 'SSO ' + keyCloak.token)
          localStorage.setItem('logoutUrl', keyCloak.createLogoutUrl({redirectUri: window.location.protocol + '//' + window.location.host}))
          this.setState({isAuth: true})
          CaspianApiService.publishSSOLoginCompleted().then(res =>{
            CaspianApiService.reloadUserDetails().then(() => {
              this.setState({ keyCloak: keyCloak })
            })
          }).catch(err =>{
            this.setState({noPortalUser: true})
          })
        }
      })
    }
  }

    renderMessage = (message) => {
        return (
            <div className='sso-login-page'>
                <div className='full-page-content'>
                    <div className='justify-content-center'>
                        <Container>
                            <Row className='justify-content-center'>
                                <Col lg={6} md={10} xs={12}>
                                    <Card className='card-form'>
                                        <CardBody>
                                            <h3>{message}</h3>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }

  render () {
      if (utils.userIsAuthenticated() || this.state.isAuth) {
          if (this.state.noPortalUser){
              return this.renderMessage("You don’t have access to this portal. Please Contact Caspian Support!")
          }
          else if (utils.getUserData() && utils.isUserAccountManager()) {
              return <Redirect to={'/dashboard'}/>
          }
          else if (utils.getUserData()) {
              return <Redirect to={'/dashboard/resources'}/>
          }
      }

      return this.renderMessage("Please wait for authentication...")
  }
}

export default SSOLoginPage
