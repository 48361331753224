import React from 'react';
import PropTypes from 'prop-types';
import {
    CardHeader, CardTitle, CardBody,
    Form, FormGroup, Input, Label, Alert
} from 'reactstrap';

import { FormInputs, SubmitButton } from '../../components';

import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

import caspianConfig from '../../config';
import caspianApiService from '../../services/CaspianApiService';

import { ACCOUNT_TYPE } from '../../constants';

import validationUtils from '../../utils-validations';

class CreateAccountForm extends React.Component{
  constructor(props){
      super(props);
      this.state = {
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
          accountType: null,
          role: '',
          termsCheckbox: false,
          recaptchaValid: false,
          error: '',
          emailIsEditable: true,
          loading: false
      };
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
      loadReCaptcha(caspianConfig.GOOGLE_RECAPTCHA_SITE_KEY)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...nextProps})
    if(nextProps) {
        this.setState({
            accountFor: (nextProps.accountType === ACCOUNT_TYPE.COMPANY) ? nextProps.clientName : nextProps.name,
            emailIsEditable: (nextProps.email) ? false : true
        })
    }
  }

  handleChange = event => {
      // eslint-disable-next-line
      this.state[event.target.name] = event.target.value;
      this.setState(this.state);
  }

  handleChangeTermsAndCondition = event => {
      this.setState({termsCheckbox: event.target.checked});
  }

    handleRecaptchaOnChange = value => {

        caspianApiService.recaptchaCheck(value).then(isValid =>{
            this.setState({recaptchaValid: isValid})
        })
        .catch(err =>{
            this.setState({recaptchaValid: false})
        });
    }

  renderFormError() {
      return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
  }

  handleSubmit(event) {
    event.preventDefault();

    if(!this.state.name && this.state.accountType === ACCOUNT_TYPE.COMPANY){
      this.setState({error: 'Please complete your name!'})
      return;
    }

    if(!validationUtils.isValidEmail(this.state.email)){
      this.setState({error: 'Please enter a valid email!'})
      return;
    }

    if(this.state.password !== this.state.confirmPassword){
        this.setState({error: 'The two passwords fields must match!'})
        return;
    }

    if(!validationUtils.isValidPassword(this.state.password) || !validationUtils.isValidPassword(this.state.confirmPassword)){
      this.setState({error: 'The password is not strong enough!'})
      return;
    }

    if(!this.state.role && this.state.accountType === ACCOUNT_TYPE.COMPANY){
        this.setState({error: 'Please complete the role field!'})
        return;
    }

    if(!this.state.recaptchaValid) {
      this.setState({error: 'Please check the ReCaptcha!'})
      return;
    }

    if(!this.state.termsCheckbox) {
      this.setState({error: 'You must accept the terms and conditions.'})
      return;
    }

    if(this.state.accountType === ACCOUNT_TYPE.COMPANY){
        const firstName =  this.state.name.substr(0, this.state.name.indexOf(' '));
        const lastName =  this.state.name.substr(this.state.name.indexOf(' ') + 1);

        this.setState({ loading: true })
        caspianApiService.createAccountManager(this.state.accessCode, this.state.clientName, this.state.email, firstName, lastName, this.state.password, this.state.role)
            .then((qrCodeData) => {
                this.setState({ qrCodeData: qrCodeData})
                this.props.onAccountCreatedListenerFn(this.state);
            })
            .catch((err) => {
                this.setState({error: err.errorMessage});
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    if(this.state.accountType === null || this.state.accountType === ACCOUNT_TYPE.INDIVIDUAL){
        this.setState({ loading: true })
        caspianApiService.createIndividualUser(this.state.accessCode, this.state.clientName, this.state.email, this.state.password)
            .then((qrCodeData) => {
                this.setState({ qrCodeData: qrCodeData})
                this.props.onAccountCreatedListenerFn(this.state);
            })
            .catch((err) => {
                this.setState({error: err.errorMessage});
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }
  }

  render() {

    var formInputs = []
    var ncols = []

    if(this.state.accountType === ACCOUNT_TYPE.COMPANY) {
        ncols.push(
            "col-md-12"
        )
        formInputs.push(
            {
                inputProps : {
                    type : "text",
                    name : "name",
                    value : this.state.name,
                    placeholder : "Your name...",
                    onChange : this.handleChange
                },
                addonLeft : React.createElement('i', { className: "now-ui-icons users_circle-08" }),
            }
        )
    }

    ncols.push(
        "col-md-12"
    )
    formInputs.push(
        {
            inputProps : {
                type : "email",
                name : "email",
                placeholder: "Your Email...",
                value : this.state.email,
                onChange : this.handleChange,
                disabled: !this.state.emailIsEditable
            },
            addonLeft : React.createElement('i', { className: "now-ui-icons ui-1_email-85" }),
        }
    )

    if(this.state.accountType === ACCOUNT_TYPE.COMPANY) {
        ncols.push(
            "col-md-12"
        )
        formInputs.push(
            {
                inputProps : {
                    type : "text",
                    name : "role",
                    placeholder: "Role...",
                    value: this.state.role,
                    onChange : this.handleChange
                },
                addonLeft : React.createElement('i', { className: "cas-icon cas-building" }),
            }
        )
    }

    ncols.push(
        "col-md-12", "col-md-12"
    )
    formInputs.push(
        {
            inputProps : {
                type : "password",
                name : "password",
                placeholder: "Password...",
                onChange : this.handleChange
            },
            addonLeft : React.createElement('i', { className: "now-ui-icons ui-1_lock-circle-open" }),
        },
        {
            inputProps : {
                type : "password",
                name : "confirmPassword",
                placeholder: "Confirm password...",
                onChange : this.handleChange
            },
            addonLeft : React.createElement('i', { className: "now-ui-icons ui-1_lock-circle-open" }),
        }
    )

    return(
        <div>
            <CardHeader className="text-center">
                <CardTitle>Create an account <strong>{(this.state.accountFor) ? `for ${this.state.accountFor}` : null}</strong></CardTitle>
                <div>Account type: <strong>{(this.state.accountType === ACCOUNT_TYPE.COMPANY) ? 'Fund representative' : 'Individual profile'}</strong></div>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                  {this.renderFormError()}
                  <FormInputs
                      ncols = {ncols}
                      proprieties = {formInputs}
                  />
                  <FormGroup check>
                      <Label check className="terms-checkbox">
                          <Input type="checkbox" onChange={this.handleChangeTermsAndCondition} {...this.props.inputProps} />
                          <span className="form-check-sign"></span>
                          <div>I accept the <a href="terms">terms and conditions</a>.</div>
                      </Label>
                  </FormGroup>
                  <ReCaptcha
                      sitekey={caspianConfig.GOOGLE_RECAPTCHA_SITE_KEY}
                      action="create_account"
                      verifyCallback={this.handleRecaptchaOnChange}
                  />
                  <SubmitButton data-action="create_account" className="btn btn-primary w-100" type="submit" loading={this.state.loading}>
                    Next step
                  </SubmitButton>
              </Form>
            </CardBody>
        </div>
    )
  }
}

CreateAccountForm.propTypes = {
  onAccountCreatedListenerFn: PropTypes.func.isRequired
}

export default CreateAccountForm;
