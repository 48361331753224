import React from 'react';
import Loader from "../../components/Loader/Loader";
import BootstrapTable from "react-bootstrap-table-next";

const textFormatter = (cell) => {
    return (
        <div >
            <i style={{fontSize: '12px', paddingRight: '8px'}} className="now-ui-icons arrows-1_cloud-download-93"> </i>
            <span>{ cell }</span>
        </div>
    )
};

class ExecutionsReportsTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userData: this.props.data,
            loading: false,
            editedKeys : [],
            saveButtonDisable: 'true'
        };

        this.titleStyle = {
            'padding-right' : '8px'
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.clientName !== this.props.clientName) {
            this.setState({ saveButtonDisable: 'true' });
        }
    };

    preparedColumnsConfig = () => {
            return [
                {
                    dataField: 'date',
                    text: '2018.15',
                    editable: false,
                    headerClasses: 'w-10 user-table-header',
                    classes: 'cursor-default'
                },

                {
                    dataField: 'executions',
                    text: 'Executions',
                    formatter: textFormatter,
                    editable: false,
                    headerClasses: 'w-10 user-table-header',
                    classes: 'table-cell-highlight'
                },

                {
                    dataField: 'volumes',
                    text: 'Volumes',
                    formatter: textFormatter,
                    editable: false,
                    headerClasses: 'w-10 user-table-header',
                    classes: 'table-cell-highlight'
                },

                {
                    dataField: 'consolidate',
                    text: 'Consolidate',
                    formatter: textFormatter,
                    editable: false,
                    headerClasses: 'w-10 user-table-header',
                    classes: 'table-cell-highlight',
                },
            ];
    };

    render() {
        let {data} = this.props;

        return (
            <div className="dashboard-table-wrapper">
                <Loader
                    loading={this.state.loading} />

                <BootstrapTable
                    hover
                    condensed
                    keyField='id'
                    classes='table-responsive table-executions-info'
                    rowStyle={ { fontSize: '12px' }}
                    bordered={ false }
                    data={ data }
                    columns={ this.preparedColumnsConfig() }
                />
            </div>
        );
    }
}

export default ExecutionsReportsTable;