import React from 'react'
import {
  Card, Container, CardBody, Row, Col
} from 'reactstrap'
// react plugin used to create charts

import {
  PanelHeader, CardSuccessMessage, CardAuthor
} from '../../components'

import images from '../../assets/img'

import utils from '../../utils'

import ChangePassword from './ChangePassword'
import ChangePasswordTFAuth from '../TFAuth/ChangePasswordTFAuth'

class AccountSettings extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      oldPassword: null,
      newPassword: null,
      qrCodeImage: null,
      secretKey: null,
      passwordsMatch: false,
      user: utils.getUserData()
    }
  }

  renderScreen () {
    if (this.state.isVerified) {
      return (
        <Card className="card-form">
          <CardSuccessMessage
            title = "Password successfully changed">
          </CardSuccessMessage>
        </Card>
      )
    } else if (this.state.passwordsMatch) {
      return (
        <Card className="card-form">
          <ChangePasswordTFAuth
            email={utils.getUserData().email}
            newPassword = {this.state.newPassword}
            qrCodeData = {this.state.qrCodeImage}
            secretKey = {this.state.secretKey}
            rememberMe = {this.state.rememberMe}
            onVerifiedListenerFn = { () => this.setState({ isVerified: true }) } />
        </Card>
      )
    } else {
      return (
        <ChangePassword
          onPasswordResetListenerFn = {(passwords) => {
            this.setState({
              oldPassword: passwords.newPassword,
              newPassword: passwords.confirmNewPassword,
              qrCodeImage: passwords.qrCodeImage,
              secretKey: passwords.secretKey,
              passwordsMatch: true
            })
          }} />
      )
    }
  }

  render () {
    const userFullname = this.state.user.firstName + ' ' + this.state.user.lastName
    let clientsNames = this.state.user.clientsNames

    return (
      <div className="dashboard-account-settings-page">
        <PanelHeader
          size="sm"
          content={
            <div></div>
          }
        />
        <Container className="content" style={{ float: 'none', display: 'block', minHeight: 'calc(100vh - 160px)' }}>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <Card className="card-user">
                <CardBody>
                  <CardAuthor
                    avatar={images.userAvatar}
                    title={userFullname}
                    description={this.state.user.email}
                  />
                  <font color="#9a9a9a">{clientsNames.join(', ')}</font>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={6}>
              {this.renderScreen()}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default AccountSettings
