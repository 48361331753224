import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {Footer, Header} from '../../components'

import dashboardRoutes, {mapDashRoutesPathToName} from '../../routes/dashboard.jsx'
import utils from '../../utils'
import caspianApiService from "../../services/CaspianApiService";

class Dashboard extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateActualPath()
  }

  componentDidMount() {
    this.updateActualPath()
  }

  updateActualPath() {
    if (window.location && window.location.pathname in mapDashRoutesPathToName) {
      caspianApiService.postWebPageAccessed(mapDashRoutesPathToName[window.location.pathname],
                                            window.location.href)
    }
  }

  render() {
    if (!utils.userIsAuthenticated()) {
      return <Redirect to={'/login'}/>
    }
    if (!utils.isUserAdmin()) {
      if (this.props.location.pathname !== '/dashboard/settings' && this.props.location.pathname
          !== '/dashboard/documentation/pms' && this.props.location.pathname !== '/dashboard/documentation/mdata') {
        if (!utils.isOnboardingCompleted()) {
          if (!utils.isUserAccountManager() && !utils.isApplicationApproved()) {
            return <Redirect to={'/terms'}/>
          }

          if (utils.isKYCCompleted() || utils.isSubmittedInitialData() || (utils.isApplicationApproved()
              && this.props.location.pathname !== '/dashboard/resources')) {
            if (utils.isUserAccountManager()) {
              return <Redirect to={'/client-info'}/>
            }
          } else {
            if (utils.isAwaitingKYC() || utils.isKYCPending()) {
              return <Redirect to={'/kyc'}/>
            }
          }
        }
      }
    }

    return (
      <div className="wrapper dashboard-layout">
        <div className="main-panel" ref="mainPanel">
          {
            this.props.location.pathname.includes('/dashboard/environments') ? '' : <Header {...this.props}/>
          }
          <Switch>
            {
              dashboardRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return prop.views.map((prop2, key2) => {
                    return (
                      <Route path={prop2.path} component={prop2.component} key={key2}/>
                    )
                  })
                }
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key}/>
                  )
                }
                return (
                  <Route path={prop.path} component={prop.component} key={key}/>
                )
              })
            }
          </Switch>
          {
            this.props.location.pathname.includes('/dashboard/environments') ? '' : <Footer/>
          }

        </div>
      </div>
    )
  }
}

export default Dashboard
