import React from 'react'
import {
  Card, CardHeader, CardTitle, CardBody,
  Container, Row, Col
} from 'reactstrap'
import utils from '../../utils'
import caspianApiService from '../../services/CaspianApiService'
import { Redirect } from 'react-router-dom'

class UserGuide extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      user: utils.getUserData(),
      content: ''
    }
  };

  componentWillMount () {
    this.getContent()
  }

  getContent () {
    caspianApiService.getUserGuide().then((data) => {
      this.setState({
        content: data
      })
    })
  };

  render () {
    if (!utils.isUserAdmin()) {
      return <Redirect to={'/dashboard'}/>
    }
    return (
      <div>
        <div className="full-page-content static-page">
          <div>
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={8} md={8} xs={12}>
                  <Card className="content">
                    <CardHeader>
                      <CardTitle><h3>User Guide</h3></CardTitle>
                    </CardHeader>
                    <CardBody className="content-body">
                      <p>
                      </p>
                      <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

export default UserGuide
