import React from 'react';
import utils from "../../utils";
import {Card, CardHeader, CardBody} from "reactstrap";
import Loadable from "react-loading-overlay";
import ExecutionReportsTable from './ExecutionsReportsTable'
import ClientsSidebar from "../../components/ClientsSidebar/ClientsSidebar";

class ExecutionsReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: utils.getUserData(),
            loadingData: false,
            loadingText: '',
            clientList: [],
            isDropdownOpen: false,
            selectedClient: 'View As',
            clientData: [],
            funds: [],
            years: [],
            months: [],
            reports: [],
            currentYear: undefined,
            currentMonth: undefined,
            yearVolumesReport: undefined,
            MonthVolumeReport: undefined,
            MonthConsolidateReport: undefined,
            currentFund: undefined,
        };

    };

    resetClientData () {
        this.setState({
            funds: [],
            years: [],
            months: [],
            reports: [],
            currentYear: undefined,
            currentMonth: undefined,
            currentFund: undefined,
        })
    }


    componentDidMount() {
        this.setState({
            userIsAdmin: utils.isUserAdmin(),
            loadingData: true
        }, () => {
            this.getClient();
        });
    };

    getClient() {
        let {userIsAdmin} = this.state;

        if (userIsAdmin) {

            let clientName = JSON.parse(localStorage.getItem('activeClientName'));
            this.setState({
                selectedClient: clientName,
            }, () => {
                this.getClientData(clientName)
            });

            // this.getClientsList();
        } else {
            let {user} = this.state;
            if (user) {
                this.setState({selectedClient: user.clientName}, () => {
                    this.getClientData(this.state.selectedClient);
                })
            }
        }
    };



    getClientData (clientName) {
        this.resetClientData();
        this.mockClientData = [
            {
                fundName: 'DummyFundName',
                availableReports: [
                    {
                        year: 2015,
                        availableMonths: [1, 2, 3, 7, 8, 9, 10]
                    },

                    {
                        year: 2022,
                        availableMonths: [1, 2, 3, 7, 8, 9, 10]
                    },
                    {
                        year: 2017,
                        availableMonths: [1, 2, 3, 7, 8, 9, 10, 11, 12]
                    }
                ]
            },

            {
                fundName: 'RandomFundName',
                availableReports: [
                    {
                        year: 2005,
                        availableMonths: [1, 2, 3, 7, 8, 9, 10]
                    },

                    {
                        year: 2010,
                        availableMonths: [1, 2, 3, 5, 8, 9, 10]
                    },
                    {
                        year: 2011,
                        availableMonths: [7, 8, 9, 10, 11, 12]
                    }
                ]
            },


        ];
        let clientFunds = [];

        this.mockClientData.forEach((obj, index) => {
            if (obj.fundName) {
                clientFunds.push(obj.fundName)
            }
        });

        this.setState({
            clientData: this.mockClientData,
            funds : clientFunds,
        }, ()=> {
            this.onFundClick(clientFunds[0]);
        });

    };

    onClientChange = () => {
        this.setState({loadingData: true});
        let clientName = JSON.parse(localStorage.getItem('activeClientName'));
        this.setState({
            selectedClient: clientName,
        }, () => {this.getClientData(clientName)})
    };

    getLatestUserReports() {
        let {years} = this.state;

        let latestYear = years[years.length -1 ];
        this.setState({currentYear:latestYear}, () => {
            this.getYearVolumesReport(latestYear);
            this.getClientAvailableMonths(latestYear).then((months) => {
                let latestMonth = months[months.length - 1];
                this.onMonthClick(latestMonth);
            })
        });
    };

    getClientAvailableYears (currentFund) {
        //Get Years for selected client
        let { clientData } = this.state,
            years = [];

        clientData.forEach((fundObj, index) => {
            if (fundObj.fundName === currentFund) {
                fundObj.availableReports.forEach((report) => {
                    if (report.year){
                        years.push(report.year);
                    }
                })
            }
        });

        this.setState({years: years.sort((a, b) => a - b)}, ()=> {
            this.getLatestUserReports();
            return Promise.resolve(years)
        })
    };

    getClientAvailableMonths (year) {
        //Get Months for client
        let {clientData, currentFund} = this.state;
        let months = [];

        clientData.forEach((fundObj) => {
            if (fundObj.fundName === currentFund) {
                fundObj.availableReports.forEach((rep) => {
                    if (rep.year === year) {
                      months = rep.availableMonths.sort((a, b) => {
                        return a - b;
                      });
                    }
                })
            }
        });

        this.setState({months});
        return Promise.resolve(months);
    };


    onFundClick = (currentFund) => {
        this.setState({currentFund}, () => {
            this.getClientAvailableYears(currentFund)
        });
    };

    getYearVolumesReport (year) {
        //request for Year Volumes Report
        this.setState({yearVolumesReport : 'Vol_CLIENT_2018.pdf'})
    };

    getMonthVolumeReport (month) {
        //request for Month Volume Report
        this.setState({MonthVolumeReport : 'Vol_CLIENT_201812.pdf'})
    }

    getMonthConsolidateReport (month) {
        this.setState({MonthConsolidateReport : 'Consolidare_CLIENT_201812.csv'})
    };

    onYearClick = (currentYear) => {
        this.setState({currentYear}, () => {
            this.getYearVolumesReport(currentYear);
            this.getClientAvailableMonths(currentYear)
        });
    };

    onMonthClick = (currentMonth) => {
        this.setState({currentMonth}, () => {
            this.getMonthVolumeReport(currentMonth);
            this.getMonthConsolidateReport(currentMonth);
            //get client Reports
            this.getClientReports();
        });
    };

    getClientReports () {
        // let {selectedClient, currentYear, currentMonth} = this.state;
        //Request for reports
        this.mockReports = [
            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },
            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },
            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },
            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },
            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },

            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            },
            {
                date: '26, Wednsday',
                executions: 'CLIENT-NAME_201805.csv.gz',
                volumes: 'CLIENT-NAME_201805.pdf',
                consolidate: 'CLIENT-NAME_201805.csv.gz',
            }

        ];
        this.setState({
            reports: this.mockReports,
            loadingData: false
        });
    };

    monthFormatter (month) {
        let monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return monthsOfYear[month - 1];
    };

    dayFormatter (day) {
        let formattedDay;
        switch (day) {
            case 1:
                formattedDay = '1st';
                break;
            case 2:
                formattedDay = '2nd';
                break;
            case 3:
                formattedDay = '3rd';
                break;
            default:
                formattedDay = `${day}th`;
        }
        return formattedDay;
    };

    render () {
        let { selectedClient, years, months, reports, currentYear, currentMonth,  yearVolumesReport, MonthVolumeReport, MonthConsolidateReport, funds, currentFund, userIsAdmin} = this.state;

        return (
            <Loadable active={this.state.loadingData} spinner text={this.state.loadingText}>
                <div className="full-page-content small">

                    {
                        userIsAdmin &&
                        <ClientsSidebar onClientChange={this.onClientChange}/>
                    }

                    <div id={'content'} className="content" style={{'paddingTop': '35px'}}>
                        <Card className="card-stats card-raised executions-container"
                              style={{visibility: funds.length ? 'visible' : 'hidden'}}>

                            <CardHeader>
                                <h4 className="titleContainerStyle"> {selectedClient} </h4>
                                <i className="now-ui-icons arrows-1_minimal-right"> </i>
                                <h4 className="titleContainerStyle"> EXECUTIONS </h4>
                            </CardHeader>

                            <CardBody>
                                <div className="container-for-dates">

                                    <div className="datesContainerStyle">
                                        {
                                            funds.map((fund) => {
                                                return (
                                                    <div className="fundStyle"
                                                         onClick={e => this.onFundClick(fund)}>
                                                    <span
                                                        className={'date-text ' + (currentFund === fund ? 'active' : '')}> {fund} </span>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                    {
                                        years.length ?
                                            <div className="datesContainerStyle">
                                                <div className="dateTitleStyle">Years:</div>
                                                {
                                                    years.map((year) => {
                                                        return (
                                                            <div className="dateStyle"
                                                                 onClick={e => this.onYearClick(year)}>
                                                    <span
                                                        className={'date-text ' + (currentYear === year ? 'active' : '')}> {year} </span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            :
                                            ''
                                    }

                                    {
                                        months.length ?
                                            <div className="datesContainerStyle">
                                                <div className="dateTitleStyle"> Months:</div>
                                                {
                                                    months.map((month) => {
                                                        return (
                                                            <div className="dateStyle"
                                                                 onClick={e => this.onMonthClick(month)}>
                                                            <span
                                                                className={'date-text ' + (currentMonth === month ? 'active' : '')}> {this.monthFormatter(month)} </span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            :
                                            ''
                                    }
                                    {
                                        currentYear ?
                                            <div className="datesContainerStyle border-hr" style={{paddingTop:'15px'}}>
                                                <div className='full-reports'>
                                                    <span className="full-rep"> Year volumes report: </span>
                                                    {
                                                        currentMonth ?
                                                            <div>
                                                            <span className="full-rep"> Month volume report: </span>
                                                            <span className="full-rep"> Month consolidate report: </span>
                                                            </div>
                                                        :
                                                        ''
                                                    }

                                                </div>

                                                <div className="rep-file-container">
                                                    {
                                                        yearVolumesReport ?
                                                            <span className="rep-file"> {yearVolumesReport} </span>
                                                            :
                                                            <span className="rep-file-empty"> - </span>
                                                    }

                                                    {
                                                        currentMonth ?
                                                            <div>
                                                                {
                                                                    MonthVolumeReport ?
                                                                        <span className="rep-file"> {MonthVolumeReport} </span>
                                                                        :
                                                                        <span className="rep-file-empty"> - </span>
                                                                }
                                                                {
                                                                    MonthConsolidateReport ?
                                                                        <span className="rep-file"> {MonthConsolidateReport} </span>
                                                                        :
                                                                        <span className="rep-file-empty"> - </span>
                                                                }
                                                            </div>
                                                            :
                                                            ''
                                                    }


                                                </div>

                                            </div>
                                            :
                                            ""
                                    }

                                    {
                                        reports.length > 0 ?
                                            <div className='table-container'>
                                                <ExecutionReportsTable data={reports}/>

                                            </div>
                                                :
                                            ''
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div style={{clear: 'both'}}> </div>
                </div>
            </Loadable>
        );
    }



}

export default ExecutionsReportsPage;