import axios from 'axios'
import utils from '../utils'

const API_URL = `/api`

class CaspianApi {
  constructor () {
    let apiConfig = {
      baseURL: API_URL,
      timeout: 60000
    }

    this.apiClient = axios.create(apiConfig)

    this.apiClient.interceptors.response.use(
      this.handleSuccess,
      (error) => this.handleError(error, this.refreshToken)
    )

    this.apiClient.interceptors.request.use(async config => {
      if (localStorage.getItem('token') != null) {
        let token = await localStorage.getItem('token')
        if (!config.headers.Authorization) {
          config.headers.Authorization = token
        }
      }
      return config
    })

    return this.apiClient
  }

  handleSuccess (response) {
    return response.data
  }

  async handleError (error) {
    let errorMessage = ''

    if (!error.response.data.detail) {
      errorMessage = error.response.data.title
    } else {
      errorMessage = error.response.data.title + ': ' + error.response.data.detail
    }

    if (!error.response.data.detail && !error.response.data.title) {
      errorMessage = error.response.status + ' ' + error.response.data.detail
    }

    if (error.response.data.message) {
      errorMessage = error.response.data.message
    } else {
      errorMessage = error.response.data
    }

    let token = await localStorage.getItem('token')

    if (token && error.response.status === 401) {
      utils.logoutUser()
    }

    return Promise.reject({errorMessage: errorMessage, errorStatus: error.response.status})
  }
}

export default new CaspianApi()
