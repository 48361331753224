import React from 'react'
import KycIndividual from './KycIndividual'
import KycCompany from './KycCompany'
import utils from '../../utils'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from 'reactstrap'
import Loadable from 'react-loading-overlay'
import ClientsSidebar from "../../components/ClientsSidebar/ClientsSidebar";

class KYCPage extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      user: utils.getUserData(),
      loadingData: false,
      loadingText: '',
      clientList: [],
      isDropdownOpen: false,
      selectedClient: 'View As',
      clientName: null,
    };
  }

  editKYC = () => {
      sessionStorage.setItem(this.state.selectedClient +'_edit-kyc', 'true');
      window.location.reload()
  };

  componentDidMount () {
      if (utils.isUserAdmin()) {
          let clientName = JSON.parse(localStorage.getItem('activeClientName'));
          this.setState({
              selectedClient: clientName,
              clientName: clientName,
              visible: true
          })
      } else if (utils.isUserAccountManager()) {

          if (this.state.user && Array.isArray(this.state.user.clientsNames)) {

              this.setState({isMultipleClientsAccountManager: true})
          } else {
              this.setState({isMultipleClientsAccountManager: false})
              let container = document.getElementById('content'),
                  footer = document.getElementById('footer');

              container.style.width = '99%';
              container.style.transition ='none';
              footer.style.paddingLeft= '0 1%';
          }

          let clientName = JSON.parse(localStorage.getItem('activeClientName'));
          this.setState({
              selectedClient: clientName,
              visible: true
          });
      }
  }

  loadingData = (isLoading, loadingText = 'Loading submitted KYC data') => {
      this.setState({
          loadingData: isLoading,
          loadingText: loadingText,
      })
  };

  onClientChange = () => {
      let clientName = JSON.parse(localStorage.getItem('activeClientName'));
      this.setState({
          selectedClient: clientName,
          clientName: clientName,
          visible: true
      })
  };

  renderForm() {
      if (!utils.isUserAdmin()) {
          if (!utils.isUserAccountManager() && utils.isOnboardingCompleted()) {
              return (
                  <Redirect to={'/dashboard'}/>
              )
          } else if (!utils.isUserAccountManager()) {
              return (
                  <Redirect to={'/login'}/>
              )
          }
          if (utils.isKYCCompleted() || utils.isSubmittedInitialData() || utils.isApplicationApproved()) {
              return (
                  <Redirect to={'/client-info'}/>
              )
          }
          if (utils.isOnboardingCompleted()) {
              return (
                  <Redirect to={'/dashboard'}/>
              )
          }
      }

      let isEditKyc = sessionStorage.getItem( this.state.selectedClient +'_edit-kyc') === 'true';

      if (utils.isKYCPending() && !isEditKyc && !utils.isUserAdmin()) {
          let fullName = this.state.user.firstName + ' ' + this.state.user.lastName
          return (
              <div>
                  <Container fluid className="kyc-page">
                      <Row className="justify-content-center">
                          <Col md={6}>
                              <Card className="kyc-status">
                                  <CardHeader>
                                      <CardTitle>
                                          <h4 className="kyc-page">Welcome, {fullName}</h4>
                                      </CardTitle>
                                      <CardBody className="status-card">
                                          <div className="row">
                                              <div className="col-6 vertical-middle">
                                                  <i className="now-ui-icons loader_refresh spin"></i>
                                                  <span className="status">
                                                    Your KYC is pending / processing
                                                  </span>
                                              </div>
                                              <div className="col-6">
                                                <span className="float-right">
                                                  <Button size="sm" color="primary" onClick={this.editKYC}>
                                                    Update KYC details
                                                  </Button>
                                                </span>
                                              </div>
                                          </div>
                                      </CardBody>
                                  </CardHeader>
                              </Card>
                          </Col>
                      </Row>
                  </Container>
              </div>
          )
      }

      return utils.isCorporateUser() ?
          (
              <KycCompany loading={this.loadingData} clientName={this.state.selectedClient}
                          visible={!utils.isUserAdmin() || this.state.clientName !== null}
                          isMultipleClientsAccountManager={this.state.isMultipleClientsAccountManager}/>
          )
          :
          (
              <KycIndividual loading={this.loadingData} clientName={this.state.selectedClient}
                             visible={!utils.isUserAdmin() || this.state.clientName !== null}/>
          )
  }

  render () {
    if (!utils.userIsAuthenticated()) {
      return (
        <Redirect to={'/login'}/>
      )
    }
    return (
        <Loadable active={this.state.loadingData} spinner text={this.state.loadingText}>
            <div className="full-page-content small">
             {
               utils.isUserAdmin() &&
               <ClientsSidebar onClientChange={this.onClientChange}/>
             }

                {
                    this.state.isMultipleClientsAccountManager && this.state.isMultipleClientsAccountManager === true ?
                        <ClientsSidebar isMultipleClientsAccountManager={true} user={this.state.user} onClientChange={this.onClientChange}/>
                        :
                        ''
                }
              <div id={'content'} className="content">
                {
                  this.renderForm()
                }
              </div>
              <div style={{clear: 'both'}}> </div>
            </div>
        </Loadable>
    )
  }
}

export default KYCPage
