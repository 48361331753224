import React from 'react'
import ReactDOM from 'react-dom'
import { unregister } from './registerServiceWorker'

import 'bootstrap/dist/css/bootstrap.css'

import { createBrowserHistory } from 'history'
import {
  Router,
  Route,
  Switch
} from 'react-router-dom'

import indexRoutes from './routes/index.jsx'
import './assets/scss/now-ui-dashboard.css'
import './assets/scss/custom-fonts.scss'

const hist = createBrowserHistory()

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {
        indexRoutes.map((prop, key) => {
          return (
            <Route
              path={prop.path}
              key={key}
              component={prop.component}
            />
          )
        })
      }
    </Switch>
  </Router>
  , document.getElementById('root'))
unregister()
