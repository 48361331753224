import React from 'react'
import {
  Container, Row, Col
} from 'reactstrap'

import queryString from 'qs'
import _ from 'lodash'
import SweetAlert from 'react-bootstrap-sweetalert'

import CreateAccountWizard from './CreateAccountWizard.jsx'

import caspianApiService from '../../services/CaspianApiService'

import bgImage from '../../assets/img/bg_login.jpg'

class CreateAccount extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      accountType: '',
      email: '',
      accessCode: '',
      clientName: ''
    }

    this.accessCodeInvalidAlert = this.accessCodeInvalidAlert.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
  }

  componentDidMount () {
    const values = queryString.parse(this.props.location.search.replace('?', ''))
    if (_.isUndefined(values.code) || values.code.length === 0) {
      this.props.history.push('/login')
    }

    this.setState({ accessCode: values.code })
    caspianApiService.verifyAcccessCode(values.code)
      .then((response) => {
        this.setState({
          name: response.name,
          accountType: response.type,
          email: response.email,
          clientName: response.clientName
        })
      })
      .catch((err) => {
        this.accessCodeInvalidAlert()
      })
  }

  accessCodeInvalidAlert () {
    this.setState({
      accessCodeInvalidAlert: (
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title="Access code invalid"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}>
          <div>
            <div>In order to register you need a valid access code. If you think this is a mistake please contact caspian support at: support@caspian.tech</div>
            <small>You will be redirected in a few seconds</small>
          </div>
        </SweetAlert>
      )
    })
    setTimeout(this.hideAlert, 5000)
  }

  hideAlert () {
    this.setState({
      accessCodeInvalidAlert: null
    })
    this.props.history.push('/get-in-touch')
  }

  render () {
    return (
      <div className="create-account-page">
        <div className="full-page-content">
          {this.state.accessCodeInvalidAlert}
          <div>
            <Container>
              <Row className="justify-content-center">
                <Col lg={4} md={8} xs={12}>
                  <CreateAccountWizard
                    accessData={this.state} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="full-page-background" style={{ backgroundImage: 'url(' + bgImage + ')' }}></div>
      </div>
    )
  }
}

export default CreateAccount
