import React from 'react'
import {
  Card, CardHeader, CardTitle, CardBody,
  Container, Row, Col
} from 'reactstrap'
class FAQPage extends React.Component {
  render () {
    return (
      <div>
        <div className="full-page-content static-page">
          <div>
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={8} md={8} xs={12}>
                  <Card className="content">
                    <CardHeader>
                      <CardTitle><h3>FAQ</h3></CardTitle>
                    </CardHeader>
                    <CardBody className="content-body">
                      <h4 className="faq-title">Lorem ipsum dolor sit amet?</h4>
                      <h4 className="faq-title">Lorem ipsum dolor sit amet?</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Facilisi cras fermentum odio eu feugiat pretium nibh ipsum. Fringilla ut morbi tincidunt augue interdum. Commodo odio aenean sed adipiscing.
                                                Pharetra massa massa ultricies mi quis. Neque ornare aenean euismod elementum nisi quis eleifend quam. Laoreet non curabitur gravida arcu ac tortor.
                                                Et netus et malesuada fames ac turpis egestas maecenas. Tempus quam pellentesque nec nam aliquam. Eget egestas purus viverra accumsan in nisl.
                                                Ipsum nunc aliquet bibendum enim. Tincidunt augue interdum velit euismod in pellentesque massa placerat. Consectetur purus ut faucibus pulvinar elementum integer.
                                                Felis bibendum ut tristique et egestas quis ipsum suspendisse ultrices.
                                                Placerat orci nulla pellentesque dignissim enim. Risus nec feugiat in fermentum posuere urna nec tincidunt praesent.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

export default FAQPage
