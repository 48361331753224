export class CRPOptions {
  constructor () {
    this.crpRoles = [
      {
        value: 'PRIMARY',
        label: 'Primary'
      },
      {
        value: 'OWNER',
        label: 'Owner'
      },
      {
        value: 'NOMINEE/TRUSTEE',
        label: 'Nominee/Trustee'
      },
      {
        value: 'LOCAL MANAGER',
        label: 'Local manager'
      },
      {
        value: 'PARTNER',
        label: 'Partner'
      },
      {
        value: 'GENERAL PARTNER',
        label: 'General partner'
      },
      {
        value: 'LIMITED PARTNER',
        label: 'Limited partner'
      },
      {
        value: 'ULTIMATE BENEFICIAL OWNER',
        label: 'Ultimate beneficial owner'
      },
      {
        value: 'DIRECTOR',
        label: 'Director'
      },
      {
        value: 'SHAREHOLDER',
        label: 'Shareholder'
      },
      {
        value: 'BRANCH MANAGER',
        label: 'Branch manager'
      },
      {
        value: 'REPRESENTATIVE AGENT',
        label: 'Representative agent'
      },
      {
        value: 'HEAD OFFICE',
        label: 'Head office'
      },
      {
        value: 'REMITTER',
        label: 'Remitter'
      },
      {
        value: 'BENEFICIARY',
        label: 'Beneficiary'
      },
      {
        value: 'AUTHORISED SIGNATORY',
        label: 'Authorized signatory'
      },
      {
        value: 'PROTECTOR',
        label: 'Protector'
      },
      {
        value: 'SETTLOR',
        label: 'Settlor'
      },
      {
        value: 'BOARD MEMBER',
        label: 'Board member'
      },
      {
        value: 'COMMITTEE MEMBER',
        label: 'Committee member'
      },
      {
        value: 'RELEVANT PERSON',
        label: 'Relevant person'
      },
      {
        value: 'CONNECTED PARTY',
        label: 'Connected party'
      }
    ]
    this.crpGenderOptions = [
      {
        value: 'FEMALE',
        label: 'Female'
      },
      {
        value: 'MALE',
        label: 'Male'
      }
    ]

    this.crpTypeOptions = [
      {
        value: 'CORPORATE',
        label: 'Corporate'
      },
      {
        value: 'INDIVIDUAL',
        label: 'Individual'
      }
    ]
  }

  getCRPRolesOptions () {
    return this.crpRoles
  }

  getCRPGenderOptions () {
    return this.crpGenderOptions
  }

  getCRPTypeOptions () {
    return this.crpTypeOptions
  }
}
