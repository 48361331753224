export const PGP_PUBLIC_KEY =
  `-----BEGIN PGP PUBLIC KEY BLOCK-----
Comment: signer-pgp-prod

mQINBFvcNJgBEAC4fT8MTCiBJMJ6r5QszjLvaZDF9hFWQxv/ENAe7Uvu8I1XQ9Ko
V0Hpm1BuUDgwV/vH04SBDl/7IVXiLiwgksJvkfGszCPheaCrMKWxW7bPYgoWRUbB
jfeU4qGMgCu+UtRVa74i637RPL43R/ppgaY+vMxEkq1d9fVSvV52wYPEVBHnvv5R
gcIcVEEEtMcZdtA7gdeKUUMCowDrcsqKeWagQPQFelZl7srH8mBUtAUZYCD0t08U
1IV8pROZj7drX2oq69ADUO7GBw+iAjNxO2jezhAmrJvg3TLpPglHij5z24LH3Tl9
zrf1TiXLTwxYbGPKn7SJgtUna8//v6e5ZAqs4f6yK/dwIpPpwmW8fSMdMbqYbggz
CfVQr6MotuKFRHmlYRAT6fuaQ322tGdrrspeAY9S9LeR9wyFt94bKbYckV9ANNZj
rsgT+LgNz9BJJ5leIs+ldKxgu4ldMJ+cE1L5KQezsuMGQwGxkn45kZ1tepyerE8W
DyUcxWrI1L7vHiTldH9ZiYG985J36LNgfeYYtbhy1g2X9exxyOwVBxFmlseAay/Z
Px5hj5t9saZNy/UrMON2RfxCfyjXiSkwesrTlzaK1j/csqOkCTytB8V0DwHBP5YD
Z0uZhgqiBBnoXvdLX079yCGxVrmye919anTK+845hmP4KlJl/mPloE58HwARAQAB
tCNDQVNQSUFOIE1TUyA8bXNzLXByb2RAY2FzcGlhbi50ZWNoPokCTgQTAQgAOBYh
BK6lbyUcHjX22M4adEeIYrfpt2UABQJb3DSYAhsDBQsJCAcCBhUKCQgLAgQWAgMB
Ah4BAheAAAoJEEeIYrfpt2UAuz4P/jqV59258wVxcTxLhN690MJ3Xx5f8EbV+uMf
hHm+sO2AR2zrm6JuGxnYW+y6O4EvmSpbTjKob/+9hu1/d+3EWb4mwwew6HxWnA7M
Liupwbe4g60HsRoV+/jtCpk0hziNgefg+dwF6R7qUHSx/KScmLyC1W+8cvu5iKqu
g9bs+WbBsUUEDbVIVfciDr7m+eG5eeCuMv8fTeWNesnmMmxscfrUJ0IOzq6zQCBF
nJHCuLiIrroJcHd/JqsXcmR/HzWDeIz5P3HUSrV73g1pd9/kGCLDmr8erwO3lHEA
E5xTbf8eeJtEdImPs38QI0rtvKlGJR5aKvmn+Fh192gHyn/a10WjqK75Zh0oYD8Z
fHSP2+FN2iiK4qpf79oGbwhQRpMXkBzMOo/oksXeUJfxNmycwC8KQY1RtJj/o8Qx
mBnvKFsYAS+VHsJneYFhh8+UzgkyDNYZY5oDjpzGALN11HUedAhD5ojonbe2XZqc
lqhmOu+3yD46KEX4sDwr+X5IWtPg5BKTJSVfkuOI0QR9tM+GcSEXoZOyUT5RiFPR
p2RlRd79kU3nj/QDMwqpFLl7IdkcKeCw9k7vLmnNIdxWA1wgGLRM+QmLrqmej2eU
FzSyg/FnemY56vQCPPexhOOLhQFNg2EhLAqyJW0cVTLiOjc0t6EInAViDlbuIbY6
7FK+YxN4uQINBFvcNJgBEADbFyyBAbCaXJj1mjXlRIDMD4KfzrXdVV11KsYagDpz
8MoPVNByfF/JLxVzvCVY15YuYu3cptajm622f0S0CbPigtw9+hqKNmaPhs+NOfeT
qmP7Y9whqP4awL6cggG6rpGEYTSbpqWVJ4fb+vHrjaj31j/CKdAnLN5vuPUlb5yi
YH8MVnHgClsJekj+OryfP4Ja3/4K+9iFGSvs8qqQj3EtAju6HthHkJMQgrUnAInZ
YoQZfBWW/tBhzXX3fnYUCdtvNUIlUF4h+v/jyhxZE8ouLne5GUg+NxphVPtdS4Jk
gwz1e5kvM31741BCta0Rp2rJ/TtDSvj0VsCiOucrTbV6UDdC3ZvrUaswOtnkoMyA
R9j915kSZ1bG4Qf0j7kahsbMRrUV1RIfmrscmy0Tgbr3mwnOkZZbFDU5aXFgYhrt
GrY3aLSe9eM5rqnsgH8s25FJfkjjn0hwfDoluQ/d8pzl9otZ2sLFuE6lI8YsxSRt
J04vo2dSOMk5YmjNhb3uCqvbh8a9D4tP0YmsmZGur36jG9uzUEJca8PUjx0YvRa+
HYNGPUyq7D7OiBS/cP/JP0LuXM1SIQbd4G72DZgwWOPZ7AxM2R+5CqV7tJHCZmN1
4dZ2W3ikVF1CJMFl8kCLKAq28yBRmuXhXGnfekZif59rr+mTjNIpXETpMVZbOhvN
wwARAQABiQI2BBgBCAAgFiEErqVvJRweNfbYzhp0R4hit+m3ZQAFAlvcNJgCGwwA
CgkQR4hit+m3ZQDANw/+PozsiONT7e216FNLe64R0kYv2nj6EUXM91k1keuKIQ9j
exh/6qc6SolwYNpe1JoW3Eyjm9tEWlb6Qj2YcH/EpW4lXqXhK4MqrObwWV320/Zn
sy9BYer+JbOKb4ltEuISi/u5Il5L7VeparjGi+QSHmK2lZrVHX8OReIVe+Xxx9R9
i12fC+AsztH23asKCbAJ/38V1yBVyVlVr4n4iHGl3yzxvQ/Fybr7OA15cWjuJo7l
2fS/Rpcx+z8gbRGIN8F9VwlMyEXsPzISwyzU8QYCiC8YdPbQVBX3MMucNjlZmGO6
ZDH+YIOsQztD5ZoXidG7JXZX2xUou/kIfMrqADQMhiX4/72PnbeFJDUCBu0CsZoo
KbIZZQ4GpOe1tkuuytS+Ro7v3CxUWtc5TGiNmyQ1BMTuzj/k9AEjcb0zbFOPduo6
6pZ90Ev7t6e60lFoC2P+T1hI67eO4WreMBmtR6XhQw4viT3O7hTDcC4neQJWkAjh
nckxxwwRkNeey1c231tNXCwmBrSaUURRf75xKJtk2/tHGmjYdjwl2Q2/TphMJN52
LefJjXRxNGczn/sOx+Lfyn/1HxQoKu/fZXgU2geSO8+EYV1hUN9s7nh8yDiuZUHh
cYHK3/MvyRNmkfNmKTxALKdGZ592EiIqtf/HL426eAap/fil8TO5ubcpwTlKpa4=
=MVVf
-----END PGP PUBLIC KEY BLOCK-----`
