// import pages to be routed
import LoginPage from '../views/Login/LoginPage'
import ForgotPassword from '../views/ForgotPassword/ForgotPassword'
import ResetPassword from '../views/ResetPassword/ResetPassword'
import Terms from '../views/Terms/Terms'
import Privacy from '../views/Privacy/Privacy'
import FAQPage from '../views/FAQ/FAQ'
import KYCPage from '../views/KYC/KYCPage'
import CreateAccount from '../views/CreateAccount/CreateAccount'
import ActivateAccount from '../views/CreateAccount/ActivateAccount'
import ClientInfo from '../views/ClientInfo/ClientInfo'
import AuditReportsPage from '../views/Reports/AuditReportsPage'
import ExecutionsReportsPage from '../views/Reports/ExecutionsReportsPage'
import UserGuide from '../views/UserGuide/UserGuide'
import SSOLoginPage from '../views/Login/SSOLoginPage'
import utils from '../utils'
// import GetInTouch from '../views/GetInTouch/GetInTouch'

const pagesRoutes = [
  { path: '/login', invisible: true, name: 'Login Page', short: 'Login', mini: 'LP', icon: 'users_circle-08', component: LoginPage },
  { path: '/ssoLogin', invisible: true, name: 'Login Page', short: 'Login', mini: 'LP', icon: 'users_circle-08', component: SSOLoginPage },
  { path: '/forgot-password', invisible: true, component: ForgotPassword },
  { path: '/reset/finish', invisible: true, component: ResetPassword },
  { path: '/faq', invisible: true, component: FAQPage },
  { path: '/terms', invisible: true, component: Terms },
  { path: '/privacy', invisible: true, component: Privacy },
  { path: '/create-account', name: 'Register', invisible: true, component: CreateAccount },
  { path: '/activate', name: 'Activate account', invisible: true, component: ActivateAccount },
  { path: '/kyc', name: 'KYC', invisible: true, component: KYCPage },
  { path: '/client-info', name: 'Onboarding', invisible: true, component: ClientInfo },
  { path: '/reports/audit', name: 'Reports', invisible: true, component: AuditReportsPage },
  { path: '/reports/executions', name: 'Reports', invisible: true, component: ExecutionsReportsPage },
  { path: '/user-guide', name: 'User Guide', invisible: true, component: UserGuide },
  { redirect: true, path: '/', pathTo: '/login', name: 'Login' }

]

export const mapPagesRoutesPathToName = utils.createRoutesNameMap(pagesRoutes)

export default pagesRoutes
