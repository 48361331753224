export const DELETE_SESSIONS_INSTEAD_OF_UPDATE = true
export const ACCOUNT_TYPE = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual'
}

export const TICKET_STATUS = {
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
  NONE: 'NONE',
  CANCELED: 'CANCELED',
  NEW: 'NEW',
  PENDING: 'PENDING'
}

export const FINAL_TICKET_STATUSES = [
  TICKET_STATUS.RESOLVED,
  TICKET_STATUS.REJECTED,
  TICKET_STATUS.NONE,
  TICKET_STATUS.CANCELED
]
