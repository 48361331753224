import React from 'react'
import _ from 'lodash'
import jwt_decode from 'jwt-decode'

import {UncontrolledTooltip} from 'reactstrap'
import dateFormat from 'dateformat'
import Keycloak from 'keycloak-js'

let keyCloak = null

const userIsAuthenticated = () => {
  const token = localStorage.getItem('token')
  if (token !== null) {
    const decoded = jwt_decode(token)
    if (decoded.exp > (Date.now() / 1000)) {
      return true
    } else {
      cleanAfterLogout()
    }
  }
  return false
}

const logoutUser = () => {
  if (!userIsAuthenticated()) {
    return
  }

  const token = localStorage.getItem('token')
  if (token && token.startsWith('Bearer')) {
    cleanAfterLogout()
    window.location.reload()
    return
  }

  const logoutUrl = localStorage.getItem('logoutUrl')
  cleanAfterLogout()
  window.location.href = logoutUrl
}

const cleanAfterLogout = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  localStorage.removeItem('kyc_status')
  localStorage.removeItem('storedClientsList')
  localStorage.removeItem('inputFilter')
  localStorage.removeItem('activeClientUploadKeys')
  localStorage.removeItem('activeClientName')

  sessionStorage.removeItem('edit-kyc')
  sessionStorage.removeItem('activeClientName')
  sessionStorage.removeItem('sidebarStatus')
}

const isKYCCompleted = () => {
  const kycStatus = localStorage.getItem('kyc_status')
  return kycStatus === 'KYC_COMPLETED'
}

const isKYCPending = () => {
  const kycStatus = localStorage.getItem('kyc_status')
  return kycStatus === 'KYC_PENDING'
}

const isAwaitingKYC = () => {
  const kycStatus = localStorage.getItem('kyc_status')
  return kycStatus === 'AWAITING_KYC'
}

const isOnboardingCompleted = () => {
  const kycStatus = localStorage.getItem('kyc_status')
  return kycStatus === 'ONBOARDING_COMPLETED'
}

const isSubmittedInitialData = () => {
  const kycStatus = localStorage.getItem('kyc_status')
  return kycStatus === 'SUBMITTED_INITIAL_DATA'
}

const isApplicationApproved = () => {
  const kycStatus = localStorage.getItem('kyc_status')
  return kycStatus === 'APPLICATION_APPROVED'
}

const isCorporateUser = () => {
  return true
  // kycStatus === 'COMPLETED'
}

const getUserData = () => {
  return JSON.parse(localStorage.getItem('user'))
}

const isUserAccountManager = () => {
  if (getUserData() == null || getUserData().authorities == null) {
    return false
  }
  return (_.indexOf(getUserData().authorities, 'ACCOUNT_HOLDER') >= 0)
}

const canViewOnboarding = () => {
  let canView = JSON.parse(localStorage.getItem('canViewOnboarding'))
  if (canView !== null) {
    return canView
  }
  const userData = getUserData()
  return userData !== null && userData.canViewOnboarding
}

const isUserAdmin = () => {
  if (getUserData() == null || getUserData().authorities == null) {
    return false
  }

  return (_.indexOf(getUserData().authorities, 'ROLE_ADMIN') >= 0) || isUserAdminReadOnly()
}

const isUserAdminReadOnly = () => {
  if (getUserData() == null || getUserData().authorities == null) {
    return false
  }

  return (_.indexOf(getUserData().authorities, 'ADMIN_READONLY') >= 0)
}

const getTicketByType = (tickets, type) => {
  if (tickets){
    return tickets.find(ticket => ticket.type === type)
  }
}

const statusFormater = (cell, row) => {
  return (
    <span>
      <span id={ 'status-' + row.id }>{ (cell) || 'N/A' }</span>
      {
        (row.ticketStatus !== 'NONE')
          ? <UncontrolledTooltip placement="top" target={ 'status-' + row.id }>
                      Last Ticket Status: { row.ticketStatus }
          </UncontrolledTooltip>
          : ''
      }
    </span>
  )
}

const dateFormater = (cell, row) => {
  return dateFormat(cell, 'mmmm dS, yyyy')
}

const buf2hex = (buffer) => { // buffer is an ArrayBuffer
  return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('')
}

const isNullOrEmpty = (string) => {
  return string === undefined || string === null || string === '' || string.trim() === ''
}

const createRoutesNameMap = (routes) => {
  let mapPathToPageName = {}
  routes.forEach(item => {
    if (item.name) {
      mapPathToPageName[item.path] = item.name
    }
  })

  return mapPathToPageName
}

export default {
  userIsAuthenticated,
  isKYCCompleted,
  isKYCPending,
  isOnboardingCompleted,
  isAwaitingKYC,
  isSubmittedInitialData,
  isApplicationApproved,
  isCorporateUser,
  logoutUser,
  getUserData,
  isUserAccountManager,
  canViewOnboarding,
  isUserAdmin,
  isUserAdminReadOnly,
  statusFormater,
  dateFormater,
  buf2hex,
  isNullOrEmpty,
  getTicketByType,
  keyCloak,
  createRoutesNameMap
}
