import React from 'react';
import caspianApiService from "../../services/CaspianApiService";
import utils from '../../utils';
import {Redirect} from "react-router-dom";


class ClientsSidebar extends React.Component{

    mapOfKycStatus = {
        AWAITING_KYC: 'Awaiting KYC',
        KYC_PENDING: 'KYC Pending',
        KYC_COMPLETED: 'KYC Completed',
        SUBMITTED_INITIAL_DATA: 'Submitted Initial Data',
        APPLICATION_APPROVED: 'Application Approved',
        ONBOARDING_COMPLETED: 'Onboarding Application Completed',
    };

    constructor(props){
        super(props);
        this.state = {
            activeClient: '',
            hoverClient: '',
            clientsList: [],
            inputValue: '',
            transformedClientsList: [],
            sidebarIsOpen : undefined,
            modalIsOpen: false,
            redirectToDashboard: false
        };
        this.handleChange = this.handleChange.bind(this);
    };

    handleChange(event) {
        let filter = event.target.value;
        localStorage.setItem('inputFilter', filter);
        this.inputTyping(filter);
    };


    inputTyping(filter){
        let { clientsList } = this.state,
            newClientsList = [];

        this.setState({
            inputValue: filter,
            transformedClientsList: clientsList
        });

        if (filter && !filter.length) return null;

        clientsList.forEach((client) => {
            let upperCaseClientName = client.name.toUpperCase();
            if (upperCaseClientName.includes(filter.toUpperCase())) {
                newClientsList.push(client)
            }
        });

        localStorage.setItem('storedClientsList', JSON.stringify(newClientsList));
        this.setState({transformedClientsList: newClientsList})
    }

    componentWillMount() {
        let sidebarStatus = localStorage.getItem('sidebarStatus') || true,
            client = localStorage.getItem('activeClientName'),
            storedClientsList = localStorage.getItem('storedClientsList'),
            inputFilter = localStorage.getItem('inputFilter');


        this.setState({sidebarIsOpen: JSON.parse(sidebarStatus)});

        if (!client) {
                caspianApiService.getUserDetails().then((user) => {
                    this.setState({activeClient: user.clientsNames[0]});
                    localStorage.setItem('activeClientName', JSON.stringify(user.clientsNames[0]));
                });
            if (this.props.isMultipleClientsAccountManager) {
                caspianApiService.getAccountHolderClientsList()
                    .then((clientsList) => {
                            this.selectClient(clientsList[0])
                    })
            }

        } else {
            this.setState({activeClient: JSON.parse(client)});
        }

        if (storedClientsList) {
            let preparedList = JSON.parse(storedClientsList);
            this.setState({clientsList: preparedList, transformedClientsList: preparedList}, () => {
                if(inputFilter && inputFilter.length > 0){
                    this.inputTyping(inputFilter)
                }
            });
        }
    };


    componentDidMount() {
        let { sidebarIsOpen } = this.state;

        let rightSideContainer = document.getElementById('content'),
            footer = document.getElementById('footer');

        if (sidebarIsOpen === true) {
            rightSideContainer.style.width = '85%';
            footer.style.paddingLeft= '15%';
        } else {
            rightSideContainer.style.width = '99%';
            footer.style.paddingLeft= '0 1%';
        }

        let sidebarContainer = document.getElementById('client-sidebar-container');
        if (this.props.isMultipleClientsAccountManager && sidebarContainer) {
            if (this.props.user.clientsNames.length < 2) {
                sidebarContainer.style.display = 'none';
                rightSideContainer.style.width = '99%';
                footer.style.paddingLeft= '0';
            }
        }

        this.fetchClientList();
    };


    selectClient = (activeClient) =>  {
        this.setState({activeClient: activeClient.name }, () => {
            localStorage.setItem('activeClientName', JSON.stringify(activeClient.name));
            localStorage.setItem('activeClientUploadKeys', JSON.stringify(activeClient.canUploadKeys));

            if (this.props.isMultipleClientsAccountManager) {
                localStorage.setItem('activeClientName', JSON.stringify(activeClient.name))
                if(activeClient){
                    localStorage.setItem('kyc_status', activeClient.kycStatus);
                }
                localStorage.setItem('canViewOnboarding', JSON.stringify(activeClient.canViewOnboarding));
            }
            this.props.onClientChange();
            if(!utils.isUserAdmin()){
                if(localStorage.getItem('kyc_status') === 'ONBOARDING_COMPLETED'){
                    this.setState({redirectToDashboard: true},()=>{
                        window.location.reload();
                    });
                } else {
                    window.location.reload();
                }
            }

        })
    };

    fetchClientList = () => {
        let inputFilter = localStorage.getItem('inputFilter');

        if (this.props.isMultipleClientsAccountManager) {
            caspianApiService.getAccountHolderClientsList()
              .then((clientsList) => {
                  let current = JSON.parse(localStorage.getItem('activeClientName'));
                  let currentClient = clientsList.find((client) => client.name === current);

                  if (currentClient === undefined) {
                      caspianApiService.getUserDetails().then((user) => {
                          this.setState({activeClient: user.clientsNames[0]});
                          localStorage.setItem('activeClientName', JSON.stringify(user.clientsNames[0]));
                      });
                      caspianApiService.getAccountHolderClientsList().then((clientsList) => {
                          this.selectClient(clientsList[0])
                      })
                  } else if (currentClient.kycStatus !== localStorage.getItem('kyc_status')) {
                      this.selectClient(currentClient)
                  }

                  this.setState({clientsList});
                  localStorage.setItem('storedClientsList', JSON.stringify(clientsList));
                  this.inputTyping(inputFilter || '')
              })
        } else {
            caspianApiService.getClientList()
              .then((clientsList) => {
                  this.setState({clientsList});
                  localStorage.setItem('storedClientsList', JSON.stringify(clientsList));
                  this.inputTyping(inputFilter || '')
              })
        }
    }

    switchSidebar = () => {
        this.setState({sidebarIsOpen: !this.state.sidebarIsOpen}, () => {
            localStorage.setItem('sidebarStatus', this.state.sidebarIsOpen);
        });
        let rightSideContainer = document.getElementById('content'),
            footer = document.getElementById('footer');

        if (this.state.sidebarIsOpen === true ) {
            rightSideContainer.style.width = '99%';
            footer.style.padding= '0 1%';
        } else {
            rightSideContainer.style.width = '85%';
            footer.style.paddingLeft= '15%';
        }
    };

    showClientStatus = (modalIsOpen, clientName) => {
        this.setState({modalIsOpen, hoverClient: clientName})
    };

    render(){

        let { activeClient, transformedClientsList, inputValue, sidebarIsOpen ,modalIsOpen, hoverClient, redirectToDashboard} = this.state;

        if (redirectToDashboard === true) {
            this.setState({redirectToDashboard: false})
            return <Redirect to={'/dashboard'}/>
        }

        return(
            <div id={'client-sidebar-container'} className={sidebarIsOpen ? 'client-sidebar-container' : 'client-sidebar-container hide' }>

                <div className='sidebar-full-container'>

                    <div className={sidebarIsOpen ? 'sidebar-switch' : 'sidebar-switch overflow-left'} onClick={() => this.switchSidebar()}>
                        <i className={sidebarIsOpen ? 'now-ui-icons arrows-1_minimal-left icon-left' : 'now-ui-icons arrows-1_minimal-left icon-right' }> </i>
                    </div>

                    <h5 className='view-data-title'>View Data For: </h5>
                    {
                        activeClient.length ?
                            <div>
                                <h5>
                                    <i className="now-ui-icons arrows-1_minimal-right" style={{fontSize: '12px', 'paddingRight': '10px'}}> </i>
                                    {activeClient}
                                </h5>
                            </div>
                                :
                                ""
                    }

                    <div className='search-container'>
                        <input type="text" placeholder='Search' value={inputValue} onChange={this.handleChange} />
                    </div>


                    {
                        transformedClientsList.length ?
                            <div className='clients-list-container'>
                                {
                                    transformedClientsList.map((client) => {
                                        return(
                                            <div key={client.id}  className={activeClient === client.name ? 'client-name active': 'client-name'}
                                                 onClick={() => this.selectClient(client)}
                                                 onMouseEnter={() => this.showClientStatus(true, client.name)}
                                                 onMouseLeave={() => this.showClientStatus(false)}>

                                                <div className={modalIsOpen && hoverClient === client.name? 'kyc-status-modal active' : 'kyc-status-modal hidden'}> {this.mapOfKycStatus[client.kycStatus]} </div>
                                                <span className='bullet'>&nbsp;</span>
                                                {client.name}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            :
                            <div className='no-clients-found-message'>
                                No results
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default ClientsSidebar;
