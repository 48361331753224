import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Col, Row} from 'reactstrap'
import caspianConfig from '../../config';
import caspianApiService from '../../services/CaspianApiService';

class TableRow extends Component {

  constructor (props) {
    super(props);

    this.state = {
      expanded: false,
      infoPopupIsOpen: false,
    };
    this.openVersionsPopup = this.openVersionsPopup.bind(this)
  }

    openVersionsPopup = (event,row) => {
      event.stopPropagation();

      let lastOpened;
      let openedPopup = document.getElementsByClassName('versions-popup');
      if (openedPopup && openedPopup.length > 0) {
          for (let i=0; i<openedPopup.length; i++){
              if (openedPopup[i].style.display === 'block'){
                  lastOpened = openedPopup[i].id;
                  openedPopup[i].previousElementSibling.style.color = '#bdbdbd';
                  openedPopup[i].style.display = 'none';
              }
          }
      }

      if (lastOpened === row.downloadURL) {
        return null
      }

      let popupToOpen = document.getElementById(row.downloadURL);
      popupToOpen.previousElementSibling.style.color = '#001EFF';
      popupToOpen.style.display = 'block';
    };


  renderCell (column, customColumnClass, index, row) {

    return (
      <Col {...column.size} className={column.customColumnClass} key={index}>
        {
          (column.actionCells) ?
            <a href={column.value} className='btn btn-smaller-padding btn-with-margin btn-primary' download target="_blank">Download</a>
              :
              <React.Fragment>
                  <span style={{ fontSize: '11px' }} onClick={this.handleClick.bind(this, row[0])}>
                    {column.value}
                  </span>

                  {
                      row[0].value  && caspianConfig.SHOW_RESOURCE_VERSION && row[0].versions && row[0].versions.length > 0 &&
                      <div className='versions' key={row[0].downloadURL}>
                          <i className='now-ui-icons travel_info table-info-icon '
                             onClick={(event) => this.openVersionsPopup(event,row[0])} />
                          {
                                <div id={row[0].downloadURL} className='versions-popup'>
                                    {row[0].versions.join(', ')}
                                </div>
                           }
                      </div>
                  }
              </React.Fragment>
        }
      </Col>
    )
  }

  isExpandable () {
    const { isExpandable, expandedRowComponentFn } = this.props

    if (!isExpandable || !expandedRowComponentFn) {
      return false
    }

    return true
  }

  handleClick (row) {
    window.open(row.downloadURL);
    caspianApiService.postDownloadedFile(row.value, row.downloadURL).then(() => {
      if (!this.isExpandable()) {
        return
      }
      this.setState({expanded: !this.state.expanded})

    })
  }

  render () {

    const { row, id, customRowClass, customColumnClass, expandedRowComponentFn } = this.props;
    const content = [];

    content.push(
      <Row
        className={this.state.expanded ? `${customRowClass} expanded` : customRowClass}
        key={id}>
        {
          row.map((cell, index) => this.renderCell(cell, customColumnClass, index, row))
        }
      </Row>
    );

    if (this.isExpandable() && this.state.expanded) {
      content.push(expandedRowComponentFn())
    }

    return content
  }
}

TableRow.propTypes = {
  row: PropTypes.array.isRequired,
  id: PropTypes.number,
  customRowClass: PropTypes.string,
  customColumnClass: PropTypes.string,
  isExpandable: PropTypes.bool,
  expandedRowComponentFn: PropTypes.func
}

export default TableRow
