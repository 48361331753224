import React from 'react';
import Loadable from "react-loading-overlay";
import {Card, CardBody, CardHeader} from "reactstrap";
import utils from "../../utils";
import '../../assets/scss/caspian-dashboard/_audit-reports-page.scss';
import caspianApiService from '../../services/CaspianApiService';
import ClientsSidebar from "../../components/ClientsSidebar/ClientsSidebar";

class AuditReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: utils.getUserData(),
            loadingData: false,
            loadingText: '',
            clientList: [],
            isDropdownOpen: false,
            selectedClient: 'View As',
            clientData: [],
            funds: [],
            years: [],
            months: [],
            reports: [],
            currentYear: undefined,
            currentMonth: undefined,
            currentFund: undefined,
        };

        this.downloadIconStyle = {
            paddingRight: '20px',
            fontSize: '15px'
        };
    };

    resetClientData () {
        this.setState({
            funds: [],
            years: [],
            months: [],
            reports: [],
            currentYear: undefined,
            currentMonth: undefined,
            currentFund: undefined,
        })
    }

    componentDidMount() {
        this.setState({
            userIsAdmin: utils.isUserAdmin(),
        }, () => {
            this.getClient();
        });
    };

    getClient() {
        this.setState({loadingData: true});
        let {userIsAdmin} = this.state;

        if (userIsAdmin) {

            let clientName = JSON.parse(localStorage.getItem('activeClientName'));
            this.setState({
                selectedClient: clientName,
            }, () => {
                this.getClientData(clientName)
            });

            // this.getClientsList();
        } else {
            let {user} = this.state;
            if (user) {
                this.setState({selectedClient: user.clientName}, () => {
                    this.getClientData(this.state.selectedClient);
                })
            }
        }
    };

    getClientData(clientName) {
        this.resetClientData();
        caspianApiService.getAvailableAuditDates(clientName).then((dates) => {
            if (dates.clientData.length === 0) {
                this.setState({
                    funds: [],
                    loadingData: false
                });
                return false;
            }

            let funds = [];

            dates.clientData.forEach((obj, index) => {
                if (obj.fundName) {
                    funds.push(obj.fundName)
                }
            });

            this.setState({
                clientData: dates.clientData,
                funds: funds,
            }, () => {
                this.onFundClick(funds[0]);
            });
        }).catch((e) => {
            this.setState({loadingData: false});
        });
    };

    onClientChange = () => {
        this.setState({loadingData: true});
        let clientName = JSON.parse(localStorage.getItem('activeClientName'));
        this.setState({
            selectedClient: clientName,
        }, () => {this.getClientData(clientName)})
    };

    getClientAvailableYears(currentFund) {
        let {clientData} = this.state,
            years = [];

        clientData.forEach((fundObj) => {
            if (fundObj.fundName === currentFund) {
                fundObj.availableReports.forEach((report) => {
                    if (report.year) {
                        years.push(report.year);
                    }
                })
            }
        });

        this.setState({years: years.sort((a,b) => a-b)}, () => {
            this.getLatestUserReports();
            return Promise.resolve(years)
        })
    };

    getLatestUserReports() {
        let {years} = this.state;

        let latestYear = years[years.length -1];
        this.setState({currentYear: latestYear}, () => {
            this.getClientAvailableMonths(latestYear).then((months) => {
                let latestMonth = months[months.length - 1];
                this.onMonthClick(latestMonth);
            })
        });
    };

    getClientAvailableMonths(year) {
        let {clientData, currentFund} = this.state;
        let months = [];

        clientData.forEach((fundObj) => {
            if (fundObj.fundName === currentFund) {
                fundObj.availableReports.forEach((rep) => {
                    if (rep.year === year) {
                      months = rep.availableMonths.sort((a, b) => {
                        return a - b;
                      });
                    }
                })
            }
        });

        this.setState({months}, () => {
            this.onMonthClick(months[months.length - 1])
        });
        return Promise.resolve(months);
    };


    onFundClick = (currentFund) => {
        this.setState({currentFund}, () => {
            this.getClientAvailableYears(currentFund)
        });
    };

    onYearClick = (currentYear) => {
        this.setState({currentYear}, () => {
            this.getClientAvailableMonths(currentYear)
        });
    };

    onMonthClick = (currentMonth) => {
        this.setState({currentMonth}, () => {
            this.getClientReports();
        });
    };

    getClientReports() {
        this.setState({loadingData: true});
        let {currentFund, currentYear, currentMonth} = this.state;
        caspianApiService.getAvailableAuditReports(currentFund, currentYear, currentMonth).then((data) => {
            this.setState({
                reports: data,
                loadingData: false
            });
        });
    };

    monthFormatter(month) {
        let monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return monthsOfYear[month - 1];
    };

    render() {




        let {userIsAdmin, selectedClient, years, months, reports, currentYear, currentMonth, funds, currentFund, loadingData} = this.state;

        return (
            <Loadable active={this.state.loadingData} spinner text={this.state.loadingText}>
                <div className="full-page-content small">

                    {
                        userIsAdmin &&
                        <ClientsSidebar onClientChange={this.onClientChange}/>
                    }

                    <div id={'content'} className="content" style={{'paddingTop': '35px'}}>
                        <Card className="card-stats card-raised audit-container"
                              style={{visibility: selectedClient !== 'View As' ? 'visible' : 'hidden'}}>

                            <CardHeader>
                                <h4 className="titleContainerStyle">{selectedClient}</h4>
                                <i className="now-ui-icons arrows-1_minimal-right"> </i>
                                <h4 className="titleContainerStyle">Audit </h4>
                            </CardHeader>

                            <CardBody>

                                {
                                    funds.length ?
                                        <div>
                                            <div className="datesContainerStyle">
                                                {
                                                    funds.map((fund) => {
                                                        return (
                                                            <div className="fundStyle"
                                                                 onClick={e => this.onFundClick(fund)}>
                                                    <span
                                                        className={'date-text ' + (currentFund === fund ? 'active' : '')}> {fund} </span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>

                                            {
                                                years.length ?
                                                    <div className="datesContainerStyle">
                                                        <div className="dateTitleStyle">Years:</div>
                                                        {
                                                            years.map((year) => {
                                                                return (
                                                                    <div className="dateStyle"
                                                                         onClick={e => this.onYearClick(year)}>
                                                    <span
                                                        className={'date-text ' + (currentYear === year ? 'active' : '')}> {year} </span>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    ""
                                            }

                                            {
                                                months.length ?
                                                    <div className="datesContainerStyle">
                                                        <div className="dateTitleStyle"> Months:</div>
                                                        {
                                                            months.map((month) => {
                                                                return (
                                                                    <div className="dateStyle"
                                                                         onClick={e => this.onMonthClick(month)}>
                                                            <span
                                                                className={'date-text ' + (currentMonth === month ? 'active' : '')}> {this.monthFormatter(month)} </span>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    ''
                                            }

                                            {
                                                reports.length ?
                                                    <div className='reports-container'>
                                                        <div className="reports-title"> Audit for {selectedClient} at
                                                            month {currentYear}.{currentMonth}:
                                                        </div>
                                                        {
                                                            reports.map((report) => {
                                                                return (
                                                                    <div className='reports-block'>
                                                                        <span> {report.date}: </span>
                                                                        <span className='file'>
                                                                            <i style={this.downloadIconStyle}
                                                                                className="now-ui-icons arrows-1_cloud-download-93">
                                                                            </i>
                                                                            <a href={report.downloadURL} target="_blank" rel="noopener noreferrer"> {report.name + '.' + report.extension} </a>
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    :

                                                    ''
                                            }
                                        </div>
                                        :
                                        <div>
                                            {
                                                loadingData ?
                                                    ''
                                                    :
                                                    <div>
                                                        {
                                                            userIsAdmin ?
                                                                <div className='no-reports-message'> No reports available for this client </div>
                                                                :
                                                                <div className='no-reports-message'> No reports available </div>
                                                        }
                                                    </div>
                                            }
                                        </div>

                                }

                            </CardBody>
                        </Card>
                    </div>
                    <div style={{clear: 'both'}}> </div>
                </div>
            </Loadable>
        );
    }
}

export default AuditReportsPage;