import React from 'react';
import PropTypes from 'prop-types';
import {
    CardHeader, CardTitle, CardBody,
    Row, Col,
    Form, InputGroup, InputGroupAddon, Input, Alert
} from 'reactstrap';

import { Button } from '../../components';

class TFAuth extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            digitCode: '',
            error: props.errorMessage || ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    };

    componentWillReceiveProps(nextProps) {
      if (nextProps.errorMessage) {
        this.setState({
          error: nextProps.errorMessage,
          digitCode: ''
        })
      } else {
        this.setState({error: nextProps.errorMessage})
      }
    }

    handleChange = event => {
        // eslint-disable-next-line
        this.state[event.target.name] = event.target.value;
        if (event.target.value.length === 6) {
          this.props.handleSubmitFn(this.state.digitCode);
        }
        this.setState(this.state);
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.digitCode.length === 0){
          this.setState({error: "Please input the code."})
          return;
        }
        this.props.handleSubmitFn(this.state.digitCode);
    }

    renderFormError() {
        return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
    }

    renderQrCode() {
      if(this.props.qrCodeData) {
        return (
          <div>
            <p style={{textAlign: "center", fontStyle: "italic"}}> <span style={{color: "#005971"}}>Secret Key</span>: {this.props.secretKey} </p>
            <p className="text-center">Scan the QR code using <a href="#">Google Authenticator</a> and follow the steps in the app</p>
            <img src={this.props.qrCodeData} alt="dummy qr" className="qr-code" />
          </div>
        )
      }
    }

    render(){
        return (
           <div className="tfauth">
                <CardHeader className="text-center">
                    <CardTitle>{this.props.title}</CardTitle>
                </CardHeader>
                <CardBody>
                  {this.renderFormError()}
                  {this.renderQrCode()}
                  <Form onSubmit={this.handleSubmit}>
                      <div>
                          <p className="text-center">Enter the code from Google Authenticator</p>
                          <Row>
                              <Col md={6} className="code-input-container">
                                  <InputGroup className={(this.state.digitCodeFocus ? "input-group-focus":"")}>
                                      <InputGroupAddon ><i className="now-ui-icons ui-1_lock-circle-open"></i></InputGroupAddon>
                                      <Input value={this.state.digitCode} autoFocus="true" type="text"
                                             placeholder="Enter code" name="digitCode" autocomplete="off"
                                             onChange={this.handleChange} onFocus={(e) =>
                                        this.setState({digitCodeFocus: true})}
                                             onBlur={(e) => this.setState({digitCodeFocus: false})}/>
                                  </InputGroup>
                              </Col>
                              <Col md={6}>
                                  <Button className="btn btn-primary btn-verify" type="submit">
                                      Verify
                                  </Button>
                              </Col>
                          </Row>
                      </div>
                  </Form>
                </CardBody>
            </div>
        );
    }
}

TFAuth.propTypes = {
  title: PropTypes.string.isRequired,
  qrCodeData: PropTypes.string,
  errorMessage: PropTypes.string,
  handleSubmitFn: PropTypes.func
}

export default TFAuth;
