import React from 'react'
import { Link } from 'react-router-dom'
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem
} from 'reactstrap'

import utils from '../../utils'

import caspianConfig from '../../config'
import images from '../../assets/img'
import Redirect from 'react-router-dom/es/Redirect'
import { createBrowserHistory } from 'history'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      apiDropdownOpen: false,
      reportsDropdownOpen: false,
      color: 'white',
      user: JSON.parse(localStorage.getItem('user')),
      activePath: ''
    }

    this.toggle = this.toggle.bind(this)
    this.dropdownToggle = this.dropdownToggle.bind(this)
    this.apiDropdownToggle = this.apiDropdownToggle.bind(this)
  }

  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  dropdownToggle (e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  apiDropdownToggle (e) {
    this.setState({ apiDropdownOpen: !this.state.apiDropdownOpen })
  }

  reportsDropdownToggle (e) {
    this.setState({ reportsDropdownOpen: !this.state.reportsDropdownOpen })
  };

  openSidebar () {
    document.documentElement.classList.toggle('nav-open')
    this.refs.sidebarToggle.classList.toggle('toggled')
  }

  componentDidMount () {
    const history = createBrowserHistory()
    this.setState({activePath: history.location.pathname})
  }

  componentDidUpdate (e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({activePath: e.history.location.pathname})
    }

    if (window.innerWidth < 993 && e.history.location.pathname !== e.location.pathname && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open')
      this.refs.sidebarToggle.classList.toggle('toggled')
    }
  }

  render () {
    let { activePath } = this.state

    if (this.state.user === null) {
      return <Redirect to={'/login'}/>
    }

    return (

      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={this.props.location.pathname.indexOf('full-screen-maps') !== -1 ? 'white' : this.state.color} expand="lg"
        className={
          this.props.location.pathname.indexOf('full-screen-maps') !== -1
            ? 'fixed-top' : 'fixed-top ' + (this.state.color === 'transparent' ? 'navbar-transparent ' : '')}>
        <Container fluid>
          <div className="navbar-wrapper">
            <NavbarBrand href="/dashboard">
              <div className="navbar-logo-container">
                <img className="logo-img" src={images.lsegToraLogo} alt="lseg tora logo" />
                <span className="logo-text">Digital Assets Portal</span>
              </div>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar className="justify-content-end">
            <Nav navbar>
              {
                (utils.isUserAdmin() || (utils.isUserAccountManager() && (utils.isKYCPending() || utils.isAwaitingKYC())))
                  ? <NavItem className={activePath === '/kyc' ? 'active-item' : ''}>
                    <Link to='/kyc' className="nav-link"><i className="now-ui-icons business_badge"></i>
                      KYC</Link>
                  </NavItem>
                  : ''
              }
              {
                ((utils.isUserAccountManager() && utils.canViewOnboarding() && (utils.isKYCCompleted() || utils.isSubmittedInitialData() || utils.isApplicationApproved())) || utils.isUserAdmin())
                  ? <NavItem className={activePath === '/client-info' ? 'active-item' : ''}>
                    <Link to='/client-info' className="nav-link navbar-link-container">
                      <i className="now-ui-icons business_badge navbar-link-icon"></i>
                      <span className="navbar-link-text">Onboarding</span></Link>
                  </NavItem>
                  : ''
              }
              {
                ((utils.isUserAccountManager() && utils.isOnboardingCompleted()) || utils.isUserAdmin())
                  ? <NavItem className={activePath === '/dashboard/home' ? 'active-item' : ''}>
                    <Link to='/dashboard' className="nav-link navbar-link-container">
                      <i className="now-ui-icons design_bullet-list-67 navbar-link-icon"></i>
                      <span className="navbar-link-text">Dashboard</span>
                    </Link>
                  </NavItem>
                  : ''
              }

              {/* Disable until backend implementation is ready */}
              {/* { */}
              {/* ((utils.isUserAccountManager() && utils.isOnboardingCompleted()) || utils.isUserAdmin()) */}
              {/* ? */}
              {/* <Dropdown nav isOpen={this.state.reportsDropdownOpen} toggle={(e) => this.reportsDropdownToggle(e)}> */}
              {/* <DropdownToggle caret nav> */}
              {/* <i className="now-ui-icons files_single-copy-04"></i> */}
              {/* REPORTS */}
              {/* </DropdownToggle> */}
              {/* <DropdownMenu right> */}
              {/* <DropdownItem tag="a" href="/reports/audit">AUDIT</DropdownItem> */}
              {/* <DropdownItem tag="a" href="/reports/executions">EXECUTIONS</DropdownItem> */}
              {/* </DropdownMenu> */}
              {/* </Dropdown> */}
              {/*: '' */}
              {/* } */}

              {
                (utils.isOnboardingCompleted() || utils.isUserAdmin() || utils.isApplicationApproved())
                  ? <NavItem className={activePath === '/dashboard/resources' ? 'active-item' : ''}>
                    <Link to='/dashboard/resources' className="nav-link navbar-link-container">
                      <i className="now-ui-icons arrows-1_cloud-download-93 navbar-link-icon"></i>
                      <span className="navbar-link-text">Resources</span></Link>
                  </NavItem>
                  : ''
              }

              {
                (utils.isUserAdmin())
                  ? <NavItem className={activePath === '/dashboard/tools' ? 'active-item' : ''}>
                    <Link to='/dashboard/tools' className="nav-link navbar-link-container">
                      <i className="now-ui-icons ui-1_settings-gear-63 navbar-link-icon"></i>
                      <span className="navbar-link-text">Tools</span>
                    </Link>
                  </NavItem>
                  : ''
              }

              {
                (utils.isOnboardingCompleted() || utils.isUserAdmin() || utils.isApplicationApproved())
                  ? <Dropdown className={activePath === '/dashboard/documentation/pms' || activePath === '/dashboard/documentation/mdata' ? 'active-dropdown-item' : ''} nav isOpen={this.state.apiDropdownOpen} toggle={(e) => this.apiDropdownToggle(e)}>
                    <DropdownToggle caret nav className="navbar-link-container">
                      <i className="now-ui-icons education_agenda-bookmark navbar-link-icon"></i>
                      <span className="navbar-link-text">API</span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag="a" href="/dashboard/documentation/pms">PMS</DropdownItem>
                      <DropdownItem tag="a" href="/dashboard/documentation/mdata">MARKET DATA</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  : ''
              }

              <Dropdown nav isOpen={this.state.dropdownOpen} toggle={(e) => this.dropdownToggle(e)}>
                <DropdownToggle caret nav className="navbar-link-user-container">
                  <i className="now-ui-icons users_single-02 navbar-link-user-icon"></i>
                  <span className="navbar-link-user-text">{this.state.user.firstName} {this.state.user.lastName}</span>
                  <p>
                    <span className="d-lg-none d-md-block">User Menu</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  {
                    (utils.isOnboardingCompleted() && utils.isUserAccountManager() && utils.canViewOnboarding())
                      ? <DropdownItem tag="a" href="/client-info">Onboarding</DropdownItem>
                      : ''}
                  {
                    (utils.isUserAdmin())
                      ? <DropdownItem tag="a" href="/user-guide">User Guide</DropdownItem>
                      : ''}
                  {
                    (utils.isUserAdmin())
                        ? <DropdownItem tag="a" href={caspianConfig.CASPIAN_ADMIN_PORTAL_LINK}>Caspian Admin Portal</DropdownItem>
                        : ''}
                  <DropdownItem tag="a" href="/dashboard/settings">Settings</DropdownItem>
                  <DropdownItem tag="a" onClick={utils.logoutUser}>Log Out</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default Header
