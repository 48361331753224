/**
 * Created by myh on 08.09.2018.
 */
import utils from './artemis_utils'

export class CRPCorporateType {
  constructor () {
    this.corporateCategories = ['LOCAL COMPANY', 'LOCAL ENTITY NOT REGISTERED WITH ACRA',
      'LIMITED LIABILITY PARTNERSHIP', 'FOREIGN COMPANY',
      'FOREIGN ENTITY NOT REGISTERED WITH ACRA', 'FOREIGN BRANCH',
      'TRUST', 'CHARITY', 'ASSOCIATION']
  }

  getCategoryOptions () {
    const corporateCategoriesJson = []
    this.corporateCategories.forEach(c => {
      corporateCategoriesJson.push({
        value: c,
        label: utils.titleCase(c)
      })
    })
    return corporateCategoriesJson
  }
}
