import React from 'react'
import PropTypes from 'prop-types'
import {Card, CardBody, CardHeader} from 'reactstrap'
import _ from 'lodash'
import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import caspianApiService from '../../services/CaspianApiService'
import Select from "react-select";

class AssetClassesMultiSelect extends React.Component {
    static propTypes = {
        value: PropTypes.array,
        onUpdate: PropTypes.func.isRequired
    }
    static defaultProps = {
        value: []
    }

    state = {
        currentSelectedAssetClasses: undefined
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.currentSelectedAssetClasses === undefined && !_.isEqual(nextProps.value, prevState.currentSelectedAssetClasses)) {
            const products = nextProps.value.map(product => ({
                value: product.id,
                label: product.name
            }))
            return { currentSelectedAssetClasses: products };
        }

        return null;
    }

    handleAssetClassesChange = () => (value) => {
        this.setState({currentSelectedAssetClasses: [...value]})
    }

    handleOnBlur = () => () => {
        this.props.onUpdate(this.mapRenderedViewToProducts([...this.state.currentSelectedAssetClasses]))
    }

    mapRawProductsToRenderedView = products => products.map(product => ({
        value: product.id,
        label: product.name
    }))

    mapRenderedViewToProducts = products => products.map(product => ({
        id: product.value,
        name: product.label
    }))

    render() {
        const { row } = this.props;
        const productsOptions = this.mapRawProductsToRenderedView(row.exchange.products)
        return <Select name="exchange_asset_classes" multi
                     value={this.state.currentSelectedAssetClasses}
                     onChange={this.handleAssetClassesChange()}
                     onBlur={this.handleOnBlur()}
                     options={productsOptions}
                />
    }
}

class OnboardingExchangeTable extends React.Component {

  booleanFormatter = (cell, row) => {
    return cell === 'true' || cell === true ?
           <i className='now-ui-icons ui-1_check checked'></i> :
           <i className='now-ui-icons ui-1_simple-remove not-checked' color='danger'></i>
  }

  exchangeFormatter = (cell, row) => {
      return cell.map(exchange => exchange.name).join(",");
  }

  constructor (props) {
    super(props);

    this.state = {
      exchangeWalletsCustodians: [],
      loading: false,
      clientName: this.props.clientName,
      error: ''
    };

    this.columnHeaderTitle = {
        'paddingRight' : '8px'
    };

      this.handleOnLoading = this.handleOnLoading.bind(this);
  };

  componentDidMount () {
    this.loadClientOnboardingExchanges(this.props.clientName);
    this.handleOnLoading(this.state.loading);
  }

  componentWillReceiveProps(nextProps) {
      if (this.state.clientName !== nextProps.clientName) {
        this.setState({clientName: nextProps.clientName});
        this.loadClientOnboardingExchanges(nextProps.clientName);
      }
  }

   componentDidUpdate(prevProps, prevState, snapshot) {
       if(prevState.loading !== this.state.loading) {
           this.handleOnLoading(this.state.loading);
       }
   }

    handleOnLoading = (isLoading) => {
        //notify parent that loading is finished
        if(false === isLoading) {
            this.props.handleOnLoading('OnboardingExchangeTable', isLoading);
        }
    };

  renderError () {
    if (this.state.error && this.state.error.length > 0) {
      this.showNotification(this.state.error, 'danger')
    }
  }

  showNotification (message, type = 'primary') {
    // var color = Math.floor((Math.random() * 5) + 1)

    let options = {
      place: 'tc',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

   columnHeaderFormatter (title)  {
       return (
           <div>
               <span style={this.columnHeaderTitle}>{title}</span>
               <i className="cas-icon cas-edit-75"></i>
           </div>
       );
   };

  render () {
    const exchangesColumnsConfig = [
      {
        dataField: 'exchange.description',
        text: 'Exchange',
        editable: false,
        headerClasses: 'w-15 user-table-header',
      },
      {
        dataField: 'required',
        text: this.columnHeaderFormatter('Required'),
        formatter: this.booleanFormatter,
        editable: !this.props.readOnly,
        headerClasses: 'w-15 user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        editor: {
          type: Type.CHECKBOX,
          value: 'true:false'
        }
      },
      {
        dataField: 'usedProductsByClient',
        text: this.columnHeaderFormatter('Exchange Asset Classes'),
        formatter: this.exchangeFormatter,
        editable: !this.props.readOnly,
        headerClasses: 'w-15 user-table-header',
        classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
          <AssetClassesMultiSelect { ...editorProps } row={ row } value={ value } />
        )
      },
    ]
    return (
      <Card className="card-stats card-raised" style={{display: this.props.visible ? 'block' : 'none'}}>
        <CardHeader>
          <h4>Exchanges</h4>
        </CardHeader>
        <CardBody>
          <div className="dashboard-table-wrapper">

            <BootstrapTable
              hover
              condensed
              keyField='exchangeId'
              classes="table-responsive table-client-info"
              bordered={false}
              rowStyle={{ fontSize: '10px' }}
              data={this.state.exchangeWalletsCustodians}
              columns={exchangesColumnsConfig}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                afterSaveCell: this.afterSaveCell
              })}
            />
          </div>
        </CardBody>
      </Card>
    )
  }

  processOnboardingExchanges = onboardingExchanges => {
      return onboardingExchanges.map(onboardingExchange => ({
          exchangeId: onboardingExchange.exchange.id,
          ...onboardingExchange
      }))
  }

  loadClientOnboardingExchanges (clientName) {
      if (clientName && clientName !== 'View As' && clientName.length > 0){
          this.setState({loading: true});
          caspianApiService.getOnboardingExchanges(clientName)
              .then((onboardingExchanges) => {
                      this.setState({
                                      exchangeWalletsCustodians: this.processOnboardingExchanges(onboardingExchanges),
                                      oldClientName: this.state.clientName,
                                      loading: false
                                    })
                    }
              , () => this.setState({loading: false})
              )
              .finally(() => this.setState({loading: false}));
      }
  }

    processBeforeSend = onboardingClientExchanges => {
      return onboardingClientExchanges.map(onboarding => ({
        exchange: onboarding.exchange,
        usedProductsByClient: onboarding.usedProductsByClient,
        required: Boolean(onboarding.required)
      }))
    }

    save = () => {
        return caspianApiService.postClientOnboardingExchanges(this.processBeforeSend(this.state.exchangeWalletsCustodians), this.props.clientName)
    }

}

OnboardingExchangeTable.propTypes = {
  readOnly: PropTypes.bool,
  clientName: PropTypes.string
};

export default OnboardingExchangeTable
