import React from 'react';
import PropTypes from 'prop-types';
import {
    Card, CardHeader, CardBody, Row, Col, Form, Alert
} from 'reactstrap';

class KycForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            singleSelect: null,
            fundName: '',
            fundAdmin: '',
            address: '',
            countryRegion: '',
            phoneNo: '',
            totalValueAssets: '',
            projectedVolume: '',
            certificateOfIncorporation: null,
            vatDocs: null
        }
        this.kyc = props.kyc;
        this.handleSave = this.handleSave.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event){
        event.preventDefault();
        // if(!this.state.certificateOfIncorporation){
        //     this.setState({error: 'Please upload the Certificate of incorporation'});
        //     return;
        // }
        // if(!this.state.vatDocs){
        //     this.setState({error: 'Please upload Tax/VAT ID Documentation'});
        //     return;
        // }
        // if(!this.state.fundName || !this.state.fundAdmin || !this.state.address ||
        //     !this.state.countryRegion || !this.state.phoneNo || !this.state.totalValueAssets || !this.state.projectedVolume){
        //     this.setState({error: 'Please complete all the fields'});
        //     return;
        // }
        this.kyc.handleSubmit(event);
        alert('submit');
    }

    handleSave(event){
        event.preventDefault();
        // if(!this.state.certificateOfIncorporation){
        //     this.setState({error: 'Please upload the Certificate of incorporation'});
        //     return;
        // }
        // if(!this.state.vatDocs){
        //     this.setState({error: 'Please upload Tax/VAT ID Documentation'});
        //     return;
        // }
        // if(!this.state.fundName || !this.state.fundAdmin || !this.state.address ||
        //     !this.state.countryRegion || !this.state.phoneNo || !this.state.totalValueAssets || !this.state.projectedVolume){
        //     this.setState({error: 'Please complete all the fields'});
        //     return;
        // }
        alert('save');
        this.setState({error: ''});
    }

    handleChange = event => {
        // eslint-disable-next-line
        this.state[event.target.name] = event.target.value;
        this.setState(this.state);
    }

    renderFormError() {
        return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
    }

    render(){
        return (
            <div>
                <div className="content kyc-form"  style={{width: '100%'}}>
                    <Row className="justify-content-center">
                        <Col xs={8} md={8} className="">
                            <Card className="card-stats card-raised">
                                <CardHeader className="kyc-header">
                                    <h2>{this.props.title}</h2>
                                    <p>{this.props.description}</p>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSave}>
                                        {this.props.children}
                                        {this.renderFormError()}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

KycForm.propTypes = {
  // Where the user to be redirected on clicking the avatar
  title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
  ]),
  description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
  ]),
  children: PropTypes.array
}

export default KycForm;
