import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'reactstrap'

import { CardSuccessMessage } from '../../components'

import CreateAccountForm from './CreateAccountForm.jsx'
import CreateAccountTFAuth from '../TFAuth/CreateAccountTFAuth.jsx'

class CreateAccountWizard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      createAccountStepDone: false,
      twoFactorAuthDone: false,
      error: ''
    }
  }

  renderForm () {
    if (this.state.twoFactorAuthDone === true) {
      return (
        <CardSuccessMessage
          title = "Account successfully created">
          <p>You've been successfully registered. A verification email was sent to your account.</p>
        </CardSuccessMessage>
      )
    } else if (this.state.createAccountStepDone === true) {
      return (
        <CreateAccountTFAuth
          {...this.state.userData}
          onVerifiedListenerFn={ () => {
            this.setState({ twoFactorAuthDone: true })
          }} />)
    } else {
      return (
        <CreateAccountForm {...this.props.accessData}
          onAccountCreatedListenerFn = { (userData) => {
            this.setState({
              userData,
              createAccountStepDone: true
            })
          }} />
      )
    }
  }

  render () {
    return (
      <div>
        <Card className="card-form">
          {this.renderForm()}
        </Card>
      </div>
    )
  }
}

CreateAccountWizard.propTypes = {
  accessData: PropTypes.object.isRequired
}

export default CreateAccountWizard
