import Pages from '../layouts/Pages/Pages.jsx'
import Dashboard from '../layouts/Dashboard/Dashboard.jsx'
import Onboarding from '../layouts/Onboarding/Onboarding'

const indexRoutes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/client-info', name: 'Onboarding', component: Onboarding },
  { path: '/kyc', name: 'KYC', component: Onboarding },
  { path: '/reports', name: 'Reports', component: Onboarding },
  { path: '/user-guide', name: 'Reports', component: Onboarding },
  { path: '/', name: 'Landing Page', component: Pages }
]

export default indexRoutes
