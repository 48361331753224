import React from 'react'
import PropTypes from 'prop-types'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import { Row, Col, Table } from 'reactstrap'

const prepareRowsData = (rowsData, columnsConfig) => {
  const rows = []

  if (rowsData === undefined) {
    return rows
  }

  rowsData.forEach(row => {
    const rowObj = []

    columnsConfig.forEach(column => {
      const splits = column.matchDataField.split('.')

      if (row == null) {
        return
      }

      // TODO: Support multiple levels of objects
      if (splits.length === 2 && row.hasOwnProperty(splits[0]) && row[splits[0]] != null && row[splits[0]].hasOwnProperty(splits[1])) {
        rowObj.push({
          field: column.matchDataField,
          value: row[splits[0]][splits[1]],
          size: column.size,
          actionCells: column.actionCells,
          customColumnClass: column.customColumnClass,
          downloadURL: row.downloadURL,
          versions: row.availableVersions
        })
      } else {
        if (row.hasOwnProperty(column.matchDataField)) {
          rowObj.push({
            field: column.matchDataField,
            value: row[column.matchDataField],
            size: column.size,
            actionCells: column.actionCells,
            customColumnClass: column.customColumnClass,
            downloadURL: row.downloadURL,
            versions: row.availableVersions
          })
        } else {
          rowObj.push({
            field: column.matchDataField,
            value: null,
            size: column.size,
            actionCells: column.actionCells,
            customColumnClass: column.customColumnClass,
            downloadURL: row.downloadURL,
            versions: row.availableVersions
          })
        }
      }
    })
    rows.push(rowObj)
  })
  return rows
}

const TableComponent = ({
  showTableHead,
  title,
  rowsData,
  columnsConfig,
  expandableRows,
  expandedRowComponentFn,
  customTableWrapperClass,
  customTitleClass,
  customHeaderColumnClass,
  customHeaderRowClass,
  customBodyColumnClass,
  customBodyRowClass
}) => {
  if (!rowsData || rowsData.length === 0) {
    return null
  }

  const rows = prepareRowsData(rowsData, columnsConfig)
  return (
    <Col xs={12} className={customTableWrapperClass}>
      {
        title && <Row className={customTitleClass}>
          {title}
        </Row>
      }
      <Table responsive hover>
        {
          (showTableHead)
            ? <TableHeader
              columns={columnsConfig}
              customRowClass={customHeaderRowClass}
              customColumnClass={customHeaderColumnClass}
            />
            : ''
        }
        <TableBody
          rows={rows}
          expandableRows={expandableRows}
          expandedRowComponentFn={expandedRowComponentFn}
          customRowClass={customBodyRowClass}
          customColumnClass={customBodyColumnClass}
        />
      </Table>
    </Col>
  )
}

TableComponent.propTypes = {
  showTableHead: PropTypes.bool,
  rowsData: PropTypes.array.isRequired,
  columnsConfig: PropTypes.array.isRequired,
  title: PropTypes.string,
  expandableRows: PropTypes.bool,
  expandedRowComponentFn: PropTypes.func,
  customTableWrapperClass: PropTypes.string,
  customTitleClass: PropTypes.string,
  customHeaderColumnClass: PropTypes.string,
  customHeaderRowClass: PropTypes.string,
  customBodyColumnClass: PropTypes.string,
  customBodyRowClass: PropTypes.string
}

export default TableComponent
