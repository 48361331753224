import React from 'react'
import PropTypes from 'prop-types'
import {Card, CardBody, CardHeader} from 'reactstrap'
import utils from '../../utils'
import KeysTbl from './KeysTbl'
import caspianApiService from '../../services/CaspianApiService'
import AddKeyForm from './AddKeyForm'
import Loadable from 'react-loading-overlay'

class KeysTableCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            usersList: [],
            loading: true,
            error: '',
            clientName: this.props.clientName,
            userIsAdmin: undefined,
            userKeysData: [],
            disableButtonAfterUpdate:false,
            clientFunds: [],
            clientCanUploadKeys: this.props.clientCanUploadKeys,
            canUploadKeysValue: null
        };

        this.handleOnLoading = this.handleOnLoading.bind(this);
    }

    componentDidMount() {
        let current = JSON.parse(localStorage.getItem('activeClientName'));
        this.setState({loading: true});
        caspianApiService.getAccountHolderClientsList().then((clientsList) => {
            clientsList.forEach((client)=> {
                if (client.name === current){
                    this.setState({canUploadKeysValue: client.canUploadKeys})
                }
            })
        });
        this.setState({userIsAdmin: utils.isUserAdmin()}, () => {
            this.loadKeysTableData(this.props.clientName);
        });
        this.handleOnLoading(this.state.loading);
    };

    componentWillReceiveProps(nextProps) {
        if (this.state.userIsAdmin && this.props.clientName !== nextProps.clientName) {
            this.loadKeysTableData(nextProps.clientName);
        }
        if (nextProps.userKeysData) {
            this.userKeysData = nextProps.userKeysData
        }

        this.setState({canUploadKeysValue: nextProps.clientCanUploadKeys})
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.clientCanUploadKeys !== this.props.clientCanUploadKeys){
            this.setState({canUploadKeysValue: this.props.clientCanUploadKeys})
        }
        if(prevState.loading !== this.state.loading) {
            this.handleOnLoading(this.state.loading);
        }

    }

    handleOnLoading = (isLoading) => {
        //notify parent that loading is finished
        // console.log('KeysTableCard' + "  loading: " + isLoading);
        if(false === isLoading) {
            this.props.onLoading('KeysTableCard', false);
        }
    };

    loadKeysTableData (clientName) {
        this.setState({loading: true});
        let { userIsAdmin } = this.state;
        if (userIsAdmin && clientName !== 'View As') {
            caspianApiService.getMssKeysClient(clientName).then((userKeysData) => {
                this.prepareTableData(userKeysData);
            }, () => {
                this.setState({loading: false})
            });

            caspianApiService.getClientFundsList(clientName).then((funds) => {
                this.setState({clientFunds: funds});
            });
        } else if (!userIsAdmin && clientName !== 'View As') {
            caspianApiService.getMssKeysForClient(clientName)
                             .then((userKeysData) => {
                                 this.prepareTableData(userKeysData);
                             }, () => {
                                 this.setState({loading: false})
                             });
        }
    };

    prepareTableData = (userKeysData) => {
        this.setState({userKeysData, loading: false})
    };

    findPairKey = (key, allKeys) => {
        let searchedType;
        if(key.type === 'Read'){
            searchedType = 'Trade'
        }
        else if (key.type === 'Trade'){
            searchedType = 'Read'
        }
        return allKeys.find(searchKey => searchKey.accountID === key.accountID && searchKey.type === searchedType)
    }

    reuploadKey = (row, keys) => () => {
        // if key has a pair we need to send the pair key because that's the one read-only
        const type = row.type.toLowerCase()
        if (row.hasPair === true){
            const searchedKey = this.findPairKey(row, keys)
            searchedKey.expired = row.expired;
            searchedKey.fullScope = row.fullScope;
            this.addKeyForm.addPairKey(searchedKey, type)
            return;
        }

        this.addKeyForm.addPairKey(row, type)
    }

    addPairKey = (row, type) => () => {
        this.addKeyForm.addPairKey(row, type)
    };

    updateFund = (fund) => {
        if (this.addKeyForm) {
            this.addKeyForm.setFund(fund)
        }

    };

    saveKeysChanges = (key) => {
        return caspianApiService.updateKey(key).then((data) => {
            this.loadKeysTableData(this.props.clientName);
            return !!(data && data === "MSS Key updated");
        });
    };


    render () {
        let {userIsAdmin, userKeysData, clientFunds} = this.state,
            {clientName, readOnly, notificationAlertHandler} = this.props

        return (
            <div>
                <Card className="card-stats card-raised keys-table-card"
                      style={{visibility: this.props.visible ? 'visible' : 'hidden'}}>
                    <Loadable active={this.state.loading} spinner text='Loading API Keys'>

                        <CardHeader>
                            {userIsAdmin && <h4 style={{marginBottom: 0}}>Keys</h4>}
                            {!userIsAdmin && <h4>Keys</h4>}
                        </CardHeader>
                        <CardBody className={'keys-body-container'}>
                            {
                                userKeysData.length > 0 ?
                                <KeysTbl
                                    readOnly={readOnly}
                                    data={userKeysData}
                                    clientFunds={clientFunds}
                                    userIsAdmin={userIsAdmin}
                                    clientName={clientName}
                                    keyUpdateFn={this.saveKeysChanges}
                                    notificationAlertHandler={notificationAlertHandler}
                                    addPairKey={this.addPairKey}
                                    reuploadKey={this.reuploadKey}
                                    updateFundSelected={this.updateFund}
                                    canUploadKeysValue={this.state.canUploadKeysValue}
                                />
                                                        :
                                <div className='no-data-table-container'>
                                    <span className='no-table-data-message'>{!this.state.loading ? 'No keys available'
                                                                                                 : ''}</span>
                                </div>

                            }

                            {
                                this.state.canUploadKeysValue === true &&
                                <AddKeyForm readOnly={utils.isUserAdmin()}
                                            clientName={clientName}
                                            userKeysData={userKeysData}
                                            refreshKeys={() => {
                                                this.loadKeysTableData(clientName)
                                            }}
                                            ref={akf => this.addKeyForm = akf}
                                />
                            }
                        </CardBody>
                    </Loadable>
                </Card>
            </div>
        )
    }
}

KeysTableCard.propTypes = {
    clientName: PropTypes.string,
    visible: PropTypes.bool,
    readOnly: PropTypes.bool,
    notificationAlertHandler: PropTypes.func,
};

export default KeysTableCard;
