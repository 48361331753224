import React from 'react'
import PropTypes from 'prop-types'

import TFAuth from '../TFAuth/TFAuth.jsx'

import caspianApiService from '../../services/CaspianApiService'

class CreateAccountTFAuth extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  };

  componentWillReceiveProps (nextProps) {
    this.setState({ ...nextProps })
  }

  handleSubmit (digitCode) {
    caspianApiService.verifyDigitCode(digitCode, this.props.email, this.props.password, this.props.rememberMe)
      .then(() => {
        this.props.onVerifiedListenerFn()
      }).catch((err) => {
        this.setState({ error: err.errorMessage })
      })
  }

  render () {
    return (
        <TFAuth
          title = "2-step verification setup"
          qrCodeData = {this.props.qrCodeData}
          errorMessage = {this.state.error}
          handleSubmitFn = {this.handleSubmit} />
    )
  }
}

CreateAccountTFAuth.propTypes = {
  onVerifiedListenerFn: PropTypes.func.isRequired
}

export default CreateAccountTFAuth
