import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardHeader } from 'reactstrap'

import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import caspianApiService from '../../services/CaspianApiService'

const booleanFormatter = (cell, row) => {
    if (cell === false || cell === 'false') {
        return (
            <span style={ { color: 'red' } }>No</span>
        )
    }
    if (cell === 'true'){
        return (
            <span style={ { color: 'green' } }>Yes</span>
        )
    }
    return (
        <span>{cell}</span>
    )
};


class ClientTradingFlowsTable extends React.Component {


  constructor (props) {
    super(props);

    this.state = {
        webAndInHouseClientTradingFlows: [],
        OTCAndOthersClientTradingFlows: [],
      loading: false,
      clientName: this.props.clientName,
      error: ''
    };

      this.columnHeaderTitle = {
          'paddingRight' : '8px'
      };

    this.handleOnLoading = this.handleOnLoading.bind(this);
  }

  componentDidMount() {
      this.loadClientTradingFlows(this.props.clientName);
      this.handleOnLoading(this.state.loading);
  }

  componentWillReceiveProps(nextProps) {
      if (this.state.clientName !== nextProps.clientName) {
        this.setState({clientName: nextProps.clientName});
        this.loadClientTradingFlows(nextProps.clientName)
      }
  }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.loading !== this.state.loading) {
            this.handleOnLoading(this.state.loading);
        }
    }

    handleOnLoading = (isLoading) => {
        //notify parent that loading is finished
        if(false === isLoading) {
            this.props.handleOnLoading('ClientTradingFlowsTable', isLoading);
        }
    };

    save = () => {
        let shouldReloadData = !this.state.webAndInHouseClientTradingFlows[0].id;

            let webAndInHousePromise = caspianApiService.postClientTradingFlowsAdmin(this.state.webAndInHouseClientTradingFlows, this.props.clientName);
            let OTCPromise = caspianApiService.postClientTradingFlowsAdmin(this.state.OTCAndOthersClientTradingFlows, this.props.clientName);

            Promise.all([webAndInHousePromise,OTCPromise]).then(()=> {
                if (shouldReloadData) {
                    this.loadClientTradingFlows(this.props.clientName)
                }
            })

    };

    columnHeaderFormatter (title)  {
        return (
            <div>
                <span style={this.columnHeaderTitle}>{title}</span>
                <i className="cas-icon cas-edit-75"></i>
            </div>

        );
    };


  render () {
      const boolOptions = [{value: '', label: ''}, {value: true, label: 'Yes'}, {value: false, label: 'No'}];
    const flowsColumnConfig = [
        {
            dataField: 'name',
            text: 'Name',
            headerClasses: 'w-15 user-table-header',
            editable: false
        },
        {
            dataField: 'used',
            text: this.columnHeaderFormatter('Used'),
            editable: !this.props.readOnly,
            formatter: booleanFormatter,
            headerClasses: 'w-15 user-table-header',
            classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
            editor: {
                type: Type.SELECT,
                options: boolOptions
            }
            // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            //     <CustomColumn { ...editorProps } value={ value } rowIndex={rowIndex}/>
            // )
        },
        {
            dataField: 'volume',
            text: this.columnHeaderFormatter('Volume (# orders/day)'),
            editable: !this.props.readOnly,
            classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
            headerClasses: 'w-15 user-table-header'
        }
    ];
      const secondFlowsColumnConfig = [
          {
              dataField: 'name',
              text: 'Name',
              headerClasses: 'w-15 user-table-header',
              editable: false
          },
          {
              dataField: 'used',
              text: this.columnHeaderFormatter('Used'),
              editable: !this.props.readOnly,
              headerClasses: 'w-15 user-table-header',
              classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
          },
          {
              dataField: 'volume',
              text: this.columnHeaderFormatter('Volume'),
              editable: !this.props.readOnly,
              classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed',
              headerClasses: 'w-15 user-table-header'
          }
      ];

    return (
      <Card className="card-stats card-raised" style={{visibility: this.props.visible ? 'visible' : 'hidden'}}>
        <CardHeader>
          <h4>How are you currently sending orders</h4>
        </CardHeader>
        <CardBody>
          <div className="dashboard-table-wrapper" style={{visibility: this.props.visible ? 'visible' : 'hidden'}}>

            <BootstrapTable
              hover
              condensed
              keyField='name'
              classes="table-responsive table-client-info"
              bordered={false}
              rowStyle={{ fontSize: '10px' }}
              data={this.state.webAndInHouseClientTradingFlows}
              columns={flowsColumnConfig}
              cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                      if (oldValue !== newValue && !row.edited) {
                          this.setState({editableUserOldValue: Object.assign({}, row)})
                      }
                  },
                  afterSaveCell: this.afterSaveCell
              })}/>
              <BootstrapTable
                  hover
                  condensed
                  keyField='name'
                  classes="table-responsive table-client-info double-table"
                  bordered={false}
                  rowStyle={{ fontSize: '10px' }}
                  data={this.state.OTCAndOthersClientTradingFlows}
                  columns={secondFlowsColumnConfig}
                  cellEdit={cellEditFactory({
                      mode: 'click',
                      blurToSave: true,
                      beforeSaveCell: (oldValue, newValue, row, column) => {
                          if (oldValue !== newValue && !row.edited) {
                              this.setState({editableUserOldValue: Object.assign({}, row)})
                          }
                      },
                      afterSaveCell: this.afterSaveCell
                  })}/>
          </div>
        </CardBody>
      </Card>
    )
  }

  loadClientTradingFlows (clientName) {
      if (clientName !== 'View As'){
          this.setState({loading: true});
          caspianApiService.getWebAndInHouseTradingFlowsList(clientName)
              .then((webAndInHouseClientTradingFlows) => {
                  this.setState({webAndInHouseClientTradingFlows: webAndInHouseClientTradingFlows})
              }).finally(() => this.setState({loading: false}));
          this.setState({loading: true});
          caspianApiService.getOTCAndOthersTradingFlowsList(clientName)
              .then((OTCAndOthersClientTradingFlows) => {
                  this.setState({OTCAndOthersClientTradingFlows: OTCAndOthersClientTradingFlows})
              }).finally(() => this.setState({loading: false}));
      }

  }
}

ClientTradingFlowsTable.propTypes = {
  readOnly: PropTypes.bool,
  clientName: PropTypes.string
};

export default ClientTradingFlowsTable
