import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'

class Loader extends React.Component {
  render () {
    if (!this.props.loading) {
      return null
    }
    return (
      <div className="loader">
        <Spinner name="ball-beat" />
      </div>
    )
  }
}

Loader.propTypes = {
  loading: PropTypes.bool
}

export default Loader
