import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';

import _ from 'lodash';

import UserTable from './UsersTable';
import AddUserForm from './AddUserForm';

import caspianApiService from '../../services/CaspianApiService';
import eventsService from '../../services/event-system/EventsClient.js';
import utils from "../../utils";

// var usersData = [
//     {
//       "activated": true,
//       "authorities": [
//         "string"
//       ],
//       "clientName": "string",
//       "companyRole": "string",
//       "createdBy": "string",
//       "createdDate": "2018-05-21T10:01:42.444Z",
//       "email": "string",
//       "firstName": "string",
//       "id": 20,
//       "imageUrl": "string",
//       "langKey": "string",
//       "lastModifiedBy": "string",
//       "lastModifiedDate": "2018-05-21T10:01:42.444Z",
//       "lastName": "string",
//       "login": "string",
//       "resetPasswordLink": "string",
//       "sendActivationEMail": true,
//       "status": "string",
//       "twoFactorAuthKey": "string",
//       "twoFactorAuthTemporaryKey": "string"
//     },
//     {
//         "activated": true,
//         "authorities": [
//           "string"
//         ],
//         "clientName": "string",
//         "companyRole": "string",
//         "createdBy": "string",
//         "createdDate": "2018-05-21T10:01:42.444Z",
//         "email": "string",
//         "firstName": "string",
//         "id": 10,
//         "imageUrl": "string",
//         "langKey": "string",
//         "lastModifiedBy": "string",
//         "lastModifiedDate": "2018-05-21T10:01:42.444Z",
//         "lastName": "string",
//         "login": "string",
//         "resetPasswordLink": "string",
//         "sendActivationEMail": true,
//         "status": "string",
//         "twoFactorAuthKey": "string",
//         "twoFactorAuthTemporaryKey": "string"
//       }
//   ];

class UserTableCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            usersList: [],
            newUser: null,
            loading: false,
            error: '',
            clientName: this.props.clientName,
            oldClientName: this.props.clientName,
        };

        this.handleOnLoading = this.handleOnLoading.bind(this);
      }

      componentWillMount() {

          if (this.props.clientName && this.props.clientName !== 'View As') {
              this.setState({loading: true});
              caspianApiService.getUsersForAClient(this.props.clientName)
                  .then((usersList) => {
                      this.setState({
                        usersList: usersList,
                        clientName: this.props.clientName,
                        oldClientName: this.state.clientName
                      })
                  }).catch((e)=>{
                  this.setState({
                      usersList: [],
                      clientName: this.props.clientName,
                      oldClientName: this.state.clientName
                  });
              }).finally(() => this.setState({loading: false}));
          }

      }

      componentWillReceiveProps(nextProps) {
        if ((utils.isUserAdmin() || utils.isUserAccountManager()) && this.props.clientName !== nextProps.clientName) {
            this.setState({loading: true});
            caspianApiService.getUsersForAClient(nextProps.clientName)
                  .then((usersList) => {
                      this.setState({
                        usersList: usersList,
                        clientName: nextProps.clientName,
                        oldClientName: this.state.clientName
                      })
                  }).catch((e)=>{
                this.setState({
                    usersList: [],
                    clientName: nextProps.clientName,
                    oldClientName: this.state.clientName
                });
            }).finally(() => this.setState({loading: false}));
          }
      }

    componentDidMount() {
        this.handleOnLoading(this.state.loading);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.loading !== this.state.loading) {
            this.handleOnLoading(this.state.loading);
        }
    }

    handleOnLoading = (isLoading) => {
        //notify parent that loading is finished
        if(false === isLoading) {
            this.props.onLoading('UsersTableCard', false);
        }
    };

      render(){
        return (
            <div>
                <Card className="card-stats card-raised" style={{visibility: this.props.visible ? 'visible' : 'hidden'}}>
                    <CardHeader>
                        {/* <Alert color="warning">Warning! This panel will become available when all the tickets from the initial setup are procesed.</Alert> */}
                        <h4>Users</h4>
                    </CardHeader>
                    <CardBody>
                        <UserTable
                            clientName={this.state.clientName}
                            readOnly={this.props.readOnly}
                            readOnlyActions={this.props.readOnlyActions}
                            readOnlyTableFields={this.props.readOnlyTableFields}
                            data={this.state.usersList}
                            handleCancelTicketFn = { (user) => {
                                let updatedUsersArray = [...this.state.usersList]
                                if(user.deleted === true) {
                                    _.remove(updatedUsersArray, { 'id': user.id })
                                } else {
                                    let oldUserIndex = _.findIndex(updatedUsersArray, { 'id': user.id })
                                    updatedUsersArray[oldUserIndex] = user
                                }

                                eventsService.emit('UPDATE_TICKETS_LIST', {})

                                this.setState({
                                    ...this.state,
                                    usersList: updatedUsersArray
                                })
                            }}
                            handleClearEditFn = { (oldUserValue) => {
                                const updatedUsersArray = [...this.state.usersList]
                                let userIndex = _.findIndex(updatedUsersArray, { 'id': oldUserValue.id })
                                updatedUsersArray[userIndex] = oldUserValue

                                eventsService.emit('UPDATE_TICKETS_LIST', {})

                                this.setState({
                                    ...this.state,
                                    usersList: updatedUsersArray
                                })
                            }}
                            handleSaveUserFn = { (user) => {
                                const updatedUsersArray = [...this.state.usersList]
                                let oldUserIndex = _.findIndex(updatedUsersArray, { 'id': user.id })
                                updatedUsersArray[oldUserIndex] = user

                                eventsService.emit('UPDATE_TICKETS_LIST', {})

                                this.setState({
                                    ...this.state,
                                    usersList: updatedUsersArray
                                })
                            }} />
                    </CardBody>
                    <CardFooter>
                        <AddUserForm
                            clientName={this.state.clientName}
                            readOnly={this.props.readOnly}
                            handleSaveUserFn = { (user) => {
                              let usersData = this.state.usersList

                              user.idx = this.state.usersList.length
                              usersData.push(user)
                              this.setState({userList: usersData})
                            }} />
                    </CardFooter>
                </Card>
            </div>
        )
      }
}


UserTableCard.propTypes = {
    clientName: PropTypes.string,
    visible: PropTypes.bool
};

export default UserTableCard;
