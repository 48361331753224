import Dashboard from '../views/Dashboard/Dashboard.jsx'
import DashboardResources from '../views/Dashboard/DashboardResources.jsx'
import AccountSettings from '../views/AccountSettings/AccountSettings'
import DocumentationContainer from '../views/StaticPages/DocumentationContainer'
import Tools from '../views/StaticPages/Tools'
import EnvironmentContainer from '../views/StaticPages/EnvironmentContainer'
import utils from '../utils'

const dashRoutes = [
  {path: '/dashboard/resources', name: 'Resources', icon: 'design_app', component: DashboardResources},
  {path: '/dashboard/settings', name: 'Account settings', icon: 'design_app', component: AccountSettings},
  {path: '/dashboard/home', name: 'Dashboard', invisible: true, component: Dashboard},
  {path: '/dashboard/tools', name: 'Tools', invisible: true, component: Tools},
  {path: '/dashboard/documentation/:documentationName', invisible: true, component: DocumentationContainer},
  {path: '/dashboard/environments/:environment/:environment', invisible: true, component: EnvironmentContainer},
  {redirect: true, path: '/dashboard', pathTo: '/dashboard/home', name: 'Dashboard'}
]

export const mapDashRoutesPathToName = utils.createRoutesNameMap(dashRoutes)

export default dashRoutes
