const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordMediumStrengthRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})')
const phoneNumberRegex = /^([+])([0-9])*$/

const isValidEmail = (email) => {
  return !!(emailRex.test(email))
}

const isValidPassword = (password) => {
  return !!(passwordMediumStrengthRegex.test(password))
}

const isValidPhoneNumber = (number) => {
  return !!(phoneNumberRegex.test(number))
}

export default {
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber
}
