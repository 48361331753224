import React from 'react'
import PropTypes from 'prop-types'
import caspianApiService from '../../services/CaspianApiService'

import TFAuth from '../TFAuth/TFAuth.jsx'

class ChangePasswordTFAuth extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  };

  handleSubmit (digitCode) {
    caspianApiService.finishChangePassword(
      digitCode,
      this.props.email,
      this.props.newPassword,
      this.props.rememberMe)
      .then(() => {
        this.props.onVerifiedListenerFn()
      })
      .catch((err) => {
        this.setState({ error: err.errorMessage })
      })
  }

  render () {
    return (
      <TFAuth
        title = "2-step verification"
        qrCodeData = {this.props.qrCodeData}
        secretKey = {this.props.secretKey}
        errorMessage = {this.state.error}
        handleSubmitFn = {this.handleSubmit} />
    )
  }
}

ChangePasswordTFAuth.propTypes = {
  newPassword: PropTypes.string.isRequired,
  resetKey: PropTypes.string.isRequired,
  qrCodeData: PropTypes.string,
  onVerifiedListenerFn: PropTypes.func.isRequired
}

export default ChangePasswordTFAuth
