import React from 'react'
import Iframe from 'react-iframe'
import utils from '../../utils'
import { Redirect } from 'react-router-dom'

class Tools extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      environment: 'CASPIAN',
      environmentsUrls: {
        'CASPIAN': '/dashboard/environments/caspian/caspian'
      }
    }

    this.environmentClicked = this.environmentClicked.bind(this)
  }

  environmentClicked (event) {
    this.setState({ environment: event.target.innerText })
  }

  render () {
    if (!utils.userIsAuthenticated()) {
      return <Redirect to={'/login'}/>
    } else if (!utils.isUserAdmin()) {
      return <Redirect to={'/dashboard'}/>
    }

    return (
      <div className='environmentAndIframeWrapper'>
        <div className='environmentsWrapper'>
          <h5 className='environmentsText'>Environment</h5>
          <ul className='environments'>
            <li className={this.state.environment === 'CASPIAN' ? 'liActive' : ''} onClick={this.environmentClicked}>CASPIAN</li>
          </ul>
        </div>
        <div className='iframeWrapper'>
          {
            this.state.environment !== ''
              ? <Iframe url={this.state.environmentsUrls[this.state.environment]}
                width='80%'
                height='800px'
                display='initial'
                position='relative'
                className='iframe'/>
              : ''
          }
        </div>
      </div>
    )
  }
}

export default Tools
