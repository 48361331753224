import React from 'react'
import Loader from '../../components/Loader/Loader'
import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import PropTypes from 'prop-types'
import {Button, Col, Row} from 'reactstrap'
import Select from 'react-select'
import caspianApiService from '../../services/CaspianApiService'

const allValues = {value: 'all', label: 'All Values'}

const firstNameFormater = (cell) => {
  let activeClass = 'active'

  return (
      <div>
        <span className={'bullet ' + activeClass}></span>
        <span>{cell}</span>
      </div>
  )
}

const apiSubkeyFormatter = (cell) => {
  return (
      <div>
        {
          cell === null ?
          <div className='disable-container'>
            <span className='disable-sign'>x</span>
          </div>
                        :
          <span>{cell}</span>
        }
      </div>
  )
}

class KeysTbl extends React.Component {

  constructor (props) {
    super(props)

    let userFilteredData = this.prepareTableData(this.props.data);
    let rowWithRag = userFilteredData.find((row) => row.rag)

    this.state = {
      userData: this.props.data,
      userFilteredData: userFilteredData,
      rag: rowWithRag ? rowWithRag.rag : '',
      loading: false,
      editedKeys: [],
      exchangeList: [],
      saveButtonDisabled: true,
      fundFilter: allValues,
      exchangeFilter: allValues,
    }

    this.ipStringNumberRegex = '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])'
    this.baseStringRegex = '-ccon-proxy-[a-z0-9_]+-[1-9]+\\.?[a-z0-9.]*\\s(?:' + this.ipStringNumberRegex
        + '(\\.(?!$)|$)){3}(?:' + this.ipStringNumberRegex + '){1}'
    this.proxyUsRegex = new RegExp('us' + this.baseStringRegex)
    this.proxyHkRegex = new RegExp('hk' + this.baseStringRegex)
    this.drcspRegex = new RegExp('drcsp' + this.baseStringRegex)

    this.filters = {}
    this.filters['fundFilter'] = allValues
    this.filters['exchangeFilter'] = allValues

    this.titleStyle = {
      'paddingRight': '8px'
    }

    this.afterSavecell = this.afterSavecell.bind(this)
  };

  componentWillMount() {
    caspianApiService.getAllExchanges().then(r => {
      if (r) {
        this.setState({
                        exchangeList: r.map(ex => {
                          return {
                            value: ex.description,
                            label: ex.description,
                          }
                        })
                      })
      }
    });
  };

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.clientName !== this.props.clientName) {
      let userFilteredData = this.prepareTableData(this.props.data);
      let rowWithRag = userFilteredData.find((row) => row.rag)

      this.setState({
                      saveButtonDisabled: true,
                      rag: rowWithRag ? rowWithRag.rag : '',
                    }, () => {
        this.updateKeysFilter(['exchangeFilter', 'fundFilter'])(allValues);
      })
    }
    if (nextProps.data) {
      let userFilteredData = this.prepareTableData(nextProps.data);
      let rowWithRag = userFilteredData.find((row) => row.rag)

      this.setState({
                      rag: rowWithRag ? rowWithRag.rag : '',
                      userData: nextProps.data,
                      editedKeys: []
                    }, () => {
        this.updateKeysFilter()();
      })
    }
  };

  columnHeaderFormatter (text) {
    return (
        <div>
          <span style={this.titleStyle}>{text}</span>
          <i className="cas-icon cas-edit-75"></i>
        </div>

    )
  };

  prepareFundOptions () {
    let {clientFunds} = this.props
    let fundOptions = []

    clientFunds.forEach((fund,) => {
      fundOptions.push({value: fund.name, label: fund.name})
    })

    return fundOptions
  }

  preparedColumnsConfig = () => {
    let {userIsAdmin} = this.props

    if (userIsAdmin) {
      return [
        {
          dataField: 'fundName',
          text: this.columnHeaderFormatter('Fund'),
          editor: {
            type: Type.SELECT,
            options: this.prepareFundOptions()
          },
          editable: true,
          headerClasses: 'w-10 user-table-header normal-column',
          classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
        },

        {
          dataField: 'exchange',
          text: 'Exchange',
          editable: false,
          headerClasses: 'w-10 user-table-header small-column',
          classes: 'cursor-not-allowed'
        },

        {
          dataField: 'type',
          text: 'Type',
          editable: false,
          headerClasses: 'w-10 user-table-header small-column',
          classes: 'cursor-not-allowed',
        },

        {
          dataField: 'scope',
          text: 'Scope',
          editable: false,
          headerClasses: 'w-10 user-table-header big-column',
          classes: 'cursor-not-allowed'
        },

        {
          dataField: 'apiKey',
          text: 'API Key',
          editable: false,
          headerClasses: 'w-10 user-table-header small-column',
          classes: 'cursor-not-allowed small-column'
        },

        {
          dataField: 'apiSubkey',
          text: 'API Subkey',
          formatter: apiSubkeyFormatter,
          editable: false,
          headerClasses: 'w-10 user-table-header small-column',
          classes: 'cursor-not-allowed'
        },

        {
          dataField: 'apiSecret',
          text: 'API Secret',
          editable: false,
          headerClasses: 'w-10 user-table-header small-column',
          classes: 'cursor-not-allowed'
        },

        {
          dataField: 'account',
          text: this.columnHeaderFormatter('Account'),
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header',
          classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
        },

        {
          dataField: 'accountID',
          text: this.columnHeaderFormatter('AccountID'),
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header',
          classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
        },

        {
          dataField: 'proxy',
          text: this.columnHeaderFormatter('PROD Proxy'),
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header big-column',
          classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
        },

        {
          dataField: 'drProxy',
          text: this.columnHeaderFormatter('DR Proxy'),
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header big-column',
          classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
        },

        {
          dataField: 'clientDescription',
          text: 'Description',
          editable: false,
          headerClasses: 'w-10 user-table-header',
          classes: 'cursor-not-allowed'
        },

        {
          dataField: 'products',
          text: 'Asset Classes',
          editable: false,
          headerClasses: 'w-10 user-table-header small-column',
          classes: 'cursor-not-allowed'
        },

        {
          dataField: 'hasPair',
          text: '',
          formatter: this.hasPairFormatter,
          editable: false,
          headerClasses: 'user-table-header w-10',
          classes: 'cursor-not-allowed'
        },

        {
          dataField: 'expired',
          text: '',
          formatter: this.reuploadKeyFormatter,
          editable: false,
          headerClasses: 'user-table-header w-10',
          classes: 'cursor-not-allowed'
        },
      ]
    } else {
      return [
        {
          dataField: 'fundName',
          text: 'Fund',
          editable: false,
          headerClasses: 'user-table-header w-10'
        },

        {
          dataField: 'exchange',
          text: 'Exchange',
          formatter: firstNameFormater,
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header small-column',
        },

        {
          dataField: 'type',
          text: 'Type',
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header small-column',
        },

        {
          dataField: 'apiKey',
          text: 'API Key',
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header small-column',
        },

        {
          dataField: 'apiSubkey',
          text: 'API Subkey',
          formatter: apiSubkeyFormatter,
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header small-column',
        },

        {
          dataField: 'apiSecret',
          text: 'API Secret',
          // formatter: firstNameFormater,
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header small-column',
        },

        {
          dataField: 'account',
          text: 'Account',
          editable: !this.props.readOnly,
          headerClasses: 'w-10 user-table-header',
          // classes: !this.props.readOnly ? 'table-cell-editable' : 'cursor-not-allowed'
        },

        {
          dataField: 'clientDescription',
          text: 'Description',
          editable: false,
          headerClasses: 'w-10 user-table-header',
          // classes: 'cursor-not-allowed'
        },

        {
          dataField: 'products',
          text: 'Asset Classes',
          editable: false,
          headerClasses: 'w-10 user-table-header small-column',
          classes: 'cursor-not-allowed'
        },

        {
          dataField: 'hasPair',
          text: '',
          formatter: this.hasPairFormatter,
          editable: false,
          headerClasses: 'user-table-header w-10',
          // classes: 'cursor-not-allowed'
        },

        {
          dataField: 'expired',
          text: '',
          formatter: this.reuploadKeyFormatter,
          editable: false,
          headerClasses: 'user-table-header w-10',
          classes: 'cursor-not-allowed'
        }
      ]
    }
  }

  afterSavecell (oldValue, newValue, row) {

    let {userData} = this.state

    userData.forEach((obj) => {
      if (obj.id === row.id) {
        let index = userData.indexOf(obj)
        userData.splice(index, 1, row)
        this.setState({userData})
      }
    })

    if (newValue !== oldValue) {
      let editedKeys = this.state.editedKeys
      editedKeys.forEach((value, index) => {
        if (value.id === row.id) {
          editedKeys.splice(index, 1)
        }
      })
      editedKeys.push(row)
      this.setState({editedKeys, saveButtonDisabled: false})
    }
  };

  reuploadKeyFormatter = (cell, row) => {
    return (
        <div>
          {
            this.props.canUploadKeysValue === true && row.expired === true && <Button onClick={this.props.reuploadKey(row, this.props.data)} className="small-button btn-primary">Reupload Key</Button>
          }
        </div>
    )
  }

  hasPairFormatter = (cell, row) => {
    return (
        <div className="text-right">
          {
            cell === false && this.props.canUploadKeysValue === true && row.type.toLowerCase() === 'read' &&
            <Button className="small-button btn-primary" onClick={this.props.addPairKey(row, 'trade')}>Add Trade Key</Button>
          }
          {
            cell === false && this.props.canUploadKeysValue === true && row.type.toLowerCase() === 'trade' &&
            <Button className="small-button btn-primary" onClick={this.props.addPairKey(row, 'read')}>Add Read Key</Button>
          }
        </div>
    )
  }

  validateProxy = (keys) => {
    return keys
    .filter((key) => key.proxy)
    .every((key) => {
      if (key.exchange === 'Binance') {
        return this.proxyHkRegex.test(key.proxy)
      }
      return this.proxyUsRegex.test(key.proxy)
    })
  }

  validateDrProxy = (keys) => {
    return keys
    .filter((key) => key.drProxy)
    .every((key) => this.drcspRegex.test(key.drProxy))
  }

  saveChanges = () => {
    let {editedKeys} = this.state,
        {keyUpdateFn, notificationAlertHandler} = this.props,
        promises = []

    if (this.validateProxy(editedKeys) && this.validateDrProxy(editedKeys)) {
      editedKeys.forEach((value) => {
        promises.push(keyUpdateFn(value));
      })

      Promise.all(promises).then(() => {
        notificationAlertHandler('Update completed!', 'success')
        this.setState({editedKeys: []})
      }, () => {
        notificationAlertHandler('There was an error during Update. Please try again.', 'danger');
      })
    } else if (!this.validateProxy(editedKeys)) {
      notificationAlertHandler(`Please input a valid PROD Proxy machine name, having the following format: 
                                us-ccon-proxy-fundNameWithLowerCase-proxyNumber validIP, examples:
                                us-ccon-proxy-lucaci-1 34.197.232.79 or 
                                us-ccon-proxy-lucaci-1.awsLink 34.197.232.79 
                                For only Binance Exchange Keys please use the above format where 'us' it is replaced with 'hk' 
                                For example: hk-ccon-proxy-lucaci-1 34.197.232.79`,
                               'danger');
    } else if (!this.validateDrProxy(editedKeys)) {
      notificationAlertHandler(`Please input a valid DR Proxy machine name, having the following format: 
                                drcsp-ccon-proxy-fundNameWithLowerCase-proxyNumber validIP, examples: 
                                drcsp-ccon-proxy-lucaci-1 54.149.21.35 or 
                                drcsp-ccon-proxy-lucaci-1.awsLink 54.149.21.35`, 'danger');
    }
  }

  updateKeysFilter = (updatedFilter) => (value) => {
    let filters = {};
    filters['fundFilter'] = this.filters['fundFilter'];
    filters['exchangeFilter'] = this.filters['exchangeFilter'];
    if (updatedFilter) {
      if (Array.isArray(updatedFilter)) {
        updatedFilter.forEach((item) => {
          filters[item] = value
          this.filters[item] = value
        })
      } else if (value !== null) {
        filters[updatedFilter] = value;
        this.filters[updatedFilter] = value;
      } else {
        filters[updatedFilter] = allValues;
        this.filters[updatedFilter] = allValues;
      }
    }

    let result = this.state.userData.filter(key => {
      let pass = true;
      if (filters['fundFilter'].value !== 'all' && key.fundName !== filters['fundFilter'].value) {
        pass = false;
      }
      if (filters['exchangeFilter'].value !== 'all' && key.exchange !== filters['exchangeFilter'].value) {
        pass = false;
      }
      return pass;
    })

    result = this.prepareTableData(result);

    this.props.updateFundSelected(filters['fundFilter'].value)
    this.setState({fundFilter: filters['fundFilter'], exchangeFilter: filters['exchangeFilter'], userFilteredData: result})
  }

  prepareTableData = (userKeysData) => {
    const accountIDKeys = new Map()

    userKeysData.forEach((value) => {
      value['apiSecret'] = '********';

      if (value.accountID) {
        const keysCount = accountIDKeys.get(value.accountID)
        accountIDKeys.set(value.accountID, keysCount ? keysCount + 1 : 1)
      }
    });

    userKeysData.forEach((value) => {
      const count = accountIDKeys.get(value.accountID)
      if (count) {
        value['hasPair'] = count > 1
      }
    });
    return userKeysData;
  }

  render () {
    let {saveButtonDisabled, userFilteredData, userData} = this.state,
        {readOnly} = this.props
    const clientName = userData.length > 0 ? userData[0].client : ''
    const organization = userData.length > 0 ? userData[0].organization : ''
    const fundOptions = [allValues].concat(this.prepareFundOptions())
    const exchangeOptions = [allValues].concat(this.state.exchangeList)

    return (
        <div className="dashboard-table-wrapper keys-table">
          <Loader
              loading={this.state.loading}/>

          {
            this.props.userIsAdmin &&
            <div className='keys-admin-fields-container'>
              <Row>
                <Col md={6} className='keys-admin-field align-middle'>Client:</Col>
                <Col md={6} className='keys-admin-field align-middle'><span>{clientName}</span></Col>
              </Row>
              <Row>
                <Col md={6} className='keys-admin-field align-middle'>Organization:</Col>
                <Col md={6} className='keys-admin-field align-middle'><span>{organization}</span></Col>
              </Row>
              <Row>
                <Col md={6} className='keys-admin-field align-middle'>RAG:</Col>
                <Col md={6} className='keys-admin-field align-middle'><span>{this.state.rag}</span></Col>
              </Row>
              <Row>
                <Col md={6} style={{marginTop: '6px'}} className='keys-admin-field align-middle'>Fund:</Col>
                <Col md={6} className='align-middle'>
                  <Select className='w-100' value={this.state.fundFilter} id='fundDropdown' options={fundOptions}
                          onChange={this.updateKeysFilter('fundFilter')}/>
                </Col>
              </Row>
              <Row>
                <Col md={6} style={{marginTop: '6px'}} className='keys-admin-field align-middle'>Exchange:</Col>
                <Col md={6} className='align-middle'>
                  <Select className='w-100' value={this.state.exchangeFilter} id='exchangeDropdown' options={exchangeOptions}
                          onChange={this.updateKeysFilter('exchangeFilter')}/>
                </Col>
              </Row>
            </div>
          }

          <BootstrapTable
              hover
              condensed
              keyField='id'
              classes='table-responsive keys'
              rowStyle={{ fontSize: '10px' }}
              rowClasses={this.computeRowClass}
              bordered={false}
              data={userFilteredData}
              columns={this.preparedColumnsConfig()}
              cellEdit={cellEditFactory({
                                          mode: 'click',
                                          blurToSave: true,
                                          afterSaveCell: this.afterSavecell
                                        })}/>

          <div className="submit-button-container">
            {
              !readOnly &&
              <SubmitButton className="btn btn-primary btn-sm" type="submit" loading={this.state.loading}
                            disabled={saveButtonDisabled} onClick={this.saveChanges}>
                Save
              </SubmitButton>
            }
          </div>

        </div>
    )
  }

  computeRowClass = (row, rowIdx) => {
    if (row.hasPair) {
      if (row.type.toLowerCase() === 'read') {
        return 'has-pair-top'
      } else {
        return 'has-pair-bottom'
      }
    } else {
      return 'no-pair'
    }
  }

  createFundOptions () {
    let options = []
    options.push({
                   value: 'all',
                   label: 'All funds'
                 })
    options.push(this.props.clientFunds.map((fund) => {
      return {
        value: fund.name,
        label: fund.name,
      }
    }))
    this.setState({fundFilterOptions: options})
    return []
  }
}

KeysTbl.propTypes = {
  data: PropTypes.array,
  readOnly: PropTypes.bool,
  clientFunds: PropTypes.array,
  userIsAdmin: PropTypes.bool,
  clientName: PropTypes.string,
  keyUpdateFn: PropTypes.func,
  notificationAlertHandler: PropTypes.func,
  addPairKey: PropTypes.func,
  updateFundSelected : PropTypes.func
}

export default KeysTbl
