import React from 'react'
import ReactDOM from 'react-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from 'reactstrap'

import {Redirect} from 'react-router-dom'
import Loadable from 'react-loading-overlay'

import caspianApiService from '../../services/CaspianApiService'
import utils from '../../utils'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import UsersTableCard from './UsersTableCard'
import FundsTable from './FundsTable'
import OnboardingExchangeTable from './OnboardingExchangeTable'
import ExchangeWalletsCustodiansTable from './ExchangeWalletsCustodiansTable'
import ClientTradingFlowsTable from './ClientTradingFlowsTable'
import NotificationAlert from 'react-notification-alert'
import $ from 'jquery'
import MarketDataTable from './MarketDataTable'
import ClientsSidebar from '../../components/ClientsSidebar/ClientsSidebar'

class ClientInfo extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: utils.getUserData(),
      fundList: [],
      clientItems: [],
      visible: false,
      isDropdownOpen: false,
      wholeSelectedClient: null,
      selectedClient: 'View As',
      selectedClientItem: 'View As',
      additionalInformation: '',
      kycStatus: '',
      dataSubmitted: false,
      showCompleteOnboardingPopUp: false,
      showInitiatingFullSetup: false,
      fullSetupInitiated: false,
      showApproveApplicationPopUp: false,
      isMultipleClientsAccountManager: null,
      sidebarKey: 1,
      isInitiatingFullSetupLoading: false,
      isCompleteOnboardingLoading: false,
      isApproveApplicationLoading: false,
      loading: true
    };
    this.usersTableCardLoading = true;
    this.fundsTableLoading = true;
    this.onboardingExchangeTableLoading = true;
    this.exchangeWalletsCustodiansTableLoading = true;
    this.clientTradingFlowsTableLoading = true;
    this.marketDataTableLoading = true;
    this.smallerTableStyle = {
      width: '75%',
      margin: 'auto'
    };
    this.bindFunctions();
  }

  bindFunctions() {
    this.handleChange = this.handleChange.bind(this)
    this.saveAll = this.saveAll.bind(this)
    this.updateAdditionalInformation = this.updateAdditionalInformation.bind(this)
    this.submit = this.submit.bind(this);
    this.toggleCompleteOnboarding = this.toggleCompleteOnboarding.bind(this);
    this.toggleApproveApplication = this.toggleApproveApplication.bind(this)
    this.toggleInitiatingFullSetup = this.toggleInitiatingFullSetup.bind(this)
    this.approveApplication = this.approveApplication.bind(this)
    this.completeOnboardingForClient = this.completeOnboardingForClient.bind(this);
    this.showFailToCompleteOnboarding = this.showFailToCompleteOnboarding.bind(this);
    this.handleChildOnLoading = this.handleChildOnLoading.bind(this);
    this.startFullSetupForClient = this.startFullSetupForClient.bind(this);
    this.validateAndToggleFullSetup = this.validateAndToggleFullSetup.bind(this)
  }

  componentDidMount () {
    this.loadCurrentClient()
    if (utils.isUserAccountManager()) {
      if (this.state.user && Array.isArray(this.state.user.clientsNames)) {

        this.setState({isMultipleClientsAccountManager: true})
      } else {
        this.setState({isMultipleClientsAccountManager: false})
        let container = document.getElementById('content');
        let footer = document.getElementById('footer');

        container.style.width = '99%';
        container.style.transition ='none';
        footer.style.paddingLeft= '0 1%';
      }
      this.loadCurrentClient();

      if (this.state.selectedClient !== 'View As'){
        caspianApiService.getClientAdditionalInformation(this.state.selectedClient)
           .then(additionalInformation => {
             this.setState({ additionalInformation: additionalInformation });
           })
      }
    }
  }

  onClientChange = () => {
    this.setState({loading: true});
    this.usersTableCardLoading = true;
    this.fundsTableLoading = true;
    this.onboardingExchangeTableLoading = true;
    this.exchangeWalletsCustodiansTableLoading = true;
    this.clientTradingFlowsTableLoading = true;
    this.marketDataTableLoading = true;
    let clientName = JSON.parse(localStorage.getItem('activeClientName'));
    this.updateClientKycStatus(clientName);

    if (clientName !== 'View As') {
      caspianApiService.getClientAdditionalInformation(clientName)
        .then((additionalInformation) => {
          this.setState({
            additionalInformation: additionalInformation,
            selectedClient: clientName,
            visible: true
          });
        });
    } else {
      this.setState({
        selectedClient: clientName,
        visible: true
      });
    }
  };

  loadCurrentClient () {
    let clientName = JSON.parse(localStorage.getItem('activeClientName'));
    this.updateClientKycStatus(clientName);

    if (clientName !== 'View As') {
      caspianApiService.getClientAdditionalInformation(clientName)
        .then(additionalInformation => {
          this.setState({
            additionalInformation: additionalInformation,
            selectedClient: clientName,
            visible: true
          });
        });
    } else {
      this.setState({
        selectedClient: clientName,
        visible: true
      });
    }
  }

  updateClientKycStatus(clientName) {
    if (this.state.user !== null) {
      caspianApiService.getClientKycStatus(clientName)
        .then(kycStatus => {
          if (this.clientSidebarRef) {
            this.clientSidebarRef.fetchClientList();
          }

          if (kycStatus === "SUBMITTED_INITIAL_DATA" || kycStatus === "APPLICATION_APPROVED" || kycStatus === "ONBOARDING_COMPLETED") {
            this.setState({
              kycStatus: kycStatus,
              dataSubmitted: true
            });
          } else {
            this.setState({
              kycStatus: kycStatus,
              dataSubmitted: false
            });
          }
        })
    }
  }

  updateAdditionalInformation() {
    let client = {
        name: this.state.selectedClient,
        additionalInformation: this.state.additionalInformation
    }

    return caspianApiService.updateClientAdditionalInformation(client)
  }

  saveAll = async () => {
    let ok = true;
    let message = null;
    let showNotification = true;
    this.setState({loading: true});
    let fundSavedWithSuccess = await this.fundTable.save();
    if (fundSavedWithSuccess === false) {
      showNotification = false;
      let fundTableDomElement = ReactDOM.findDOMNode(this.fundTable);
      fundTableDomElement.scrollIntoView();
      this.setState({loading: false});

      return Promise.resolve(false);
    } else {
      let additionalInformationPromise = this.updateAdditionalInformation();
      let exchangesResponse = this.exchangeWalletsCustodians.save();
      let onboardingResponse = this.onboardingExchangeTable.save()
      let userResponse = this.usersTableCard.save()
      let clientFlowsResponse = this.clientTradingFlowsTable.save();
      let marketDataResponse = this.marketDataTable.save();

      return Promise.all([additionalInformationPromise, exchangesResponse,
        onboardingResponse, userResponse, clientFlowsResponse, marketDataResponse])
          .then(() => {
            if (ok && showNotification) {
              this.setState({loading: false});
              this.showNotification('Save completed!', 'success')

              return Promise.resolve(true)
            }
          }, (rej) => {
            ok = false;
            if (rej && rej.errorMessage) {
              message = rej.errorMessage
            }

            if (!ok && showNotification) {
              this.showNotification('There was an error during Save. ' + message ? message : 'Please try again.', 'danger')
              caspianApiService.sendSaveOrSubmitFailureEmail('Save', this.state.selectedClient);
              this.setState({loading: false});

              return Promise.resolve(false)
            }
          })
    }
  };

  approveApplication = () => {
    this.setState({isApproveApplicationLoading: true})
    caspianApiService.approveApplication(this.state.selectedClient)
      .then(() => {
        this.showNotification("Client's status was successfully updated!")
        this.loadCurrentClient()
        this.setState({ sidebarKey: this.state.sidebarKey + 1 })
      })
      .finally(() => {
        this.setState({isApproveApplicationLoading: false})
        this.toggleApproveApplication()
      })
  }

  showNotification(message, type = 'primary'){

    let options;
    options = {
      place: 'tc',
      message: (
        <div  style={{marginTop: 400 +'px !important;'}}>
          <div style={{marginTop: 400 +'px !important;'}}>
            { message }
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    }
    this.refs.notificationAlert.notificationAlert(options);
  }

  async submit() {
    let saveCompletedWithoutErrors = await this.saveAll()

    if (saveCompletedWithoutErrors) {
      caspianApiService.updateClientKycStatus(this.state.selectedClient)
        .then(() => {
          this.showNotification('Submit completed!', 'success');
          this.setState({
            dataSubmitted: true,
            kycStatus: 'SUBMITTED_INITIAL_DATA',
            sidebarKey: this.state.sidebarKey + 1
          });
          caspianApiService.sendSubmitSuccessEmail(this.state.selectedClient);
        }).catch(() => {
        this.showNotification('Could not submit. Please try again.', 'danger');
        caspianApiService.sendSaveOrSubmitFailureEmail('Submit', this.state.selectedClient);
      })
    }
  }

  handleChange(event) {
    this.setState({ additionalInformation: event.target.value });
  }

  jQueryHover() {
    $('.table-client-info > tbody > tr').hover(function () {
      $(this).find('td').each(function () {
        if ($(this).hasClass('table-cell-editable') || $(this).hasClass('table-cell-with-border')) {
          $(this).addClass('hover');
        }
      });
    }, function () {
      $(this).removeClass('hover');
    });
  }

  render () {
    if (!utils.userIsAuthenticated()) {
      return <Redirect to={'/login'}/>
    }

    if (!utils.isUserAdmin() && !utils.canViewOnboarding()) {
      if (utils.isOnboardingCompleted()) {
        return <Redirect to={'/dashboard'}/>
      }
    }

    this.jQueryHover();

    if (utils.isUserAdmin()) {
      let isAdminReadOnly = false

      if(utils.isUserAdminReadOnly()){
        isAdminReadOnly = true
      }

      return (
        <Loadable active={this.state.loading} spinner text="Loading...">
        <div className="full-page-content small">
          <ClientsSidebar ref={clientSiderbarRef => this.clientSidebarRef = clientSiderbarRef}
                          key={this.state.sidebarKey}
                          onClientChange={this.onClientChange}/>

          <Modal isOpen={this.state.showCompleteOnboardingPopUp} toggle={this.toggleCompleteOnboarding} className="center-loader">
            <Loadable active={this.state.isCompleteOnboardingLoading} spinner text="Please stand by ...">
              <ModalHeader toggle={this.toggle} className="text-center">Complete Onboarding Application</ModalHeader>
              <ModalBody>
                Please proceed once the setup is ready for the client and he can use the platform.
                The Account Holder will receive an email with this change.
                <br/>
                <br/>
                <div className="text-center confirm">Are you sure?</div>
              </ModalBody>
              <ModalFooter className="text-center popup-footer">
                <Button color="primary" onClick={this.completeOnboardingForClient}>Yes</Button>{'   '}
                <Button color="danger" onClick={this.toggleCompleteOnboarding}>No</Button>
              </ModalFooter>
            </Loadable>
          </Modal>

          <Modal isOpen={this.state.showInitiatingFullSetup} toggle={this.toggleInitiatingFullSetup} className="center-loader">
            <Loadable active={this.state.isInitiatingFullSetupLoading} spinner text="Please stand by ...">
              <ModalHeader toggle={this.toggle} className="text-center">Initiating Full Setup</ModalHeader>
              <ModalBody>
                This will trigger creation of groups/funds and users in all selected products. It will take a while and the state
                for each particular config will be visible through each button color (e.g. for each users buttons will turn into green).
                If you spot any problems or delays please check the Account in Salesforce, you will find there all Cases generated by the Setup.
                <br/>
                <br/>
                <div className="text-center confirm">Do you want to proceed?</div>
              </ModalBody>
              <ModalFooter className="text-center popup-footer">
                <Button color="primary" onClick={this.startFullSetupForClient}>Yes</Button>{'   '}
                <Button color="danger" onClick={this.toggleInitiatingFullSetup}>No</Button>
              </ModalFooter>
            </Loadable>
          </Modal>

          <Modal isOpen={this.state.showApproveApplicationPopUp} toggle={this.toggleApproveApplication} className="center-loader">
            <Loadable active={this.state.isApproveApplicationLoading} spinner text="Please stand by ...">
              <ModalHeader toggle={this.toggle} className="text-center">Approve Application</ModalHeader>
              <ModalBody>
                This will change the status of the client to Application Approved, meaning all it's users will be
                activated and they will have access to Resources and API tabs.
                <br/>
                <br/>
                <div className="text-center confirm">Are you sure?</div>
              </ModalBody>
              <ModalFooter className="text-center popup-footer">
                <Button color="primary" onClick={this.approveApplication}>Yes</Button>{'   '}
                <Button color="danger" onClick={this.toggleApproveApplication}>No</Button>
              </ModalFooter>
            </Loadable>
          </Modal>

          <div id={'content'} className="content" style={{'paddingTop': '10px'}}>
            <Row style={{ textAlign: 'right'}}>
              <Col>
                {this.state.visible && (this.state.kycStatus === 'APPLICATION_APPROVED' || this.state.kycStatus === 'ONBOARDING_COMPLETED') &&
                  <UncontrolledTooltip placement="top" target='approveApplicationButton'>The application is already approved</UncontrolledTooltip>
                }
                {this.state.visible && (this.state.kycStatus !== 'APPLICATION_APPROVED' && this.state.kycStatus !== 'ONBOARDING_COMPLETED') && this.state.kycStatus !== 'SUBMITTED_INITIAL_DATA' &&
                <UncontrolledTooltip placement="top" target='approveApplicationButton'>The client didn't submit the initial data yet</UncontrolledTooltip>
                }
                {this.state.visible &&
                <span id='approveApplicationButton'
                      className={this.state.kycStatus !== 'SUBMITTED_INITIAL_DATA' ? 'cursor-not-allowed' : ''}>
                              <Button disabled={this.state.kycStatus !== 'SUBMITTED_INITIAL_DATA'}
                                      className="btn btn-smaller-padding btn-primary"
                                      onClick={this.toggleApproveApplication}>Approve Application</Button>
                                <br/>
                            </span>
                }
              </Col>
            </Row>
            <NotificationAlert ref="notificationAlert"/>
            <Row>
              <Col xs={12} md={12}>
                <UsersTableCard clientName={this.state.selectedClient}
                                visible={this.state.visible}
                                readOnly={isAdminReadOnly}
                                ref={utc => this.usersTableCard = utc}
                                kycStatus={this.state.kycStatus}
                                handleOnLoading = {this.handleChildOnLoading}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FundsTable readOnly={isAdminReadOnly}
                            clientName={this.state.selectedClient}
                            data={this.state.fundList}
                            ref={ft => this.fundTable = ft}
                            visible={this.state.visible}
                            kycStatus={this.state.kycStatus}
                            handleOnLoading = {this.handleChildOnLoading}/>
              </Col>
            </Row>
            <Row style={this.smallerTableStyle}>
              <Col xs={12} md={12}>
                <OnboardingExchangeTable clientName={this.state.selectedClient}
                                         readOnly={isAdminReadOnly}
                                         visible={this.state.visible} ref={oet => this.onboardingExchangeTable = oet}
                                         handleOnLoading = {this.handleChildOnLoading}/>
              </Col>
            </Row>
            {utils.isUserAdmin() &&
            <Row style={this.smallerTableStyle}>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody className={"text-center " + (this.state.kycStatus !== 'APPLICATION_APPROVED' ? 'cursor-not-allowed' : '')}>
                    {
                      this.shouldShowFullSetupTooltip() &&
                      <UncontrolledTooltip placement="top" target='startFullClientSetupButton'>
                        {this.fullSetupTooltipContent()}
                      </UncontrolledTooltip>
                    }
                    <span id='startFullClientSetupButton'>
                            <Button className="btn btn-lg"
                                    color={this.fullSetupButtonColor()}
                                    disabled={this.fullSetupIsDisabled(isAdminReadOnly)}
                                    onClick={this.validateAndToggleFullSetup}>
                              Start Full Client Setup
                            </Button>
                            </span>
                  </CardBody>
                </Card>
              </Col>
            </Row>}
            <Row style={this.smallerTableStyle}>
              <Col xs={12} md={12}>
                <ExchangeWalletsCustodiansTable visible={this.state.visible} readOnly={isAdminReadOnly} clientName={this.state.selectedClient}
                                                ref={ewct => this.exchangeWalletsCustodians = ewct}
                                                handleOnLoading = {this.handleChildOnLoading}/>
              </Col>
            </Row>
            <Row style={this.smallerTableStyle}>
                <Col xs={12} md={12}>
                  <ClientTradingFlowsTable clientName={this.state.selectedClient}
                                           visible={this.state.visible}
                                           readOnly={isAdminReadOnly}
                                           ref={ctft => this.clientTradingFlowsTable = ctft}
                                           handleOnLoading = {this.handleChildOnLoading}/>
                </Col>
            </Row>
            <Row style={this.smallerTableStyle}>
              <Col xs={12} md={12}>
                <MarketDataTable visible={this.state.visible} readOnly={isAdminReadOnly} clientName={this.state.selectedClient}
                                 ref={mData => this.marketDataTable = mData}
                                 handleOnLoading = {this.handleChildOnLoading}/>
              </Col>
            </Row>
            <Row>
                        <textarea placeholder="Additional info"
                                  className="client-info-textarea"
                                  readOnly={isAdminReadOnly}
                                  hidden={!this.state.visible}
                                  value={this.state.additionalInformation}
                                  onChange={this.handleChange}/>
            </Row>
            <Row>
              <Col>
                {this.state.visible && !isAdminReadOnly &&
                <Card>
                  <CardBody className="text-center">

                    <Button className="btn btn-smaller-padding btn-primary" onClick={this.saveAll}>Save</Button>

                    {this.state.dataSubmitted &&
                    <div className="text-center">
                      <i className="now-ui-icons loader_refresh spin"></i>
                      <span className="status">
                                        Client submitted the Onboarding info
                                        </span>
                    </div>
                    }
                  </CardBody>
                </Card>
                }
              </Col>
            </Row>
            {this.state.visible &&
            <Row>
              <Col>
                <Card>
                  <CardBody className={"text-center " + (this.state.kycStatus !== 'APPLICATION_APPROVED' ? 'cursor-not-allowed' : '')}>
                    {this.state.kycStatus !== 'APPLICATION_APPROVED' &&
                    <UncontrolledTooltip placement="top" target='completeOnboardingButton'>
                      {this.state.kycStatus !== 'ONBOARDING_COMPLETED' ? 'Please approve application first!' : 'The onboarding is already completed'}
                    </UncontrolledTooltip>
                    }
                    <span id='completeOnboardingButton'>
                            <Button className="btn btn-lg"
                                    color={this.state.kycStatus === 'APPLICATION_APPROVED' ? 'success' : 'secondary'}
                                    disabled={this.completeOnboardingIsDisabled(isAdminReadOnly)}
                                    onClick={this.toggleCompleteOnboarding}>
                              Complete Onboarding Application
                            </Button>
                            </span>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            }
          </div>
          <div style={{clear: 'both'}}> </div>
        </div>
       </Loadable>
      )
    }
    else {
      if (!utils.isUserAccountManager()) {
        return <Redirect to={'/dashboard/resources'}/>
      }
    }

    if (!utils.isKYCCompleted() && !utils.isSubmittedInitialData() && !utils.isApplicationApproved() && !utils.isOnboardingCompleted()) {
      return <Redirect to={'/kyc'}/>
    }

    return (
      <Loadable active={this.state.loading} spinner text="Loading...">
      <div className="full-page-content small">

        {
          this.state.isMultipleClientsAccountManager && this.state.isMultipleClientsAccountManager === true ?
            <ClientsSidebar key={this.state.sidebarKey} isMultipleClientsAccountManager={true}
                            user={this.state.user} onClientChange={this.onClientChange}/>
            :
            ''
        }


        <NotificationAlert ref="notificationAlert"/>

        <div id={'content'} className="content" style={{'paddingTop': '35px'}}>
          <Row>
            <Col xs={12} md={12}>
              <UsersTableCard  visible={true}
                               readOnly={this.state.dataSubmitted}
                               ref={utc => this.usersTableCard = utc}
                               isMultipleClientsAccountManager={this.state.isMultipleClientsAccountManager}
                               clientName={this.state.selectedClient}
                               handleOnLoading = {this.handleChildOnLoading}/>
            </Col>
          </Row>
          <Row>
            <Col><FundsTable data={this.state.fundList}
                          readOnly={this.state.dataSubmitted}
                          ref={ft => this.fundTable = ft} visible={true}
                          clientName={this.state.selectedClient}
                          handleOnLoading = {this.handleChildOnLoading}/>
            </Col>
          </Row>
          <Row style={this.smallerTableStyle}>
            <Col xs={12} md={12}>
              <OnboardingExchangeTable visible={true}
                                       readOnly={this.state.dataSubmitted}
                                       ref={oet => this.onboardingExchangeTable = oet}
                                       clientName={this.state.selectedClient}
                                       handleOnLoading = {this.handleChildOnLoading}/>
            </Col>
          </Row>
          <Row style={this.smallerTableStyle}>
            <Col xs={12} md={12}>
              <ExchangeWalletsCustodiansTable visible={true}
                                              readOnly={this.state.dataSubmitted}
                                              ref={ewct => this.exchangeWalletsCustodians = ewct}
                                              clientName={this.state.selectedClient}
                                              handleOnLoading = {this.handleChildOnLoading}/>
            </Col>
          </Row>
          <Row style={this.smallerTableStyle}>
              <Col xs={12} md={12}>
                <ClientTradingFlowsTable visible={true}
                                         readOnly={this.state.dataSubmitted}
                                         ref={ctft => this.clientTradingFlowsTable = ctft}
                                         clientName={this.state.selectedClient}
                                         handleOnLoading = {this.handleChildOnLoading}/>
              </Col>
          </Row>
          <Row style={this.smallerTableStyle}>
            <Col xs={12} md={12}>
              <MarketDataTable visible={true} readOnly={this.state.dataSubmitted} clientName={this.state.selectedClient}
                               ref={mData => this.marketDataTable = mData}
                               handleOnLoading = {this.handleChildOnLoading}/>
            </Col>
          </Row>
          <Row>
                    <textarea placeholder="Additional info"
                              readOnly={this.state.dataSubmitted}
                              className={(this.state.dataSubmitted) ? 'client-info-textarea cursor-not-allowed' : 'client-info-textarea'}
                              value={this.state.additionalInformation}
                              onChange={this.handleChange}/>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody className="text-center">
                  {!this.state.dataSubmitted &&
                  <Button className="btn btn-smaller-padding btn-primary"
                          style={{ marginRight: '10px' }}
                          onClick={this.saveAll}>
                    Save
                  </Button>
                  }
                  <Button className="btn btn-smaller-padding btn-primary"
                          disabled={this.state.dataSubmitted}
                          onClick={this.submit}>
                    Submit Form
                  </Button>
                  {this.state.dataSubmitted && !utils.isUserAdmin() &&
                  <div className="text-center">
                                    <span className="status">
                                  Your request has been sent and it's being processed.
                                </span>
                  </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{clear: 'both'}}> </div>
      </div>
      </Loadable>
    )
  }

  fullSetupButtonColor() {
    if(this.state.kycStatus !== 'APPLICATION_APPROVED') return 'secondary';
    const client = this.findSelectedClient(this.state.selectedClient)

    if (this.state.fullSetupInitiated){
      return 'warning';
    }

    if (client && client.fullSetupState){
      if (client.fullSetupState === 'IN_PROGRESS')
        return 'warning';

      if (client.fullSetupState === 'ERROR')
        return 'danger';

      if (client.fullSetupState === 'DONE')
        return 'success';
    }
    return 'success';
  }

  noFundsExistingForClient(client) {
    return client && (!client.funds || client.funds.length === 0)
  }

  fullSetupTooltipContent() {
    if (this.state.kycStatus !== 'APPLICATION_APPROVED')
      return 'Button is enabled only if Client is in Application Approved status!';

    const client = this.findSelectedClient(this.state.selectedClient)

    if (this.noFundsExistingForClient(client)){
      return "This client has no created Funds!"
    }

    if (this.state.fullSetupInitiated){
      return 'Full Setup is in progress!';
    }

    if (client && client.fullSetupState){
      if (client.fullSetupState === 'IN_PROGRESS')
        return 'Full Setup is in progress!';

      if (client.fullSetupState === 'ERROR')
        return 'There was an error during the Full Setup! Please check logs!';

      if (client.fullSetupState === 'DONE')
        return 'Full Setup is done!';
    }
    return "UNKNOWN ERROR"
  }

  shouldShowFullSetupTooltip() {
    const client = this.findSelectedClient(this.state.selectedClient)
    return this.state.anyProcessAlreadyStarted || this.state.kycStatus !== 'APPLICATION_APPROVED'
        || (client && client.fullSetupState) || this.noFundsExistingForClient(client);
  }

  fullSetupIsDisabled(isAdminReadOnly) {
    if (this.state.selectedClient && this.state.selectedClient !== "View As"){
      const client = this.findSelectedClient(this.state.selectedClient)
      return this.state.kycStatus !== 'APPLICATION_APPROVED' || isAdminReadOnly
          || (client && client.fullSetupState) || this.noFundsExistingForClient(client)
          || this.checkForAnyStartedOnboardingProcess(client);
    }
    return false;
  }

  completeOnboardingIsDisabled(isAdminReadOnly){
    const client = this.findSelectedClient(this.state.selectedClient)
    return this.checkForAnyOngoingOnboardingProcess(client) || this.state.kycStatus !== 'APPLICATION_APPROVED' || isAdminReadOnly
  }

  checkForAnyOngoingOnboardingProcess(client) {
    return client && client.funds.some(fund => fund.tickets.some(ticket => ticket.status === "NEW" || ticket.status === "PENDING"))
  }

  checkForAnyStartedOnboardingProcess(client) {
    const onboardingTicket = (ticket) => ticket.type === "OEMS_BOOTSTRAP_SETUP" || ticket.type === "PMS_BOOTSTRAP_SETUP" || ticket.type === "OEMS_SETUP" || ticket.type === "LINK_SETUP";
    return client && client.funds.some(fund => fund.tickets.some(onboardingTicket))
  }

  toggleApproveApplication() {
    this.setState({showApproveApplicationPopUp: !this.state.showApproveApplicationPopUp})
  }

  toggleCompleteOnboarding() {
    this.setState({showCompleteOnboardingPopUp: !this.state.showCompleteOnboardingPopUp})
  }

  toggleInitiatingFullSetup() {
    this.setState({showInitiatingFullSetup: !this.state.showInitiatingFullSetup})
  }

  validateAndToggleFullSetup() {
    if (this.fundTable.validFundsForFullSetup()) {
      this.toggleInitiatingFullSetup();
    } else {
      let fundTableDomElement = ReactDOM.findDOMNode(this.fundTable);
      fundTableDomElement.scrollIntoView();
    }
  }

  findSelectedClient(selectedClientName) {
    const allStoredClientsRaw = localStorage.getItem("storedClientsList")
    if (allStoredClientsRaw) {
      const allStoredClients = JSON.parse(allStoredClientsRaw)
      return allStoredClients.find(client => client.name === selectedClientName)
    }
    return undefined
  }

  async startFullSetupForClient() {
    this.setState({isInitiatingFullSetupLoading: true})
    const clientId = this.findSelectedClient(this.state.selectedClient).id;
    if (!clientId) {
      return
    }

    let fundSavedWithSuccess = await this.fundTable.saveWithoutValidation();
    if (fundSavedWithSuccess === false) {
      this.setState({isInitiatingFullSetupLoading: false})
      this.showFailToInitiateFullSetup()
      return Promise.resolve(false);
    }

    caspianApiService.initiateFullSetup(clientId)
                     .then(() => {
                       this.toggleInitiatingFullSetup()
                       this.showNotification("Client's full setup has started!")
                       this.loadCurrentClient()
                       this.setState({fullSetupInitiated: true})
                     }, () => {
                       this.showFailToInitiateFullSetup()
                     }, () => {
                       this.showFailToInitiateFullSetup()
                     })
                     .finally(() => {
                       this.setState({isInitiatingFullSetupLoading: false})
                     })
  }

  completeOnboardingForClient() {
    this.setState({isCompleteOnboardingLoading: true})
    caspianApiService.completeOndoardingForClient(this.state.selectedClient)
      .then(() => {
        this.toggleCompleteOnboarding()
        this.showNotification("Client's status was successfully updated!")
        this.loadCurrentClient()
        this.setState({ sidebarKey: this.state.sidebarKey + 1 })
      }, () => {
        this.showFailToCompleteOnboarding()
      }, () => {
        this.showFailToCompleteOnboarding()
      })
      .finally(() => {
        this.setState({isCompleteOnboardingLoading: false})
      })
  }

  showFailToCompleteOnboarding () {
    this.toggleCompleteOnboarding()
    this.showNotification("Client's status could not be updated!", 'danger')
  }

  showFailToInitiateFullSetup () {
    this.toggleInitiatingFullSetup()
    this.showNotification("Initiating client initial full setup has failed!", 'danger')
  }

  handleChildOnLoading = (childName, isLoading) =>  {

    // console.log(childName + " notifies onboarding with loading: " + isLoading);
      switch (childName) {
      case 'UsersTableCard' : this.usersTableCardLoading = isLoading;
        break;
      case 'FundsTable' : this.fundsTableLoading = isLoading;
        break;
      case 'OnboardingExchangeTable' : this.onboardingExchangeTableLoading = isLoading;
        break;
      case 'ExchangeWalletsCustodiansTable' : this.exchangeWalletsCustodiansTableLoading = isLoading;
        break;
      case 'ClientTradingFlowsTable' : this.clientTradingFlowsTableLoading = isLoading;
        break;
      case 'MarketDataTable' : this.marketDataTableLoading = isLoading;
        break;
      default:
        console.log(childName + ' component loading state is ignored');
        break;
    }

    let loading = this.usersTableCardLoading || this.fundsTableLoading || this.onboardingExchangeTableLoading
        || this.exchangeWalletsCustodiansTableLoading || this.clientTradingFlowsTableLoading
        || this.marketDataTableLoading;
    if(false === loading && false !== this.state.loading) {
      //parent still loading if any child is loading
      this.setState({loading: false})
    }
  }
}

export default ClientInfo
