import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

const renderColumn = (column, columnCustomClass, index) => {
  return (
    <Col {...column.size} className={columnCustomClass} key={index}>
      {column.label}
    </Col>
  )
}

const TableHeader = ({ columns = [], customColumnClass, customRowClass }) => (
  <thead>
    <Row className={customRowClass}>
      {
        columns.map((column, index) => renderColumn(column, customColumnClass, index))
      }
    </Row>
  </thead>
)

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  customColumnClass: PropTypes.string,
  customRowClass: PropTypes.string
}

export default TableHeader
