import React from 'react';
import { Row, Col, Button, Input, Label,Alert } from 'reactstrap';
import {FormInputs} from '../../components';
import KycForm from './KycForm.jsx';

var requiredFiles = [
  {
    "label": "Valid government-issued photo identity document",
    "name" : "govIssuesId"
  },
  {
    "label": "Proof of permanent address within the last 3 months (eg bank statements, utility bills)",
    "name" : "proofOfResidence"
  },
  {
    "label": "Photo showing face and holding up identity document with a handwritten post-it note affixed to the identity document with the words “Caspian and Date of the Photo, e.g. Caspian May 23, 2018”",
    "name" : "photoPostIt"
  }
];

class KycIndividual extends React.Component{
    constructor(props){
        super(props);
        this.state={
            singleSelect: null,
            fundName: '',
            fundAdmin: '',
            address: '',
            countryRegion: '',
            phoneNo: '',
            totalValueAssets: '',
            projectedVolume: '',
            certificateOfIncorporation: null,
            vatDocs: null
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event){
        event.preventDefault();
        if(!this.state.certificateOfIncorporation){
            this.setState({error: 'Please upload the Certificate of incorporation'});
            return;
        }
        if(!this.state.vatDocs){
            this.setState({error: 'Please upload Tax/VAT ID Documentation'});
            return;
        }
        if(!this.state.fundName || !this.state.fundAdmin || !this.state.address ||
            !this.state.countryRegion || !this.state.phoneNo || !this.state.totalValueAssets || !this.state.projectedVolume){
            this.setState({error: 'Please complete all the fields'});
            return;
        }
        alert('submit');
    }

    handleSave(event){
        event.preventDefault();
        if(!this.state.certificateOfIncorporation){
            this.setState({error: 'Please upload the Certificate of incorporation'});
            return;
        }
        if(!this.state.vatDocs){
            this.setState({error: 'Please upload Tax/VAT ID Documentation'});
            return;
        }
        if(!this.state.fundName || !this.state.fundAdmin || !this.state.address ||
            !this.state.countryRegion || !this.state.phoneNo || !this.state.totalValueAssets || !this.state.projectedVolume){
            this.setState({error: 'Please complete all the fields'});
            return;
        }
        alert('save');
        this.setState({error: ''});
    }

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    }

    renderFormError() {
        return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
    }

    renderFilesForm() {
      return (
        requiredFiles.map(function (fileObj, index) {
          return (
            <Col md={6}>
              <Label>{fileObj.label}</Label>
              <Input type="file" name="{fileObj.name}" multiple/>
              <hr />
            </Col>
          )
        })
      )
    }

    render(){
        return (
            <div>
              <KycForm
                title="Add your personal details"
                description="As acustomed, we need to run verifications, please fill the following details">

                {this.renderFormError()}

                <FormInputs
                    ncols = {["col-md-6" , "col-md-6"]}
                    proprieties = {[
                        {
                            inputProps : {
                                type : "text",
                                placeholder: 'Full name',
                                name: "fullName",
                                onChange: this.handleChange

                            }
                        },
                        {
                            inputProps : {
                                type: "text",
                                placeholder : "Other names",
                                name: "otherNames",
                                onChange: this.handleChange

                            }
                        }
                    ]}
                />
                <FormInputs
                    ncols = {["col-md-6" , "col-md-3", "col-md-3"]}
                    proprieties = {[
                        {
                            inputProps : {
                                type : "text",
                                placeholder: "Nationality",
                                name: "nationality",
                                onChange: this.handleChange

                            }
                        },
                        {
                            inputProps : {
                                type : "text",
                                placeholder: "Place of Residence / Country",
                                name: "residenceCountry",
                                onChange: this.handleChange

                            }
                        },
                        {
                          inputProps: {
                            type: 'text',
                            placeholder: 'Email for official correspondence',
                            name: 'officialEmail',
                            onChange: this.handleChange

                          }
                        },
                    ]}
                />
{/*
                <FormInputs
                    ncols = {["col-md-6" , "col-md-6"]}
                    proprieties = {[
                        {
                            inputProps : {
                                type : "text",
                                placeholder: "Total value of assets under management",
                                name: "totalValueAUM",
                                onChange: this.handleChange
                            }
                        },
                        {
                            inputProps : {
                                type : "text",
                                placeholder: "Projected volume of transactions",
                                name: "projectedVolumeOfTransactions",
                                onChange: this.handleChange
                            }
                        }
                    ]}
                />
*/}

                <h4>Required Documents</h4>
                <Row className="justify-content-center">
                  {this.renderFilesForm()}
                </Row>
                <Row className="justify-content-center">
                  <Col md={3}>
                    <Button className="btn w-100" type="submit" onClick={this.handleSubmit}>Submit</Button>
                    <p>Your data will be submited to our staff and we will come back to you as soon as possible.</p>
                  </Col>
                </Row>

              </KycForm>
            </div>
        );
    }
}

export default KycIndividual;
