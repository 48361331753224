import loginBackground from './bg_login.jpg'
import lsegToraLogo from './logos_lseg-tora-logo-mix.svg'
import placeholder from './placeholder.jpg'
import userAvatar from './web_icons_main_app-icon-default.png'
import caspianStaffAvatar from './web_icons_main_app-icon-default.png'

export default {
  loginBackground,
  lsegToraLogo,
  placeholder,
  userAvatar,
  caspianStaffAvatar,
}
