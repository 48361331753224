import React from 'react'
import PropTypes from 'prop-types'

import { Badge } from 'reactstrap'

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'

import utils from '../../utils'

// const firstNameFormater = (cell, row) => {
//     if(row.status === "Active") {
//         return (
//             <span>
//                 <strong style={ { color: 'green' } }>A</strong> { cell }
//             </span>
//         )
//     }
//
//     return (
//         <span>
//             <strong>D</strong> { cell }
//         </span>
//     )
// }

const statusFormatter = (cell, row) => {
  let badgeClassName
  switch (row.status.toUpperCase()) {
    case 'NEW':
      badgeClassName = 'info'
      break

    case 'CANCELED':
      badgeClassName = 'secondary'
      break

    case 'PENDING':
      badgeClassName = 'warning'
      break

    case 'RESOLVED':
      badgeClassName = 'success'
      break

    case 'REJECTED':
      badgeClassName = 'danger'
      break

    case 'IN PROGRES':
      badgeClassName = 'warning'
      break
    default:
      badgeClassName = ''
  }

  return (
    <Badge color={badgeClassName}>{cell}</Badge>
  )
}

class TicketsTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      ticketsData: this.props.data,
      editableUserIndex: null,
      editableUser: null,
      loading: false,
      error: ''
    }
  }

  componentWillMount () {

  }

  componentWillReceiveProps (nextProps) {
    this.setState({ticketsData: nextProps.data})
  }

  actionsFormatter (cell, row, rowIndex, formatExtraData) {
    if (row.edited) {
      return (
        <a href="#" className="btn btn-primary" disabled={this.props.readOnly} onClick={this.onSaveClick}>Request</a>
      )
    }
    return null
  }

  render () {
    const ticketsColumnsConfig = [
      {
        dataField: 'id',
        text: 'Ticket No',
        headerClasses: 'w-10 ticket-table-header',
        sort: true
      },
      {
        dataField: 'description',
        headerClasses: 'ticket-table-header',
        text: 'Description'
      },
      {
        dataField: 'creationTime',
        text: 'Created',
        formatter: utils.dateFormater,
        headerClasses: 'w-10 ticket-table-header',
        sort: true
      },
      {
        dataField: 'updateTime',
        text: 'Updated',
        formatter: utils.dateFormater,
        headerClasses: 'w-10 ticket-table-header',
        sort: true
      },
      {
        dataField: 'status',
        text: 'Status',
        formatter: statusFormatter,
        headerClasses: 'w-10 ticket-table-header',
        sort: true
      }
    ]
    return (
      <BootstrapTable
        hover
        condensed
        keyField='id'
        classes="table-responsive"
        bordered={ false }
        data={ this.state.ticketsData }
        columns={ ticketsColumnsConfig }
        rowStyle={{ fontSize: '10px' }}
        pagination={ paginationFactory({
          sizePerPage: 10,
          hideSizePerPage: true,
          showTotal: true,
          firstPageText: 'First',
          prePageText: 'Back',
          nextPageText: 'Next',
          lastPageText: 'Last',
          nextPageTitle: 'First page',
          prePageTitle: 'Pre page',
          firstPageTitle: 'Next page',
          lastPageTitle: 'Last page'
        }) } />
    )
  }
}

TicketsTable.propTypes = {
  data: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
}

export default TicketsTable
