import React from 'react'
import PropTypes from 'prop-types'

import caspianApiService from '../../services/CaspianApiService'

import TFAuth from '../TFAuth/TFAuth.jsx'

class LoginTFAuth extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  };

  handleSubmit (digitCode) {
    caspianApiService.authenticateTwoFactor(
      digitCode,
      this.props.email,
      this.props.password,
      this.props.rememberMe)
      .then(() => {
        this.props.onVerifiedListenerFn()
      })
      .catch((err) => {
        this.setState({ error: err.errorMessage })
      })
  }

  render () {
    return (
      <TFAuth
        title = "2-step verification"
        errorMessage = {this.state.error}
        handleSubmitFn = {this.handleSubmit} />
    )
  }
}

LoginTFAuth.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  onVerifiedListenerFn: PropTypes.func.isRequired
}

export default LoginTFAuth
