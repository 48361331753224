import React from 'react'
import {
  Container, Row, Col
} from 'reactstrap'

import queryString from 'qs'
import _ from 'lodash'
import SweetAlert from 'react-bootstrap-sweetalert'

import caspianApiService from '../../services/CaspianApiService'

import bgImage from '../../assets/img/bg_login.jpg'

class ActivateAccount extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      alertMessage: '',
      accountActivationStatus: false,
      accessCode: '',
      clientName: ''
    }

    this.validationStatusAlert = this.validationStatusAlert.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
  }

  componentDidMount () {
    const values = queryString.parse(this.props.location.search.replace('?', ''))
    if (_.isUndefined(values.key) || values.key.length === 0) {
      this.setState({
        accountActivationStatus: false,
        alertMessage: 'This account activation code is not valid. If you think this is a mistake please contact caspian support at: support@caspian.tech'
      })
      this.validationStatusAlert()
    }

    this.setState({validationCode: values.key})
    caspianApiService.validateUserAccount(values.key)
      .then((response) => {
        this.setState({
          accountActivationStatus: true,
          alertMessage: 'Your Caspian account has been activated. You can now login using your credentials.'
        })
      })
      .catch((err) => {
        this.setState({
          accountActivationStatus: false,
          alertMessage: 'This account activation code is not valid. If you think this is a mistake please contact caspian support at: support@caspian.tech'
        })
      })
      .finally(() => {
        this.validationStatusAlert()
      })
  }

  validationStatusAlert () {
    this.setState({
      validationStatusAlert: (
        <SweetAlert
          style={{display: 'block', marginTop: '-100px'}}
          title="Caspian Account Validation"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}>
          <div>
            <div>{this.state.alertMessage}</div>
            <small>You will be redirected in a few seconds</small>
          </div>
        </SweetAlert>
      )
    })
    setTimeout(this.hideAlert, 5000)
  }

  hideAlert () {
    this.setState({
      validationStatusAlert: null
    })
    if (this.state.accountActivationStatus) {
      this.props.history.push('/login')
    } else {
      this.props.history.push('/get-in-touch')
    }
  }

  render () {
    return (
      <div className="create-account-page">
        <div className="full-page-content">
          {this.state.validationStatusAlert}
          <div>
            <Container>
              <Row className="justify-content-center">
                <Col lg={5} md={8} xs={12} className="mt-5">

                </Col>
                <Col lg={4} md={8} xs={12}>

                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="full-page-background" style={{backgroundImage: 'url(' + bgImage + ')' }}></div>
      </div>
    )
  }
}

export default ActivateAccount
