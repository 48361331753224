import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'

class Footer extends React.Component {
  render () {
    return (
      <div id={'footer'} className='footer-container'>
        Visit&nbsp;<span><a href="https://www.lseg.com/en/data-analytics/trading-solutions/multi-asset-trading/tora" className='custom-a' target="_blank">lseg.com/tora</a></span>&nbsp;to learn more about the modular LSEG TORA platform. © {1900 + (new Date()).getYear()} LSEG
      </div>
    )
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
}

export default Footer
